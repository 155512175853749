import React, {useState} from 'react';
import {GridRenderEditCellParams, GridRowId, GridRowModel, useGridApiContext} from '@mui/x-data-grid';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import {editRow} from './editHelper';
import EditMode from './EditMode';

interface FreeEditProps extends GridRenderEditCellParams {
    types: string[];
}
export default function FreeEdit(props: FreeEditProps) {
    const updateAble = (row: any) => {
        return !!props.types.find((element) => element === row['Sample Type'])
    }
    const [open, setOpen] = useState<boolean>(true)
    const [free, setFree] = useState<string>(props.value as string)
    const [mode, setMode] = useState<string>('currentOnly')
    const apiRef = useGridApiContext()
    const editable = updateAble(props.row)
    // console.log(`The field is ${props.field}`, props)


    const handleClose = async () => {
        // console.log('handleClose')
        await props.api.stopCellEditMode({id: props.id, field: props.field})
        setOpen(false)
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const formJson = Object.fromEntries((formData as any).entries())
        // console.log(`handleSubmit with keys ${Object.keys(formJson)}.`, formJson)
        // console.log(`handleSubmit with mode ${mode} and props.id ${props.id} `)
        await apiRef.current.setEditCellValue({id: props.id, field: props.field, value: formJson.fieldValue})
        await handleClose()
        if (mode === "typed") {
            // exclude 'fieldValue' and 'fieldEditMode' keys to get just the checked checkbox keys
            const keys = Object.keys(formJson).filter((k) => !k.startsWith('field'))
            if (keys.length > 0) {
                const rowsIds = apiRef.current.getAllRowIds()
                for (const id of rowsIds) {
                    let row = apiRef.current.getRow(id)
                    if (keys.find((k) => k === row['Sample Type'])) {
                        // console.log(`updating matching ROW`, row)
                        await editRow(props.api, id, props.field, formJson.fieldValue)
                    }
                }
            }
        } else if (mode === 'checkedRows') {
            const map: Map<GridRowId, GridRowModel> = apiRef.current.getSelectedRows()
            for (const key of map.keys()) {
                await editRow(props.api, key, props.field, formJson.fieldValue)
            }
        }
    }

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}
        >
            <DialogTitle>Update {props.field}</DialogTitle>
            <DialogContent>
                {editable ?
                <>
                <TextField autoFocus required margin="dense" id={`${props.field}-edit`}
                       name="fieldValue" label={props.field} type="text" fullWidth
                       value={free}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFree(event.target.value)
                       }}
                       variant="standard" />
                <EditMode mode={mode} setMode={setMode} types={props.types} />
                </>
                : <Alert severity="error">
                        {props.field} is only editable for the following sample types: <strong>{props.types.join(', ')}</strong>
                  </Alert>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {editable && <Button type="submit">Update</Button>}
            </DialogActions>
        </Dialog>
    )
}
