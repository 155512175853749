import * as d3 from 'd3';
import {
    IBoxPlotOptions, IBoxPlotProps, boxplotSymbolDot,
    boxplotSymbolTick, boxplot, IPlotData
} from 'd3-boxplot';

class BoxPlotD3 {
    options: IBoxPlotOptions;
    props: IBoxPlotProps;
    svg: any;
    margin: any;

    constructor(containerDiv: HTMLDivElement, options: IBoxPlotOptions, props: IBoxPlotProps) {
        this.options = options;
        this.props = props;

        this.svg = d3.select(containerDiv)
            .append('svg')
            .attr('id', this.options.plotId)
            .attr('width', this.props.width)
            .attr('height', this.props.height);
        this.svg.append('g').attr('class', 'plots');

        this.draw(true);
    }

    public resize(width: number, height: number) {
        this.props.width = width;
        this.props.height = height;
        d3.select('#' + this.options.plotId)
            .attr('width', width)
            .attr('height', height);
        this.draw(false);
    }

    public updateData(boxPlot: IPlotData) {
        this.props.data = boxPlot;
        this.draw(true);
    }

    public draw(makeAxis: boolean): void {
        // console.log(`BoxPlotD3 draw with ${this.props.data.stats.length}`);
        const { svg, options, props } = this;

        // This is a hack because normally we would want to use the d3 pattern of join/enter/update,
        // but when there is a lack of examples of using this pattern for boxplot, so this is what
        // we have.
        if (makeAxis) {
            d3.select('#' + this.options.plotId).select('.plots').selectAll("*").remove();
        }
        // Y axis
        let scale: any;
        if (options.scale === 'linear') {
            scale = d3.scaleLinear();
        } else if (options.scale === 'log') {
            scale = d3.scaleSymlog();
        }
        scale!.domain([props.data.min, props.data.max])
            .range([this.props.height - options.margins.top - options.margins.bottom, options.margins.top]);

        // X axis
        const band = d3.scaleBand()
            .domain(props.data.bandDomain)
            .range([options.margins.left, props.width - options.margins.left - options.margins.right])
            .paddingInner(options.minimalStyle ? 0 : 0.3)
            .paddingOuter(options.minimalStyle ? 2 : 0.2);

        // makeAxis should be true for a new plot or a change in data, but not when we are resizing.
        if (makeAxis) {
            svg.select('g.plots')
                .append('g')
                .attr('class', 'y axis ' + options.plotId)
                .attr('transform', `translate(${options.margins.left},${options.margins.top})`)
                .call(d3.axisLeft(scale));
            svg.select('g.plots')
                .append('text')
                .attr('transform', 'rotate(-90)')
                .attr('y', 0 - options.margins.left)
                .attr('x', 0 - (props.height - options.margins.bottom)/2)
                .attr('dy', '1em')
                .style('text-anchor', 'middle')
                .text('Log 2 Ratios');

            svg.select('g.plots')
                .append('g')
                .attr('class', 'x axis ' + options.plotId)
                .attr('transform', `translate(0,${props.height - options.margins.bottom})`)
                .call(d3.axisBottom(band))
                .selectAll("text")
                .attr("y", 0)
                .attr("x", 9)
                .style('text-anchor', 'start')
                .attr('class', 'x label ' + options.plotId)
                .attr("transform", "rotate(90)");
        } else {
            svg.select('.y.axis.' + options.plotId)
                .transition('750')
                .call(d3.axisLeft(scale));

            svg.select('.x.axis.' + options.plotId)
                .transition('750')
                .attr('transform', `translate(0,${props.height - options.margins.bottom})`)
                .call(d3.axisBottom(band))
                .selectAll("text")
                .attr("y", 0)
                .attr("x", 9)
                .style('text-anchor', 'start')
                .attr('class', 'x label ' + options.plotId)
                .attr("transform", "rotate(90)");
        }

        const plot = boxplot()
            .scale(scale)
            .jitter(options.jitter)
            .opacity(options.opacity)
            .showInnerDots(options.showInnerDots)
            .symbol(options.useSymbolTick ? boxplotSymbolTick : boxplotSymbolDot)
            .bandwidth(band.bandwidth())
            .boxwidth(options.minimalStyle ? 6 : band.bandwidth())
            .vertical(true)
            .key((d: number) => d);

        svg.select('g.plots')
            .attr('transform', 'translate(' + [options.margins.left, options.margins.top] + ')')
            .selectAll('.plot')
            .data(props.data.stats)
            .join('g')
            .attr('class', 'plot')
            .transition()
            .attr('transform', (v: any, i: any) => `translate(${[band(props.data.bandDomain[i]), 0]})`)
            .attr('color', (v: any, i: any) => '#000')
            .call(plot as any);
    }
}

export default BoxPlotD3;