import {GridRenderEditCellParams, GridRowId, GridRowModel} from '@mui/x-data-grid';
import React, {SyntheticEvent, useContext, useState} from 'react';
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from '@mui/material';
import EditMode from './EditMode';
import {createFilterOptions} from '@mui/material/Autocomplete';
import {ReferenceContext} from '../../../../../../store/ReferenceContext';
import {editRow} from './editHelper';
import useCellLines from './useCellLines';

export default function CellLineEdit(props: GridRenderEditCellParams) {
    const referenceContext = useContext<References>(ReferenceContext)
    const lines = [...referenceContext.cellLines];
    const [open, setOpen] = useState<boolean>(true)
    const [cellLine, setCellLine] = useState<CellLine|null>(null)
    const [mode, setMode] = useState<string>('currentOnly')
    const [cellInput, setCellInput] = useState<string>('')

    const cellLineName = useCellLines(props.value);

    const handleClose = async () => {
        await props.api.stopCellEditMode({id: props.id, field: props.field})
        setOpen(false)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const formJson = Object.fromEntries((formData as any).entries())
        await props.api.setEditCellValue({id: props.id, field: props.field, value: cellLine?.benchling_id})
        await handleClose()
        if (mode === "typed") {
            const keys = Object.keys(formJson).filter((k) => !k.startsWith('field'))
            console.log(`mode is typed and the keys are ${keys.join(`, `)}`)
            if (keys.length > 0) {
                const rowIds = props.api.getAllRowIds()
                for (const id of rowIds) {
                    let row = props.api.getRow(id)
                    if (keys.find((k) => k === row['Sample Type'])) {
                        await editRow(props.api, id, props.field, cellLine!.benchling_id!)
                    }
                }
            }
        } else if (mode === 'checkedRows') {
            const map: Map<GridRowId, GridRowModel> = props.api.getSelectedRows()
            for (const key of map.keys()) {
                await editRow(props.api, key, props.field, cellLine!.benchling_id!)
            }
        }
    }
    const cellChange = (event: SyntheticEvent, value: CellLine | null) => {
        setCellLine(value)
    }
    const filterOptions = createFilterOptions({
        stringify: (option: any) => {
            const start = option?.cell_line_short_name.replaceAll("_", " ")
            return `${start} ${option?.cell_line_short_name} ${option?.benchling_id}`
        }
    })

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="md"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}
        >
            <DialogTitle>Update {props.field}</DialogTitle>
            <DialogContent>
                <Typography variant='body1' sx={{mt:3}}>
                    Current Row: <strong>{cellLineName}</strong>
                </Typography>
                <Autocomplete
                    id={'selectCellsID'}
                    value={cellLine}
                    options={lines}
                    getOptionLabel={(option: CellLine) => `${option.cell_line_short_name} - ${option.benchling_id} `}
                    isOptionEqualToValue={(option: CellLine, value: CellLine) => {
                        return option.benchling_id === value.benchling_id;
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   label="Cells" variant="standard" margin="none" fullWidth />
                    }
                    onChange={cellChange}
                    inputValue={cellInput}
                    onInputChange={(event, newInputValue) => {
                        setCellInput(newInputValue)
                    }}
                    filterOptions={filterOptions}
                    openOnFocus selectOnFocus handleHomeEndKeys />
                <EditMode mode={mode} setMode={setMode} types={['Unknown', 'QC', 'Blank']} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button type="submit" disabled={cellLine === null}>Update</Button>
            </DialogActions>
        </Dialog>
    )
}