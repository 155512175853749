import {FC} from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {mtxTheme} from './theme';

interface AppThemeProps {
    children: any;
}
export const AppThemeProvider: FC<AppThemeProps> = ({ children} ) => {
    return (
        <ThemeProvider theme={mtxTheme}>
            {children}
        </ThemeProvider>
    )
}