import { TableCell, Typography, styled } from '@mui/material';
import { TableCellBaseProps } from '@mui/material/TableCell';
import { colors } from '../../theme/theme';

const colMinWidthFHDdown = 28;
const colMinWidthFHDup = 42;

interface CalTableHeadCellProps extends TableCellBaseProps {
    date: Date;
    classes?: string | undefined;
}

export default function CalTableHeadCell({date, classes}: CalTableHeadCellProps) {
    
    const [calWeekday, calDay] = [
        date.toLocaleString('default', { timeZone: 'UTC', weekday: 'short' }),
        date.getUTCDate(),
    ];
    
    const StyledCalTableHeadCell = styled(TableCell)(({theme}) => ({
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 1.25,
        backgroundColor: colors.mtxUISlate[50],
        [theme.breakpoints.down(theme.breakpoints.values.fhd)]: { 
            minWidth: colMinWidthFHDdown // maintains consistent width for the calendar columns
        },
        [theme.breakpoints.up(theme.breakpoints.values.fhd)]: { 
            minWidth: colMinWidthFHDup // maintains consistent width for the calendar columns
        },
        '&:not(.thCalRangeStart)': {
            // creates illusion of space between cells without set border-collapse: separate on entire table
            borderLeft: `1px solid ${theme.palette.background.paper}`
        },
        '&.thCalRangeStart': {
            // when cal range columns are preceded by other data columns then
            // use to give first cal range cell in each row a border to separate cal columns from rest of table
            borderLeft: `2px solid ${colors.mtxUISlate[100]}`
        },
        '&.thCalRangeEnd': {
            // when cal range columns are followed by other data columns then
            // use to give last cal range cell in each row a border to separate range columns from rest of table
            borderRight: `2px solid ${colors.mtxUISlate[100]}`
        },
    }));
    
    return (
        <StyledCalTableHeadCell align="center" className={classes}>
            <Typography component="time" dateTime={date.toISOString().slice(0,10)}
                fontSize="inherit" fontWeight="inherit" lineHeight="inherit"
                sx={{'& span': { display: 'block'}}}
            >
                <span>{calDay}</span>
                <span>{calWeekday}</span>
            </Typography>
        </StyledCalTableHeadCell>
    );
}