import React, {useCallback, useContext, useEffect, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material/';
import {
    experimentTypes,
    getRowDataSample, TMTOptions, TMTProDOptions
} from '../../../util/options';
import AlertDialog from '../AlertDialog/AlertDialog';
import DesignTable from '../DesignTable/DesignTable';
import ExperimentForm from '../ExperimentForm/ExperimentForm';
import {CreateExperimentDataCy} from './CreateNewExperimentModal.cy';
import {appendSamples, filledSampleData, hasReplicate} from '../../../util/util';
import CreateNewExperimentFooter from './CreateNewExperimentFooter';
import {generateCSV} from '../../../util/design-exporter';
import designs from '../../../services/designs';
import {ReferenceContext} from '../../../store/ReferenceContext';
import {fetchWorker} from './worker.util';

export const AlertTitle = {
    warning: 'Warning',
    error: 'Error'
};

const TMT_32_COUNT = 32

export const AlertErrorMessages = {
    exitWithoutSaving: 'Do you really want to cancel without saving?',
    rectifyErrorNeeded: 'Please rectify the below error before you save the experiment',
    numberOfSamplesRequired: 'No. of Samples is required',
    experimentTypeRequired: 'Experiment Type is required',
    projectNameRequired: 'Project Name is required',
    responsibleRequired: 'Responsible is required',
    collaboratorRequired: 'Collaborator is required',
    dmsoSampleRequired: 'Control (DMSO) replicate samples are required for TMT Channels',
    deuteratedDmsoSampleRequired: 'Control (DMSO) replicate samples are required for deuterated TMT Channels',
    requiredExperimentalSample: 'Please fill in at least one experimental sample row and its replicate.',
    required: ' are required',
    sampleMustHaveReplicate: (rowNumber: string) => `Sample(s) ${rowNumber} must have a replicate`,
    // IS MISSING ACTUALLY A THING NOW? WE CAN USE ANY TMT AMONG THE LIST RIGHT?
    noDuplicateTmt: 'No duplicate TMT allowed.',
    noControlReplicates: (
        rowNumber: number
    ) => `Sample ${rowNumber} has no matching TMT control replicates`,
    noDeuteratedControlReplicates: (rowNumber: number) =>
        `Sample ${rowNumber} has no matching deuterated TMT control replicates`,
    incompleteSample: (rowNumber: number,
                       stuff: string[]) => `Sample ${rowNumber} is missing data for ${stuff.join(", ")}.`
};

export const AlertButtons = {
    yes: 'Yes',
    no: 'No',
    ok: 'Ok'
};

export default function CreateNewExperimentModal({handleClose,
                                                     experimentDesign,
                                                     existingExperimentName,
                                                     handleSuccess
}: CreateNewExpModalProps) {
    const [rowDataSample, setRowDataSample] = useState<SampleRow[]>([]);
    const windowTitleId = 'create-new-exp-title';
    const referenceContext: References = useContext<References>(ReferenceContext)
    const [rowData, setRowData] = useState<SampleRow[]>([]);
    const [numSamples, setNumSamples] = useState<number>(0);
    const [openCancelWarning, setOpenCancelWarning] = useState(false);
    const [openFormError, setOpenFormError] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>();
    const useCompound = experimentDesign ? experimentDesign.type !== "QC" : true;
    const [completedSampleCount, setCompletedSampleCount] = useState(0);
    const [downloadCSV, setDownloadCSV] = useState(false);
    // const [benchling, setBenchling] = useState<string>(experimentDesign? experimentDesign.benchling : '')
    const [projectName, setProjectName] = useState<string>(experimentDesign ? experimentDesign.projectName : '')
    const [responsible, setResponsible] = useState<string>(experimentDesign ? experimentDesign.responsible : '')
    const [collaborator, setCollaborator] = useState<string>(experimentDesign ? experimentDesign.collaborator: '')
    const [experimentType, setExperimentType] = useState<string>(experimentDesign ? experimentDesign.type : "DEGRADATION");
    const [tmtOptions, setTmtOptions] = useState([...TMTOptions])
    const webWorker = fetchWorker();
    if (webWorker) {
        webWorker.onerror = (event) => {
            console.log('error event', event)
        }
        webWorker.onmessage = ({data}) => {
            setRowData(data.samples)
        }
    }


    const get32ChannelOptions = (): Labeled[] => {
        const options: Labeled[] = TMTOptions.slice(0,16)
        options.push(...TMTProDOptions)
        return options
    }

    useEffect(() => {
        const sampleData = getRowDataSample()
        const data = filledSampleData(experimentDesign, sampleData, referenceContext)
        setRowDataSample(sampleData)
        setRowData(data)
        setNumSamples(data.length)
        if (data.length === TMT_32_COUNT) {
            setTmtOptions(get32ChannelOptions())
        }
        return () => {
            if (webWorker) {
                // @ts-ignore
                webWorker.terminate();
            }
        }
        // webWorker doesn't need to be in the list of dependencies
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experimentDesign, referenceContext])

    useEffect(() => {
        // let rowDataFilled = rowData.slice(0, numSamples).filter(row => {
        let rowDataFilled = rowData.filter(row => {
            return row.cellLine?.benchling_id && row.type
                && (useCompound ? (row.treatmentTime && row.treatmentTimeUnit
                    && row.compounds.some(com => {
                        return com.compound?.value && com.batch && com.conc && com.unit?.value
                    })
                ): true)
        })
        setCompletedSampleCount(rowDataFilled.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowData])

    const handleCancelWarningOpen = () => {
        setOpenCancelWarning(true);
    }

    const handleCancelWarningClose = () => {
        setOpenCancelWarning(false);
    }

    const handleFormErrorOpen = () => {
        setOpenFormError(true);
    }

    const handleFormErrorClose = () => {
        setOpenFormError(false);
    }

    const handleRowUpdate = useCallback((index: number, row: SampleRow) => {
        const copy = [...rowData]
        copy[index] = row
        setRowData(copy)
        // console.log(`updated row ${index}`, row)
    }, [rowData])

    function closeDialog() {
        // reset();
        handleClose();
    }

    const handleDuplicate = (index: number) => {
        if(index < rowData.length - 1) {
            let updatedRowData = [...rowData];
            let currentSample = JSON.parse(JSON.stringify(updatedRowData[index]));
            // console.log(`copying ${JSON.stringify(currentSample)}`)
            let nextSample = updatedRowData[index+1];
            delete currentSample.numComps;
            delete currentSample.sampleNum;
            delete currentSample.tmt;
            //const copy = {...nextSample, ...currentSample};
            // console.log(`copied ${JSON.stringify(copy)} to ${index + 1}`)
            updatedRowData[index+1] = {...nextSample, ...currentSample};
            setRowData(updatedRowData);
        }
    }

    const handleClearRow = (index: number) => {
        let updatedRowData = [...rowData];
        updatedRowData.splice(index, 1, rowDataSample[index]);
        setRowData(updatedRowData);
    }

    const updateRowsForSamples = async (count: number, currentRowData: SampleRow[], samples: SampleRow[]) => {
        let copy: SampleRow[] | undefined
        let tmtOptionsCopy: Labeled[] | undefined = undefined
        if (count > currentRowData.length) {
            if (count === TMT_32_COUNT && !!webWorker) {
                tmtOptionsCopy = get32ChannelOptions()
                webWorker.postMessage({existing: currentRowData, defaultRows: samples, expectedCount: count})
            } else {
                copy = [...currentRowData]
                appendSamples(copy, samples, count)
            }
        } else if (count < currentRowData.length) {
            if (currentRowData.length === TMT_32_COUNT) {
                tmtOptionsCopy = [...TMTOptions]
            }
            // this could be potentially not have the correct tmtValues if they have been re-ordered
            copy = (currentRowData.slice(0, count))
        }
        if (tmtOptionsCopy) {
            setTmtOptions(tmtOptionsCopy)
        }
        if (copy) {
            setRowData(copy)
        }
    }

    const numSamplesChangeHandler = (count: number) => {
        setNumSamples(count)
        updateRowsForSamples(count, rowData, rowDataSample)
    }

    const handleSave = async (newExperimentDetail: any) => {
        const selectedExperimentType = experimentTypes.find(experiment => experiment.value === newExperimentDetail.type);
        if(isValidated(selectedExperimentType?.code || "", newExperimentDetail)) {
            let experimentName = existingExperimentName;
            let oldType = '';
            if (!experimentName) {
                // Not an edit, so we have to create a new name.
                experimentName = await designs.createName(selectedExperimentType!)
            } else {
                // Edit so we have to check that the name is appropriate for the selectedExperimentType
                if (!experimentName.includes(selectedExperimentType!.code)) {
                    const regex = /PX_([A-Z]*)_\d{5}_2\d{3}/;
                    const originalMatch = experimentName.match(regex);
                    if (originalMatch) {
                        oldType = originalMatch[1];
                        // console.log(`The ORIGINAL EXPERIMENT WAS TYPE ${oldType}`);
                        experimentName = experimentName.replace(oldType, selectedExperimentType!.code);
                    }
                }
            }
            let experimentList = [];

            let recordValue = {...newExperimentDetail};
            rowData.splice(numSamples);
            recordValue.conditions = rowData.map(row => {
                let tmpRow = JSON.parse(JSON.stringify(row));
                if(useCompound) {
                    tmpRow.compounds = tmpRow.compounds.map((comp: any) => {
                        return {
                            batch: comp?.batch,
                            compound: comp.compound?.value,
                            concentration: {
                                concentration: comp.conc,
                                unit: comp.unit?.value
                            }
                        }
                    })
                } else {
                    tmpRow.compounds = [];
                }
                if (tmpRow.tmt) {
                    tmpRow.channel = tmpRow.tmt;
                }
                tmpRow.cells = tmpRow.cellLine.benchling_id
                tmpRow.treatmentTime = parseInt(tmpRow.treatmentTime);
                delete tmpRow.numComps;
                delete tmpRow.sampleNum;
                delete tmpRow.tmt;
                delete tmpRow.cellLine
                return tmpRow;
            })
            let designExperiment: any = {
                experiment: experimentName,
                record_type: "DESIGN",
                record_value: recordValue
            };
            let statusExperiment: any = {
                experiment: experimentName,
                record_type: (selectedExperimentType?.code === "DEG") ? "STATUS": selectedExperimentType?.value+"_STATUS"
            };
            if (oldType) {
                designExperiment['original_name'] = existingExperimentName;
                statusExperiment['original_name'] = existingExperimentName;
                const originalExperimentType = experimentTypes.find(type => type.code === oldType);
                statusExperiment['original_type'] = (oldType === "DEG") ? "STATUS": originalExperimentType!.value + "_STATUS";
                //statusExperiment['original_type'] = original_record_type;
            }
            experimentList.push(designExperiment);
            experimentList.push(statusExperiment);
            const results: any = await designs.uploadPlanned(experimentList);
            if(results) {
                if(results.data.uploadPlannedExperiment.length>1) {
                      if(downloadCSV){
                        generateCSV(designExperiment);
                    }
                    closeDialog();
                    const action = existingExperimentName ? 'updated' : 'created';
                    handleSuccess(`Experiment with name '${results.data.uploadPlannedExperiment[0]?.experiment}' has been successfully ${action}!`,
                        selectedExperimentType!);
                    //reset();
                }
            }

        } else {
            handleFormErrorOpen();
        }
    }

    function tmtRowInPlay(row: any): boolean {
        if (row.type === 'Empty') {
            return false
        }
        return row.cells || row.treatmentTime || row.type !== 'NaN'
                    || row.compounds.some((c: {batch: string, compound: {value: string}, conc: string, unit: {value: string}}) => {
                        return c.batch || c.compound?.value || c.conc || c.unit.value;
                    });
    }

    function checkForMissingTmtChannelData(row: SampleRow) {
        const missing = [];
        !row.tmt && missing.push("TMT")
        !row.cellLine?.benchling_id && missing.push('Cells');
        let compoundCount = 0;
        row.compounds.forEach(c => {
            compoundCount++;
            !c.compound?.value && missing.push('Compound');
            !c.batch && missing.push('Batch');
            !c.conc && missing.push('Concentration');
            !c.unit?.value && missing.push('Concentration units');
        });
        !compoundCount && missing.push('Compound') && missing.push('Batch') && missing.push('Concentration')
            && missing.push('Concentration units');
        !row.treatmentTime && missing.push('Treatment time');
        !row.treatmentTimeUnit && missing.push('Treatment time units');
        !row.type && missing.push('Type');
        return missing;
    }

    function checkForMissingYTKData(row: SampleRow) {
        const missing = [];
        !row.tmt && missing.push("TMT")
        !row.cellLine?.benchling_id && missing.push('Cells');
        !row.type && missing.push('Type');
        return missing;
    }

    function hasDmsoControl(row: SampleRow, data: SampleRow[]): boolean {
        return data.some(d => {
            const isDmso = d.type === 'NaN' && d.compounds.some(dc => dc.compound?.value === 'DMSO');
            if (isDmso) {
                // console.log(`Comparing Control ${d.treatmentTime}${d.treatmentTimeUnit} vs exp ${row.treatmentTime}${row.treatmentTimeUnit}`)
                return d.treatmentTime === row.treatmentTime
                    && d.treatmentTimeUnit === row.treatmentTimeUnit
                    && d.cellLine?.benchling_id === row.cellLine?.benchling_id;
            } else {
                return false;
            }
        });
    }

    const isValidated = (expCode: string, newExperimentDetail: any) => {
        let errorMessageList = []
        if (!numSamples) {
            errorMessageList.push(AlertErrorMessages.numberOfSamplesRequired);
        }
        if (!newExperimentDetail.type) {
            errorMessageList.push(AlertErrorMessages.experimentTypeRequired);
        }
        if (!newExperimentDetail.projectName) {
            errorMessageList.push(AlertErrorMessages.projectNameRequired);
        }
        if (!newExperimentDetail.responsible) {
            errorMessageList.push(AlertErrorMessages.responsibleRequired)
        }
        if (!newExperimentDetail.collaborator) {
            errorMessageList.push(AlertErrorMessages.collaboratorRequired)
        }
        const duplicates = doTmtDuplicateValidation()
        if (duplicates) {
            errorMessageList.push(duplicates)
        }
        if (rowData.length === TMT_32_COUNT) {
            const aList = rowData.slice(0, 16)
            const bList = rowData.slice(16)
            console.log(`aList: ${aList.length}, bList: ${bList.length}`)
            errorMessageList.push(...doTableValidation(expCode, aList, 16))
            errorMessageList.push(...doTableValidation(expCode, bList, 16))
        } else {
            errorMessageList.push(...doTableValidation(expCode, rowData, numSamples))
        }
        setErrorMessages(errorMessageList);
        return errorMessageList.length === 0;
    }

    const doTmtDuplicateValidation = (): string => {
        const allTmt = rowData.map((row) => row.tmt)
        const duplicates = allTmt.filter((item, index) => allTmt.indexOf(item) !== index)
        return duplicates.length > 0 ? AlertErrorMessages.noDuplicateTmt : ''
    }

    const doTableValidation = (expCode: string, data: SampleRow[], count: number): string[] => {
        const tableErrors: string[] = []
        let dmsoRowPresent = data.some((row) =>
            row.type === 'NaN' && row.compounds.some((com) => {
                return com.compound?.value === 'DMSO';
            })
        );
        if (useCompound && !dmsoRowPresent) {
            const msg = data[0].tmt.endsWith('D') ? AlertErrorMessages.deuteratedDmsoSampleRequired
                : AlertErrorMessages.dmsoSampleRequired
            tableErrors.push(msg);
        }
        const missingChannelData = new Map();
        let experimentalRows = 0;
        for (let i = 0; i < count; i++) {
            const row = data[i];
            //console.log(`DATA ROW: ${JSON.stringify(row)}`);
            if (expCode === 'YTK') {
                const missing = checkForMissingYTKData(row);
                if (missing.length > 0) {
                    missingChannelData.set(row.sampleNum, missing);
                }
            } else {
                if (tmtRowInPlay(row)) {
                    const missing = checkForMissingTmtChannelData(row);

                    if (missing.length > 0) {
                        missingChannelData.set(row.sampleNum, missing);
                    }

                    if (!row.compounds.some(c => c.compound?.value === 'DMSO')) {
                        experimentalRows++;
                        if (row.type === 'NaN' && row.treatmentTime && row.treatmentTimeUnit) {
                            const dmsoRows = hasDmsoControl(row, data);
                            if (!dmsoRows) {
                                const msg = row.tmt.endsWith('D')
                                    ? AlertErrorMessages.noDeuteratedControlReplicates(row.sampleNum)
                                    : AlertErrorMessages.noControlReplicates(row.sampleNum)
                                tableErrors.push(msg);
                            }
                        }
                    }
                }
            }
        }
        if (experimentalRows === 0 && expCode !== "YTK") {
            tableErrors.push(AlertErrorMessages.requiredExperimentalSample);
        }
        // console.log(`missingChannelData is now ${missingChannelData.size}`);

        if(missingChannelData.size > 0) {
            missingChannelData.forEach((value: string[], key: number, ) => {
                tableErrors.push(AlertErrorMessages.incompleteSample(key, value));
            });
        }

        const nonDuplicateSampleNumber = lackingReplicates(data);
        if (nonDuplicateSampleNumber.length > 0) {
            tableErrors.push(
                AlertErrorMessages.sampleMustHaveReplicate(nonDuplicateSampleNumber.join(', '))
            );
        }
        return tableErrors
    }

    const lackingReplicates = (data: SampleRow[]): number[] => {
        const lacking: number[] = []
        data.forEach((row, index, data) => {
            if (!hasReplicate(data, row)) {
                lacking.push(row.sampleNum)
            }
        })
        return lacking
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const fieldValues = Object.fromEntries(formData.entries())
        let newExperimentDetailValues = {
            // benchling: fieldValues.benchling,
            collaborator: referenceContext.shortUsers.find( collaborator => collaborator.value ===fieldValues.collaborator)?.value,
            projectName: fieldValues.projectName,
            responsible: referenceContext.shortUsers.find(responsible => responsible.value === fieldValues.responsible)?.value,
            type: experimentTypes.find(type => type.label === fieldValues.type)?.value,
            noOfSamples: numSamples
        }
        handleSave(newExperimentDetailValues).then();
    }

    const handleSummaryChange = (key: string, value: any) => {
        switch(key) {
            // case 'benchling':
            //     setBenchling(value)
            //     break
            case 'projectName':
                setProjectName(value)
                break
            case 'responsible':
                setResponsible(value)
                break
            case 'collaborator':
                setCollaborator(value)
                break
        }
    };

    const renderCancelExperimentDialog = () => {
        return (
            <AlertDialog
                openDialog={openCancelWarning}
                handleDialogClose={handleCancelWarningClose}
                dialogTitle={AlertTitle.warning}
                handlePositiveClick={() => {
                    closeDialog();
                    handleCancelWarningClose()}}
                dialogContentText={AlertErrorMessages.exitWithoutSaving}
                positiveButtonText={AlertButtons.yes}
                negativeButtonText={AlertButtons.no}
            />
        )
    }

    const renderFormErrorsDialog = () => {
        return (
            <AlertDialog
                openDialog={openFormError}
                handleDialogClose={handleFormErrorClose}
                dialogTitle={AlertTitle.error}
                handlePositiveClick={handleFormErrorClose}
                dialogContentText={AlertErrorMessages.rectifyErrorNeeded}
                dialogContentExtraText={errorMessages?.map((error, index) => {
                    return (
                        <div key={index}>{error}</div>
                    )
                })}
                positiveButtonText={AlertButtons.ok}
            />
        )
    }

    const handleDownloadCSVChange = (event: { target: { checked: any; }; }) => {
        setDownloadCSV(event.target.checked);
    }
    const aNameHeading = existingExperimentName ? existingExperimentName : 'New Experiment';
    
    return (
        <Dialog
            fullScreen
            PaperProps={{
                square: true,
                elevation: 0,
                sx: { border: '2px solid black', overflowY: 'hidden'}
            }}
            open={true}
            onClose={handleClose}
            aria-labelledby={windowTitleId}
        >
            <DialogTitle data-cy={CreateExperimentDataCy.header} id={windowTitleId}> 
                {aNameHeading}
            </DialogTitle>
            <Box position="absolute" top={0} right={0} mt={1} mr={2}>
                <IconButton size="large" color="secondary" onClick={handleCancelWarningOpen} 
                    aria-label="Close dialog"
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </Box>
            <Box name="createnew" component="form" 
                noValidate onSubmit={handleSubmit} autoComplete="off"
            >
                <DialogContent dividers>
                    <ExperimentForm
                        experimentType={experimentType}
                        setExperimentType={setExperimentType}
                        numSamples={numSamples}
                        numSamplesChangeHandler={numSamplesChangeHandler}
                        // benchling={benchling}
                        projectName={projectName}
                        responsible={responsible}
                        collaborator={collaborator}
                        handleSummaryChange={handleSummaryChange}
                        tmt={true}
                    />
                    {
                    <DesignTable
                        rowData={rowData}
                        newExperimentType={experimentType}
                        useCompound={useCompound}
                        handleDuplicate={handleDuplicate}
                        handleClearRow={handleClearRow}
                        handleRowUpdate={handleRowUpdate}
                        tmtOptions={tmtOptions}
                    />
                    }
                </DialogContent>
                <CreateNewExperimentFooter completedSampleCount={completedSampleCount}
                                           sampleCount={rowData.length} downloadCSV={downloadCSV}
                                           handleDownloadCSVChange={handleDownloadCSVChange}
                                           handleCancelWarningOpen={handleCancelWarningOpen} />
            </Box>
            {renderCancelExperimentDialog()}
            {renderFormErrorsDialog()}
        </Dialog>
    );
}
