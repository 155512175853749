import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { uiColors } from '../../theme/theme';
import { APPBAR_HEIGHT, FILTERBAR_HEIGHT } from '../../theme/Constants';

const StyledFilterBar = styled(Toolbar)(({ theme }) => ({
    width: '100%',
    height: FILTERBAR_HEIGHT,
    backgroundColor: uiColors.filters.toolbarBg,
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    borderBottom: `2px solid ${uiColors.filters.toolbarAccentBorder}`,
    position: 'fixed',
    top: APPBAR_HEIGHT, //default AppBar height and minHeight of Toolbar
    zIndex: 1199, //default 1100
    [theme.breakpoints.down('xl')]: {
        '& > * + *:not(button)': { marginLeft: theme.spacing(1.5)},
        '& > button': { marginLeft: theme.spacing(1)}
    },
    [theme.breakpoints.up('xl')]: {
        '& > * + *:not(button)': { marginLeft: theme.spacing(3)},
        '& > button': { marginLeft: theme.spacing(2)}
    }
}));

export default StyledFilterBar;