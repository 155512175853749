import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';

const StyledListTable = styled(Table)(({ padding, theme }) => ({
    ...((padding === undefined || padding === 'normal') && {
        '& > .MuiTableHead-root': {
            '& .MuiTableCell-root': { padding: 0},
        },
        '& > .MuiTableBody-root': {
            '& .MuiTableCell-root': {
                paddingTop: theme.spacing(0.5),
                paddingBottom: theme.spacing(0.5),
                paddingRight: 0,
                '&:not(th)': { paddingLeft: 0}
            },
            '& th.MuiTableCell-root': { paddingLeft: theme.spacing(1)}
        },
        '& > .MuiTableFooter-root': {
            '& .MuiTableCell-root': {
                paddingBottom: theme.spacing(0.5),
                paddingTop: theme.spacing(0.5),
                paddingRight: 0,
                '&:not(th)': { paddingLeft: 0}
            },
            '& th.MuiTableCell-root': { paddingLeft: theme.spacing(1)}
        }
    }),
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    '& .MuiTableBody-root th, .MuiTableFooter-root th': {
        fontWeight: theme.typography.fontWeightMedium
    },
    '& .MuiTableCell-root': {
        verticalAlign: 'bottom',
        borderBottom: 'none',
    },
    '& .MuiTableCell-head': {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 400,
        letterSpacing: '0.09em',
        textTransform: 'uppercase',
    },
    '& .MuiTableCell-body': {
        backgroundColor: theme.palette.grey[50],
        '& .MuiSvgIcon-root': { verticalAlign: '-4px'}
    },
    '& .MuiTableCell-footer': {
        fontSize: theme.typography.body1.fontSize,
        color: theme.palette.text.primary  
    },
    '& .MuiButton-text': {
        padding: 0,
        lineHeight: 1.43,
    },
}));

export default StyledListTable;