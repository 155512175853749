import {FC} from 'react';
import {FlashAnalysis, FlashStatistics} from '../../../../../API';
import {Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import { StyledListBox} from '../../StyledComponents';
import {numberFormat} from '../../../../../util/util';

interface FlashStatsPanel {
    analysis: FlashAnalysis | null | undefined;
    statistics: FlashStatistics | undefined;
}

const FlashStatsPanel: FC<FlashStatsPanel> = ({analysis, statistics}) => {

    if (!analysis) {
        return null
    }

    return (
        <Box mb={4} id={'flashAnalysis-toPdf'}>
            <Typography gutterBottom variant="h5" component="h2">
                Analysis Parameters & Statistics
            </Typography>
            <StyledListBox component="ul" mt={1.5} mb={4} ml={2}>
                <Typography component="li">
                    <strong>Transfer q-value cutoff:{' '}</strong>
                    {analysis.record_value?.transfer_qValue_cutoff}
                </Typography>
            </StyledListBox>
            {statistics?.record_value?.missing_MSF_files &&
                <>
                    <Typography gutterBottom variant="h5" component="h3">
                        Unavailable for processing
                    </Typography>
                    <StyledListBox component="ul" mt={1.5} mb={4} ml={2}>
                        {statistics?.record_value?.missing_MSF_files.map((value) => {
                            return <Typography component="li" key={value}>
                                {value}
                            </Typography>
                        })}
                    </StyledListBox>
                </>
            }
            {statistics?.record_value?.issues_parsing_MSF_files &&
                <>
                    <Typography gutterBottom variant="h5" component="h3">
                        Issues during parsing
                    </Typography>
                    <StyledListBox component="ul" mt={1.5} mb={4} ml={2}>
                        {statistics?.record_value?.issues_parsing_MSF_files.map((value) => {
                            return <Typography component="li" key={value}>
                                {value}
                            </Typography>
                        })}
                    </StyledListBox>
                </>
            }

            {statistics && <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '60vh' }}>
                    <Table sx={{ minWidth: 750 }} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                </TableCell>
                                <TableCell>PCMs total</TableCell>
                                <TableCell>Peptides total</TableCell>
                                <TableCell>Proteins total</TableCell>
                                <TableCell>PCMs unique</TableCell>
                                <TableCell>Peptides unique</TableCell>
                                <TableCell>Proteins unique</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell variant="head">unfiltered</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.starting_total_PCM_count)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.starting_total_peptide_count)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.starting_total_protein_count)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.starting_unique_PCM_count)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.starting_unique_peptide_count)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.starting_unique_protein_count)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">proteotypic</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.total_number_proteotypic_PCM)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.total_number_proteotypic_peptides)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.total_number_proteotypic_proteins)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.unique_number_proteotypic_PCM)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.unique_number_proteotypic_peptides)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.unique_number_proteotypic_proteins)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">method A</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.total_PCM_count_remaining)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.total_peptide_count_remaining)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.total_protein_count_remaining)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.unique_PCM_count_remaining)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.unique_peptide_count_remaining)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.unique_protein_count_remaining)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell variant="head">rescued due to transfer q-value</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.number_PCM_rescued_due_to_transfer_q_value)}</TableCell>
                                <TableCell>{numberFormat(statistics.record_value?.method_a_filters_count?.number_peptides_rescued_due_to_transfer_q_value)}</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                                <TableCell>0</TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>}
        </Box>
    )

}

export default FlashStatsPanel