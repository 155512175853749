import {useEffect, useLayoutEffect, useState, useRef, RefObject, memo} from 'react';
import {ResponsiveBar} from '@nivo/bar';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import {IHistogramProps, INumberBar, HistogramData} from 'histogram';
import {processHistogram, computeHistogramMedian} from './HistogramUtil';
import {NamedStatsData} from 'experiment';
import * as _ from 'lodash';
import useMounted from "../../util/useMounted";

export const SNHistogramPanelDataCy = {
    histogram: 'sn-histogram'
}

const SNHistogramPanel = ({experimentName, data, loading}: NamedStatsData) => {

    const isMounted = useMounted();
    const ref: RefObject<HTMLDivElement> | null = useRef(null);
    const [histogram, setHistogram] = useState<IHistogramProps | null>(null);
    const histogramConfig = {min: -1, max: 3};
    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3);

    useLayoutEffect(() => {
        const handleResize = _.debounce(() => {
            setGraphHeight(window.innerWidth / 3);
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const getWarning = (data: any): string => {
            let warning = '';
            if (data.binCounts.length === 0) {
                warning = 'No acceptable PSMs in run';
            } else if (data.max < histogramConfig.min) {
                warning = `All PSMs have SN less than ${histogramConfig.min}`;
            } else if (data.min > histogramConfig.max) {
                warning = `All PSMs have SN greater than ${histogramConfig.max}`
            }
            return warning;
        };

        const processData = (experiment: string, data: HistogramData | undefined) => {
            try {
                if (data) {
                    const bars: INumberBar[] = processHistogram(data, histogramConfig);
                    const myHistogram: IHistogramProps = {
                        experiment: experiment,
                        bars: bars,
                        binSize: data.binWidth,
                        median: computeHistogramMedian(data),
                        warning: getWarning(data)
                    };
                    if (isMounted() && histogram?.experiment !== myHistogram.experiment) {
                        setHistogram(myHistogram);
                    }
                } else {
                    isMounted() && setHistogram(null);
                }
                
            } catch (error) {
                console.error('SNHistogramPanel error', error);
                isMounted() && setHistogram(null);
            }
        };

        processData(experimentName, data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experimentName, data, isMounted]);

    const testTickValue = (n: number) => {
        return (Math.floor(n) + histogram!.binSize > n);
    };
    const CustomTick = (tick: any) => {
        if (testTickValue(tick.value)) {
            return <g className="tick"  opacity="1" transform={`translate(${tick.x},0)`} textAnchor="middle">
                <line stroke="currentColor" y2="6"/>
                <text fill="currentColor" y="9" dy="0.71em">{Math.floor(tick.value)}</text>
            </g>
        }
        return null;
    };

    // @ts-ignore
    if (histogram != null) {
        // This will get looked up as a string label, so needs to match exactly 3 decimal digits with no trailing zeros
        let median = (histogram.median as number).toFixed(3).replace(/([0-9]+(\.[0-9]+?[1-9]?)?)(\.?0+$)/,'$1');
        return (
            <Box data-cy={SNHistogramPanelDataCy.histogram} ref={ref} height={graphHeight}>
                {histogram.warning ? <Alert severity="warning">{histogram.warning}</Alert> : null }
                <ResponsiveBar
                    animate={false}
                    data={histogram.bars}
                    keys={['count']}
                    indexBy="x"
                    margin={{top: 10, bottom:50, left: 55, right: 10}}
                    padding={0}
                    colors={{scheme: 'category10'}}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        legend: 'PSM Count',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    axisBottom={{
                        legend: 'Log₁₀ S/N (binned)',
                        legendOffset: 40,
                        legendPosition: 'middle',
                        // @ts-ignore
                        renderTick: CustomTick,
                    }}
                    labelSkipWidth={25}
                    markers={[
                        {axis: 'x',
                         value: median,
                         lineStyle: { stroke: 'rgba(0, 255, 0, .6)', strokeWidth: 3 },
                         legend: `Median: ${(+median).toFixed(2)}`,
                        },
                    ]}
                />
            </Box>
        );
    }    
    if(loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}
function areEqual(prevProps: NamedStatsData, nextProps: NamedStatsData) {
    return prevProps.experimentName === nextProps.experimentName
        && prevProps.data?.max === nextProps.data?.max;
}
export default memo(SNHistogramPanel, areEqual);