export const ExperimentStatisticsPanelDataCy = {
    instrument: 'esp-instrument',
    psmFile: 'esp-psm-file',
    pdRejects: 'esp-pd-rejects',
    database: 'esp-database',
    pdVersion: 'esp-pd-version',
    tmtLot: 'esp-tmt-lot',
    psmCount: 'esp-prm-count',
    missingChannelThreshold: 'esp-missing-channel-threshold',
    isolationInterference: 'esp-isolation-interference',
    averageReporterSn: 'esp-average-reporter-sn-cutoff',
    spsMassMatchesCutoff: 'esp-sps-mass-matches-cutoff',
    ignoreSpsMassMatches: 'esp-ignore-sps-mass-matches',
    psmAmbiguity: 'esp-psm-ambiguity',
    confidence: 'esp-psm-confidence',
    numberOfProteins: 'esp-number-of-proteins',
    identifyingNode: 'esp-identifying-node',
    skipNormalization: 'esp-skip-normalization',
    psmLabel: 'esp-psm-label',
    psmPie: 'esp-psm-pie',
    removedLabel: 'esp-removed-label',
    removedBar: 'esp-removed-bar',
    proteinsLabel: 'esp-proteins-label',
    proteinsPie: 'esp-proteins-pie',
    filterSummary: 'esp-filter-summary',
    filterRowsRemoved: 'esp-filter-rows-removed'
};
