import {useEffect, useLayoutEffect, useState, useRef, RefObject, useCallback, useMemo, useContext} from 'react';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { StyledCenteringBox, StyledPlotTipPaper } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import { dataColors } from '../../theme/theme';
import {ResponsiveScatterPlot} from '@nivo/scatterplot';
import {IPlotProps, IPointData} from "plot";
import * as _ from 'lodash';
import {RefAwareStatsData} from 'experiment';
import useMounted from "../../util/useMounted";
import {calculateHeight} from './PlotUtils';
import {DisplayContext} from '../../store/DisplayContext';
import {ReferenceContext} from '../../store/ReferenceContext';

function renameCells(data: IPointData[], cellLines: CellLine[]) {
    const benchling_regex = /^Cell[\d]{5}$/
    data.forEach((item: IPointData) => {
        if (item.id.match(benchling_regex)) {
            const line = cellLines.find((cellLine: CellLine) => cellLine.benchling_id === item.id)
            if (line) {
                item.id = line.cell_line_short_name
            }
        }
    })
}

export default function PcaAnalysisPanel({showsRef, experimentName, data, loading}: RefAwareStatsData) {

    const isMounted = useMounted();
    const detailsDisplay = useContext<DetailsDisplay>(DisplayContext);
    const ref: RefObject<HTMLDivElement> | null = useRef(null);
    const [pcaPlot, setPcaPlot] = useState<IPlotProps | null>(null);
    const [graphHeight, setGraphHeight] = useState(calculateHeight(showsRef, detailsDisplay.wideView));
    const [serieId, setSerieId] = useState<string | null>(null);
    const referenceContext = useContext<References>(ReferenceContext)

    useLayoutEffect(() => {

        const handleResize = _.debounce(() => {
            setGraphHeight(calculateHeight(showsRef, detailsDisplay.wideView));
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);

    });

    useEffect(() => {

        const getWarning = (data: any): string => {
            let warning = '';
            if (data === undefined || data.length === 0) {
                warning = 'No PCA Calculation performed';
            }
            return warning;
        };

        const processData = (data: IPointData[] | null, experimentName: string, cellLines: CellLine[]) => {

            try {
                if (data) {
                    const plotData = [...data]
                    if (experimentName.startsWith('PX_YTK')) {
                        renameCells(plotData, cellLines)
                    }
                    const xValues = plotData.flatMap(point => point.data.map(p => p.x));
                    const yValues: number[] = plotData.flatMap(point => point.data.map(p => p.y));

                    const pcaPlot: IPlotProps = {
                        points: plotData,
                        warning: getWarning(data),
                        yMin: Math.min(...yValues) - 50,
                        yMax: Math.max(...yValues) + 50,
                        xMin: Math.min(...xValues) - 50,
                        xMax: Math.max(...xValues) + 50
                    };
                    isMounted() && setPcaPlot(pcaPlot);
                } else {
                    isMounted() && setPcaPlot(null);
                }
            } catch (error) {
                console.debug('Pca Panel error', error);
                isMounted() && setPcaPlot(null);
            }
        };

        processData(data, experimentName, referenceContext.cellLines);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setGraphHeight(calculateHeight(showsRef, detailsDisplay.wideView));
    }, [showsRef, detailsDisplay.wideView])

    const tip = (node: any) => {
        return (
            <StyledPlotTipPaper elevation={1}>
                <Box width="0.7em" height="0.7em" mr={0.75}
                    display="inline-block" sx={{backgroundColor: node.node.color}}
                />
                <Typography component="p" fontWeight="medium" display="inline-block">
                    {node.node.serieId}
                </Typography>
            </StyledPlotTipPaper>
        );
    }

    const doMouseEnter = useCallback((data: any) => {
        setSerieId(data.id);
    }, [setSerieId]);

    const doMouseLeave = useCallback(() => {
        setSerieId(null);
    }, [setSerieId]);

    const getNodeSize = useMemo(
        () => (node: any) => {
            if (serieId && serieId === node.serieId) {
                return 18;
            }
            return 9;
        },
        [serieId]
    );
    const calcWidth = () => {
        return showsRef ? graphHeight + 80 : graphHeight + 160
    }

    // @ts-ignore
    if (pcaPlot != null) {
        return <>
            <Box ref={ref} height={graphHeight} width={calcWidth} mx={'auto'} id={`${experimentName}-PCA`}>
                {pcaPlot.warning ?
                    <Alert severity="warning">{pcaPlot.warning}</Alert>
                    :
                    <ResponsiveScatterPlot
                        data={pcaPlot.points}
                        margin={{top: 10, bottom:50, left: 55, right: 160}}
                        xScale={{ type: 'linear', min: pcaPlot.xMin, max: pcaPlot.xMax }}
                        yScale={{ type: 'linear', min: pcaPlot.yMin, max: pcaPlot.yMax }}
                        blendMode="multiply"
                        nodeSize={getNodeSize}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                            legend: 'PC2',
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        axisBottom={{
                            legend: 'PC1 (mouse over legend to highlight points)',
                            legendOffset: 40,
                            legendPosition: 'middle',
                        }}
                        colors={dataColors.categorical.contrastScatter}
                        legends={[{
                            anchor: 'top-right',
                            direction: 'column',
                            justify: false,
                            translateX: 160,
                            translateY: 0,
                            itemWidth: 150,
                            itemHeight: 12,
                            itemsSpacing: 5,
                            itemDirection: 'left-to-right',
                            symbolSize: 12,
                            symbolShape: 'circle',
                            onMouseEnter: doMouseEnter,
                            onMouseLeave: doMouseLeave,
                            effects: [{
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }]
                        }]}
                        tooltip={tip}
                    /> }
            </Box>
        </>
    }
    if(loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}