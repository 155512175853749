import React, {Fragment, useEffect, useState} from 'react';
import {
   Alert, Box, Button, InputLabel, LinearProgress,
   Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import FileField from '../FileField';
import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import axios, {AxiosProgressEvent} from 'axios';

type FastaUploadModalProps = {
   open: boolean;
   handleClose: () => void;
}

type FastaUploadProgressProps = {
    fastaFile: File;
    handleUploadFinished: () => void;
}

export const fastaModal = {
    header: { 
        selector: 'fasta-upload-modal-title',
        label: 'Upload Fasta Database'
    },
    fastaFileInput: {
        selector: 'fasta-upload',
        label: 'Fasta File'
    },
    uploadButton: {
        selector: 'fasta-upload-button',
        label: 'Upload'
    },
    closeButton: {
        selector: 'fasta-close-button',
        cancelLabel: 'cancel',
        closeLabel: 'close'
    },
    fileUploading: {
        selector: 'fasta-file-progress',
        label: 'Fasta file upload progress'
    },
    fileValidation: {
        selector: 'fasta-validation',
        label: 'Fasta file validation'
    }
}

function FastaUploadProgress({fastaFile, handleUploadFinished}: FastaUploadProgressProps) {
    const [fastaFileProgress, setFastaFileProgress] = useState(0);
    const [validationStatus, setValidationStatus] = useState('');

    useEffect(() => {
        const uploadFile = async () => {
            try {
                const result: any
                    = await API.graphql(graphqlOperation(queries.getFastaUploadUrl, {key: fastaFile.name}));

                const config = {
                    onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                        setFastaFileProgress((progressEvent.loaded / progressEvent.total!) * 100);
                    }
                };
                await axios.put(result.data.getFastaUploadUrl.url, fastaFile, config);
                const validateResult: any
                    = await API.graphql(graphqlOperation(mutations.validateFastaUpload, {
                        input: {key: fastaFile.name}
                      }));
                // console.log(`Validation Results: ${JSON.stringify(validateResult.data)}`);
                if (result.data.validateFastaUpload === 'OK') {
                    handleUploadFinished();
                } else {
                    setValidationStatus(validateResult.data.validateFastaUpload);
                }
            } catch (error) {
                console.log('Fasta Upload Error', error);
                handleUploadFinished();
            }
        };
        uploadFile();
    }, [fastaFile, handleUploadFinished]);

    return <Fragment>
        <InputLabel htmlFor={fastaModal.fileUploading.selector}>{fastaModal.fileUploading.label}</InputLabel>
        {fastaFileProgress === 0 ?
            <LinearProgress id={fastaModal.fileUploading.selector} />
            :
            <LinearProgress id={fastaModal.fileUploading.selector} variant="determinate" value={fastaFileProgress}/>
        }
        <InputLabel htmlFor={fastaModal.fileValidation.selector}>{fastaModal.fileValidation.label}</InputLabel>
        {validationStatus ? (
            <Alert severity={validationStatus === 'OK' ? 'success' : 'error'}>{validationStatus}</Alert>
        ) : (
            <LinearProgress/>
        )}
    </Fragment>
}

export default function FastaUploadModal({open, handleClose}: FastaUploadModalProps) {

    const [upload, setUpload] = useState<boolean>(false);
    const [fastaFile, setFastaFile] = useState<any>();
    const [uploadDisabled, setUploadDisabled] = useState<boolean>(true);

    const closeDialog = (): void => {
        setUpload(false);
        setFastaFile(undefined);
        handleClose();
    };

   return (
      <Dialog
         fullWidth
         maxWidth={'sm'}
         PaperProps={{
            square: true,
            elevation: 0,
            sx: {border: '2px solid black'}
         }}
         open={open}
         onClose={() => handleClose}
         aria-labelledby={fastaModal.header.selector}
      >
         <DialogTitle data-cy={fastaModal.header.selector} id={fastaModal.header.selector}> 
            {fastaModal.header.label}
         </DialogTitle>
         <form action="/" method="POST"
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
               event.preventDefault();
               setUpload(true);
               setUploadDisabled(true);
            }}
         >
            <DialogContent dividers>
               <FileField
                  id={fastaModal.fastaFileInput.selector}
                  label={fastaModal.fastaFileInput.label}
                  validate={true}
                  file={fastaFile}
                  setFile={setFastaFile}
                  acceptedFiles={['.fasta,application/x-fasta']}
                  onChange={(file) => { setUploadDisabled(false);}}
               />
               {upload &&
               <Box px={1} sx={{ '& > *': { mt: 1 }}}>
                  <FastaUploadProgress fastaFile={fastaFile} handleUploadFinished={() => closeDialog()}/>
               </Box>
               }
            </DialogContent>
            <DialogActions>
               <Button data-cy={fastaModal.uploadButton.selector} type="submit" disabled={uploadDisabled}>
                  {fastaModal.uploadButton.label}
               </Button>
               <Button data-cy={fastaModal.closeButton.selector} onClick={closeDialog}>
                  {upload ? fastaModal.closeButton.closeLabel : fastaModal.closeButton.cancelLabel}
               </Button>
            </DialogActions>
         </form>
      </Dialog>
   );
}