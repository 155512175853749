import React, {useContext, useEffect, useState} from 'react';
import {Button, FormControl, MenuItem, Select, Typography, useMediaQuery} from '@mui/material';
import InstrumentFilter from './InstrumentFilter';
import DateRangeFilter from './DateRangeFilter';
import {experimentTypes} from '../../util/options';
import {Link, useLocation, useParams} from 'react-router-dom';
import dateFormat from 'dateformat';
import {StyledFilterBar, StyledFilterBase, StyledFilterLabel} from '../StyledComponents';
import {FiltersProps, ProjectCompound} from 'filters';
import {dateFormatMask, threeMonthsAgo} from '../../util/util';
import ProjectCompoundFilter, {PROJECT_COMPOUND_DEFAULT_OPTION} from './ProjectCompoundFilter';
import KnownOptionsFilter from './KnownOptionsFilter';
import CellLineFilter from './CellLineFilter';
import {ReferenceContext} from '../../store/ReferenceContext';
import filterUtils, {DEFAULT_CELL_LINE, DEFAULT_OPTION} from './filterUtils';
import {allInstruments, Instruments} from "../../store/Instruments";

const oldestExpDate = '2019-12-31';


export default function FilterBar({handleFilterChange}: FiltersProps) {
    let present = dateFormat(new Date(), dateFormatMask)
    let {experimentCode} = useParams()
    let location = useLocation()
    const [tab, setTab] = useState('')
    const [instrument, setInstrument] = useState<string>(DEFAULT_OPTION);
    const [start, setStart] = React.useState(threeMonthsAgo())
    const [end, setEnd] = React.useState(dateFormat(new Date(), dateFormatMask))
    const [cellLine, setCellLine] = useState<any>(DEFAULT_CELL_LINE)
    const referenceContext = useContext<References>(ReferenceContext)
    const cellLineOptions = referenceContext.cellLines.map((c: CellLine) => {
        const value = c.cell_line_short_name ? `${c.cell_line_short_name} - ${c.benchling_id}` : c.benchling_id
        return {label: value, id: c.benchling_id}
    })
    cellLineOptions.unshift(DEFAULT_CELL_LINE)
    const instrumentOptions = [Instruments.all, ...allInstruments]
    const [projectCompound, setProjectCompound] = useState<ProjectCompound>(PROJECT_COMPOUND_DEFAULT_OPTION)
    const allCompounds: string[] = referenceContext.refCompounds.map((comp) => comp.value)
    const [experimenter, setExperimenter] = useState(DEFAULT_OPTION)
    const allExperimenters: string[] = referenceContext.shortUsers.map((v:CddValue) => v.value).sort()
    allExperimenters.unshift(DEFAULT_OPTION)

    useEffect(() => {
        const parts = location.pathname.split('/')
        setTab(parts[parts.length - 1])
    }, [location])

    const cellLineChange = (value: any) => {
        setCellLine(value)
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(value),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter)
        })
    }

    const handleInstrumentFilterChange = (value: string) => {
        setInstrument(value);
        handleFilterChange({
            instrument: filterUtils.getNullableValue(value),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter)
        });
    }

    const handleProjectCompoundFilterChange = (value: ProjectCompound) => {
        setProjectCompound(value);
        // console.log(`handleFilterChange has value ${value.name} : ${value.type}`)
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(value),
            experimenter: filterUtils.getNullableValue(experimenter)
        })
    }

    const handleExperimenterChange = (value: string) => {
        setExperimenter(value)
        // console.log(`handleExperimenterChange with ${value}`)
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(value)
        })
    }

    const handleStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStart(event.currentTarget.value);
    }

    const handleEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnd(event.currentTarget.value);
    }

    const handleApplyDateFilter = () => {
        const current = filterUtils.getDateRange(start, end);
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: current,
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter)
        });
    }

    const doReset = () => {
        const resetStart = threeMonthsAgo();
        setStart(resetStart);
        present =  dateFormat(new Date(), dateFormatMask);
        setEnd(present);
        setCellLine(DEFAULT_CELL_LINE)
        setInstrument(DEFAULT_OPTION);
        setProjectCompound(PROJECT_COMPOUND_DEFAULT_OPTION)
        setExperimenter(DEFAULT_OPTION)
        handleFilterChange({
            instrument: null,
            cellLine: null,
            dateRange: filterUtils.getDateRange(resetStart, present),
            projectCompound: null,
            experimenter: null
        });
    }

    const RenderExpTypeMenu = () => {

        return (
            <FormControl variant="filled" size="small" sx={{ minWidth: 200 }}>
                <StyledFilterLabel id="exp-type-menu-label">Display</StyledFilterLabel>
                <Select
                    id="exp-type-menu"
                    labelId="exp-type-menu-label"
                    value={experimentCode}
                    input={<StyledFilterBase />}
                >
                    {experimentTypes.map((t: ExperimentType) => (
                        // @ts-ignore
                        <MenuItem
                            disabled={!t.filterable}
                            key={'typekey_' + t.code}
                            value={t.code}
                            selected={t.code === experimentCode}
                            // @ts-ignore
                            component={Link}
                            to={'/experiment/' + t.code + '/' + tab}
                        >
                            {t.label} Experiments
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    };
    
    const hideText = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));
    
    return (
    <StyledFilterBar variant="denseOptical" role="toolbar" aria-label="Options for filtering the experiments">
        <Typography component="h2" variant="subtitle1" fontWeight="medium" color="white">Filters</Typography>
        <RenderExpTypeMenu />
        <InstrumentFilter options={instrumentOptions} instrument={instrument}
                          onInstrumentChangeCallback={handleInstrumentFilterChange}/>
        <DateRangeFilter min={oldestExpDate} max={present} start={start} end={end}
                         handleStart={handleStart} handleEnd={handleEnd} handleApplyDates={handleApplyDateFilter}/>
        {experimentCode !== 'YTK' && <CellLineFilter selected={cellLine} options={cellLineOptions}
                                                     cellLineCallback={cellLineChange} />}
        {experimentCode !== 'YTK' && <ProjectCompoundFilter value={projectCompound}
                               projects={referenceContext.projects}
                               cddCompounds={allCompounds} onFilterChangeCallback={handleProjectCompoundFilterChange}/>}
        <KnownOptionsFilter id="experimenter-filter" label="Responsible or Collaborator"
                            selectedOption={experimenter} knownOptions={allExperimenters}
                            onSelectionChangeCallback={handleExperimenterChange} />
        <Button type="reset" color="primary" variant="contained" onClick={doReset} disableElevation>
            {hideText? 'Reset' : 'Reset Filters'}
        </Button>
    </StyledFilterBar>
    );
}
