import axios from 'axios';
import {OptionsObject, useSnackbar} from 'notistack';

export const AxiosInterceptor = () => {
    const {enqueueSnackbar} = useSnackbar();
    const errorOptions: OptionsObject = {
        variant: 'error',
        persist: true
    };

    axios.interceptors.request.use((config: any) => {
        // console.log(`REQUEST: ${JSON.stringify(request)}`);
        return config;
    }, error => {
        console.error(`ERROR: ${JSON.stringify(error)}`);
        const msg = `Request Error: ${error.toLocaleString()}`;
        enqueueSnackbar(msg, errorOptions);
        return Promise.reject(error);
    });

    axios.interceptors.response.use(response => {
        if (response.status === 200) {
            const errors = response?.data?.errors ?? (response as any)?.errors;
            if (errors) {
                // console.log(`RESP: ${JSON.stringify(response, null, 4)}`);
                let msg = 'Unexpected Error';
                errors.forEach((err: any) => {
                    let path = err.path && err.path.length ? err.path[0] : '';
                    console.warn(`RESPONSE ERROR: ${path} ${err.message}`);
                    if (path) {
                        msg = msg + ' requesting ' + path;
                    }
                    msg = msg + ': ' + err.message + '.';

                });
                enqueueSnackbar(msg, errorOptions);
            }
        } else {
            console.log(`Response Status: ${response.status}; statusText: ${response.statusText}`);
            const msg = `Response Error: ${response.status}: ${response.statusText}`;
            enqueueSnackbar(msg, errorOptions);
        }
        return response;
    }, error => {
        console.error(`RESP ERROR: ${JSON.stringify(error)}`);
        const msg = `Unexpected Response Error: ${error.toLocaleString()}`;
        enqueueSnackbar(msg, errorOptions);
        return Promise.reject(error);
    });
    return null;
};