import {FC, useEffect, useState} from "react";
import {
    Autocomplete, Box, Button,
    Fade, FormControl, FormControlLabel, FormLabel,
    DialogActions, DialogContent, DialogTitle,
    Radio, RadioGroup, TextField,
} from "@mui/material";
import {DesignSelectFormDataCy} from "./DesignSelectForm.cy";
import {ExperimentTemplates} from './ExperimentTemplates';
import designs from '../../../../services/designs';
import details from '../../../../services/details';

enum Selection {
    FromExperiment = 'FromExperiment',
    FromTemplate = 'FromTemplate',
    NewDesign = 'NewDesign',
    NOT_TMT = 'NOT_TMT',
    FLASH = 'FLASH'
}

export interface DesignSelectFormProps {
    setExperimentDesign: (design: any) => void;
    handleContinue:(continueAs: 'TMT'|'NOT_TMT'|'FLASH') => void;
    handleClose: () => void;
}

const DesignSelectForm: FC<DesignSelectFormProps> = ({setExperimentDesign, handleContinue, handleClose}) => {
    const [value, setTypeValue] = useState<string>(Selection.NewDesign);
    const [experiments, setExperiments] = useState<any[]>([]);
    const [selectedExperiment, setSelectedExperiment] = useState<any>();

    useEffect(() => {
        const fetchExperiments = async () => {
            const experiments = await designs.fetchNonQcExperiments();
            setExperiments(experiments);

        };
        fetchExperiments();
    }, [])

    function closeDialog() {
        handleClose();
        setTypeValue('new');
        setSelectedExperiment(null);
    }

    async function submitContinueAsNew() {
        if (value === Selection.NOT_TMT || value === Selection.FLASH) {
            handleContinue(value)
        } else {
            if( value === Selection.FromExperiment ) {
                const experimentDesign: any = await details.fetchDesign(selectedExperiment.experiment)
                setExperimentDesign(experimentDesign);
            } else if (value === Selection.FromTemplate) {
                setExperimentDesign(selectedExperiment.template);
            }
            handleContinue('TMT');
        }
    }
    const disableContinue = () => {
        if (value === Selection.NewDesign || value === Selection.NOT_TMT || value === Selection.FLASH) {
            return false
        }
        return !selectedExperiment
    }

    return <>
        <DialogTitle id={DesignSelectFormDataCy.header} data-cy={DesignSelectFormDataCy.header}>
            New Experiment Design
        </DialogTitle>
        <Box component="form" display="flex" flexDirection="column" sx={{ minHeight: 230 }}
            name="pickcreatetype" action="/" method="POST" noValidate autoComplete="off"
        >
            <DialogContent dividers>
                <FormControl component="fieldset" sx={{ mb: 2 }}>
                    <FormLabel component="legend" sx={{ mb: 1 }}>
                        Select an option to continue:
                    </FormLabel>
                    <RadioGroup row name="createtypes" value={value}
                        onChange={e => {
                            // console.log(`The experiment type being set is ${e.target.value}`)
                            setExperimentDesign(null);
                            setTypeValue(e.target.value)
                            setSelectedExperiment(null)
                        }}
                        aria-label="Options for creating a new experiment"
                    >
                        <FormControlLabel
                            data-cy={DesignSelectFormDataCy.createNew}
                            value={Selection.NewDesign} label="Create New"
                            control={<Radio color="primary"/>}
                        />
                        <FormControlLabel
                            data-cy={DesignSelectFormDataCy.createFromExperiment}
                            value={Selection.FromExperiment} label="Create from Experiment"
                            control={<Radio color="primary"/>}
                        />
                        <FormControlLabel
                            data-cy={DesignSelectFormDataCy.createFromTemplate}
                            value={Selection.FromTemplate} label="Create from Template"
                            control={<Radio color="primary"/>}
                        />
                        <FormControlLabel value={Selection.NOT_TMT} label="Not TMT"
                            control={<Radio color="primary" />}
                        />
                        <FormControlLabel value={Selection.FLASH} label="Create New Flash Degradation"
                                          control={<Radio color="primary" />}
                        />
                    </RadioGroup>
                </FormControl>
                {value === Selection.FromExperiment &&
                    <Fade in={true}>
                        <Autocomplete
                            id="select-from-exp"
                            data-cy={DesignSelectFormDataCy.experimentList}
                            options={experiments}
                            getOptionLabel={(option) => option.experiment}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Experiment Name" variant="outlined"
                                    helperText="Select experiment to create from"/>
                            }
                            onChange={(e: any, newValue: string | null) => {setSelectedExperiment(newValue);}}
                        />
                    </Fade>}
                {value === Selection.FromTemplate &&
                    <Fade in={true}>
                        <Autocomplete
                            id="select-from-template"
                            data-cy={DesignSelectFormDataCy.templateList}
                            options={ExperimentTemplates}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) =>
                                <TextField {...params}
                                    label="Template Name" variant="outlined"
                                    helperText="Select template to create from"/>
                            }
                            onChange={(e: any, newValue: any | null) => {setSelectedExperiment(newValue);}}
                        />
                    </Fade>}
            </DialogContent>
            <DialogActions sx={{ marginTop: 'auto' }}>
                <Button disabled={disableContinue()} onClick={() => submitContinueAsNew()}>
                    Continue
                </Button>
                <Button data-cy={DesignSelectFormDataCy.cancel} onClick={() => closeDialog()}>
                    Cancel
                </Button>
            </DialogActions>
        </Box>
    </>
}

export default DesignSelectForm;