import {GridRenderEditCellParams, GridRowId, GridRowModel} from '@mui/x-data-grid';
import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import EditMode from './EditMode';
import {editRow} from './editHelper';

export default function TimeEdit(props: GridRenderEditCellParams) {
    const [open, setOpen] = useState<boolean>(true)
    const [mode, setMode] = useState<string>('currentOnly')
    const [time, setTime] = useState<string>(props.value as string)

    const handleClose = async () => {
        await props.api.stopCellEditMode({id: props.id, field: props.field})
        setOpen(false)
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const formData = new FormData(event.currentTarget)
        const formJson = Object.fromEntries((formData as any).entries())
        await props.api.setEditCellValue({id: props.id, field: props.field, value: time})
        await handleClose()
        if (mode === "typed") {
            // exclude 'fieldValue' and 'fieldEditMode' keys to get just the checked checkbox keys
            const keys = Object.keys(formJson).filter((k) => !k.startsWith('field'))
            if (keys.length > 0) {
                const rowsIds = props.api.getAllRowIds()
                for (const id of rowsIds) {
                    let row = props.api.getRow(id)
                    if (keys.find((k) => k === row['Sample Type'])) {
                        console.log(`updating matching ROW`, row)
                        await editRow(props.api, id, props.field, time)
                    }
                }
            }
        } else if (mode === 'checkedRows') {
            const map: Map<GridRowId, GridRowModel> = props.api.getSelectedRows()
            for (const key of map.keys()) {
                await editRow(props.api, key, props.field, time)
            }
        }
    }
    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}
        >
            <DialogTitle>Update {props.field}</DialogTitle>
            <DialogContent>
            <TextField autoFocus required margin="dense" id={`${props.field}-edit`}
                       name="fieldValue" label={props.field} type="number" fullWidth
                       value={time}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                           setTime(event.target.value)
                       }}
                       variant="standard" />
            <EditMode mode={mode} setMode={setMode} types={['Unknown', 'QC', 'Blank']}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
               <Button type="submit">Update</Button>
            </DialogActions>
        </Dialog>
    )
}