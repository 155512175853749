import {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import {IPlotProps, IPointData} from "plot";
import {API, graphqlOperation} from "aws-amplify";
import * as queries from "../../../../../../../graphql/queries";
import axios, {AxiosProgressEvent} from "axios";
import Observable from "zen-observable-ts";
import * as subscriptions from "../../../../../../../graphql/subscriptions";
import {
    Alert,
    InputLabel,
    LinearProgress,
    Paper,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, Box,
    TableRow, Typography, Divider
} from "@mui/material";
import { StyledChartTipCircleKey, StyledPlotTipPaper } from '../../../../StyledComponents';
import { dataColors } from '../../../../../../../theme/theme';
import LabellingCheckBoxPlot from "./LabellingCheckBoxPlot/LabellingCheckBoxPlot";
import {ScatterPlot} from "@nivo/scatterplot";
import {
    LabellingCheckDataCy, pcaLabel, plotHelperText,
    processingText,
    psmFileUploadProgressText,
    resultsText,
    resultTableHeaders
} from "../LabellingCheckerModal";
import LabellingTableRow from "./LabellingTableRow";
import ImageExporter from "../../../../../../../util/ImageExporter";
import { get } from "lodash";

export const UploadProgressIds = {
    results: 'label-check-toPdf',
    boxPlot: 'labelling-check-box-plot-toPdf',
    pcaHeader: 'pca-label-toPdf',
    scatterPlot: 'scatter-plot-toPdf'
}
interface ProgressProps {
    psmFile: any;
    design: string;
    tmt: string;
    setChangeFile: (changable: boolean) => void;
    setProcessingFailed: () => void;
}

const UploadProgress = (props: ProgressProps): JSX.Element => {

    const [psmFileProgress, setPsmFileProgress] = useState(0);
    const [labellingResults, setLabellingResults] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [pcaPlot, setPcaPlot] = useState<IPlotProps | null>(null);
    const [pcaError, setPcaError] = useState<string>('');
    const [serieId, setSerieId] =useState<string | null>(null);

    const psmFileName = props.psmFile.name;
    const experiment = props.design;
    const processPcaData = (data: IPointData[]) => {
        const xValues = data.flatMap(point => point.data.map(p => p.x));
        const yValues: number[] = data.flatMap(point => point.data.map(p => p.y));

        const pcaPlot: IPlotProps = {
            points: data,
            yMin: Math.min(...yValues) - 50,
            yMax: Math.max(...yValues) + 50,
            xMin: Math.min(...xValues) - 50,
            xMax: Math.max(...xValues) + 50
        };
        setPcaPlot(pcaPlot);
    }

    useEffect(() => {
        let subscription: any;
        const uploadFile = async () => {
            try {
                const psmUploadResult: any = await API.graphql(graphqlOperation(
                    queries.getLabelEfficiencyUploadUrl, {key: psmFileName}));
                const config = {
                    onUploadProgress: function (progressEvent: AxiosProgressEvent) {

                        setPsmFileProgress((progressEvent.loaded / progressEvent.total!) * 100)
                    },
                };
                await axios.put(psmUploadResult.data.getLabelEfficiencyUploadUrl.url, props.psmFile, config)
                const pca: Observable<object> = API.graphql(graphqlOperation(subscriptions.pcaAnalysisForLabelCheck)) as Observable<object>;
                subscription = pca.subscribe({
                    next: function(result: any) {
                        // console.log('PCA: ', result.value.data.pcaAnalysisForLabelCheck);
                        if (experiment === result.value.data.pcaAnalysisForLabelCheck.experimentName) {
                            const data: IPointData[] = result.value.data.pcaAnalysisForLabelCheck.plot;
                            if (data) {
                                processPcaData(data);
                            } else  {
                                const message = result.value.data.pcaAnalysisForLabelCheck.message
                                    ? result.value.data.pcaAnalysisForLabelCheck.message : 'Unexpected Error'
                                setPcaError(message);
                            }
                            props.setChangeFile(true)
                        }
                    },
                    error(err:any) {
                        console.log('ERROR: ' + err);
                    },
                    complete() {
                        console.log('COMPLETE');
                    }
                });
                const labelingResult: any = await API.graphql(graphqlOperation(queries.getLabelEfficiency, {
                    psmFile: psmFileName, tmt: props.tmt, pdVersion: 'PD_2_5',
                    experimentName: experiment, experimentType: 'DEGRADATION'
                }));
                setLabellingResults(labelingResult.data.getLabelEfficiency);
            } catch (error: any) {
                console.log("LABELING CHECK ERROR", error);

                const errorMessage = get(error, 'errors[0].message', 'unknown error');
                setErrorMessage(`Upload failure: ${error?.message ?? errorMessage}`);
                props.setProcessingFailed();
            }
        };
        uploadFile();
        return function cleanup() {
            if (subscription) {
                subscription.unsubscribe();
            }
            props.setChangeFile(true);
        }
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [props.psmFile, psmFileName, props.tmt]);

    const tip = (node: any) => {
        return (
            <StyledPlotTipPaper elevation={1} sx={{ display: 'flex' }}>
                <StyledChartTipCircleKey sx={{color: node.node.color}} aria-label={node.node.serieId + ' icon'} />
                <Typography variant="body1" fontWeight="medium" sx={{ mx: 0.5, lineHeight: 1 }}>
                    {node.node.serieId}
                </Typography>
            </StyledPlotTipPaper>
        );
    }

    const doMouseEnter = useCallback((data: any) => {
        setSerieId(data.id);
    }, [setSerieId]);

    const doMouseLeave = useCallback(() => {
        setSerieId(null);
    }, [setSerieId]);

    const getNodeSize = useMemo(
        () => (node: any) => {
            if (serieId && serieId === node.serieId) {
                return 18;
            }
            return 9;
        },
        [serieId]
    );

    const PCA = (): JSX.Element => {
        if (pcaError) {
            return <Alert severity="error">No PCA plot available - {pcaError}</Alert>
        } else if (pcaPlot) {
            return <Box id={UploadProgressIds.scatterPlot} display="flex" justifyContent="center">
                    <ScatterPlot
                        data={pcaPlot.points}
                        margin={{top: 10, bottom: 50, left: 55, right: 360}}
                        xScale={{type: 'linear', min: pcaPlot.xMin, max: pcaPlot.xMax}}
                        yScale={{type: 'linear', min: pcaPlot.yMin, max: pcaPlot.yMax}}
                        width={940}
                        height={585}
                        blendMode="multiply"
                        nodeSize={getNodeSize}
                        axisTop={null}
                        axisRight={null}
                        axisLeft={{
                            legend: 'PC2',
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        axisBottom={{
                            legend: plotHelperText,
                            legendOffset: 40,
                            legendPosition: 'middle',
                        }}
                        colors={dataColors.categorical.contrastScatter}
                        legends={[{
                            anchor: 'top-right',
                            direction: 'column',
                            justify: false,
                            translateX: 385,
                            translateY: 0,
                            itemWidth: 360,
                            itemHeight: 12,
                            itemsSpacing: 8,
                            itemDirection: 'left-to-right',
                            symbolSize: 12,
                            symbolShape: 'circle',
                            onMouseEnter: doMouseEnter,
                            onMouseLeave: doMouseLeave,
                            effects: [{
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }]
                        }]}
                        tooltip={tip}
                    />
            </Box>
        } else {
            return <LinearProgress/>
        }

    }

    return <Fragment>

        {(() => {
            if (psmFileProgress === 0)
                return <Box mt={2}>
                    <InputLabel htmlFor="psm-file-progress">{psmFileUploadProgressText}</InputLabel>
                    <LinearProgress/>
                </Box>;
            else if (psmFileProgress < 100)
                return <Box mt={2}>
                    <InputLabel htmlFor="psm-file-progress">{psmFileUploadProgressText}</InputLabel>
                    <LinearProgress variant="determinate" value={psmFileProgress}/>
                </Box>;
            else if (!labellingResults)
                return <Box mt={2}>
                    <InputLabel htmlFor="psm-conversion-progress">{processingText}</InputLabel>
                    <LinearProgress/>
                </Box>;
            else if (labellingResults)
                return <Fragment>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h6" component="h3" sx={{ mb: 2 }}>{resultsText}</Typography>

                    <ImageExporter fileName={`${experiment}_TMT_labelling_check`} exportEnabled={!!pcaPlot}>
                        <Box mt={1} p={3} bgcolor={'background.default'} borderRadius={1}>
                            <TableContainer id={UploadProgressIds.results} component={Paper} sx={{width:'60%'}}>
                                <Table data-cy={LabellingCheckDataCy.resultsTable}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{resultTableHeaders[0]}</TableCell>
                                            <TableCell align="right">{resultTableHeaders[1]}</TableCell>
                                            <TableCell align="right">{resultTableHeaders[2]}</TableCell>
                                            <TableCell align="right">{resultTableHeaders[3]}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <LabellingTableRow name='N-Term' total={labellingResults.totalPeptides}
                                                           labeled={labellingResults.labeledNTermPeptides}/>
                                        <LabellingTableRow name='C-Term K' total={labellingResults.totalKTermPeptides}
                                                           labeled={labellingResults.labelKTermPeptides}/>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                            <Box mt={2} id={UploadProgressIds.boxPlot}>
                                <LabellingCheckBoxPlot boxPlot={labellingResults.boxPlot}/>
                            </Box>
                            <Box mt={2}>
                                <Typography id={UploadProgressIds.pcaHeader} component="h3" variant="h6"
                                    align="center" sx={{ mb: 1 }}
                                >
                                   {pcaLabel}
                                </Typography>
                                <PCA />
                            </Box>
                    </ImageExporter>
                </Fragment>
            else if (errorMessage)
                return <Alert severity="error" sx={{ mt: 2 }}>{errorMessage}</Alert>
        })()}
    </Fragment>
}

export default UploadProgress;