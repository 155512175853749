import {FC, RefObject, useLayoutEffect, useRef, useState} from 'react'
import {StatsData} from 'experiment';
import {StyledCenteringBox} from '../Dashboard/ExperimentsPanel/StyledComponents';
import {Box, CircularProgress, Typography} from '@mui/material';
import {ResponsiveScatterPlot, ScatterPlotLayerProps} from '@nivo/scatterplot';
import debounce from 'lodash/debounce';
import {conditionAcculator} from '../../util/util';


const FluorescencePanel: FC<StatsData> = ({data, loading}) => {

    const ref: RefObject<HTMLDivElement> | null = useRef(null)
    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3)

    useLayoutEffect(() => {
        const handleResize = debounce(() => {
            setGraphHeight(window.innerWidth / 3);
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const MarkerTics = ({
        nodes,
        xScale,
        yScale,
        innerWidth
    }: ScatterPlotLayerProps<{x: number, y: number, markerTics: any[] | undefined}>) => {
        return nodes.map(({id, data, index}) => {
            if (data.markerTics) {
                return <g key={'MyTics' + index} transform={`translate(${innerWidth},0)`}
                          style={{fontFamily: 'sans-serif', fontSize: '11px', fill: 'rgb(51, 51, 51)'}}>
                    {data.markerTics && data.markerTics.map((tic: any, index) => {
                        return <text key={`marker${index}`} transform={`translate(10, ${yScale(tic.value)})`}
                                     dominantBaseline="central" textAnchor="start"
                        >{tic.txt}</text>
                    })}
                </g>
            }
        })
    }

    if (data && data.standards) {
        //const markerTics = [{value: data.experiment_properties.standards_mean_fluorescence_values_RFU[0], txt: data.experiment_properties.standards_concentrations_ng_ul_W[0]}]
        const markerTics = data.standards.map((value: any) => {
          return {value: value.mean_fluorescence_values_RFU, txt: value.concentrations_ng_ul_W}
        })

        const points: any = data.samples.map((sample: any, index: number) => {
            return {
                x: index,
                y: sample.measured_fluorescence_RFU,
                sPlateWell: sample.sample_plate_well,
                aPlateWell: sample.assay_plate_well,
                condition: sample.condition
            }
        })
        // hang the markerTics off of the first point
        points[0]['markerTics'] = markerTics
        // console.log(`THE SAMPLES: `, points)

        let yMin = points.reduce((accumulater: number, current: any) => accumulater < current.y ? accumulater : current.y)
        let yMax = points.reduce((accumulater: number, current: any) => accumulater > current.y ? accumulater : current.y)
        const accumulator: any[] = []
        const plotData = points.reduce(conditionAcculator, accumulator)
        // console.log(`MIN ${yMin} and MAX ${yMax}`, plotData)
        const markers = data.standards.map((val: any) => {
            return {axis: 'y', lineStyle: {stroke: '#FF0000', strokeWidth: 1, opacity: 0.3}, value: val.mean_fluorescence_values_RFU}
        })
        yMin = data.standards.reduce((acc: number, current: any) => {
            return acc < current.mean_fluorescence_values_RFU ? acc : current.mean_fluorescence_values_RFU
        }, yMin)
        yMax = data.standards.reduce((acc: number, current: any) => {
            return acc > current.mean_fluorescence_values_RFU ? acc : current.mean_fluorescence_values_RFU
        }, yMax)


        return (
    <Box ref={ref} height={graphHeight}>
        <ResponsiveScatterPlot
            theme={{axis: {legend: {text: {fontSize: 16}}}}}
            data={plotData}
            // @ts-ignore
            layers={['grid', 'axes', 'markers', 'nodes', 'mesh', 'legends', 'annotations', MarkerTics]}
            margin={{top: 90, right: 140, bottom: 70, left: 90}}
            xScale={{type: 'linear', min: 0, max: 'auto'}}
            yScale={{type: 'linear', min: yMin, max: yMax}}
            blendMode="multiply"
            axisBottom={{tickSize: 5, tickPadding: 5, tickRotation:0,
                legend: 'Samples', legendPosition: 'middle', legendOffset: 46}}
            axisLeft={{tickSize: 5, tickPadding: 5, tickRotation: 0,
                legend: "Fluorescence (RFU)", legendPosition: 'middle',
                legendOffset: -60
            }}
            axisRight={{tickSize: 0, tickValues: [],
                legend: 'Standards (ng / \u00B5l, axis scale adapted to RFU)', legendPosition: 'middle',
                legendOffset: 80
            }}
            tooltip={(d: any) => {
                return <Box width={195} px={1} py={0.5} bgcolor="common.white" boxShadow={2} component="ul" sx={{listStyleType: 'none'}}>
                    <Typography component="li" variant="body2">Sample Plate Well: {d.node.data.sPlateWell}</Typography>
                    <Typography component="li" variant="body2">Assay Plate Well: {d.node.data.aPlateWell}</Typography>
                    <Typography component="li" variant="body2">Measured RFU: {d.node.data.y}</Typography>
                    <Typography component="li" variant="body2">Condition: {d.node.data.condition}</Typography>
                </Box>
                }}
            useMesh={false}
            markers={markers}
        />
    </Box>
        )
    }

    if (loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }

    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}

export default FluorescencePanel