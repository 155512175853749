import {FC, useEffect, useState} from 'react';
import {Box, CardContent, Typography} from '@mui/material';
import NewDesignDetailsPanel from '../../../../visualizations/NewDesignDetailsPanel/NewDesignDetailsPanel';
import FlashXcaliburPanel from '../FlashXcaliburPanel';
import flashService from '../../../../../services/flashService';
import {FlashAnalysis, FlashStatistics} from '../../../../../API';
import FlashStatsPanel from './FlashStatsPanel';

interface FlashDetailsProps {
    experiment: {experiment: string, status: string};
    experimentType: ExperimentType;
    design: any;
    xcaliburReady: boolean;
    exportReady: boolean;
    analysis: FlashAnalysis | null | undefined;
    statistics: FlashStatistics | undefined;
}

const FlashDetails: FC<FlashDetailsProps> = ({experiment,
                                                experimentType,
                                                design,
                                                xcaliburReady,
                                                exportReady,
                                                analysis,
                                                statistics}) => {

    const [xcaliburRows, setXcaliburRows] = useState<any>(null)
    const [header, setHeader] = useState<string|null>(null)

    useEffect(() => {
        const loadX = async (name: string) => {
            const data = await flashService.fetchXcaliburSampleList(name)
            // sometimes we think we're ready with data, but we're not
            if (data) {
                if (analysis?.record_value?.samples_selected_by_user) {
                    const rows: any[] = data.rows
                    rows.forEach((row: any) => {
                        if (analysis!.record_value!.samples_selected_by_user!.includes(row['File Name'])) {
                            row['processed'] = true
                        }
                    })
                }
                setXcaliburRows(data.rows)
                setHeader(data.header)
            }
        }
        // console.log(`Experiment is ${experiment.experiment} and X ready is ${xcaliburRows}`)
        // when switching between experiments, sometimes xcaliburReady lags
        if (design?.peptideBarcode && xcaliburReady) {
            setXcaliburRows(null)
            loadX(experiment.experiment)
        } else {
            setXcaliburRows(null)
        }

    }, [experiment, xcaliburReady, analysis, design?.peptideBarcode])

    if (!design) {
        return null
    }

    return (
        <CardContent>
            <Box mb={4} id={'flashDetails-toPdf'}>
                <Typography gutterBottom variant="h5" component="h2">
                    Overview
                </Typography>
                <NewDesignDetailsPanel experimentDesign={design} experimentFolder={design.experiment_folder}
                                       experimentType={experimentType}>
                    <Typography component="li" variant="body1">
                        <strong>Samples per block:</strong> {design?.noOfSamples}
                    </Typography>
                    <Typography component="li" variant="body1">
                        <strong>Buffer Volume in &#181;L</strong> {design?.bufferVolume}
                    </Typography>
                    <Typography component="li" variant="body1">
                        <strong>Scinamic plate map:</strong> {design?.scinamicPlateMap}
                    </Typography>
                    <Typography component="li" variant="body1">
                        <strong>Cell culture barcode:</strong> {design?.cellCultureBarcode}
                    </Typography>
                    <Typography component="li" variant="body1">
                        <strong>Peptide barcode:</strong> {design?.peptideBarcode}
                    </Typography>
                </NewDesignDetailsPanel>
            </Box>
            <FlashStatsPanel analysis={analysis} statistics={statistics}/>
            {xcaliburRows && <>
                <Typography gutterBottom variant="h5" component="h2">
                    Xcalibur Sample List
                </Typography>
                <FlashXcaliburPanel experiment={experiment}
                                    xcaliburHeader={header!}
                                    xcaliburRows={xcaliburRows} exportReady={exportReady}/>
                </>}
        </CardContent>
    )
}

export default FlashDetails