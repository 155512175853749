import {ChangeEvent, useEffect, useState} from "react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
    Box, Button, Checkbox,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, 
    MenuItem, TextField, Typography
} from '@mui/material';
import PropTypes from "prop-types";
import UploadProgress from "./UploadProgress";
import FileField from "../../../../../FileField";
import useMounted from "../../../../../../util/useMounted";
import {Condition, LcmsStatus, PDVersion} from "../../../../../../API";
import details from '../../../../../../services/details'
import lcmsStatus from '../../../../../../services/lcmsStatus';
import {activeInstruments} from "../../../../../../store/Instruments";

const copyPasteHelp =
    "To copy the full path of a file or folder on Windows 10/11, hold Shift and right-click the file or folder, "
    +" then select 'Copy as Path' from the context menu. Press Ctrl+V (or use the context menu) to paste the path here."
export const uploadModal = {
    title: {
        id: 'simple-modal-title',
    },
    designFile: {
        id: 'design-file',
        label: 'Design File'
    },
    pdPsmFile: {
        id: 'pd-psm-file',
        dataCy: 'pd-psm-file',
        label: 'PD PSM File',
    },
    experimentName: {
        id: 'experiment-name',
        label: 'Experiment Name',
    },
    experimentType: {
        id: 'experiment-type',
        dataCy: 'experiment-type',
        label: 'Experiment Type',
    },
    instrument: {
        id: 'instrument',
        dataCy: 'instrument',
        label: 'Instrument'
    },
    database: {
        id: 'database',
        dataCy: 'database',
        label: 'Database'
    },
    pdVersion: {
        id: 'pd-version',
        dataCy: 'pd-version',
        label: 'PD Version'
    },
    injectionNumber: {
        id: 'injection-number',
        dataCy: 'injection-number',
        label: 'Injection Number'
    },
    channelCount: {
        id: 'channel-count',
        dataCy: 'channel-count',
        label: 'Channels'
    },
    tmtLot: {
        id: 'tmt-lot',
        dataCy: 'tmt-lot',
        label: 'TMT Lot',
        helperSelector: 'tmt-lot-helper-text'
    },
    psmThreshold: {
        id: 'psm-threshold',
        dataCy: 'psm-threshold',
        label: 'PSM count'
    },
    missingChannelThreshold: {
        id: 'missing-channel-threshold',
        dataCy: 'missing-channel-threshold',
        label: 'Allowed number of empty channels'
    },
    isolationInterference: {
        id: 'isolation-interference-cutoff',
        dataCy: 'isolation-interference-cutoff',
        label: 'Isolation Interference'
    },
    averageSignalNoise: {
        id: 'average-reporter-sn-cutoff',
        dataCy: 'average-reporter-sn-cutoff',
        label: 'Average Reporter Signal Noise'
    },
    spsMassMatches: {
        id: 'sps-mass-matches-cutoff',
        dataCy: 'sps-mass-matches-cutoff',
        label: 'SPS Mass Matches'
    },
    submitButton: {
        id: 'submit-btn',
        dataCy: 'submit-btn',
    },
    cancelButton: {
        id: 'cancel-btn',
        dataCy: 'cancel-btn',
    },
    errorMessage: {
        dataCy: 'error-message'
    }
};

export const experimentTypeValues = {
    degradation: 'Degradation',
    turboId: 'TurboID',
    yeastTko: 'Yeast TKO'
}

export const pdVersionValues = {
    version2_3: '2.3',
    version2_4: '2.4',
    version2_5: '2.5',
    version3_0: '3.0',
    version3_1: '3.1',

}

type ExperimentUploadModalProps = ModalProps & { currentExperiment: any }

export const injectionNumberHelperText = 'Please specify an injection number between 1 and 9999';
export const oneLotNumberHelperText
    = 'Please specify either one or two 8 character alphanumeric lot numbers (space separated if using two)';
export const twoLotNumbersHelperText
    = 'Please specify 2 space separated lot numbers of 8 alphanumeric characters each';
export const supportedChannelsError = 'File does not contain the appropriate number of supported channels.';
const pd3dot0AmbiguityOptions = {Unambiguous: true, Selected: true, Rejected: false}
const formerAmbiguityOptions = {Unambiguous: true, Selected: false, Rejected: false}
const pd3dot0IdentifyingNodeOptions = {Sequest: true, Comet: true}
const formerIdentifyingNodeOptions = {Sequest: false, Comet: false}

export const defaultValues = {
    isolationInterferenceCutoff: 50,
    averageReporterSNCutoff: 10,
    missingChannelThreshold: 0,
    database: 'UP000005640_9606_Human.fasta',
    pdVersion: PDVersion.PD_3_1,
    spsMassMatches: 0,
    psmAmbiguityOptions: pd3dot0AmbiguityOptions,
    identifyingNodeOptions: pd3dot0IdentifyingNodeOptions
}

function getOptions(refOptions: { [key: string]: boolean}, trueKeys: string[] | null) {
    const options: { [key: string]: boolean} = {}
    for (const [key] of Object.entries(refOptions)) {
        const index = trueKeys ? trueKeys.findIndex((el: string) => el === key) : -1
        options[key] = index > -1
    }
    return options
}

export default function ExperimentUploadModal({
                                                  open,
                                                  handleClose,
                                                  currentExperiment
                                              }: ExperimentUploadModalProps) {
    const TEN_SIXTEEN_LOT_REGEX = /^[a-zA-z0-9]{8}$/;
    const ELEVEN_LOT_REGEX = /^[a-zA-z0-9]{8} [a-zA-z0-9]{8}$/;

    const isMounted = useMounted();

    const [experimentHelp, setExperimentHelp] = useState<string>('');
    const [psmExportFile, setPsmExportFile] = useState<File>();
    const [experimentFolder, setExperimentFolder] = useState<string>('');
    const [benchling, setBenchling] = useState<string>('')
    const [database, setDatabase] = useState<string>('');
    const [instrument, setInstrument] = useState<string>('');
    const [psmThreshold, setPsmThreshold] = useState<number>(1);
    const [isolationInterferenceCutoff, setIsolationInterferenceCutoff] = useState<number>(defaultValues.isolationInterferenceCutoff);
    const [averageReporterSNCutoff, setAverageReporterSNCutoff] = useState<number>(defaultValues.averageReporterSNCutoff);
    const [missingChannelThreshold, setMissingChannelThreshold] = useState<number>(defaultValues.missingChannelThreshold);
    const [ignoreSpsMassMatches, setIgnoreSpsMassMatches] = useState(false);
    const [spsMassMatches, setSpsMassMatches] = useState<number|null>(defaultValues.spsMassMatches);
    const [pdVersion, setPdVersion] = useState<string>(defaultValues.pdVersion);
    const [injectionNumber, setInjectionNumber] = useState<number|null>(null);
    const [tmt, setTmt] = useState<string>('');
    const [tmtLot, setTmtLot] = useState<string>('');
    const [tmtLotHelp, setTmtLotHelp] = useState<string>('');
    const [psmFileErrors, setPsmFileErrors] = useState<string[]>([]);
    const [channels, setChannels] = useState<string[]>([]);
    const [recordType, setRecordType] = useState<string>('');
    const [invalid, setInvalid] = useState<boolean>(false);
    const [upload, setUpload] = useState<boolean>(false);
    const [fastaDatabases, setFastaDatabases] = useState<string[]>([]);
    const [skipNormalization, setSkipNormalization] = useState<boolean>(false)
    const [psmAmbiguityOptions, setPsmAmbiguityOptions] = useState<{[key: string]: boolean}>(defaultValues.psmAmbiguityOptions)
    const [identifyingNodeOptions, setIdentifyingNodeOptions] = useState<{[key: string]: boolean}>(defaultValues.identifyingNodeOptions)

    useEffect(() => {
        const fetchDatabaseData = async () => {
            try {
                const dbs: string[] = await details.fetchFastaDatabases()
                setFastaDatabases(dbs);
            } catch (error) {
                console.error('Fasta Database Error', error);
            }
        };
        fetchDatabaseData().then();
    }, [open]);

    useEffect(() => {
        const fetchDesign = async () => {
            try {
                const design = await details.fetchDesign(currentExperiment.experiment);
                if (design) {
                    const conditions: Condition[] = design.conditions
                    // @ts-ignore
                    const channels = conditions.map(x => x.channel.toString());
                    let analysis
                    let lcmsRecord: LcmsStatus | null = null
                    if (currentExperiment.status === 'PROCESSED') {
                        analysis = await details.fetchAnalysis(currentExperiment.experiment);
                    } else {
                        const filter = {start: currentExperiment.date.toISOString().slice(0,10),
                            end: currentExperiment.date.toISOString().slice(0,10),
                            experiment: currentExperiment.experiment}
                        const lcmsRecords = await lcmsStatus.fetchData(filter)
                        if (lcmsRecords && lcmsRecords.length === 1) {
                            lcmsRecord = lcmsRecords[0]
                        }
                    }
                    if (isMounted()) {
                        setChannels(channels);
                        setRecordType(design.type);
                        design.benchling && setBenchling(design.benchling)
                        design.tmt_lot && setTmtLot(design.tmt_lot)
                        if (design.type !== 'QC') {
                            if (channels.length === 11) {
                                setTmtLotHelp(twoLotNumbersHelperText)
                            } else {
                                setTmtLotHelp(oneLotNumberHelperText)
                            }
                            setMissingChannelThreshold(defaultValues.missingChannelThreshold)
                            setDatabase(defaultValues.database);
                        } else {
                            setTmtLotHelp('');
                            setMissingChannelThreshold(3)
                            setDatabase('')
                        }
                        if (analysis) {
                            setDatabase(analysis.database)
                            setInstrument(analysis.instrument)
                            setPdVersion(analysis.pd_version)
                            setPsmThreshold(analysis.psm_threshold)
                            setIsolationInterferenceCutoff(analysis.isolation_interference_cutoff)
                            setAverageReporterSNCutoff(analysis.average_reporter_sn_cutoff)
                            setMissingChannelThreshold(analysis.missing_channel_threshold)
                            setSpsMassMatches(analysis.sps_mass_matches_cutoff)
                            setIgnoreSpsMassMatches(analysis.ignore_sps_mass_matches)
                            setSkipNormalization(analysis.skip_normalization)
                            const ambiguityOptions = (analysis.pd_version === PDVersion.PD_3_0 || analysis.pd_version === PDVersion.PD_3_1) ? pd3dot0AmbiguityOptions : formerAmbiguityOptions
                            const options: { [key: string]: boolean} = getOptions(ambiguityOptions, analysis.psm_ambiguity)
                            setPsmAmbiguityOptions(options)
                            const nodeOptions = (analysis.pd_version === PDVersion.PD_3_0 || analysis.pd_version === PDVersion.PD_3_1) ? pd3dot0IdentifyingNodeOptions : formerIdentifyingNodeOptions
                            const newNodeOptions: { [key: string]: boolean} = getOptions(nodeOptions, analysis.identifying_node)
                            setIdentifyingNodeOptions(newNodeOptions)
                            analysis.experiment_folder && setExperimentFolder(analysis.experiment_folder)
                        } else if (lcmsRecord && lcmsRecord.instrument) {
                            setInstrument(lcmsRecord.instrument)
                        }
                    }
                } else {
                    isMounted() && setChannels([]);
                    isMounted() && setRecordType('');
                }
            } catch (error) {
                console.error(`Error fetching design for ${currentExperiment.experiment}`, error)
            }
        };
        fetchDesign().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    function closeDialog() {
        setUpload(false);
        setInvalid(false);
        setPsmExportFile(undefined);
        setExperimentFolder('')
        setPsmFileErrors([])
        setTmt('');
        setChannels([]);
        setPdVersion(defaultValues.pdVersion);
        setInjectionNumber(null);
        setTmtLot('');
        setDatabase(defaultValues.database);
        setInstrument('');
        setTmtLotHelp('');
        setExperimentHelp('');
        setSpsMassMatches(defaultValues.spsMassMatches);
        setIgnoreSpsMassMatches(false);
        setIsolationInterferenceCutoff(defaultValues.isolationInterferenceCutoff);
        setAverageReporterSNCutoff(defaultValues.averageReporterSNCutoff);
        setMissingChannelThreshold(defaultValues.missingChannelThreshold);
        setSkipNormalization(false);
        setPsmAmbiguityOptions(defaultValues.psmAmbiguityOptions);
        setIdentifyingNodeOptions(defaultValues.identifyingNodeOptions)
        handleClose();
    }

    function handlePsmFileChange(file: File) {

        const fileReader = new FileReader();

        fileReader.onload = () => {
            const text = fileReader.result;

            function psmChannelsMatchDesign(psmChannels: string[], designChannels: string[]): [string[], string[]] {
                // PSM column headers look like 'Abundance: XXX'
                // Stored channels looks like '_XXX_
                const cleanPsmChannels = psmChannels.map((channel: string) => {
                    const c = channel.split(' ')[1].replace('"','');
                    // 10 plex experiment PSM files list channel 131N as 'Abundance: 131'
                    if (channels.length === 10 && c === '131') {
                        return '131N'
                    }
                    return c;
                });
                const cleanDesignChannels = designChannels?.map(channel => channel.replaceAll('_',''));

                const missingFromPsm = cleanDesignChannels.filter(channel => !cleanPsmChannels.includes(channel));
                const missingFromDesign = cleanPsmChannels.filter(channel => !cleanDesignChannels.includes(channel));

                return[missingFromPsm, missingFromDesign]
            }

            function addErrorAboutMissingExperimentChannels(psmFileErrorAggregator: string[], missingFromPsm: string[]) {
                if (missingFromPsm.length > 0)
                    psmFileErrorAggregator.push("Channel(s) in the experiment design but not in the uploaded file: "
                        + missingFromPsm.toString())
            }

            function addErrorAboutUnknownUploadedChannels(psmFileErrorAggregator: string[], missingFromDesign: string[]) {
                if (missingFromDesign.length > 0)
                    psmFileErrorAggregator.push("Channel(s) in the uploaded PSM file but not in the experiment design: "
                        + missingFromDesign.toString())
            }

            if (text) {
                const lines = text.toString().split(/\n/);
                const columns = lines[0].split(/\t/).map(el => el.replaceAll('"', ''));
                const psmChannels = columns.filter(column => column.toLowerCase().includes('abundance'));
                const isIntensityIncluded = columns
                    .filter(column => column === 'Intensity').length > 0;

                const [missingFromPsm, missingFromDesign] = psmChannelsMatchDesign(psmChannels, channels)
                let psmFileErrorAggregator: string[] = []
                if (missingFromPsm.length === 0 && missingFromDesign.length === 0) {
                    const tmtString = `TMT_${psmChannels.length}_PLEX`;
                    setTmt(tmtString);
                } else {
                    addErrorAboutMissingExperimentChannels(psmFileErrorAggregator, missingFromPsm);
                    addErrorAboutUnknownUploadedChannels(psmFileErrorAggregator, missingFromDesign);
                }
                if (!isIntensityIncluded) {
                    psmFileErrorAggregator.push(
                        "The provided PSMs file is missing the 'Intensity' column. " +
                        "Please double check your input file. " +
                        "Maybe you've forgotten to export it in Proteome Discoverer.")
                }
                if (psmFileErrorAggregator.length > 0)
                    setPsmFileErrors(psmFileErrorAggregator)
            } else {
                setPsmFileErrors(['Unable to read psm file.']);
            }
        };
        if (file) {
            fileReader.readAsText(file);
        } else {
            setPsmFileErrors([]);
            setTmt('');
        }
    }

    function updateTmtLotHelp(inputValue: string, tmt: string) {
        let tmtLot: string = inputValue;
            if (tmt === 'TMT_11_PLEX' && !ELEVEN_LOT_REGEX.test(tmtLot)) {
                setTmtLotHelp(twoLotNumbersHelperText);
            } else if (tmt !== 'TMT_11_PLEX'
                && !(TEN_SIXTEEN_LOT_REGEX.test(tmtLot) || ELEVEN_LOT_REGEX.test(tmtLot))) {
                setTmtLotHelp(oneLotNumberHelperText);
            } else {
                setTmtLotHelp('');
            }
        return tmtLot;
    }

    function validateTmt() {
        if (recordType === 'QC' && !tmtLot) {
            return true;
        }
        return tmt && tmtLot && !tmtLotHelp;
    }

    function validateTmtError(tmtLot: string) {
        return !(recordType === 'QC' && !tmtLot);
    }

    function handleSpsMassMatches(event: ChangeEvent<HTMLInputElement>) {
        const raw = event.target.value;
        const input = parseInt(raw.substring(0,2));
        setSpsMassMatches(input);
    }

    function benchlingChange(event: ChangeEvent<HTMLInputElement>) {
        setBenchling(event.target.value)
    }

    const toggleIgnoreSpsMatches = () => {
        if (ignoreSpsMassMatches) {
            setSpsMassMatches(defaultValues.spsMassMatches);
            setIgnoreSpsMassMatches(false);
        } else {
            setSpsMassMatches(null);
            setIgnoreSpsMassMatches(true);
        }
    }

    const toggleNormalization = () => {
        setSkipNormalization(!skipNormalization)
    }

    const handlePdVersionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPdVersion(event.target.value)
        if (event.target.value === PDVersion.PD_3_0 || event.target.value === PDVersion.PD_3_1) {
            setPsmAmbiguityOptions(pd3dot0AmbiguityOptions)
            setIdentifyingNodeOptions(pd3dot0IdentifyingNodeOptions)
        } else {
            setPsmAmbiguityOptions(formerAmbiguityOptions)
            setIdentifyingNodeOptions(formerIdentifyingNodeOptions)
        }
    }


    const handleAmbiguityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPsmAmbiguityOptions({
            ...psmAmbiguityOptions,
            [event.target.name]: event.target.checked
        })
    }

    const handleIdentifyingNodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIdentifyingNodeOptions({
            ...identifyingNodeOptions,
            [event.target.name]: event.target.checked
        })
    }
    const handleFolderChange = async (event: ChangeEvent<HTMLInputElement>) => {
        setExperimentFolder(event.target.value)
    }
    const {Unambiguous, Selected, Rejected} = psmAmbiguityOptions
    const {Sequest, Comet} = identifyingNodeOptions

    return (
    <Dialog
        fullWidth
        maxWidth={'md'}
        PaperProps={{
            square: true,
            elevation: 0,
            sx: {border: '2px solid black'}
        }}
        open={open}
        onClose={() => handleClose}
        aria-labelledby={uploadModal.title.id}
    >
        <DialogTitle id={uploadModal.title.id}>Upload Experiment - {currentExperiment.experiment}</DialogTitle>
        <Box component="form" display="flex" flexDirection="column" sx={{ overflowY: 'hidden' }}
            action="/" method="POST" noValidate autoComplete="off"
            // @ts-ignore
            onSubmit={e => {
                e.preventDefault();
                // console.log(`The selected instrument is ${instrument} for ${recordType}`)
                if (currentExperiment.experiment && psmExportFile && database && instrument !== 'unassigned'
                    && !isNaN(psmThreshold)
                    && !isNaN(isolationInterferenceCutoff) && !isNaN(averageReporterSNCutoff)
                    && !isNaN(missingChannelThreshold) && pdVersion && validateTmt() && !experimentHelp
                    && (ignoreSpsMassMatches || (spsMassMatches !== null && !isNaN(spsMassMatches)))
                    && (recordType === 'QC' ? (injectionNumber !== null && injectionNumber <= 9999 && injectionNumber >= 1) : true )) {
                    setUpload(true);
                } else {

                    setInvalid(true);
                }
            }}
        >
            <DialogContent sx={{ minHeight: 600, maxHeight: '75vh', '& legend': { fontWeight: 500 }}} dividers>
               <FormControl fullWidth component="fieldset" id="fset-readonly-details"
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'baseline',
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                }}>
                   <Typography component="legend" variant="srOnly">Experiment Details</Typography>
                   <TextField id={uploadModal.experimentType.id}
                               data-cy={uploadModal.experimentType.dataCy}
                               value={recordType}
                               label={uploadModal.experimentType.label}
                               variant="standard"
                               InputProps={{
                                   readOnly: true,
                               }}>
                    </TextField>
                    <TextField id={uploadModal.channelCount.id}
                               data-cy={uploadModal.channelCount.dataCy}
                               value={channels.length}
                               label={uploadModal.channelCount.label}
                               variant="standard"
                               InputProps={{
                                   readOnly: true,
                               }}>
                    </TextField>
                </FormControl>
                
                <FormControl fullWidth component="fieldset" id="fset-file" sx={{ mt: 0 }}>
                    <FormLabel component="legend">PSM File</FormLabel>
                    <Box ml={1} mr={1} mt={0} width={'calc(100% - 16px)'}>
                        <FileField id={uploadModal.pdPsmFile.id}
                                   dataCy={uploadModal.pdPsmFile.dataCy}
                                   label={uploadModal.pdPsmFile.label}
                                   validate={invalid || psmFileErrors.length !== 0}
                                   file={psmExportFile}
                                   setFile={(file) => setPsmExportFile(file)}
                                   acceptedFiles={['text/plain']}
                                   onChange={handlePsmFileChange}
                        />
                    </Box>
                </FormControl>

                <FormControl fullWidth component="fieldset" id="fset-file" sx={{ mt: 0 }}>
                    <TextField id="expFolderId" label="Experiment Folder" variant="standard" value={experimentFolder}
                        onChange={handleFolderChange} helperText={copyPasteHelp}/>
                </FormControl>

                <FormControl fullWidth component="fieldset" id="fset-params"
                    sx={{
                        mt: 2,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        '& > .MuiTextField-root': { m: 1, width: '33ch' },
                }}>
                    <FormLabel component="legend">Parameters</FormLabel>
                    <TextField id="input-benchling" name="benchling" label="Benchling"
                               value={benchling}
                               variant="standard"
                               onChange={benchlingChange} />
                    <TextField id={uploadModal.instrument.id}
                               data-cy={uploadModal.instrument.dataCy} 
                               select
                               label={uploadModal.instrument.label}
                               value={instrument || ''}
                               onChange={e => setInstrument(e.target.value)}
                               variant="standard"
                               error={invalid && instrument === 'unassigned'}
                               helperText="Please select the experimental instrument"
                    >
                        {activeInstruments.map((instrument, idx) => (
                            <MenuItem key={`instrument-option-${idx}`} value={instrument}>{instrument}</MenuItem>
                        ))}
                    </TextField>
                    <TextField id={uploadModal.database.id}
                               data-cy={uploadModal.database.dataCy}
                               select
                               label={uploadModal.database.label}
                               variant="standard"
                               value={database || ''}
                               onChange={e => setDatabase(e.target.value)}
                               error={invalid && !database}
                    >
                        {fastaDatabases.map((file: string) => (
                            <MenuItem key={file} value={file}>
                                {file.slice(0, file.lastIndexOf('.'))}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField id={uploadModal.pdVersion.id}
                               data-cy={uploadModal.pdVersion.dataCy}
                               select
                               label={uploadModal.pdVersion.label}
                               variant="standard"
                               value={pdVersion || ''}
                               onChange={handlePdVersionChange}
                               error={invalid && !pdVersion}
                    >
                        <MenuItem value={PDVersion.PD_2_3}>{pdVersionValues.version2_3}</MenuItem>
                        <MenuItem value={PDVersion.PD_2_4}>{pdVersionValues.version2_4}</MenuItem>
                        <MenuItem value={PDVersion.PD_2_5}>{pdVersionValues.version2_5}</MenuItem>
                        <MenuItem value={PDVersion.PD_3_0}>{pdVersionValues.version3_0}</MenuItem>
                        <MenuItem value={PDVersion.PD_3_1}>{pdVersionValues.version3_1}</MenuItem>
                    </TextField>
                    {recordType === 'QC' ?
                    <TextField id={uploadModal.injectionNumber.id}
                               data-cy={uploadModal.injectionNumber.dataCy}
                               variant="standard"
                               onChange={(e) => setInjectionNumber(parseInt(e.target.value))}
                               label={uploadModal.injectionNumber.label}
                               value={injectionNumber === null || isNaN(injectionNumber) || injectionNumber > 9999 || injectionNumber < 1 ? '' : injectionNumber}
                               error={invalid && (injectionNumber === null || isNaN(injectionNumber) || injectionNumber > 9999 || injectionNumber < 1 )}
                               helperText={injectionNumberHelperText}                               
                    />
                    :  <TextField id={uploadModal.tmtLot.id}
                               data-cy={uploadModal.tmtLot.dataCy}
                               onChange={(e) => {
                                   let val = e.target.value.trimStart();
                                   setTmtLot(val);
                                   updateTmtLotHelp(val, tmt);
                               }}
                               label={uploadModal.tmtLot.label}
                               value={tmtLot}
                               variant="standard"
                               error={invalid && validateTmtError(tmtLot) && (!tmtLot || tmtLotHelp.length > 0)}
                               helperText={tmtLotHelp}
                    />}
                    <TextField id={uploadModal.missingChannelThreshold.id}
                               data-cy={uploadModal.missingChannelThreshold.dataCy}
                               onChange={(e) => setMissingChannelThreshold(parseInt(e.target.value))}
                               label={uploadModal.missingChannelThreshold.label}
                               value={isNaN(missingChannelThreshold) ? '' : missingChannelThreshold}
                               error={invalid && isNaN(missingChannelThreshold)}
                               variant="standard"
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">{"<="}</InputAdornment>,
                               }}
                    />
                    <TextField id={uploadModal.isolationInterference.id}
                               data-cy={uploadModal.isolationInterference.dataCy}
                               onChange={(e) => setIsolationInterferenceCutoff(parseFloat(e.target.value))}
                               label={uploadModal.isolationInterference.label}
                               value={isNaN(isolationInterferenceCutoff) ? '' : isolationInterferenceCutoff}
                               error={invalid && isNaN(isolationInterferenceCutoff)}
                               variant="standard"
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">{"<="}</InputAdornment>,
                               }}
                    />
                    <TextField id={uploadModal.averageSignalNoise.id}
                               data-cy={uploadModal.averageSignalNoise.dataCy}
                               onChange={(e) => setAverageReporterSNCutoff(parseFloat(e.target.value))}
                               label={uploadModal.averageSignalNoise.label}
                               value={isNaN(averageReporterSNCutoff) ? '' : averageReporterSNCutoff}
                               error={invalid && isNaN(averageReporterSNCutoff)}
                               variant="standard"
                               InputProps={{
                                   startAdornment: <InputAdornment position="start">{">="}</InputAdornment>,
                               }}
                    />
                    <FormGroup sx={{
                        m: 1,
                        width: '33ch',
                        flexDirection: 'row',
                        '& > *': { width: '16ch'}
                    }}>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{ verticalAlign: 'bottom' }}
                                checked={ignoreSpsMassMatches}
                                onClick={toggleIgnoreSpsMatches}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                name="ignoreSps"
                            />}
                            label={
                                <Typography component="span" variant="body2" color="text.secondary">
                                    Ignore SPS Mass Matches
                                </Typography>
                            }
                        />
                        <TextField id={uploadModal.spsMassMatches.id}
                            data-cy={uploadModal.spsMassMatches.dataCy}
                            onChange={handleSpsMassMatches}
                            label={uploadModal.spsMassMatches.label}
                            value={(spsMassMatches === null || isNaN(spsMassMatches)) ? '' : spsMassMatches}
                            error={invalid && (spsMassMatches === null || isNaN(spsMassMatches)) && !ignoreSpsMassMatches}
                            variant="standard"
                            disabled={ignoreSpsMassMatches}
                            InputProps={{
                               startAdornment: <InputAdornment position="start">{">="}</InputAdornment>,
                            }}
                        />
                    </FormGroup>
                    {(pdVersion === PDVersion.PD_3_0 || pdVersion === PDVersion.PD_3_1) ? <>

                    <FormControl sx={{m: 1, width: '33ch'}} component="fieldset" variant="standard">
                        <FormLabel component="legend">PSM Ambiguity</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={Unambiguous} onChange={handleAmbiguityChange} name="Unambiguous" sx={{padding: 0}}/>
                            } label="Unambiguous"/>
                            <FormControlLabel control={
                                <Checkbox checked={Selected} onChange={handleAmbiguityChange} name="Selected" sx={{padding: 0}}/>
                            } label="Selected"/>
                            <FormControlLabel control={
                                <Checkbox checked={Rejected} onChange={handleAmbiguityChange} name="Rejected" sx={{padding: 0}}/>
                            } label="Rejected"/>
                        </FormGroup>
                    </FormControl>
                    <FormControl sx={{m: 1, width: '33ch'}} component="fieldset" variant="standard">
                        <FormLabel component="legend">Identifying Node</FormLabel>
                        <FormGroup>
                            <FormControlLabel control={
                                <Checkbox checked={Sequest} onChange={handleIdentifyingNodeChange} name="Sequest"  sx={{padding: 0}}/>
                                } label="Sequest" />
                            <FormControlLabel control={
                                <Checkbox checked={Comet} onChange={handleIdentifyingNodeChange} name="Comet" sx={{padding: 0}}/>
                            } label="Comet" />
                        </FormGroup>
                    </FormControl>
                    </> : null}
                    <FormGroup sx={{
                        m: 1,
                        width: '30ch',
                        flexDirection: 'row',
                        '& > *': { width: '20ch'}
                    }}>
                        <FormControlLabel
                            control={<Checkbox
                                sx={{ verticalAlign: 'bottom' }}
                                checked={skipNormalization}
                                onClick={toggleNormalization}
                                icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                name="normalization"
                            />}
                            label={
                                <Typography component="span" variant="body2" color="text.secondary">
                                    Do NOT normalize TMT channels
                                </Typography>
                            }
                        />
                    </FormGroup>
                    
                </FormControl>
                {upload &&
                <Box sx={{ '& > label': { mt: 2, mb: 1 } }}>
                    <UploadProgress experimentName={currentExperiment.experiment} experimentType={recordType}
                                psmFile={psmExportFile} database={database} instrument={instrument}
                                benchling={benchling}
                                experimentFolder={experimentFolder}
                                psmThreshold={psmThreshold}
                                isolationInterferenceCutoff={isolationInterferenceCutoff}
                                averageReporterSNCutoff={averageReporterSNCutoff}
                                missingChannelThreshold={missingChannelThreshold}
                                spsMassMatches={spsMassMatches}
                                ignoreSpsMassMatches={ignoreSpsMassMatches}
                                psmAmbiguityOptions={Object.entries(psmAmbiguityOptions)
                                    .filter((entry) => entry[1]).map((entry) => entry[0])}
                                identifyingNodeOptions={Object.entries(identifyingNodeOptions)
                                    .filter((entry) => entry[1]).map((entry) => entry[0])}
                                skipNormalization={skipNormalization}
                                tmt={tmt} tmtLot={tmtLot} pdVersion={pdVersion} injectionNumber={injectionNumber}
                                handleUploadFinished={closeDialog}/>
                </Box>
                }
            </DialogContent>
            {upload ?
            <DialogActions>
                <Button onClick={closeDialog}>Close</Button>
            </DialogActions>
            :
            <DialogActions>
                {psmFileErrors.length !== 0 ?
                <Box width="60%" mr={'auto'}>
                    <Typography data-cy={uploadModal.errorMessage.dataCy}
                        variant="subtitle2" component="strong" color="error"
                    >
                        {psmFileErrors.map((line, index) => <span key={index}>{line}</span>)}
                    </Typography>
                </Box>
                : null }
                <Button
                    id={uploadModal.submitButton.id}
                    data-cy={uploadModal.submitButton.dataCy}
                    disabled={psmFileErrors.length !== 0}
                    type="submit"
                >
                    Upload
                </Button>
                <Button
                    id={uploadModal.cancelButton.id}
                    data-cy={uploadModal.cancelButton.dataCy}
                    onClick={() => closeDialog()}
                >
                    Cancel
                </Button>
            </DialogActions>
            }
        </Box>
    </Dialog>
    );
}

ExperimentUploadModal.propTypes = {
    open: PropTypes.bool.isRequired,
};
