import {TableCell, TableRow} from "@mui/material";

interface LabellingTableRowProps {
    name: string;
    total: number;
    labeled: number
}

const LabellingTableRow = (props: LabellingTableRowProps): JSX.Element => {

    return <TableRow>
        <TableCell component="th" scope="row">
            {props.name}
        </TableCell>
        <TableCell align="right">{props.total}</TableCell>
        <TableCell align="right">{props.total - props.labeled}</TableCell>
        <TableCell align="right">{((props.labeled / props.total) * 100).toFixed(2) + "%"}</TableCell>
    </TableRow>
}

export default LabellingTableRow;