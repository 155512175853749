import React, {useContext, useEffect, useState} from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import {DesignPanelDataCy} from "./DesignPanel.cy";
import {ReferenceContext} from '../../../../../store/ReferenceContext';
import {createCellMap} from '../../../../../util/util';

export const channels = ["126_", "127N", "127C", "128N", "128C", "129N", "129C", "130N", "130C", "131N", "131C", "132N", "132C", "133N", "133C", "134N"];

type DesignPanelProps = {
    experimentDesign: any;
}

export default function DesignPanel({experimentDesign}: DesignPanelProps) {
    
    const [conditions, setConditions] = useState<any[]>([]);
    const [schema, setSchema] = useState('2');
    const referenceContext = useContext<References>(ReferenceContext)
    const [cellLineMap, setCellLineMap] = useState(new Map())

    useEffect(() => {
        // console.log('DesignPanel change', experimentDesign)
        if (experimentDesign) {
            setSchema(experimentDesign.schema_version)
            setConditions(experimentDesign.conditions);
            const map = createCellMap(experimentDesign.conditions, referenceContext.cellLines)
            setCellLineMap(map)
        } else {
            setSchema('2')
            setConditions([])
        }
    }, [experimentDesign, referenceContext]);

    const displayValue = (thing: string|null) => {
        if (thing) {
            return thing
        }
        return ''
    }

    const renderOne = () => {
        return (
            <Table data-cy={DesignPanelDataCy.designTable} size="small" aria-label="design table"
                   sx={{ width: 'auto', overflowX: 'auto'}} >
                <TableHead>
                    <TableRow role="row">
                        <TableCell align="left">TMT</TableCell>
                        <TableCell align="left">Cells</TableCell>
                        <TableCell align="left">Compound</TableCell>
                        <TableCell align="left">Concentration</TableCell>
                        <TableCell align="left">Treatment time</TableCell>
                        <TableCell align="left">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {conditions.map(condition => (
                        <TableRow key={condition.channel} role="row">
                            <TableCell align="left">{condition.channel.replace("_", "")}</TableCell>
                            <TableCell align="left">{renderCell(condition.cells)}</TableCell>
                            <TableCell align="left">{condition.compound}</TableCell>
                            <TableCell align="right">{condition.concentration?.concentration} {condition.concentration?.unit}</TableCell>
                            <TableCell align="right">{condition.treatmentTime}h</TableCell>
                            <TableCell align="left">{condition.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
    }
    const renderTwo = () => {
        return (
            <Table data-cy={DesignPanelDataCy.designTable} size="small" aria-label="design table"
                   sx={{ width: 'auto', overflowX: 'auto'}} >
                <TableHead>
                    <TableRow role="row">
                        <TableCell align="left">TMT</TableCell>
                        <TableCell align="left">Cells</TableCell>
                        <TableCell align="left">Genotype</TableCell>
                        <TableCell align="left">Compound</TableCell>
                        <TableCell align="left">Batch</TableCell>
                        <TableCell align="left">Concentration Unit</TableCell>
                        <TableCell align="left">Treatment Time</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Taxon</TableCell>
                        <TableCell align="left">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {conditions.map((condition, index) => (
                        <React.Fragment key={index}>
                            <TableRow key={"table-row-"+index} role="row">
                                <TableCell align="left">{condition.channel.substring(1)}</TableCell>
                                <TableCell align="left">{renderCell(condition.cells)}</TableCell>
                                <TableCell align="left">{condition.genotype}</TableCell>
                                <TableCell align="left">{condition.compounds?.length>0 && condition.compounds[0].compound}</TableCell>
                                <TableCell align="left">{condition.compounds?.length>0 && condition.compounds[0].batch}</TableCell>
                                <TableCell align="left">{condition.compounds?.length>0 && condition.compounds[0].concentration?.concentration && (condition.compounds[0].concentration?.concentration+condition.compounds[0].concentration?.unit)}</TableCell>
                                <TableCell align="left">{condition.treatmentTime} {condition.treatmentTimeUnit}</TableCell>
                                <TableCell align="right">{condition.type}</TableCell>
                                <TableCell align="right">{condition.taxon}</TableCell>
                                <TableCell align="left">{condition.description}</TableCell>
                            </TableRow>
                            {condition.compounds?.map((compound:any, compoundIndex:number) => {
                                if(compoundIndex === 0) return null;
                                return (
                                    <TableRow key={compoundIndex} role="row">
                                        {compoundIndex===1 && (<TableCell rowSpan={condition.compounds.length-1} colSpan={3}/>)}
                                        <TableCell align="left">{compound.compound}</TableCell>
                                        <TableCell align="left">{compound.batch}</TableCell>
                                        <TableCell align="left">{compound.concentration?.concentration && (compound.concentration?.concentration+compound.concentration?.unit)}</TableCell>
                                        {compoundIndex===1 && (<TableCell rowSpan={condition.compounds.length-1} colSpan={4} />)}
                                    </TableRow>
                                )
                            })}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const renderThree = () => {
        return (
            <Table data-cy={DesignPanelDataCy.designTable} size="small" aria-label="design table"
                   sx={{ width: 'auto', overflowX: 'auto'}} >
                <TableHead>
                    <TableRow role="row">
                        <TableCell align="left">TMT</TableCell>
                        <TableCell align="left">Cells</TableCell>
                        <TableCell align="left">Compound</TableCell>
                        <TableCell align="left">Batch</TableCell>
                        <TableCell align="left">Concentration</TableCell>
                        <TableCell align="left">Treatment Time</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Taxon</TableCell>
                        <TableCell align="left">Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {conditions.map((condition, index) => (
                        <React.Fragment key={index}>
                            <TableRow key={"table-row-"+index} role="row">
                                <TableCell align="left">{condition.channel ? condition.channel.substring(1) : ''}</TableCell>
                                <TableCell align="left">{renderCell(condition.cells)}</TableCell>
                                <TableCell align="left">{condition.compounds?.length>0 && condition.compounds[0].compound}</TableCell>
                                <TableCell align="left">{condition.compounds?.length>0 && condition.compounds[0].batch}</TableCell>
                                <TableCell align="left">
                                    {condition.compounds?.length>0 && `${displayValue(condition.compounds[0].concentration?.concentration)} ${displayValue(condition.compounds[0].concentration?.unit)}`}
                                </TableCell>
                                <TableCell align="left">{condition.treatmentTime} {displayValue(condition.treatmentTimeUnit)}</TableCell>
                                <TableCell align="right">{condition.type}</TableCell>
                                <TableCell align="right">{cellLineMap.get(condition.cells)?.species}</TableCell>
                                <TableCell align="left">{condition.description}</TableCell>
                            </TableRow>
                            {condition.compounds?.map((compound:any, compoundIndex:number) => {
                                if(compoundIndex === 0) return null;
                                return (
                                    <TableRow key={compoundIndex} role="row">
                                        {compoundIndex===1 && (<TableCell rowSpan={condition.compounds.length-1} colSpan={2}/>)}
                                        <TableCell align="left">{compound.compound}</TableCell>
                                        <TableCell align="left">{compound.batch}</TableCell>
                                        <TableCell align="left">{displayValue(compound.concentration?.concentration)} {displayValue(compound.concentration?.unit)}</TableCell>
                                        {compoundIndex===1 && (<TableCell rowSpan={condition.compounds.length-1} colSpan={4} />)}
                                    </TableRow>
                                )
                            })}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        )
    }

    const renderCell = (cells: string) => {
        const cellLine = cellLineMap.get(cells)
        if (!cellLine) {
            return (<>{cells}</>)
        }
        return (<>{cellLine.cell_line_short_name} - <Link href={cellLine.web_url} target="_blank">{cells}</Link></>)
    }

    return <TableContainer>
        {schema === '3' && renderThree()}
        {schema === '2' && renderTwo()}
        {schema === '1' && renderOne()}
    </TableContainer>
}