export const CreateExperimentDataCy = {
    header: 'create-exp-header',
    saveExperiment: 'create-exp-submit',
    cancelExperiment: 'create-exp-cancel',
    experimentType: 'create-exp-type',
    numberOfSamples: 'create-exp-samples',
    benchling: 'create-exp-benchling',
    projectName: 'create-exp-project-name',
    responsiblePerson: 'create-exp-person',
    collaborator: 'create-exp-collaborator',
    filledSamples: 'create-exp-filled-samples'
};
