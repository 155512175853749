import React, {useState} from "react";
import {
    Button, TextField, Alert, Box,
    Dialog, DialogActions, DialogContent, DialogTitle
} from "@mui/material";
import FileField from "../../../../../FileField";
import tmtUtil from '../../../../../../util/tmt-util';
import UploadProgress from "./UploadProgress";

export const psmFileUploadProgressText = 'PSM file upload progress';
export const processingText = 'Processing';
export const resultsText = 'Results';
export const resultTableHeaders = ['TMT Position', 'Total', 'Missing', 'Percentage'];
export const pcaLabel = 'PCA';
export const plotHelperText = 'PC1 (mouse over legend to highlight points)';

type LabelingCheckerProps = ModalProps & { experimentName: string };

export const LabellingCheckDataCy = {
    modalTitle: 'lc-modal-title',
    experimentName: 'lc-experiment-name',
    pdPsmFile: 'lc-pd-psm-file',
    uploadButton: 'lc-upload',
    cancelButton: 'lc-cancel',
    closeButton: 'lc-close',
    resultsTable: 'lc-results-table'
}

export default function LabellingCheckerModal({open, handleClose, experimentName}: LabelingCheckerProps) {

    const [psmExportFile, setPsmExportFile] = useState<any>();
    const [channelCount, setChannelCount] = useState<number>(0);
    const [psmFileChannelError, setPsmFileChannelError] = useState<string>('');
    const [validate, setValidate] = useState(false);
    const [upload, setUpload] = useState(false);
    const [changeFile, setChangeFile] = useState(true);

    function closeDialog() {
        setUpload(false);
        setValidate(false);
        setPsmExportFile(undefined);
        setChannelCount(0);
        setPsmFileChannelError('')
        handleClose();
        setChangeFile(true);
    }

    const handlePsmFileChange = (file: any) => {
        setUpload(false);
        if (file) {
            tmtUtil(file, setChannelCount, setPsmFileChannelError);
        } else {
            setChannelCount(0);
            setPsmFileChannelError('');
        }
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (psmExportFile && channelCount > 0) {
            setChangeFile(false);
            setUpload(true);
        } else {
            setValidate(true);
        }
    }

    const uploadProcessingFailed = ()=> {
        setUpload(false);
        setChangeFile(true);
    }
    
    const dialogWindowId = 'lc-window-id';
    const dialogTitleId = LabellingCheckDataCy.modalTitle;
    const dialogDescId = 'lc-desc';
    
    return <Dialog
        fullWidth
        maxWidth={'lg'}
        PaperProps={{
            square: true,
            elevation: 0,
            sx: {border: '2px solid black'}
        }}
        id={dialogWindowId}
        open={open}
        onClose={closeDialog}
        aria-labelledby={dialogTitleId}
        aria-describedby={dialogDescId}
    >
        <DialogTitle id={dialogTitleId} data-cy={LabellingCheckDataCy.modalTitle}>
            Labelling Check
        </DialogTitle>
        <Box component="form" display="flex" flexDirection="column" sx={{ overflowY: 'hidden' }}
            action="/" method="POST" noValidate autoComplete="off" onSubmit={handleSubmit}   
        >
            <DialogContent sx={{ minHeight: 200 }} dividers>
                <TextField id='experiment-name' value={experimentName} label="Experiment Name"
                    variant="standard" size="small" sx={{ mb: 2 }}
                    inputProps={{ readOnly: true }}
                    data-cy={LabellingCheckDataCy.experimentName}
                />
                <FileField id='pd-psm-file'
                   label={'PD PSM File'}
                   validate={validate}
                   file={psmExportFile}
                   setFile={setPsmExportFile}
                   acceptedFiles={['text/plain']}
                   onChange={handlePsmFileChange}
                   disabled={!changeFile}
                   dataCy={LabellingCheckDataCy.pdPsmFile}
                />
                {psmFileChannelError ? <Alert severity="error">{psmFileChannelError}</Alert> : null}

                {upload &&
                <UploadProgress psmFile={psmExportFile} tmt={'TMT_'+ channelCount + '_PLEX'}
                    design={experimentName} setChangeFile={setChangeFile}
                    setProcessingFailed={uploadProcessingFailed}
                />
                }
            </DialogContent>
            {upload ?
            <DialogActions>
                <Button onClick={() => closeDialog()} data-cy={LabellingCheckDataCy.closeButton}>Close</Button>
            </DialogActions>
            :
            <DialogActions>
                <Button type="submit" data-cy={LabellingCheckDataCy.uploadButton}>Upload</Button>
                <Button onClick={closeDialog} data-cy={LabellingCheckDataCy.cancelButton}>Cancel</Button>
            </DialogActions>
            }
        </Box>
    </Dialog>
}
