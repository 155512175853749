import {useContext} from 'react';
import {ReferenceContext} from '../../../../../../store/ReferenceContext';

/**
 * Custom hook to show something more descriptive than a benchling_id given an actual
 * benchling_id.
 * @param benchlingId
 */
function useCellLines(benchlingId: string|undefined) {
    const lines: CellLine[] = useContext<References>(ReferenceContext).cellLines;
    if (benchlingId && benchlingId.match(/Cell\d+/)) {
         const line = lines.find((cl) => cl.benchling_id === benchlingId)
        if (line) {
            return line.cell_line_short_name + ' - ' + benchlingId
        }
    }
    return benchlingId;
}
export default useCellLines