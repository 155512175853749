import {FC} from 'react'
import { Alert, AlertTitle, Box, CardContent, Grid, Typography } from '@mui/material';
import { StyledListBox } from '../../StyledComponents';
import StandardCurvePanel from '../../../../visualizations/StandardCurvePanel';
import FluorescencePanel from '../../../../visualizations/FluorescencePanel';
import SampleConcentrationsPanel from '../../../../visualizations/SampleConcentrationsPanel';

interface WrapperProps {
    header: string;
    componentId: string;
    children: any;
}
const Wrapper: FC<WrapperProps> = ({header, componentId, children}) => {
    return <Box id={componentId} mb={4} >
        <Typography gutterBottom variant="h5" component="h2" ml={10}>
            {header}
        </Typography>
        {children}
    </Box>
}

interface FlashPlotsTabPanelProps {
    experiment: {experiment: string, status: string};
    sampleResults: any;

}

const FlashPlotsTabPanel: FC<FlashPlotsTabPanelProps> = ({experiment, sampleResults}) => {
    // console.log('THE DATA IS', sampleResults)

    const label = `y = ${sampleResults.experiment_properties.ax_b_formula[0]}x + ${sampleResults.experiment_properties.ax_b_formula[1]} (R`

    return (
    <CardContent>
        <Grid container columnSpacing={2}>
            <Grid item xs={12}>
                {!!sampleResults.experiment_properties.warnings &&
                    <Box px={2} mb={4} id="SampleWarnings-toPdf">
                    <Alert severity="warning">
                        <AlertTitle>Warnings</AlertTitle>
                        <StyledListBox component="ul">
                            <Box component="li">
                                <Typography component="strong" variant="body2" mr={1}>Missing well(s):{' '}</Typography>
                                {sampleResults.experiment_properties.warnings.missing_well_warnings ?
                                <Box component="ul" m={0}>
                                    {sampleResults.experiment_properties.warnings.missing_well_warnings.map((warning: string, index: number) => {
                                        return(
                                      <Typography component="li" variant="body2" key={'missing-well-warn-' + index}>
                                          {warning}
                                      </Typography>)
                                    })}
                                </Box> : <span>None</span>}
                            </Box>
                            <Box component="li">
                                <Typography component="strong" variant="body2" mr={1}>Out-of-range:{' '}</Typography>
                                {sampleResults.experiment_properties.warnings.out_of_range_warnings ?
                                <Box component="ul" m={0}>
                                    {sampleResults.experiment_properties.warnings.out_of_range_warnings.map((warning: string, index: number) => {
                                        return(
                                            <Typography component="li" variant="body2" key={'out-of-range-warn-' + index}>
                                                {warning}
                                            </Typography>)
                                    })}
                                </Box> : <span>None</span>}
                            </Box>
                            <Box component="li">
                                <Typography component="strong" variant="body2" mr={1}>Injection volume:{' '}</Typography>
                                {sampleResults.experiment_properties.warnings.injection_volume_warnings ?
                                <Box component="ul" m={0}>
                                    {sampleResults.experiment_properties.warnings.injection_volume_warnings.map((warning: string, index: number) => {
                                        return (
                                            <Typography component="li" variant="body2" key={'injection-vol-warn-' + index}>
                                                {warning}
                                            </Typography>
                                        )
                                    })}
                                </Box> : <span>None</span>}
                            </Box>
                        </StyledListBox>
                    </Alert>
                </Box>}
                <Wrapper header="Reference Standard Curve" componentId="referenceStandardCurve-toPdf">
                    <Typography gutterBottom variant="h6" component="h3" ml={12}>
                        <svg width="40" height="10"><line x1="0" y1="0" x2="30" y2="0" stroke="#1f77b4" strokeWidth="4" /></svg>
                        {label}<sup>2</sup> = {sampleResults.experiment_properties.R_2_value})
                    </Typography>
                    <StandardCurvePanel data={sampleResults} loading={false}/>
                </Wrapper>
                <Wrapper header="Raw Fluorescence of Samples"  componentId="rawFluorescence-toPdf">
                    <FluorescencePanel data={sampleResults} loading={false} />
                </Wrapper>
                <Wrapper header="Calculated Sample Concentrations" componentId="calculatedSampleConcentrations-toPdf">
                    <SampleConcentrationsPanel data={sampleResults} loading={false} />
                </Wrapper>
            </Grid>
        </Grid>
    </CardContent>
    )
}

export default FlashPlotsTabPanel