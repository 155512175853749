import dateFormat from 'dateformat';
import {isEqual, range} from 'lodash';
import {TMTOptions, concentrationUnits, emptyCellLine} from './options';

const dateFormatMask = 'UTC:yyyy-mm-dd';

const formatter = new Intl.NumberFormat('en-US')
const numberFormat = (val: number | null | undefined) => {
    if (val) {
        return formatter.format(val)
    }
    return ''
}

function usernameCleanup(username: string | undefined): string {
    // console.log('username: ', username)
    if (username) {
        let cleanUsername = username.replace(/^MRTX(-[A-Z]+)*_/i, '')
            .replace(/@monterosatx.com$/, '')
            .replace(/@mrtherapeutics.com$/, '');
        // console.log('cleanUsername', cleanUsername);
        return cleanUsername;
    }
    return 'Unknown'
}

function threeMonthsAgo() {
    const d = new Date();
    d.setMonth(d.getMonth() - 3);
    return  dateFormat(d, dateFormatMask);
}

function hasReplicate(otherRows: SampleRow[], sampleRow: SampleRow): boolean {
    return otherRows.some((row) => {
        return (sampleRow.type === 'bridge' || sampleRow.type === 'Empty')
            ||
            (sampleRow.sampleNum !== row.sampleNum && isEqual(sampleRow.cellLine, row.cellLine)
            && sampleRow.type === row.type && isEqual(sampleRow.compounds, row.compounds)
            && sampleRow.treatmentTime === row.treatmentTime
            && sampleRow.treatmentTimeUnit === row.treatmentTimeUnit)
    })
}

function createCellMap(conditions: any[], cellLines: CellLine[]): Map<string, CellLine|undefined> {
    const map = new Map<string, CellLine|undefined>()
    conditions && conditions.forEach((condition: any) => {
        if (!map.has(condition.cells)) {
            const match = cellLines.find((cellLine) => cellLine.benchling_id === condition.cells)
            map.set(condition.cells, match)
        }
    })
    return map
}

function calculateExpectedSampleCountFromSamples(samples: SampleRow[]): number {
    const length = samples.length
    if (length === 10 || length === 11 || length === 16 || length === 18) {
        return length
    }
    const sorted = [...samples].sort((a, b) => {
        const aValue = TMTOptions.findIndex((option) => option.value === a.tmt)
        const bValue = TMTOptions.findIndex((option) => option.value === b.tmt)
        return aValue - bValue
    })
    const lastTmt = sorted[sorted.length - 1].tmt
    const tmtIndexOfLastTmt = TMTOptions.findIndex((option) => option.value === lastTmt)
    if (tmtIndexOfLastTmt <= 9) {
        return 10;
    } else if (tmtIndexOfLastTmt === 10) {
        return 11
    } else if (tmtIndexOfLastTmt <= 15) {
        return 16
    }
    return 18
}

function convertConditionsToSampleRows(design: any,
                                       blanks: SampleRow[],
                                       references: References): SampleRow[] {
    let rows: SampleRow[]
    if (design.schema_version === '3') {
        rows = convertSchemaThree(design, references)
    } else if (design.schema_version === '2') {
        rows = convertSchemaTwo(design, references)
    } else if (design.schema_version === '1') {
        // schema changes from a number to a string between 1 and 2 (that's messed up!)
        rows = convertSchemaOne(design, references)
    } else {
        throw new Error(`Unable to convert design data for schema version: ${design.schema_version}`)
    }
    let expectedSampleCount: number
    // console.log(`Current Samples: ${design.conditions.length} and noOfSamples ${design.noOfSamples}`)
    if (design.noOfSamples) {
        expectedSampleCount = design.noOfSamples
    } else {
        expectedSampleCount = calculateExpectedSampleCountFromSamples(rows)
    }

    if (rows.length < expectedSampleCount) {
        appendSamples(rows, blanks, expectedSampleCount)
    }
    return rows
}

function appendSamples(existing: SampleRow[], defaultRows: SampleRow[], expectedCount: number) {
    const start = existing[existing.length - 1].sampleNum + 1
    const samplesToFill = range(start, expectedCount + 1)
    // console.log(`given start ${start} and end ${expectedCount}`, samplesToFill)

    samplesToFill.forEach((sample: number) => {
        let found = false
        defaultRows.slice(0, expectedCount).forEach((blank) => {
            if (!found) {
                if (!existing.some((row) => row.tmt === blank.tmt)) {
                    const copy = JSON.parse(JSON.stringify(blank))
                    copy.sampleNum = sample
                    existing.push(copy)
                    // ensures we don't add more with this sampleNum
                    found = true
                }
            }
        })
    })
}

function convertSchemaThree(design: any, references: References): SampleRow[] {
    const map = createCellMap(design.conditions, references.cellLines)
    return design.conditions.map((condition: any, index: number) => {
        const compounds = condition.compounds.map((comp: any) => {
            const concentrationString = comp.concentration.concentration + ''
            const match = references.refCompounds.find(option => option.value === comp.compound)
            return {
                compound: {
                    value: match?.value,
                    batches: match?.batches
                },
                batch: comp.batch,
                conc: concentrationString,
                unit: concentrationUnits.find(unit => unit.value === comp.concentration.unit)
            }
        })
        const cellLine = map.get(condition.cells);
        const cellsValue = cellLine ? cellLine : emptyCellLine
        return {cellLine: cellsValue,
            treatmentTime: condition.treatmentTime ? condition.treatmentTime + '' : '',
            treatmentTimeUnit: condition.treatmentTimeUnit,
            description: condition.description,
            sampleNum: index + 1,
            tmt: condition.channel,
            compounds: compounds,
            type: condition.type,
        }
    })
}

function convertSchemaTwo(design: any, references: References): SampleRow[] {
    const map = createCellMap(design.conditions, references.cellLines)
    return design.conditions.map((condition: any, index: number) => {
        const compounds = condition.compounds.map((comp: any) => {
            const concentrationString = comp.concentration.concentration + ''
            const match = references.refCompounds.find(option => option.value === comp.compound)
            return {
                compound: {
                    value: match?.value,
                    batches: match?.batches
                },
                batch: comp.batch,
                conc: concentrationString,
                unit: concentrationUnits.find(unit => unit.value === comp.concentration.unit)
            }
        })
        const cellLine = map.get(condition.cells);
        const cellsValue = cellLine ? cellLine : emptyCellLine
        return {cells: condition.cells,
            cellLine: cellsValue,
            treatmentTime: condition.treatmentTime + '',
            treatmentTimeUnit: condition.treatmentTimeUnit,
            description: condition.description,
            sampleNum: index + 1,
            tmt: condition.channel,
            compounds: compounds,
            type: condition.type,
            }
    })
}

function convertSchemaOne(design: any, references: References): SampleRow[] {
    const map = createCellMap(design.conditions, references.cellLines)
    return design.conditions.map((condition: any, index: number) => {
        const match = references.refCompounds.find(option => option.value === condition.compound)
        const cellLine = map.get(condition.cells);
        const cellsValue = cellLine ? cellLine : emptyCellLine
        return {
            cellLine: cellsValue,
            cells: condition.cells,
            treatmentTime: condition.treatmentTime + '',
            treatmentTimeUnit: "hour",
            description: condition.description,
            sampleNum: index + 1,
            tmt: condition.channel,
            compounds: [{
                compound: {
                    value: match?.value,
                    batches: match?.batches
                },
                batch: '',
                conc: condition.concentration.concentration + '',
                unit: concentrationUnits.find(option => option.value === condition.concentration.unit)}],
            taxon: '',
            type: 'NaN',
            genotype: 'wt'}
    })
}

function filledSampleData(design: any, default_data: SampleRow[], references: References): SampleRow[] {
    let data: SampleRow[]
    try {
        if (design) {
            // console.log('Calling filledSampleData with DESIGN', design)
            data = convertConditionsToSampleRows(design, default_data, references)
        } else {
            data = default_data.slice(0, 16)
        }
    } catch (error) {
        console.error('problem loading design', error)
        data = []
    }
    return data
}

function paddedNumber(num: number, size: number) {
    let numStr = num.toString();
    while (numStr.length < size) numStr = "0" + numStr;
    return numStr;
}

function conditionAcculator(groups: any[], point: any) {
    const cond = point.condition
    let group = groups.find((g: any) => g.id === cond)
    if (!group) {
        group = { id: cond, data: []}
        groups.push(group)
    }
    group.data.push(point)
    return groups
}



export {dateFormatMask, filledSampleData, appendSamples, calculateExpectedSampleCountFromSamples,
    hasReplicate, threeMonthsAgo, usernameCleanup, createCellMap, paddedNumber, conditionAcculator,
    numberFormat}
