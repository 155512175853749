import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';

const StyledTbodyCell = styled(TableCell)(({ theme }) => ({
    padding: theme.spacing(0, 1, 1),
    verticalAlign: 'bottom',
    '& .MuiInputBase-readOnly': {
        textAlign: 'right',
        fontSize: '.9rem',
        fontWeight: theme.typography.fontWeightMedium
    },
    '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 8px) scale(0.75)',
        '&.MuiFormLabel-filled:not(.Mui-focused)': { display: 'none'}
    },
    '& *[class*="MuiAutocomplete-root"]': {
        '& .MuiInput-root .MuiInput-input': { paddingRight: 0},
        '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': { minWidth: 35},
        '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
            paddingRight: 40
        }
    }
}));

export default StyledTbodyCell;