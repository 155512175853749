export const ExperimentTemplates = [{
    name: 'Yeast TKO',
    template: {
        type: "QC",
        projectName: 'QC',
        schema_version: "3",
        conditions: [{
           cells: "Cell02883",
           treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_126_",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02883",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_127N",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02883",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_127C",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02884",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_128N",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02884",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_128C",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02884",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_129N",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02889",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_129C",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02889",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_130N",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02889",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_130C",
            description: "KO",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02885",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_131N",
            description: "PARENTAL",
            compounds: [],
            type: "NaN",
        }, {
            cells: "Cell02885",
            treatmentTime: null,
            treatmentTimeUnit: "hour",
            channel: "_131C",
            description: "PARENTAL",
            compounds: [],
            type: "NaN",
        }
    ]}
}];