import {ExportToCsv} from 'export-to-csv';

export function generateCSV(designExperiment: { experiment: any; record_type: any; record_value?: any; }) {

    let samples = 0;
    let maxNumOfCompoundInRow = 1;
    designExperiment.record_value.conditions.forEach( (condition: any)=>{
        maxNumOfCompoundInRow = maxNumOfCompoundInRow < condition.compounds.length ? condition.compounds.length : maxNumOfCompoundInRow;
    })

    let rowCompound = designExperiment.record_value.conditions.map( (row: any) =>{
        const numOfCompound = row.compounds.length;
        let compoundObject = {};
        for ( let i = 0 ; i < numOfCompound ; i++){
            compoundObject = {
                ...compoundObject,
                ['Compound_' +i]: row.compounds[i].compound,
                ['Batch_' + i] :  row.compounds[i].batch,
                ['Concentration_'+i]:  row.compounds[i].concentration.concentration + " "+ row.compounds[i].concentration.unit,
                ['Treatment Time_' +i ]: row.treatmentTime +  " " + row.treatmentTimeUnit
            }
        }
        // fill empty row to make the size
        for ( let i = numOfCompound ; i < maxNumOfCompoundInRow ; i++){
            compoundObject = {
                ...compoundObject,
                ['Compound_' +i ]: "",
                ['Batch_' + i ]: "",
                ['Concentration_' + i ]: "",
                ['Treatment Time_' + i ]: "",
            }
        }
        return compoundObject;
    });

    let csvData =  designExperiment.record_value.conditions.map( (row: any, index : number) =>{
        const part_1 ={
            'Project Name': designExperiment.record_value.projectName,
            'Responsible': designExperiment.record_value.responsible ? designExperiment.record_value.responsible : "",
            'Collaborator': designExperiment.record_value.collaborator ? designExperiment.record_value.collaborator: "",
            'Experiment Type': designExperiment.record_value.type,
            'Samples': ++samples,
            'TMT': row.channel,
            'cells benchling ID': row.cells,
        }
        const part_3 = {
            'Type':row.type,
            'benchling': designExperiment.record_value.benchling,
            'Description' : row.description
        }

        return{
            ...part_1,
            ...rowCompound[index],
            ...part_3
        }

    });

    let exporter =  new ExportToCsv({
        filename: designExperiment.experiment,
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true
    })

    return exporter.generateCsv(csvData)
}