import {useState} from 'react';
import {IconButton, Snackbar, Tooltip} from "@mui/material";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";

type CopyToClipboardProps = {
    folder: string;
}

const CopyToClipboard = ({folder}: CopyToClipboardProps) => {
    const [snackbarMsg, setSnackbarMsg] = useState('');
    const [openToast, setOpenToast] = useState(false);

    const doClipboard = () => {
        const cleaner = /^"(.+)"$/
        const match = folder.match(cleaner)
        const cleanFolder = match ? match[1] : folder
        let clipboard = navigator.clipboard;
        let successMsg = `Successfully copied ${cleanFolder} to clipboard`;
        clipboard.writeText(cleanFolder).then(() => {
            setSnackbarMsg(successMsg);
            setOpenToast(true)
        }).catch(err => {
            setSnackbarMsg(`Failed to copy experiment folder to the clipboard: ${err}`);
            setOpenToast(true)
        });
    }

    const toastClosed = () => {
        setOpenToast(false)
        setSnackbarMsg('')
    }

    return (
        <>
            <IconButton size="small" color="primary"
                        onClick={doClipboard}
                        sx={{marginLeft: '2px'}}
            >
                <Tooltip title="Copy experiment folder to clipboard">
                    <FolderCopyIcon fontSize="small"/>
                </Tooltip>
            </IconButton>
            <Snackbar open={openToast} autoHideDuration={4000} onClose={toastClosed} message={snackbarMsg}/>
        </>
    )
}
export default CopyToClipboard;