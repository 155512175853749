import React, {useCallback, useEffect, useState} from 'react';
import {
    Box, Button, ButtonGroup, Checkbox, Divider,
    Dialog, DialogTitle, DialogContent, DialogActions,
    FormControlLabel, InputAdornment,
    List, ListItem, ListItemIcon, ListItemText,
    TextField, Typography,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import {WorkingContrast} from '../visualizations/VolcanoPanel/VolcanoPanel';
import without from 'lodash/without'

interface ContrastSelectModalProps {
    contrasts: WorkingContrast[];
    selectionHandler: (list: number[]) => void;
    handleBenchlingIds: boolean;
}

interface RenderContrastListProps {
    list: WorkingContrast[];
    callback: (event: React.ChangeEvent<HTMLInputElement>) => void;

}
export default function ContrastSelectModal({contrasts, selectionHandler, handleBenchlingIds}: ContrastSelectModalProps) {
    
    const splitter = / - /;

    const [open, setOpen] = useState(false);
    const [touched, setTouched] = useState(false);
    const [controlOnly, setControlOnly] = useState(false);
    const [list, setList] = useState(contrasts);
    const [checkedIndices, setCheckedIndices] = useState<number[]>([]);
    const [filterText, setFilterText] = useState('');

    useEffect(() => {
        setCheckedIndices([]);
        setList(contrasts);
    }, [contrasts]);

    useEffect(() => {
        setTouched(checkedIndices.length > 0);
    }, [checkedIndices]);
    
    const handleOpen = () => { setOpen(true);};
    const handleClose = () => { setOpen(false);};
    const cancelMe = () => {
        setTouched(false);
        setOpen(false);
    }
    const showPlots = () => {
        setTouched(false);
        setOpen(false);
        selectionHandler(checkedIndices);
    }

    const toggleControlOnly = () => {
        setList(calculateNewList(checkedIndices));
        setControlOnly(!controlOnly);
    }

    const calculateNewList = (current: number[]): WorkingContrast[] => {
        return list.map((w: WorkingContrast) => {
            return {
                selected: current.some(e => e === w.index),
                contrast: w.contrast,
                display: w.display,
                index: w.index,
                control: w.control
            }
        });
    }

    const filterMatch = (working: WorkingContrast) => {
        if (controlOnly && filterText) {
            return working.control && working.display.toLowerCase().includes(filterText.toLowerCase())
        } else if (controlOnly) {
            return working.control
        } else if (filterText) {
            return working.display.toLowerCase().includes(filterText.toLowerCase())
        }
        return true;
    }

    //// Removed due to performance concerns
    // const handleSelectAll = () => {
    //     const toBeSelected = contrasts.filter((c: WorkingContrast) => filterMatch(c))
    //         .map((c: WorkingContrast) => c.index);
    //     setCheckedIndices(union(checkedIndices, toBeSelected));
    // }

    const handleClear = () => {
        const toBeRemoved = contrasts.filter((c: WorkingContrast) => filterMatch(c))
            .map((c: WorkingContrast) => c.index);
        setCheckedIndices(without(checkedIndices, ...toBeRemoved));
    }
    const handleFilterChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    }, []);
    
    const modalTitleId = 'modal-select-contrasts-title';
    const modalDescId = 'modal-select-contrasts-desc';
    const modalTitleText = 'Select Contrast';

    const checkedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        const value = parseInt(event.target.value);
        setCheckedIndices(checkedIndices => {
            // Removing the ability for multiple toggles
            // return checked ? [...checkedIndices, value]
            return checked ? [value]
            : checkedIndices.filter((i) => i !== value)}
        );
    }, []);

    const RenderContrastList = ({list, callback}: RenderContrastListProps) => {
        return(
            <>
            {list.filter((working: WorkingContrast) => filterMatch(working))
                .map((working: WorkingContrast) => {
                const pair = working.display.split(splitter);
                const labelId = `contrasts-list-label-${working.index}`;
                const listkey = `listkey${working.index}`;
                const selected = checkedIndices.findIndex(value => value === working.index) > - 1;
                return (
                    <MemoizedListContrast key={listkey} selected={selected} pair={pair} index={working.index} labelId={labelId} checkHandler={callback}/>
                );
             })}
            </>
        );
    }
    const MemoizedRenderContrastList = React.memo(RenderContrastList);


    return (<>
        <Button onClick={handleOpen} variant="outlined" color="primary" size="small">
            Select Contrast
        </Button>
        <Dialog
            fullWidth
            maxWidth={'md'}
            PaperProps={{
                square: true,
                elevation: 0,
                sx: {border: '2px solid black'}
             }}
            open={open}
            onClose={handleClose}
            aria-labelledby={modalTitleId}
            aria-describedby={modalDescId}
            closeAfterTransition
        >
            <DialogTitle id={modalTitleId}>{modalTitleText}</DialogTitle>
            {/*disabled for performance considerations*/}
            {/*<Typography id={modalDescId} variant="body2" paragraph>*/}
            {/*    Selecting more than one contrast pair will display volcano plots as multiples.*/}
            {/*</Typography>*/}
            <DialogContent dividers>
                <Box role="toolbar" aria-label="Filter list of Contrasts"
                    display="flex" alignItems="center" mb={1}
                >
                    {/*<input type="text" value={filterText} onChange={handleFilterChange}/>*/}
                    <TextField
                        value={filterText}
                        onChange={handleFilterChange}
                        label="Filter by..."
                        id="input-filter-list"
                        InputProps={{
                            endAdornment: <InputAdornment position="end"><FilterListIcon /></InputAdornment>,
                        }}
                        variant="outlined" size="small" sx={{ mr: 2 }} />
                    <FormControlLabel
                        control={<Checkbox
                            checked={controlOnly}
                            onClick={toggleControlOnly}
                            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                            checkedIcon={<CheckBoxIcon fontSize="small"/>}
                            name="onlyControls"
                        />}
                        label="Show only contrasts with controls"
                    />
                </Box>
                <Box minWidth={600} pt={1} px={1} border={1} borderColor={'secondary.main'}>
                    <ButtonGroup
                        sx={{ ml: -1, '& .MuiButton-textSizeSmall': { px: 2 }}}
                        aria-label="Select All or Clear Checkboxes"
                        variant="text" color="primary" size="small">
                        {/*disabled for performance considerations*/}
                        {/*<Button onClick={handleSelectAll}>Select All</Button>*/}
                        <Button onClick={handleClear}>Clear</Button>
                    </ButtonGroup>
                    <List sx={theme => ({
                        minHeight: 250, maxHeight: 400, pr: 1, overflowY: 'auto',
                        '& .MuiListItem-root': {
                            justifyContent: 'space-between', borderBottom: `1px solid ${theme.palette.divider}`
                        },
                        '& .MuiListItemText-root': { flex: '0 1 35%'}
                        })}
                    >
                        <MemoizedRenderContrastList list={list} callback={checkedHandler}/>
                    </List>
                </Box>
            </DialogContent>
            <DialogActions>
                <Typography color="secondary" variant="body2" component="strong" sx={{ mr: 'auto', ml: 1 }}>
                    {checkedIndices.length} selected
                    / {contrasts.reduce((acc: number, w: WorkingContrast) => filterMatch(w) ? ++acc : acc, 0)} shown
                    / {contrasts.length} total
                </Typography>
                <Button onClick={showPlots} disabled={!touched}>Show Plot</Button>
                <Button onClick={cancelMe}>Cancel</Button>
            </DialogActions>
        </Dialog>
    </>);
}

interface ListContrastProps {
    selected: boolean;
    pair: string[];
    labelId: string;
    index: number;
    checkHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
function ListContrast({selected, pair, labelId, index, checkHandler}: ListContrastProps) {
    return (
        <ListItem role={undefined} dense button>
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={selected}
                    onChange={(event) => checkHandler(event)}
                    value={index}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                />
            </ListItemIcon>
            <ListItemText
                primary={pair[0].split(/_/).join(' | ')}
            />
            <Divider orientation="vertical" flexItem sx={{ my: 0.5, mx: 2 }} />
            <ListItemText
                primary={pair[1].split(/_/).join(' | ')}
            />
        </ListItem>
    );
}

const MemoizedListContrast = React.memo(ListContrast);


