import {useEffect, useState} from 'react';
import SquareIcon from '@mui/icons-material/SquareRounded';
import {
    Alert, Box, Button, Typography, Grid,
    Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress,
    FormControl, TextField, FormHelperText,
    useTheme, alpha
} from '@mui/material';
import ProcessExperimentTable from './ProcessExperimentTable/ProcessExperimentTable';
import flashService from '../../../../../../services/flashService';
import {Hub} from '@aws-amplify/core';
import {FlashAnalysis} from '../../../../../../API';

export type ProcessExperimentModalProps = ModalProps & {
    currentExperiment: any;
    analysis: FlashAnalysis | null | undefined;
};

export default function ProcessExperimentModal({open, handleClose, currentExperiment, analysis}: ProcessExperimentModalProps) {
    
    const theme = useTheme();
    const [process, setProcess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true)
    const [samples, setSamples] = useState<FlashSample[]>([])
    const [selected, setSelected] = useState<string[]>([]);
    const [processingError, setProcessingError] = useState<string>('');
    const [transferQValueCutoff, setTransferQValueCutoff]
        = useState<number>(analysis?.record_value?.transfer_qValue_cutoff ? analysis.record_value.transfer_qValue_cutoff  : 0.3)
    const [qvalError, setQvalError] = useState<boolean>(false)
    const [displaySizeWarning] = useState<boolean>(false);

    useEffect(() => {
        const loadSamples = async (experiment: string) => {
            setLoading(true)
            const expSamples = await flashService.getProcessableSamples(experiment)
            if (!expSamples.some((s: FlashSample) => s.fileSize)) {
                setProcessingError('No Processing data available for any samples.')
            }
            setSamples(expSamples)
            setLoading(false)
        }
        loadSamples(currentExperiment.experiment)
    }, [currentExperiment])
    
    function closeDialog() {
        setProcess(false);
        setSelected([]);
        handleClose();
    }

    function selectAvailable() {
        const available = samples.filter((sample) => !!sample.fileSize)
                                 .map((sample) => sample.fileName)
        setSelected(available)
    }
    
    async function handleProcess() {
        setProcess(true);
        console.log(`DD-2764 will provide a backend for the actual processing of ${selected.join(', ')} with transferQValueCutoff of ${transferQValueCutoff}`)
        try {
            const result: any = await flashService.processExperiment(currentExperiment.experiment, transferQValueCutoff, selected)
            if (result.status === 'PENDING') {
                result['type'] = {code: 'FDG'}
                Hub.dispatch('DesignChannel', {event: 'started', data: result})
            }
        } catch (err) {
            // error highlights will be in UI via axios interceptor
            console.log('Error processing experiment', err)
        }
    }
    
    const dialogWindowId = 'processExp-window-id';
    const dialogTitleId = 'processExp-modal-title';
    const dialogDescId = 'processExp-desc';
    
    const sizeWarningColor = alpha(theme.palette.warning.light, 0.15);
    const byteThreshold = 104857600;
    const sizeWarningMsg = 'Highlighted rows indicate files of less than '+ (byteThreshold / 1048576).toFixed(0) + ' MB in size. You may still select these files for processing.';
    
    return (
    <Dialog
        fullWidth
        maxWidth={'xl'}
        PaperProps={{
            square: true,
            elevation: 0,
            sx: {border: '2px solid black'}
        }}
        id={dialogWindowId}
        open={open}
        onClose={closeDialog}
        aria-labelledby={dialogTitleId}
        aria-describedby={dialogDescId}
    >
        <DialogTitle id={dialogTitleId} >
            Process Experiment - {currentExperiment.experiment}
        </DialogTitle>
        <DialogContent sx={{ minHeight: 200 }} dividers>
            {processingError ? <Alert severity="error" sx={{mb:1.5}}>{processingError}</Alert> : null}
            <Typography mb={0.25}>Select samples for processing.</Typography>
            <Typography fontWeight={500} mb={2.25}>Processing time expected to take &lt; 1h for a full 96-well-plate.</Typography>
            <Grid container columnSpacing={2} rowSpacing={1} mb={2}>
                <Grid item xs={6} sm={4} md={3} lg={2}>
                    <FormControl variant="outlined" required fullWidth>
                        <TextField id="transfer-qval-cutoff" type="number" size="small"
                                   label="Transfer q-value cutoff"
                                   name="transferQValueCutoff"
                                   value={transferQValueCutoff}
                                   onChange={(evt) => {
                                       const val = parseFloat(evt.target.value)
                                       setTransferQValueCutoff(val)
                                       setQvalError(!(val <= 1 && val >= 0.1))
                                   }}
                                   error={qvalError}
                                   aria-describedby="transfer-qval-cutoff-helper-text"
                        />
                        <FormHelperText id="transfer-qval-cutoff-helper-text">
                            Default 0.3, valid values between 0.01 and 1
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button size="small" onClick={selectAvailable}
                        disabled={samples.length === 0 || qvalError}
                        sx={{mt:0.5}}
                    >
                        Select all available MSF files
                    </Button>
                </Grid>
            </Grid>
            {!loading && displaySizeWarning && 
            <Box display="flex" alignItems="center" mt={-0.5} mb={2}>
                <SquareIcon fontSize="small" sx={{ 
                    color: sizeWarningColor,
                    stroke: alpha(theme.palette.warning.light, 0.5), 
                    strokeWidth: 1
                }} />
                <Typography component="p" variant="body2" ml={0.5}>{sizeWarningMsg}</Typography>
            </Box>}
            {analysis?.record_value?.samples_selected_by_user &&
            <Box display="flex" alignItems="center" mt={-0.5} mb={2}>
                <SquareIcon fontSize="small" sx={{
                    color: alpha(theme.palette.success.light, 0.7),
                    stroke: alpha(theme.palette.success.light, 0.7),
                    strokeWidth: 1
                }} />
                <Typography component="p" variant="body2" ml={0.5}>Already Processed Rows</Typography>
            </Box>}
            {loading ?
                <CircularProgress /> :
                <ProcessExperimentTable flashSamples={samples}
                                        selected={selected}
                                        setSelected={setSelected}
                                        analysis={analysis}/> }
        </DialogContent>
        {process ?
        <DialogActions>
            <Typography color="success" variant="body2" component="strong" sx={{ mr: 'auto', ml: 1 }}>
                Processing in progress. Modal window may be safely closed.
            </Typography>
            <Button onClick={closeDialog}>Close</Button>
        </DialogActions>
        :
        <DialogActions>
            <Typography variant="body1" component="strong" fontWeight={500} sx={{ mr: 'auto', ml: 1 }}>
                {selected.length} selected
                / {samples.filter((sample) => !!sample.fileSize).length} available
                / {samples.length} total
            </Typography>
            <Button type="submit" onClick={handleProcess} disabled={selected.length === 0 || qvalError} >
                Process
            </Button>
            <Button onClick={closeDialog} >Cancel</Button>
        </DialogActions>
        }
    </Dialog>
    );
}