import {graphqlOperation} from 'aws-amplify';
import {API} from '@aws-amplify/api'
import {deleteBarcodePair, updateBarcodePair} from '../graphql/mutations';


const barcodeService = {
    updateBarcode: async (parent: string, child: string) => {
        const variables = {parent, child}
        const result: any = await API.graphql(graphqlOperation(updateBarcodePair, variables))
        return result.data.updateBarcodePair
    },
    deleteBarcode: async (parent: string, child: string) => {
        const result: any = await API.graphql(graphqlOperation(deleteBarcodePair, {parent, child}))
        return result.data.deleteBarcodePair
    }
}

export default barcodeService