import {Box, Button, Checkbox, DialogActions, FormControlLabel, Typography} from '@mui/material';
import {CreateExperimentDataCy} from './CreateNewExperimentModal.cy';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';

interface FooterProps {
    completedSampleCount?: number
    sampleCount?: number
    downloadCSV?: boolean
    handleDownloadCSVChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleCancelWarningOpen: () => void
}

export default function CreateNewExperimentFooter({completedSampleCount,
                                                      sampleCount,
                                                      downloadCSV,
                                                      handleDownloadCSVChange,
                                                      handleCancelWarningOpen}: FooterProps) {

    const completedMsg = `${completedSampleCount} of ${sampleCount} samples complete.`;


    return (
        <DialogActions>
            {sampleCount && <Box mr={'auto'} ml={2}>
                <Typography data-cy={CreateExperimentDataCy.filledSamples}
                            variant="subtitle1" component="p" fontWeight={500} gutterBottom
                >
                    {completedMsg}
                </Typography>
                <Box display="flex" justifyContent="flex-start">
                    <WarningIcon color="error" fontSize="small" />
                    <Typography variant="subtitle2" component="p" color="error" sx={{ ml: 0.5 }}>
                        At least 2 replicates required.
                    </Typography>
                </Box>
            </Box>}
            <Box mr={1} display="flex" justifyContent="center">
                {downloadCSV !== undefined && <FormControlLabel
                    control={<Checkbox name="download" checked={downloadCSV} onChange={handleDownloadCSVChange} />}
                    label="Download Experiment file after save"
                />}
                <Button type="submit" size="large" data-cy={CreateExperimentDataCy.saveExperiment}>
                    Save
                </Button>
                <Button size="large"
                        onClick={handleCancelWarningOpen} data-cy={CreateExperimentDataCy.cancelExperiment}
                >
                    Cancel
                </Button>
            </Box>
        </DialogActions>
    )
}