import {FC} from 'react'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
interface BarcodeInfoTableProps {
    data: any[] | null
}

const BarcodeInfoTable: FC<BarcodeInfoTableProps> = ({data}) => {
    if (!data) {
        return null
    }
    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer sx={{maxHeight:630}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell>description</TableCell>
                            <TableCell>creationuser</TableCell>
                            <TableCell>creationdate</TableCell>
                            <TableCell>location</TableCell>
                            <TableCell>compoundbatch</TableCell>
                            <TableCell>volumeul</TableCell>
                            <TableCell>concentrationum</TableCell>
                            <TableCell>type</TableCell>
                            <TableCell>active</TableCell>
                            <TableCell>parent</TableCell>
                            <TableCell>source</TableCell>
                            <TableCell>barcode</TableCell>
                            <TableCell>well</TableCell>
                            <TableCell>sourcewell</TableCell>
                            <TableCell>sourceplatetype</TableCell>
                            <TableCell>sourceplatebarcode</TableCell>
                            <TableCell>destinationplatetype</TableCell>
                            <TableCell>destinationplatebarcode</TableCell>
                            <TableCell>destinationwell</TableCell>
                            <TableCell>transfervolume</TableCell>
                            <TableCell>actualvolume</TableCell>
                            <TableCell>transferstatus</TableCell>
                            <TableCell>section</TableCell>
                            <TableCell>time</TableCell>
                            <TableCell>filename</TableCell>
                            <TableCell>processed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index + '-' + row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.creationuser}</TableCell>
                                <TableCell>{row.creationdate}</TableCell>
                                <TableCell>{row.location}</TableCell>
                                <TableCell>{row.compoundbatch}</TableCell>
                                <TableCell>{row.volumeul}</TableCell>
                                <TableCell>{row.concentrationum}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell>{row.active}</TableCell>
                                <TableCell>{row.parent}</TableCell>
                                <TableCell>{row.source}</TableCell>
                                <TableCell>{row.barcode}</TableCell>
                                <TableCell>{row.well}</TableCell>
                                <TableCell>{row.sourcewell}</TableCell>
                                <TableCell>{row.sourceplatetype}</TableCell>
                                <TableCell>{row.sourceplatebarcode}</TableCell>
                                <TableCell>{row.destinationplatetype}</TableCell>
                                <TableCell>{row.destinationplatebarcode}</TableCell>
                                <TableCell>{row.destinationwell}</TableCell>
                                <TableCell>{row.transfervolume}</TableCell>
                                <TableCell>{row.actualvolume}</TableCell>
                                <TableCell>{row.transferstatus}</TableCell>
                                <TableCell>{row.section}</TableCell>
                                <TableCell>{row.time}</TableCell>
                                <TableCell>{row.filename}</TableCell>
                                <TableCell>{row.processed}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

export default BarcodeInfoTable