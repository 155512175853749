/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getYeastTkoStats = /* GraphQL */ `
  query GetYeastTkoStats($experiment: String!) {
    getYeastTkoStats(experiment: $experiment) {
      imputedValue
      ifiStatistics {
        TMT_channel
        num_PSMs
        num_imputed_values
        median
        mean
        num_outliers
      }
    }
  }
`;
export const getExperimentStatistics = /* GraphQL */ `
  query GetExperimentStatistics(
    $recordType: String!
    $filters: ExperimentFilterInput!
  ) {
    getExperimentStatistics(recordType: $recordType, filters: $filters) {
      items {
        experiment
        date
        starting_psm_count
        starting_protein_count
        high_isolation_interfence
        low_signal_to_noise
        total_number_of_rows_removed
        num_proteins_remaining
        instrument
        injectionNumber
        pd_psm_file
      }
    }
  }
`;
export const getExperimentStatistic = /* GraphQL */ `
  query GetExperimentStatistic($experiment: String!, $recordType: String!) {
    getExperimentStatistic(experiment: $experiment, recordType: $recordType) {
      experiment
      date
      starting_psm_count
      high_interference_psm_count
      low_signal_noise_psm_count
      missing_channel_psm_count
      under_sps_mass_count
      identifying_node_count
      confidence_count
      ambiguity_count
      number_of_proteins_count
      total_psm_removed
      starting_protein_count
      remaining_protein_count
      unique_peptides_filter_count
      pd_psm_file
      instrument
      injectionNumber
      unmodified_peptides_counter
      modified_peptides_counter
      removed_by_sum_PSMs_filters_count
      removed_by_MVI_TMP_filters_count
    }
  }
`;
export const getFlashStats = /* GraphQL */ `
  query GetFlashStats($experiment: String!) {
    getFlashStats(experiment: $experiment) {
      experiment
      record_value {
        method_a_filters_count {
          number_PCM_rescued_due_to_transfer_q_value
          number_peptides_rescued_due_to_transfer_q_value
          total_PCM_count_remaining
          total_peptide_count_remaining
          total_protein_count_remaining
          unique_PCM_count_remaining
          unique_peptide_count_remaining
          unique_protein_count_remaining
        }
        starting_total_PCM_count
        starting_total_peptide_count
        starting_total_protein_count
        starting_unique_PCM_count
        starting_unique_peptide_count
        starting_unique_protein_count
        total_number_proteotypic_PCM
        total_number_proteotypic_peptides
        total_number_proteotypic_proteins
        unique_number_proteotypic_PCM
        unique_number_proteotypic_peptides
        unique_number_proteotypic_proteins
        missing_MSF_files
        issues_parsing_MSF_files
      }
    }
  }
`;
export const getExperimentList = /* GraphQL */ `
  query GetExperimentList(
    $recordType: String!
    $filters: ExperimentFilterInput!
  ) {
    getExperimentList(recordType: $recordType, filters: $filters) {
      items {
        experiment
        status
        history {
          status
          date
        }
        injectionNumber
      }
      nextToken
    }
  }
`;
export const getMostRecentNonQCDesigns = /* GraphQL */ `
  query GetMostRecentNonQCDesigns($limit: Int, $nextToken: String) {
    getMostRecentNonQCDesigns(limit: $limit, nextToken: $nextToken) {
      items {
        experiment
        creation_date
      }
      nextToken
    }
  }
`;
export const getExperimentContrasts = /* GraphQL */ `
  query GetExperimentContrasts($experimentName: String!) {
    getExperimentContrasts(experimentName: $experimentName)
  }
`;
export const getExperimentDesign = /* GraphQL */ `
  query GetExperimentDesign($experiment: String!) {
    getExperimentDesign(experiment: $experiment) {
      conditions {
        channel
        cells
        treatmentTime
        description
        ... on Condition {
          genotype
          treatmentTimeUnit
          type
          taxon
          compounds {
            batch
            compound
            concentration {
              concentration
              unit
            }
          }
        }
        ... on TMTCondition {
          compound
          cddBatchId
          concentration {
            concentration
            unit
          }
        }
      }
      type
      tmt_lot
      projectName
      responsible
      collaborator
      benchling
      noOfSamples
      bufferVolume
      schema_version
      experiment_folder
      scinamicPlateMap
      cellCultureBarcode
      peptideBarcode
      flashWarningsAcknowledged
    }
  }
`;
export const getExperimentStatus = /* GraphQL */ `
  query GetExperimentStatus($experiment: String!, $recordType: String!) {
    getExperimentStatus(experiment: $experiment, recordType: $recordType) {
      experiment
      status
    }
  }
`;
export const getExperimentAnalysis = /* GraphQL */ `
  query GetExperimentAnalysis($experiment: String!) {
    getExperimentAnalysis(experiment: $experiment) {
      database
      instrument
      pd_version
      pd_psm_file
      psm_threshold
      isolation_interference_cutoff
      average_reporter_sn_cutoff
      missing_channel_threshold
      sps_mass_matches_cutoff
      ignore_sps_mass_matches
      skip_normalization
      psm_ambiguity
      identifying_node
      experiment_folder
    }
  }
`;
export const getFlashAnalysis = /* GraphQL */ `
  query GetFlashAnalysis($experiment: String!) {
    getFlashAnalysis(experiment: $experiment) {
      experiment
      record_value {
        samples_selected_by_user
        transfer_qValue_cutoff
      }
    }
  }
`;
export const getUploadUrl = /* GraphQL */ `
  query GetUploadUrl($key: String!) {
    getUploadUrl(key: $key) {
      url
      key
    }
  }
`;
export const getExperimentDownloadUrl = /* GraphQL */ `
  query GetExperimentDownloadUrl(
    $keys: [String]!
    $experimentDownload: ExperimentDownload!
  ) {
    getExperimentDownloadUrl(
      keys: $keys
      experimentDownload: $experimentDownload
    ) {
      url
      key
    }
  }
`;
export const getLabelEfficiencyUploadUrl = /* GraphQL */ `
  query GetLabelEfficiencyUploadUrl($key: String!) {
    getLabelEfficiencyUploadUrl(key: $key) {
      url
      key
    }
  }
`;
export const getLabelEfficiency = /* GraphQL */ `
  query GetLabelEfficiency(
    $psmFile: String!
    $pdVersion: PDVersion
    $tmt: TMT!
    $experimentName: String
    $experimentType: ExperimentType
  ) {
    getLabelEfficiency(
      psmFile: $psmFile
      pdVersion: $pdVersion
      tmt: $tmt
      experimentName: $experimentName
      experimentType: $experimentType
    ) {
      totalPeptides
      labeledNTermPeptides
      totalKTermPeptides
      labelKTermPeptides
      boxPlot {
        min
        max
        bandDomain
        stats {
          fiveNums
          iqr
          step
          fences {
            start
            end
          }
          boxes {
            start
            end
          }
          whiskers {
            start
            end
          }
          points {
            value
            datum
            outlier
            farout
          }
        }
      }
    }
  }
`;
export const getExperimentPcaData = /* GraphQL */ `
  query GetExperimentPcaData($experiment: String!, $recordType: String!) {
    getExperimentPcaData(experiment: $experiment, recordType: $recordType) {
      id
      data {
        x
        y
      }
    }
  }
`;
export const getFastaDatabases = /* GraphQL */ `
  query GetFastaDatabases {
    getFastaDatabases
  }
`;
export const getFastaUploadUrl = /* GraphQL */ `
  query GetFastaUploadUrl($key: String!) {
    getFastaUploadUrl(key: $key) {
      url
      key
    }
  }
`;
export const getStats = /* GraphQL */ `
  query GetStats($experiment: String!, $recordType: String!) {
    getStats(experiment: $experiment, recordType: $recordType) {
      snLog10Histogram {
        min
        max
        binWidth
        binCounts
      }
      channelIntensityStats {
        min
        max
        bandDomain
        stats {
          fiveNums
          iqr
          step
          fences {
            start
            end
          }
          boxes {
            start
            end
          }
          whiskers {
            start
            end
          }
          points {
            value
            datum
            outlier
            farout
          }
        }
      }
      peptideOverlaps {
        fraction
        keys
        counts
      }
      fractionCounts {
        fraction
        psm
        qpsm
      }
      psmPrecursorIntensities {
        min
        max
        binWidth
        binCounts
      }
      qpsmPrecursorIntensities {
        min
        max
        binWidth
        binCounts
      }
      massErrorHistogram {
        min
        max
        binWidth
        binCounts
      }
      sampleControlRatios {
        min
        max
        bandDomain
        stats {
          fiveNums
          iqr
          step
          fences {
            start
            end
          }
          boxes {
            start
            end
          }
          whiskers {
            start
            end
          }
          points {
            value
            datum
            outlier
            farout
          }
        }
      }
      pcaData {
        id
        data {
          x
          y
        }
      }
    }
  }
`;
export const dispatchPostgresFunction = /* GraphQL */ `
  query DispatchPostgresFunction($input: DispatchInput!) {
    dispatchPostgresFunction(input: $input)
  }
`;
export const getYeastReferenceExperiment = /* GraphQL */ `
  query GetYeastReferenceExperiment($instrument: String!) {
    getYeastReferenceExperiment(instrument: $instrument) {
      instrument
      experiment
    }
  }
`;
export const getLcmsStatus = /* GraphQL */ `
  query GetLcmsStatus($filters: LcmsFilters) {
    getLcmsStatus(filters: $filters) {
      items {
        experiment
        exp_type
        exp_status
        planned_date
        processed_date
        published_date
        responsible
        collaborator
        projectName
        benchling
        lcms_start
        lcms_end
        instrument
        experiment_folder
      }
    }
  }
`;
export const getFlashExperimentUploadUrl = /* GraphQL */ `
  query GetFlashExperimentUploadUrl($key: String) {
    getFlashExperimentUploadUrl(key: $key) {
      url
      key
    }
  }
`;
export const getBarcodeInfo = /* GraphQL */ `
  query GetBarcodeInfo($peptideBarcode: String) {
    getBarcodeInfo(peptideBarcode: $peptideBarcode)
  }
`;
export const getProcessableFlashSamples = /* GraphQL */ `
  query GetProcessableFlashSamples($experiment: String!) {
    getProcessableFlashSamples(experiment: $experiment) {
      fileName
      sampleId
      position
      injVol
      L1Replicate
      L2Compound
      L3CUM
      L4Time
      L5CellLine
      comment
      sampleName
      fileSize
    }
  }
`;
export const keyExists = /* GraphQL */ `
  query KeyExists($key: String, $bucketType: ExperimentDownload) {
    keyExists(key: $key, bucketType: $bucketType)
  }
`;
export const getResource = /* GraphQL */ `
  query GetResource($resource: String!) {
    getResource(resource: $resource) {
      resource_name
      resource
    }
  }
`;
