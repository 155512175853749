import {FC, RefObject, useLayoutEffect, useRef, useState} from 'react'
import {StatsData} from 'experiment';
import {StyledCenteringBox} from '../Dashboard/ExperimentsPanel/StyledComponents';
import {Box, CircularProgress, Typography} from '@mui/material';
import debounce from 'lodash/debounce';
import {ResponsiveScatterPlot} from '@nivo/scatterplot';
import {conditionAcculator} from '../../util/util';

const SampleConcentrationsPanel: FC<StatsData> = ({data, loading}) => {
    const ref: RefObject<HTMLDivElement> | null = useRef(null)
    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3)

    useLayoutEffect(() => {
        const handleResize = debounce(() => {
            setGraphHeight(window.innerWidth / 3);
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (data) {
        const nodes: any = data.samples.map((sample: any, index: number) => {
            return {
                x: index,
                y: sample.measured_concentration_ng_ul,
                sPlateWell: sample.sample_plate_well,
                injectionVolume: sample.injection_volume_200_ng,
                condition: sample.condition
            }
        })
        const accumulator: any[] = []
        const plotData = nodes.reduce(conditionAcculator, accumulator)

        return <Box ref={ref} height={graphHeight}>
            <ResponsiveScatterPlot data={plotData}
                theme={{axis: {legend: {text: {fontSize: 16}}}}}
                margin={{top: 20, right: 140, bottom: 70, left: 90}}
                xScale={{type: 'linear', min: 0, max: 'auto'}}
                yScale={{type: 'linear', min: 0, max: 'auto'}}
                blendMode="multiply"
                axisBottom={{tickSize: 5, tickPadding: 5, tickRotation: 0,
                    legend: "Samples", legendPosition: 'middle', legendOffset: 47}}
                axisLeft={{tickSize: 5, tickPadding: 5, tickRotation: 0,
                    legend: "Concentration ( ng / \u00B5l )", legendPosition: 'middle', legendOffset: -60}}
                tooltip={(d: any) => {
                    return <Box width={195} px={1} py={0.5} bgcolor="common.white" boxShadow={2} component="ul" sx={{listStyleType: 'none'}}>
                        <Typography component="li" variant="body2">Sample Plate Well: {d.node.data.sPlateWell}</Typography>
                        <Typography component="li" variant="body2">Measured Concentration: {d.node.data.y}</Typography>
                        <Typography component="li" variant="body2">Calculated Injection Volume: {d.node.data.injectionVolume}</Typography>
                        <Typography component="li" variant="body2">Condition: {d.node.data.condition}</Typography>
                    </Box>
                }}
                useMesh={false}
            />
        </Box>
    }

    if (loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }

    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}

export default SampleConcentrationsPanel