import React from 'react';
import { uiColors } from '../../theme/theme';
import { Box, Button, FormControl, useMediaQuery } from '@mui/material';
import { StyledFilterBase, StyledFilterLabel } from '../StyledComponents';

interface DateRangeFilterProps {
    min: string;
    max: string;
    start: string;
    end: string;
    handleStart: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleEnd: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleApplyDates: () => void;
    labelPrefix?: string
}

export default function DateRangeFilter({
                                            min,
                                            max,
                                            start,
                                            end,
                                            handleStart,
                                            handleEnd,
                                            handleApplyDates,
                                            labelPrefix
                                        }: DateRangeFilterProps) {
    
    const hideText = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));
    
    return (
        <Box role="group" display="flex" ml={3}
            sx={{
                backgroundColor: uiColors.filters.inputBaseBg,
                borderBottom: `${uiColors.filters.inputActionBorder} 1px solid`,
                borderTopRightRadius: 4,
                borderTopLeftRadius: 4
            }}
        >
            <FormControl variant="filled" size="small" sx={{ width: 130, mr: 1 }}>
                <StyledFilterLabel htmlFor="pick-from-date">{labelPrefix} From Date</StyledFilterLabel>
                <StyledFilterBase type="date"
                    id="pick-from-date"
                    placeholder="dd-mm-yyyy"
                    value={start}
                    onChange={handleStart}
                    inputProps={{ min: `${min}`, max: `${max}` }}
                />
            </FormControl>
            <FormControl variant="filled" size="small" sx={{ width: 130, ml: 1 }}>
                <StyledFilterLabel htmlFor="pick-to-date">To Date</StyledFilterLabel>
                <StyledFilterBase type="date"
                    id="pick-to-date"
                    placeholder="dd-mm-yyyy"
                    value={end}
                    onChange={handleEnd}
                    inputProps={{ min: `${min}`, max: `${max}` }}
                />
            </FormControl>
            <Button
                id="apply-filter-date"
                variant="text"
                color="primary"
                size="small"
                onClick={handleApplyDates}
                sx={{ px: '12px' }}
            >
                {hideText? 'Apply' : 'Apply Dates'}
            </Button>
        </Box>
    );
}