import {FC, RefObject, useLayoutEffect, useRef, useState} from 'react'
import {StatsData} from 'experiment';
import {ResponsiveScatterPlot, ScatterPlotLayerProps} from '@nivo/scatterplot'
import {Box, Typography} from '@mui/material/';
import {CircularProgress} from '@mui/material';
import {StyledCenteringBox} from '../Dashboard/ExperimentsPanel/StyledComponents';
import debounce from 'lodash/debounce';

const StandardCurvePanel: FC<StatsData> = ({data, loading}) => {

    const ref: RefObject<HTMLDivElement> | null = useRef(null);
    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3);
    // console.log('My DATA', data)

    useLayoutEffect(() => {
        const handleResize = debounce(() => {
            setGraphHeight(window.innerWidth / 3);
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const LinesLayer = ({
                          nodes,
                          xScale,
                          yScale
    }: ScatterPlotLayerProps<{x: number, y: number, stdev: number, regression: any[] | undefined}>) => {
        return nodes.map(({id, data, index}) => {
            return <g key={'lines' + index}>
                {data.regression && <line key="regression" x1={xScale(data.regression[0].x)} x2={xScale(data.regression[1].x)}
                                          y1={yScale(data.regression[0].y)} y2={yScale(data.regression[1].y)}
                                          stroke='green' style={{strokeWidth: 2}}></line>}
                <line key={'errorBar' + index}  x1={xScale(data.x)} x2={xScale(data.x)} y1={yScale(data.y + data.stdev)}
                      y2={yScale(data.y - data.stdev)}
                         stroke='red' style={{strokeWidth: 1}}></line>
                <line key={'top' + index} x1={xScale(data.x - 0.01)} x2={xScale(data.x + 0.01)} y1={yScale(data.y + data.stdev)}
                      y2={yScale(data.y + data.stdev)}
                          stroke='red' style={{strokeWidth: 1}}></line>
                <line key={'bottom' + index} x1={xScale(data.x - 0.01)} x2={xScale(data.x + 0.01)} y1={yScale(data.y - data.stdev)}
                      y2={yScale(data.y - data.stdev)}
                          stroke='red' style={{strokeWidth: 1}}></line>
            </g>
        })
    }

    if (data && data.standards) {
        const points: any = []
        const yMax = data.standards.reduce((acc: number, curr: any) => acc > curr.mean_fluorescence_values_RFU ? acc : curr.mean_fluorescence_values_RFU)
        const concValues = data.standards.map((s: any) => s.concentrations_ng_ul_W)
        const xMax = Math.max(...concValues)
        const xMin = Math.min(...concValues)

        data.standards.forEach((std: any) => {
            points.push({
                "x": std.concentrations_ng_ul_W,
                "y": std.mean_fluorescence_values_RFU,
                "stdev": std.stdev_fluorescence_values_RFU,
                "plateWell": std.assay_plate_wells
            })
        })
        const curveData = [{
            "id": "curveData",
            "data": points
        }]
        const linePoints: any = []
        linePoints.push({x: xMin, y: (data.experiment_properties.ax_b_formula[0] * xMin + data.experiment_properties.ax_b_formula[1])})
        linePoints.push({x: xMax, y: (data.experiment_properties.ax_b_formula[0] * xMax + data.experiment_properties.ax_b_formula[1])})
        // hanging the regression line data off of the first point
        points[0]['regression'] = linePoints

        return (
            <Box ref={ref} height={graphHeight}>
                <ResponsiveScatterPlot data={curveData}
                                theme={{axis: {legend: {text: {fontSize: 16}}}}}
                                margin={{top:20, right:110, bottom:50, left:90}}
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: yMax
                                }}
                                xScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: xMax
                                }}
                                colors={{scheme: 'category10'}}
                                xFormat=" >-.2f"
                                yFormat=" >-.2f"
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'W concentration [ng / \u00B5l]',
                                    legendOffset: 36,
                                    legendPosition: 'middle'
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Fluorescence (RFU)',
                                    legendOffset: -60,
                                    legendPosition: 'middle'
                                }}
                                // pointColor={theme.palette.primary.main}
                                useMesh={false}
                                tooltip={(d: any) => {
                                    return (
                                        <Box width={195} px={1} py={0.5} bgcolor="common.white" boxShadow={2} component="ul" sx={{listStyleType: 'none'}}>
                                            <Typography component="li" variant="body2">Mean RFU: {d.node.data.y}</Typography>
                                            <Typography component="li" variant="body2">Stdev: {d.node.data.stdev}</Typography>
                                            <Typography component="li" variant="body2">Assay Plate Well: [{d.node.data.plateWell.join(', ')}]</Typography>
                                        </Box>
                                    )
                                }}
                                layers={[
                                    'grid',
                                    'axes',
                                    'nodes',
                                    // @ts-ignore
                                    LinesLayer,
                                    'legends',
                                ]}
                />
            </Box>
        )
    }
    if (loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }

    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}

export default StandardCurvePanel