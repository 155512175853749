import {useEffect, useState} from "react";
import Observable from "zen-observable-ts";
import {API, graphqlOperation} from "aws-amplify";
import * as subscriptions from "../../../../../../../graphql/subscriptions";
import * as queries from "../../../../../../../graphql/queries";
import axios, {AxiosProgressEvent} from "axios";
import * as mutations from "../../../../../../../graphql/mutations";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import {Alert} from "@mui/material";
import { get } from "lodash";

interface UploadProgressProps {
    experimentName: string;
    experimentType: string;
    psmFile: any;
    experimentFolder: string;
    database: string;
    benchling: string
    instrument: string;
    psmThreshold: number;
    isolationInterferenceCutoff: number;
    averageReporterSNCutoff: number;
    missingChannelThreshold: number;
    spsMassMatches: number | null;
    ignoreSpsMassMatches: boolean;
    skipNormalization: boolean;
    psmAmbiguityOptions: string[];
    identifyingNodeOptions: string[];
    tmt: string;
    tmtLot: string;
    pdVersion: string;
    injectionNumber: number|null;
    handleUploadFinished: () => void;
}

const UploadProgress = ({experimentName, experimentType, psmFile, database, benchling, instrument, psmThreshold,
                            isolationInterferenceCutoff, averageReporterSNCutoff, missingChannelThreshold,
                            spsMassMatches, ignoreSpsMassMatches, skipNormalization, psmAmbiguityOptions,
                            identifyingNodeOptions, tmt, tmtLot, pdVersion, experimentFolder,
                            injectionNumber, handleUploadFinished}: UploadProgressProps) => {

    const [psmFileProgress, setPsmFileProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [progressColor, setProgressColor] = useState<'primary'|'secondary'>('primary');

    const psmFileName = 'psms/' + experimentName + '_PSMs.txt'

    useEffect(() => {
        let subscription: any;
        const uploadFile = async () => {
            try {
                const completedAnalysisStep: Observable<object> = API.graphql(graphqlOperation((subscriptions.completedAnalysisStep))) as Observable<object>;
                subscription = completedAnalysisStep.subscribe({
                    next: function(result: any) {
                        console.log('UploadProgress subscribe next called', result)
                        if (result.value.data.completedAnalysisStep.experimentName === experimentName) {
                            if (result.value.data.completedAnalysisStep.status === "OK") {
                                handleUploadFinished();
                            } else {
                                setErrorMessage(result.value.data.completedAnalysisStep.message);
                                setProgressColor('secondary');
                                setPsmFileProgress(100);
                            }
                        }
                    },
                    error(err: any) {
                        console.log('Error: ' + err);
                        handleUploadFinished();
                    },
                    complete() {
                        console.log('COMPLETE');
                        handleUploadFinished();
                    }
                });

                const psmUploadUrlResult: any = await API.graphql(graphqlOperation(queries.getUploadUrl, {key: psmFileName}));
                const configForPsm = {
                    onUploadProgress: function (progressEvent: AxiosProgressEvent) {

                        setPsmFileProgress((progressEvent.loaded / progressEvent.total!) * 100)
                    },
                };

                await axios.put(psmUploadUrlResult.data.getUploadUrl.url, psmFile, configForPsm);
                await API.graphql(graphqlOperation(mutations.startAnalysis, {
                    input: {
                        experimentName: experimentName,
                        experimentType: experimentType,
                        psmFile: psmFile.name,
                        experimentFolder: experimentFolder,
                        database: database,
                        instrument: instrument,
                        benchling: benchling,
                        psmThreshold: psmThreshold,
                        isolationInterferenceCutoff: isolationInterferenceCutoff,
                        averageReporterSNCutoff: averageReporterSNCutoff,
                        missingChannelThreshold: missingChannelThreshold,
                        spsMassMatchesCutoff: spsMassMatches,
                        ignoreSpsMassMatches: ignoreSpsMassMatches,
                        skipNormalization: skipNormalization,
                        psmAmbiguityOptions: psmAmbiguityOptions,
                        identifyingNodeOptions: identifyingNodeOptions,
                        tmt: tmt,
                        tmtLot: tmtLot,
                        pdVersion: pdVersion,
                        injectionNumber: injectionNumber,
                        quantitationMethod: 'MS_3' //TODO add to UI
                    }
                }));
            } catch(error: any) {
                console.log("UPLOAD ERROR", error);

                const errorMessage = get(error, 'errors[0].message', 'unknown error');
                setErrorMessage(`Upload failure: ${error?.message ?? errorMessage}`);
            }
        };
        uploadFile().then();
        return function cleanup() {
            if (subscription) {
                subscription.unsubscribe();
            }
        }
        // Using empty array to ensure this hook only runs once.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        <InputLabel htmlFor="psm-file-progress">PSM file upload progress</InputLabel>
        {psmFileProgress === 0 ?
            <LinearProgress color={progressColor}/>
            :
            <LinearProgress variant="determinate" value={psmFileProgress} color={progressColor}/>
        }
        <InputLabel htmlFor="psm-conversion-progress">PSM conversion progress</InputLabel>
        {errorMessage ?
            <Alert severity="error">{errorMessage}</Alert>
            :
            <LinearProgress/>
        }
    </>
}

export default UploadProgress;