import { Box, Typography } from '@mui/material';

import BoxPlot from '../../../../../../../visualizations/BoxPlot';

export default function LabellingCheckBoxPlot(boxPlot: any) {
    if (boxPlot) {
        return <BoxPlot plotData={boxPlot} />;
    } else {
        return (
            <Box display='flex' flexDirection='column' alignItems='center'>
                <Typography align="center" paragraph>Channel Intensities data unavailable.</Typography>
            </Box>
        )
    }
}

