import {useEffect, useState} from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import BoxPlot from './BoxPlot';
import {StatsData} from "experiment";
import useMounted from "../../util/useMounted";


export default function ChannelIntensitiesPanel({data, loading}: StatsData) {

    const isMounted = useMounted();
    const [boxPlot, setBoxPlot] = useState<any | null>(null);

    useEffect(() => {
        const processData = (data: any) => {
            try {
                if (data) {
                    const boxPlotData = {
                        boxPlot: data
                    };
                    isMounted() && setBoxPlot(boxPlotData);
                } else {
                    isMounted() && setBoxPlot(null);
                }
            } catch (error) {
                isMounted() && setBoxPlot(null);
                console.error('Channel Intensities error: ', error);
            }
        };

        processData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (boxPlot != null) {
        return <BoxPlot plotData={boxPlot} />;
    }
    if(loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}
