import React, {ChangeEvent, ReactChild, useEffect, useState} from 'react';
import {ListChildComponentProps, VariableSizeList} from 'react-window';
import {
    Autocomplete,
    autocompleteClasses,
    FormControl, IconButton,
    Popper,
    Typography,
    useMediaQuery
} from '@mui/material';
import {styled, useTheme} from '@mui/material/styles';
import {StyledAutoCompleteInput} from '../../StyledComponents';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {DesignTableDataCy} from './DesignTable.cy';

interface CompoundProps {
    rowIndex: number
    compoundIndex: number
    options: any[]
    compound: any
    compoundChangeHandler: (value: any, compoundIndex: number) => void
}

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const {data, index, style} = props
    const dataSet = data[index]
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING
    }
    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1].value}
        </Typography>
    )
}
const OuterElementContext = React.createContext({})

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const {children, ...other} = props;
        const itemData: React.ReactChild[] = [];
        (children as React.ReactChild[]).forEach((item: ReactChild & { children?: React.ReactChild[]}) => {
            itemData.push(item);
            itemData.push(...(item.children || []))
        })

        const theme = useTheme()
        // @ts-ignore
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true,
        })
        const itemCount = itemData.length
        const itemSize = smUp ? 36 : 48

        const getHeight = () => {
            if (itemCount > 10) {
                return 10 * itemSize
            }
            return itemCount * itemSize
        }
        const gridRef = useResetCache(itemCount)

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={() => itemSize}
                        overscanCount={5}
                        itemCount={itemCount}>
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        )
    }
)

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
})

export default function VirtualizedCompoundAutocomplete({rowIndex,
                                                        compoundIndex,
                                                        options,
                                                        compound,
                                                        compoundChangeHandler}: CompoundProps) {
    const [autoValue, setAutoValue] = useState<SampleCompound | null>(compound);
    const [inputValue, setInputValue] = useState<string>('');

    const doInputChange = (event: ChangeEvent<any>, newInputValue: string) => {
        setInputValue(newInputValue)
        const match = options.find((element: any) => element.value === newInputValue)
        // the event will be null if a row is being duplicated, in which case we don't need the compoundChangeHandler
        if (match && event) {
            compoundChangeHandler(match, compoundIndex);
        }
    }

    const doValueChange = (e: ChangeEvent<any>, newValue: SampleCompound | null) => {
        setAutoValue(newValue)
    }

    useEffect(() => {

            setAutoValue(compound)

    }, [compound])

    return (
        <Autocomplete
            id={'selectCompoundID-' + rowIndex + '-' + compoundIndex}
            data-cy={DesignTableDataCy.compound + rowIndex}
            value={autoValue}
            isOptionEqualToValue={(option, autoValue) => {
                return option.value === autoValue?.value;
            }}
            onChange={doValueChange}
            inputValue={inputValue}
            onInputChange={doInputChange}
            getOptionLabel={(option) => option.value ? option.value: ""}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={options}
            size="small" fullWidth openOnFocus
            renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
            renderGroup={(params) => params as unknown as React.ReactNode}
            renderInput={(params) => (
                <FormControl ref={params.InputProps.ref}
                             className="MuiTextField-root"
                             variant="standard" size="small" fullWidth
                >
                    <StyledAutoCompleteInput
                        inputProps={params.inputProps}
                        componentsProps={{
                            root: {
                                className: 'MuiFilledInput-root MuiAutocomplete-inputRoot'
                            },
                            input: {
                                className: 'MuiFilledInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused'
                            }
                        }}
                        endAdornment={
                            <div className="MuiAutocomplete-endAdornment">
                                <IconButton className="MuiAutocomplete-clearIndicator"
                                            aria-label="Clear" title="Clear"
                                            onClick={() => setInputValue('')}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                                <IconButton className="MuiAutocomplete-popupIndicator">
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </div>
                        }
                    />
                </FormControl>
            )}
        />
    )
}
