import {ExperimentFilters} from 'filters';
import {API, graphqlOperation} from '@aws-amplify/api';
import * as queries from '../graphql/queries';
import {experimentTypes} from '../util/options';
import {Experiment} from 'experiment';
import {reverseCompareByDate} from '../CompareExperiments';
import * as subscriptions from '../graphql/subscriptions';

const experimentsService = {
    fetchExperimentStatusList: async (listRecordType: string, filter: ExperimentFilters) => {
        const result: any = await API.graphql(graphqlOperation(queries.getExperimentList,
            {recordType: listRecordType, filters: filter}));
        return result.data.getExperimentList.items;
    },
    fetchExperimentStats: async (experimentCode: string, filters: ExperimentFilters) => {
        let statsRecordType = experimentTypes.find(t => t.code === experimentCode)!.statsType;
        try {
            const result: any = await API.graphql(graphqlOperation(queries.getExperimentStatistics,
                {recordType: statsRecordType, filters: filters}));

            const experiments = result.data.getExperimentStatistics.items;
            for (let i = 0; i < experiments.length; i++) {
                const experiment = experiments[i] as Experiment;

                if (experiment) {
                    // @ts-ignore
                    experiment.date = new Date(experiment.date);
                }
            }

            experiments.sort(reverseCompareByDate)
            return experiments
        } catch (error) {
            console.warn('Error fetching experiments statistics', error);
        }
        return []
    },
    fetchAnalysisCompleteSubscription: () => {
        return API.graphql(graphqlOperation(subscriptions.completedAnalysisStep))
    }
}

export default experimentsService