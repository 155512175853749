import {graphqlOperation} from 'aws-amplify';
import {API} from '@aws-amplify/api';
import {completeExperiment, deleteExperiment, revertExperiment, publishExperiment} from '../graphql/mutations';

const menuServices = {
    setComplete: async (experiment: string, experimentType: ExperimentType): Promise<boolean> => {
        const variables = {experimentName: experiment, experimentType: experimentType.value, status: 'COMPLETE'}
        try {
            const result: any = await API.graphql(graphqlOperation(completeExperiment, variables))
            // console.log(`RESULT ${JSON.stringify(result)}`)
            return result?.data.completeExperiment === 'OK'
        } catch (error) {
            console.error(`Error setting complete ${experiment}`, error)
        }
        return false
    },
    revertToPlanned: async (experiment: string, experimentType: ExperimentType): Promise<void> => {
        const variables = {experimentName: experiment, experimentType: experimentType.value};
        try {
            await API.graphql(graphqlOperation(revertExperiment, variables))
        } catch(error) {
            console.log("Error reverting experiment: " + experiment, error);
        }
    },
    deleteExperiment: async (experiment: string, experimentType: ExperimentType): Promise<void> => {
        const variables = {experimentName: experiment, experimentType: experimentType.value};
        let result: any
        try {
            result = await API.graphql(graphqlOperation(deleteExperiment, variables))
        } catch(error) {
            console.log("Error deleting experiment: " + experiment, error);
        }
        return result
    },
    publish: async (experiment: string, experimentType: ExperimentType): Promise<any> => {
        const input = {
            experimentName: experiment,
            significanceMeasure: 'P_VALUE',
            experimentType: experimentType.value
        }
        const result: any = await API.graphql(graphqlOperation(publishExperiment, {input}))
        return result.data.publishExperiment
    }
}

export default menuServices