import React, {FC} from 'react'
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup} from '@mui/material';


interface EditModeProps {
    mode: string;
    setMode: (newMode: string) => void;
    types: string[];
}

const EditMode: FC<EditModeProps> = ({mode, setMode, types}) => {
    return (<>
    <FormControl>
        <FormLabel id="rowEditMode">Row Edit Mode</FormLabel>
        <RadioGroup
            aria-labelledby="rowEditMode"
            value={mode}
            onChange={(event:React.ChangeEvent<HTMLInputElement>) => setMode(event.target.value)}
            name="fieldEditMode">
            <FormControlLabel value="currentOnly" control={<Radio />} label="Current Row Only" />
            <FormControlLabel value="checkedRows" control={<Radio />} label="Current and All Checked Rows" />
            <FormControlLabel value="typed" control={<Radio />} label="Current and Selected Sample Types" />
        </RadioGroup>
    </FormControl>
    <FormControl sx={{mt:2}} component="fieldset" variant="standard" disabled={mode !== 'typed'}>
        <FormLabel component="legend">Update this row AND ALL rows of type:</FormLabel>
        <FormGroup>
            {types.map((type) => (
                <FormControlLabel key={type} control={<Checkbox name={type} />} label={type} />
            ))}
        </FormGroup>
    </FormControl>
    </>
    )
}

export default EditMode