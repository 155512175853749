import React, {FC, useContext, useState} from 'react';
import {ReferenceContext} from '../../../store/ReferenceContext';
import CreateNewExperimentFooter from './CreateNewExperimentFooter';
import {Box, Dialog, DialogContent, DialogTitle, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExperimentForm from '../ExperimentForm';
import {experimentTypes} from '../../../util/options';
import {AlertButtons, AlertErrorMessages, AlertTitle} from './CreateNewExperimentModal';
import designs from '../../../services/designs';
import AlertDialog from '../AlertDialog';

const CreateNotTmtExperimentModal: FC<CreateNewExpModalProps>
                    = ({handleClose,
                           handleSuccess,
                           existingExperimentName,
                           experimentDesign }) => {
    const referenceContext: References = useContext<References>(ReferenceContext)
    const [open, setOpen] = useState<boolean>(true)
    const [numSamples, setNumSamples] = useState<number>(experimentDesign ? experimentDesign.noOfSamples : 16);
    const [projectName, setProjectName] = useState<string>(experimentDesign ? experimentDesign.projectName : '')
    const [responsible, setResponsible] = useState<string>(experimentDesign ? experimentDesign.responsible : '')
    const [collaborator, setCollaborator] = useState<string>(experimentDesign ? experimentDesign.collaborator : '')
    const [benchling, setBenchling] = useState<string>(experimentDesign ? experimentDesign.benchling : '')
    const [experiment_folder, setExperimentFolder] = useState(experimentDesign ? experimentDesign.experiment_folder : '')
    const experimentType = "GENERAL"
    const [errorMessages, setErrorMessages] = useState<string[]>();
    const [openFormError, setOpenFormError] = useState(false);
    const windowTitleId = 'NewNotTMTExp';
    const dialogTitle = existingExperimentName ? existingExperimentName : 'New Not TMT Experiment'

    const numSamplesChangeHandler = (count: number) => {
        setNumSamples(count)
    }
    const handleSummaryChange = (key: string, value: any) => {
        switch(key) {
            case 'numSamples':
                setNumSamples(parseInt(value))
                break
            case 'projectName':
                setProjectName(value)
                break
            case 'responsible':
                setResponsible(value)
                break
            case 'collaborator':
                setCollaborator(value)
                break
            case 'benchling':
                setBenchling(value)
                break
            case 'experiment_folder':
                setExperimentFolder(value)
                break
        }
    };
    const isValidated = (newExperimentDetail: any) => {
        let errorMessageList = []
        if (isNaN(numSamples) || numSamples < 1) {
            errorMessageList.push(AlertErrorMessages.numberOfSamplesRequired);
        }
        if (!newExperimentDetail.projectName) {
            errorMessageList.push(AlertErrorMessages.projectNameRequired);
        }
        if (!newExperimentDetail.responsible) {
            errorMessageList.push(AlertErrorMessages.responsibleRequired)
        }
        if (!newExperimentDetail.collaborator) {
            errorMessageList.push(AlertErrorMessages.collaboratorRequired)
        }
        setErrorMessages(errorMessageList);
        return errorMessageList.length === 0;
    }

    const handleSave = async (newExperimentDetail: any) => {
        if (isValidated(newExperimentDetail)) {
            const type = experimentTypes.find((type) => type.value === 'GENERAL')!
            let experimentName = existingExperimentName;
            if (!experimentName) {
                experimentName = await designs.createName(type)
            }
            let experimentList = [];
            let recordValue = {...newExperimentDetail};
            recordValue.conditions = []
            let designExperiment: any = {
                experiment: experimentName,
                record_type: "DESIGN",
                record_value: recordValue,
            };
            let statusExperiment: any = {
                experiment: experimentName,
                record_type: "GENERAL_STATUS"
            };
            experimentList.push(designExperiment);
            experimentList.push(statusExperiment);
            const results: any = await designs.uploadPlanned(experimentList);
            if(results) {
                handleClose();
                const action = existingExperimentName ? 'updated' : 'created';
                handleSuccess(`Experiment with name '${results.data.uploadPlannedExperiment[0]?.experiment}' has been successfully ${action}!`,
                    type);
            }
        } else {
            setOpenFormError(true);
        }

    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        // console.log('handleSubmit called...')
        const formData = new FormData(event.currentTarget)
        const fieldValues = Object.fromEntries(formData.entries())
        let newExperimentDetailValues = {
            collaborator: referenceContext.shortUsers.find( collaborator => collaborator.value ===fieldValues.collaborator)?.value,
            projectName: fieldValues.projectName,
            responsible: referenceContext.shortUsers.find(responsible => responsible.value === fieldValues.responsible)?.value,
            type: experimentTypes.find(type => type.label === fieldValues.type)?.value,
            noOfSamples: numSamples,
            benchling: benchling,
            experiment_folder: experiment_folder
        }
        handleSave(newExperimentDetailValues)
    }

    const renderFormErrorsDialog = () => {
        return (
            <AlertDialog
                openDialog={openFormError}
                handleDialogClose={() => setOpenFormError(false)}
                dialogTitle={AlertTitle.error}
                handlePositiveClick={() => setOpenFormError(false)}
                dialogContentText={AlertErrorMessages.rectifyErrorNeeded}
                dialogContentExtraText={errorMessages?.map((error, index) => {
                    return (
                        <div key={index}>{error}</div>
                    )
                })}
                positiveButtonText={AlertButtons.ok}
            />
        )
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            PaperProps={{
                square: true,
                elevation: 0,
                sx: { border: '2px solid black', overflowY: 'hidden'}
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby={windowTitleId}
        >
            <DialogTitle id={windowTitleId}>{dialogTitle}</DialogTitle>
            <Box position="absolute" top={0} right={0} mt={1} mr={2}>
                <IconButton size="large" color="secondary" aria-label="Close dialog"
                        onClick={() => setOpen(false)}
                >
                    <CloseIcon fontSize="inherit" />
                </IconButton>
            </Box>
            <Box name="createnew" component="form"
                 noValidate onSubmit={handleSubmit} autoComplete="off"
            >
                <DialogContent dividers>
                    <ExperimentForm
                        experimentType={experimentType}
                        setExperimentType={(type:string) => {throw Error('Experiment type is readonly')}}
                        numSamples={numSamples}
                        numSamplesChangeHandler={numSamplesChangeHandler}
                        projectName={projectName}
                        responsible={responsible}
                        collaborator={collaborator}
                        handleSummaryChange={handleSummaryChange}
                        tmt={false}
                        benchling={benchling}
                        experiment_folder={experiment_folder}
                    />
                </DialogContent>
                <CreateNewExperimentFooter handleCancelWarningOpen={handleClose}/>
            </Box>
            {renderFormErrorsDialog()}
        </Dialog>
    )
}

export default CreateNotTmtExperimentModal