import React, {FC, SyntheticEvent, useState} from 'react';
import {SingleColLayout} from '../SharedComponents';
import {Box, Paper, Tabs, Tab, Typography} from '@mui/material';
import PairMapper from './PairMapper';
import BarcodeInfo from './BarcodeInfo';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;
    return (
        <Box
            role="tabpanel"
            id={`barcode-tabpanel-${index}`}
            hidden={value !== index}
            aria-labelledby={`barcode-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </Box>
    );
}

function a11yProps(index: any) {
    return {
        id: `barcode-tab-${index}`,
        'aria-controls': `barcode-tabpanel-${index}`
    };
}

const BarcodeView: FC = () => {
    const [value, setValue] = useState(0);
    const [pairs, setPairs] = useState<BarcodePair[]>([])

    const handleTabSelection = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <SingleColLayout>
            <Box mt={0.5} mb={0.5} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h4" width="100%" textAlign="center">Barcode Mapper</Typography>
            </Box>
            <Paper sx={{minHeight: 600, display: 'flex', flexDirection: 'column'}}>
                <Box pt={1} width="100%" borderBottom={1} borderColor="divider">
                    <Tabs value={value} onChange={handleTabSelection} aria-label="Barcode options" centered>
                        <Tab label="Map Barcode pair" {...a11yProps(0)} />
                        <Tab label="Fetch Barcode Info" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <Box px={2} pt={3} pb={4}>
                    <TabPanel index={0} value={value}>
                        <PairMapper pairs={pairs} setPairs={setPairs} />
                    </TabPanel>
                    <TabPanel index={1} value={value}>
                        <BarcodeInfo />
                    </TabPanel>
                </Box>
            </Paper>
        </SingleColLayout>
    );
};

export default BarcodeView;
