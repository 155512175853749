const supportedChannels = [10, 11, 16, 18];

export default function tmtUtil(file: any, callback: (chanelCnt: number) => void, errorCallback: (msg: string) => void) {
    const fileReader = new FileReader();
    fileReader.onload = () => {
        const text = fileReader.result;

        if (text) {
            const lines = text.toString().split(/\n/);
            const columns = lines[0].split(/\t/);
            const channels = columns.filter(column => column.toLowerCase().includes('abundance'));
            if (!supportedChannels.includes(channels.length)) {
                errorCallback('File does not contain the appropriate number of supported channels.');
            } else {
                callback(channels.length);
            }
        } else {
            errorCallback('Unable to read psm file.');
        }
    };
    fileReader.readAsText(file);
}
