import {DesignTableDataCy} from './DesignTable.cy';
import {StyledTbodyCell} from './StyledComponents';
import {
    Autocomplete,
    Box,
    FormControl, IconButton,
    InputBase,
    InputLabel,
    NativeSelect, TableRow,
    TextField,
    Typography
} from '@mui/material';
import {
    concentrationOptions,
    concentrationUnits, concOptions,
    treatmentTimeOptions
} from '../../../util/options';
import React, {SyntheticEvent, useContext, useEffect, useState} from 'react';
import SampleRowMenu from '../SampleRowMenu';
import VirtualizedCompoundAutocomplete from './VirtualizedCompoundAutocomplete';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import {ReferenceContext} from '../../../store/ReferenceContext';
import {createFilterOptions} from '@mui/material/Autocomplete';

interface DesignTableRowProps {
    row: SampleRow;
    index: number;
    isTmt: boolean;
    useCompound: boolean;
    cellLines: CellLine[];
    availableTmtValues: Labeled[];
    tmtInUse: string[];
    newExperimentType: string;
    handleDuplicate: (index: number) => void;
    handleClearRow: (index: number) => void;
    handleRowUpdate: (index: number, row: SampleRow) => void;
}

const defaults: { readonly [key: string]: { readonly [key: string]: string|object }} = {
    DEGRADATION: {conc: '10', unit: concentrationUnits[2], treatmentTime: '24'},
    TURBO_ID: {conc: '10', unit: concentrationUnits[2], treatmentTime: '6'},
    CHEMOPROTEOMICS: {conc: '', unit: concentrationUnits[0], treatmentTime: ''},
    PHOSPHORYLATION: {conc: '', unit: concentrationUnits[0], treatmentTime: ''},
    FLOW_THROUGH: {conc: '', unit: concentrationUnits[0], treatmentTime: ''},
}

export default function DesignTableRow({row, index, isTmt, useCompound,
                                           cellLines,
                                           availableTmtValues,
                                           tmtInUse,
                                           newExperimentType,
                                           handleDuplicate,
                                           handleClearRow,
                                           handleRowUpdate}: DesignTableRowProps) {
    const [desc, setDesc] = useState<string>(row.description)
    const referenceContext: References = useContext<References>(ReferenceContext)
    const [cellInput, setCellInput] = useState<string>('')
    const [tmtHelperTxt, setTmtHelperTxt] = useState<string>('')
    const [tmtInError, setTmtInError] = useState<boolean>(false)

    useEffect(() => {
        setDesc(row.description)
    }, [row.description])
    useEffect(() => {
        // console.log('We have tmtInUse', tmtInUse)
        const inError = tmtInUse.filter((tmt: string) => tmt === row.tmt).length > 1
        if (tmtInError !== inError) {
            setTmtInError(inError)
            if (inError) {
                setTmtHelperTxt('TMT values can only be used once')
            } else {
                setTmtHelperTxt('')
            }
        }
    }, [tmtInUse, tmtInError, row.tmt])

    const filterOptions = createFilterOptions({
        stringify: (option: any) => {
            const start = option?.cell_line_short_name.replaceAll("_", " ")
            return `${start} ${option?.cell_line_short_name} ${option?.benchling_id}`
        }
    })

    const tmtChange = (event: SyntheticEvent, obj: Labeled | null) => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.tmt = obj ? obj.value : ''
        handleRowUpdate(index, copy)
    }
    const cellChange = (event: SyntheticEvent, value: CellLine | null) => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.cells = value ? value.benchling_id : ''
        copy.cellLine = value
        handleRowUpdate(index, copy);
    }

    const compoundChange = (value: any, compoundIndex: number) => {

        const copy = JSON.parse(JSON.stringify(row))
        // console.log(`compoundChange for ${row.sampleNum}`, [copy, value])
        const aBatch = value?.batches ?  value.batches[value.batches.length - 1] : null
        if (value.value === 'DMSO') {
            copy.compounds[compoundIndex] = {
                compound: value,
                batch: aBatch,
                conc: copy.compounds[compoundIndex]?.unit && copy.compounds[compoundIndex]?.unit === '%' ? copy.compounds[compoundIndex]?.conc : '0.1',
                unit: concentrationUnits.find((option) => option.value === '%')
            }
        } else {
            copy.compounds[compoundIndex] = {
                compound: value,
                batch: aBatch,
                conc: copy.compounds[compoundIndex] ? copy.compounds[compoundIndex].conc : defaults[newExperimentType]?.conc,
                unit: copy.compounds[compoundIndex] ? copy.compounds[compoundIndex].unit : defaults[newExperimentType]?.unit
            }
            if (!copy.treatmentTime) {
                copy.treatmentTime = defaults[newExperimentType]?.treatmentTime
            }
        }
        // console.log('Our new sample: ', copy)
        handleRowUpdate(index, copy);
    }

    const batchChange = (value: any, compoundIndex: number) => {
        const copy = JSON.parse(JSON.stringify(row))
        const compounds = [...copy.compounds]
        compounds[compoundIndex] = {
            compound: compounds[compoundIndex].compound,
            batch: value,
            conc: compounds[compoundIndex]?.conc,
            unit: compounds[compoundIndex]?.unit
        }
        copy.compounds = compounds
        handleRowUpdate(index, copy);
    }

    const concentrationChange = (value: any, compoundIndex: number) => {
        // console.log(`concentrationChange ${row.sampleNum}`, [row, value])
        const copy = JSON.parse(JSON.stringify(row))
        const compounds = [...copy.compounds]
        compounds[compoundIndex] = {
            compound: compounds[compoundIndex].compound,
            batch: compounds[compoundIndex].batch,
            conc: value,
            unit: compounds[compoundIndex]?.unit
        }
        copy.compounds = compounds
        handleRowUpdate(index, copy);
    }

    const concentrationUnitChange = (value: any, compoundIndex: number) => {
        const copy = JSON.parse(JSON.stringify(row))
        const compounds = [...copy.compounds]
        compounds[compoundIndex] = {
            compound: compounds[compoundIndex].compound,
            batch: compounds[compoundIndex].batch,
            conc: compounds[compoundIndex]?.conc,
            unit: value
        }
        copy.compounds = compounds
        handleRowUpdate(index, copy);
    }

    const treatmentTimeChange = (event: object, value: string) => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.treatmentTime = value
        handleRowUpdate(index, copy)
    }

    const treatmentTimeUnitChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.treatmentTimeUnit = event.target.value
        handleRowUpdate(index, copy)
    }

    const typeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.type = event.target.value
        handleRowUpdate(index, copy)
    }

    const updateDescription = () => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.description = desc
        handleRowUpdate(index, copy)
    }

    const handleAddCompound = () => {
        let compoundObject = {
            compound: {value: '', batches: []},
            batch: null,
            conc: null,
            unit: null
        }
        const copy = JSON.parse(JSON.stringify(row))
        copy.compounds.push(compoundObject);
        handleRowUpdate(index, copy)
    }

    const handleDeleteCompound = (compoundIndex: number) => {
        const copy = JSON.parse(JSON.stringify(row))
        copy.compounds.splice(compoundIndex, 1);
        handleRowUpdate(index, copy)
    }

    return (
      <React.Fragment key={index}>
        <TableRow data-cy={DesignTableDataCy.row} key={row.sampleNum + '_trKey'}>
            <StyledTbodyCell component="th" scope="row" align="right">
                <Typography component="label" variant="srOnly"
                            htmlFor={'sampleNumId' + row.sampleNum}>
                    Sample Number
                </Typography>
                <InputBase id={'sampleNumId' + row.sampleNum} name="sampleNum" margin="none"
                           defaultValue={row.sampleNum} fullWidth readOnly />
            </StyledTbodyCell>
            {isTmt && (
                <StyledTbodyCell>
                    <Autocomplete
                        id={'selectTMTID' + row.sampleNum}
                        data-cy={DesignTableDataCy.tmt + index}
                        value={row.tmt? availableTmtValues.find((tmt) => tmt.value === row.tmt): null}
                        // @ts-ignore
                        options={availableTmtValues}
                        getOptionLabel={(option) => option.label? option.label: ""}
                        isOptionEqualToValue={(option, value) => {
                            return option.value === value?.value;
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       error={tmtInError}
                                       helperText={tmtHelperTxt}
                                       label="TMT" variant="standard" margin="none" fullWidth />
                        }
                        readOnly={availableTmtValues.length === 32}
                        onChange={tmtChange}
                        openOnFocus selectOnFocus handleHomeEndKeys />
                </StyledTbodyCell>
            )}
            <StyledTbodyCell>
                {useCompound ?
                    <Autocomplete
                        id={'selectCellsID' + row.sampleNum}
                        data-cy={DesignTableDataCy.cells + index}
                        value={row.cellLine}
                        options={cellLines}
                        getOptionLabel={(option: CellLine) => `${option.cell_line_short_name} - ${option.benchling_id} `}
                        isOptionEqualToValue={(option: CellLine, value: CellLine) => {
                            return option.benchling_id === value.benchling_id;
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Cells" variant="standard" margin="none" fullWidth />
                        }
                        onChange={cellChange}
                        inputValue={cellInput}
                        onInputChange={(event, newInputValue) => {
                            setCellInput(newInputValue)
                        }}
                        filterOptions={filterOptions}
                        openOnFocus selectOnFocus handleHomeEndKeys />
           : <>{`${row.cellLine?.cell_line_short_name} - ${row.cellLine?.benchling_id}`}</>}
            </StyledTbodyCell>
            <StyledTbodyCell>
                {useCompound ? <VirtualizedCompoundAutocomplete rowIndex={index} compoundIndex={0}
                                         options={referenceContext.refCompounds}
                                         compound={row.compounds[0]?.compound ? row.compounds[0].compound : null}
                                         compoundChangeHandler={compoundChange} />
                    : <TextField
                        id={'selectCompoundID-' + index} label="Compound" margin="none" fullWidth
                        data-cy={DesignTableDataCy.compound + index}
                        disabled={true}
                        variant="standard" /> }
            </StyledTbodyCell>
            <StyledTbodyCell>
                <Autocomplete
                    id={'selectBatchID' + row.sampleNum}
                    data-cy={DesignTableDataCy.batch + index}
                    value={row.compounds[0]?.batch ? row.compounds[0]?.batch: null}
                    options={row.compounds[0]?.compound?.batches ? row.compounds[0].compound.batches : []}
                    disabled={useCompound ? (row.compounds[0] ? !row.compounds[0]?.compound: true) : !useCompound}
                    renderInput={(params) =>
                        <TextField {...params}
                                   label="Batch" variant="standard" margin="none" fullWidth />
                    }
                    onChange={(event, value) => {
                        batchChange(value, 0);
                    }}
                    openOnFocus selectOnFocus handleHomeEndKeys />
            </StyledTbodyCell>
            <StyledTbodyCell>
                <Box maxWidth={200} display="flex" flexDirection="row" alignItems="flex-end">
                    <Autocomplete
                        id={'selectConcentrationValueID' + row.sampleNum}
                        data-cy={DesignTableDataCy.concentration + index}
                        value={row.compounds[0]?.conc ? row.compounds[0].conc : null}
                        options={concOptions}
                        //inputValue={concInput}
                        disabled={!useCompound}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Conc." variant="standard" margin="none" fullWidth />
                        }
                        onInputChange={(event: object, value: string) => {
                            concentrationChange(value, 0);
                        }}
                        sx={{ flex: '1 0 auto' }}
                        freeSolo openOnFocus selectOnFocus handleHomeEndKeys />
                    <Autocomplete
                        id={'selectConcentrationUnitID' + row.sampleNum}
                        data-cy={DesignTableDataCy.concentrationUnit + index}
                        value={row.compounds[0]?.unit ? row.compounds[0]?.unit: null}
                        options={concentrationUnits}
                        getOptionLabel={(option) => option.label? option.label: ""}
                        disabled={!useCompound}
                        isOptionEqualToValue={(option, value) => {
                            return option.value === value?.value;
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Unit" variant="standard" margin="none" fullWidth />
                        }
                        onChange={(event, value) => {
                            concentrationUnitChange(value, 0);
                        }}
                        sx={{ flex: '0 0 50px', ml: '2px' }}
                        openOnFocus selectOnFocus handleHomeEndKeys />
                </Box>
            </StyledTbodyCell>
            <StyledTbodyCell>
                <Box maxWidth={200} display="flex" flexDirection="row" alignItems="flex-end">
                    <Autocomplete
                        id={'selectTreatmentTimeID' + row.sampleNum}
                        data-cy={DesignTableDataCy.treatmentTime + index}
                        value={row.treatmentTime}
                        options={treatmentTimeOptions}
                        getOptionLabel={(option: CddValue | string) => typeof option === 'string' ? option: option.value}
                        isOptionEqualToValue={(option, value) => {
                            return option.value === value?.value;
                        }}
                        disabled={!useCompound}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Time" variant="standard" margin="none" fullWidth />
                        }
                        onInputChange={treatmentTimeChange}
                        sx={{ flex: '1 0 auto' }}
                        freeSolo forcePopupIcon openOnFocus selectOnFocus handleHomeEndKeys />
                    <FormControl sx={{ flex: '0 0 50px', ml: '2px' }}>
                        <InputLabel htmlFor={'selectTTimeUnitID' + row.sampleNum}>
                            Unit
                        </InputLabel>
                        <NativeSelect
                            inputProps={{
                                id: 'selectTTimeUnitID' + row.sampleNum}}
                            data-cy={DesignTableDataCy.treatmentTimeUnit + index}
                            value={row.treatmentTimeUnit ? row.treatmentTimeUnit: ""}
                            disabled={!useCompound}
                            onChange={treatmentTimeUnitChange}
                        >
                            <option value={'hour'}>h</option>
                            <option value={'min'}>m</option>
                        </NativeSelect>
                    </FormControl>
                </Box>
            </StyledTbodyCell>
            <StyledTbodyCell>
                <FormControl required fullWidth>
                    <NativeSelect
                        required
                        inputProps={{
                            id: 'selectTypeID' + row.sampleNum
                        }}
                        value={row.type ? row.type : null}
                        disabled={!useCompound}
                        data-cy={DesignTableDataCy.type + index}
                        onChange={typeChange}
                    >
                        <option value={'NaN'}>NaN</option>
                        <option value={'bridge'}>Bridge</option>
                        <option value={'Empty'}>Empty</option>
                    </NativeSelect>
                </FormControl>
            </StyledTbodyCell>
            <StyledTbodyCell>
                <TextField
                    id={'descrID' + row.sampleNum} label="Description" margin="none" fullWidth
                    data-cy={DesignTableDataCy.description + index}
                    value={desc}
                    variant="standard"
                    onChange={(event) => {
                        setDesc(event.target.value);
                    }}
                    onBlur={() => {
                        updateDescription()
                    }}
                />
            </StyledTbodyCell>
            <StyledTbodyCell align="center" padding="none">
                {useCompound ?
                    <SampleRowMenu
                        forSample={row.tmt}
                        useCompound={useCompound}
                        handleDuplicate={() => handleDuplicate(index)}
                        handleAddCompound={handleAddCompound}
                        handleClearRow={() => handleClearRow(index)}/>
                    : null }
            </StyledTbodyCell>
        </TableRow>
        {row.compounds.map((com: any, compoundIndex: number) => {
            if(compoundIndex === 0) return null;
            return (
                <TableRow key={row.sampleNum + '_rowSpan_' + compoundIndex}>
                    <StyledTbodyCell colSpan={3} align="right" padding="none">
                        <Typography variant="srOnly">
                            Additional compound for {row.tmt}
                        </Typography>
                        <IconButton color="secondary" aria-label="Remove this compound"
                                    data-cy={row.sampleNum + DesignTableDataCy.extraCompoundDeleteButton + compoundIndex}
                                      onClick={() => handleDeleteCompound(compoundIndex)}>
                            <RemoveIcon/>
                        </IconButton>
                    </StyledTbodyCell>
                    <StyledTbodyCell>
                        <VirtualizedCompoundAutocomplete rowIndex={index} compoundIndex={compoundIndex}
                                         options={referenceContext.refCompounds}
                                         compound={com.compound ? com.compound : null}
                                         compoundChangeHandler={compoundChange} />
                      </StyledTbodyCell>
                      <StyledTbodyCell>
                          <Autocomplete
                              id={'selectBatchID' + row.sampleNum + '_nested_' + compoundIndex}
                              value={com.batch ? com.batch: null}
                              options={com.batches ? com.batches : []}
                              renderInput={(params) =>
                                  <TextField {...params}
                                             label="Batch" variant="standard" margin="none" fullWidth />
                              }
                              onChange={(event, value) => {
                                  batchChange(value, compoundIndex)
                              }}
                              openOnFocus selectOnFocus handleHomeEndKeys />
                      </StyledTbodyCell>
                      <StyledTbodyCell>
                          <Box maxWidth={200} display="flex" flexDirection="row" alignItems="flex-end">
                              <Autocomplete
                                  id={'selectConcentrationValueID' + row.sampleNum + '_nested' + compoundIndex}
                                  options={concentrationOptions.map((option) => option.value)}
                                  value={com.conc ? com.conc : null}
                                  renderInput={(params) =>
                                      <TextField {...params}
                                                 label="Conc." variant="standard" margin="none" fullWidth />
                                  }
                                  onInputChange={(event: object, value: string) => {
                                      concentrationChange(value, compoundIndex)
                                  }}
                                  sx={{ flex: '1 0 auto' }}
                                  freeSolo openOnFocus selectOnFocus handleHomeEndKeys />
                              <Autocomplete
                                  id={'selectConcentrationUnitID' + row.sampleNum + '_nested' + compoundIndex}
                                  options={concentrationUnits}
                                  value={com.unit ? com.unit: null}
                                  getOptionLabel={(option) => option.label? option.label: ""}
                                  isOptionEqualToValue={(option, value) => {
                                      return option.value === value?.value;
                                  }}
                                  renderInput={(params) =>
                                      <TextField {...params}
                                                 label="Unit" variant="standard" margin="none" fullWidth />
                                  }
                                  onChange={(event, value) => {
                                      concentrationUnitChange(value, compoundIndex)
                                  }}
                                  sx={{ flex: '0 0 50px', ml: '2px' }}
                                  openOnFocus selectOnFocus handleHomeEndKeys />
                          </Box>
                      </StyledTbodyCell>
                      <StyledTbodyCell colSpan={4}/>
                  </TableRow>
              )
        })}
      </React.Fragment>
    )
}