import {ChangeEvent, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    NativeSelect,
    OutlinedInput,
    TextField,
    Theme,
    useMediaQuery,
    useTheme
} from '@mui/material/';
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon';
import {experimentTypes} from '../../util/options';
import lcmsStatus from '../../services/lcmsStatus';
import {activeInstruments, Instruments} from "../../store/Instruments";

// TODO: this icon will eventually be included in future update to icons-material
function EditRowIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} width={24} height={24} viewBox="0 96 960 960">
            <path d="m805 696-85-85 29-29q11-11 28-11t28 11l29 29q11 11 11 28t-11 28l-29 29ZM480 936v-85l212-212 85 85-212 212h-85ZM120 736v-80h280v80H120Zm0-160v-80h440v80H120Zm0-160v-80h440v80H120Z"/>
        </SvgIcon>
    )
}

function availableForType(type: string) {
    if ('FLASH_DEGRADATION' === type) {
        return [Instruments.unassigned, activeInstruments.filter((inst) => inst.startsWith('Astral'))]
    }
    return [Instruments.unassigned, ...activeInstruments]
}

interface EditExpLCMSDialogProps {
    expName: string;
    type: string;
    start: string;
    end: string
    instrument: string;
    readonly: boolean;
    updateCallback: (record: any) => void;
}

export default function EditExpLCMSDialog({
                                              expName,
                                              type,
                                              start,
                                              end,
                                              instrument,
                                              readonly,
                                              updateCallback
                                          }: EditExpLCMSDialogProps) {
    
    const theme = useTheme<Theme>();
    const isQHDup = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.qhd));
    
    const [open, setOpen] = useState(false);
    const [lcmsStart, setLcmsStart] = useState<string>(start)
    const [lcmsEnd, setLcmsEnd] = useState<string>(end)
    const [endError, setEndError] = useState<boolean>(false)
    const [lcmsInstrument, setLcmsInstrument] = useState<string>(instrument)
    const instrumentOptions = availableForType(type)

        const handleOpen = () => { setOpen(true);};
    const handleClose = () => {
        setOpen(false);};
    const cancelMe = () => {
        setOpen(false);
        setLcmsStart(start);
        setLcmsEnd(end)
        setLcmsInstrument(instrument)
    }
    const handleUpdate = async () => {
        const updated = await lcmsStatus.updateLcms(expName, lcmsStart, lcmsEnd, lcmsInstrument)
        updateCallback(updated)
        setOpen(false);
    }

    const handleInstrumentChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setLcmsInstrument(event.target.value as string)
    }

    const handleStartChange = (event: ChangeEvent<HTMLInputElement>) => {
        const start = event.target.value
        setLcmsStart(event.target.value)
        if (!lcmsEnd) {
            const experimentType = experimentTypes.find((el) => el.value === type)
            if (experimentType && experimentType.lcms > 0) {
                let end = new Date(Date.parse(start))
                end.setDate(end.getDate() + experimentType.lcms)
                setLcmsEnd(end.toISOString().slice(0,10))
            } else {
                setLcmsEnd(start)
            }
        } else {
            setEndError(start > lcmsEnd)
        }
    }

    const handleEndChange = (event: ChangeEvent<HTMLInputElement>) => {
        const end = event.target.value
        setLcmsEnd(end)
        setEndError(lcmsStart > end)
    }

    const dialogTitleId = 'dialog-edit-lcsm-' + expName;
    const dialogTitleText = readonly ? 'Values for ' + expName : 'Editable values for ' + expName;
    
    return (<>
        <IconButton
            onClick={handleOpen}
            aria-label={'Edit row'}
            size={isQHDup? 'large' : 'medium'}
            color="primary"
            disabled={readonly}
        >
            <EditRowIcon fontSize={isQHDup? 'large' : 'medium'} />
        </IconButton>
        <Dialog
            fullWidth
            maxWidth={'sm'}
            PaperProps={{
                square: true,
                elevation: 0,
                sx: { border: '2px solid black'}
             }}
            open={open}
            onClose={handleClose}
            aria-labelledby={dialogTitleId}
            closeAfterTransition
        >
            <DialogTitle id={dialogTitleId}>{dialogTitleText}</DialogTitle>
            <form>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField id="lcms-start-date"  
                                type="date" label="LCMS start"
                                InputLabelProps={{ shrink: true}}
                                variant="outlined" size="small" fullWidth
                                value={lcmsStart}
                                onChange={handleStartChange}
                                disabled={readonly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="lcms-end-date"  
                                type="date" label="LCMS end"
                                InputLabelProps={{ shrink: true}}
                                variant="outlined" size="small" fullWidth
                                value={lcmsEnd}
                                onChange={handleEndChange}
                                disabled={readonly}
                                helperText="Must not be before start. Required when start is given"
                                error={endError}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel variant="outlined" htmlFor="instrument-select">
                                    Instrument
                                </InputLabel>
                                <NativeSelect
                                    input={<OutlinedInput label="Instrument" />}
                                    inputProps={{
                                        name: 'instrumentSelect',
                                        id: 'instrument-select',
                                    }}
                                    value={lcmsInstrument}
                                    onChange={handleInstrumentChange}
                                    disabled={readonly}
                                >
                                {instrumentOptions.map((opt) => (
                                    <option key={'select' + opt} value={opt}>{opt}</option>
                                ))}
                                </NativeSelect>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {!readonly && <Button onClick={handleUpdate} disabled={endError}>
                        Save
                    </Button>}
                    <Button onClick={cancelMe}>
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </>);
}