import {Button, FormControl, MenuItem, Select, Typography, useMediaQuery} from '@mui/material';
import {StyledFilterBar, StyledFilterBase, StyledFilterLabel} from '../StyledComponents';
import React, {useContext, useState} from 'react';
import {experimentTypes} from '../../util/options';
import {SelectChangeEvent} from '@mui/material/Select/SelectInput';
import InstrumentFilter from './InstrumentFilter';
import DateRangeFilter from './DateRangeFilter';
import {threeMonthsAgo} from '../../util/util';
import CellLineFilter from './CellLineFilter';
import ProjectCompoundFilter, {PROJECT_COMPOUND_DEFAULT_OPTION} from './ProjectCompoundFilter';
import KnownOptionsFilter from './KnownOptionsFilter';
import filterUtils, {DEFAULT_CELL_LINE, DEFAULT_OPTION} from './filterUtils';
import {ReferenceContext} from '../../store/ReferenceContext';
import {FiltersProps, ProjectCompound} from 'filters';
import {allInstruments, Instruments} from "../../store/Instruments";

export default function StatusFilterBar({handleFilterChange}: FiltersProps) {

    const [experimentCode, setExperimentCode] = useState<string>(DEFAULT_OPTION)
    const [instrument, setInstrument] = useState<string>(DEFAULT_OPTION)
    let present = new Date().toISOString().slice(0,10)
    const [start, setStart] = useState<string>(threeMonthsAgo())
    const [end, setEnd] = useState<string>(present)
    const [cellLine, setCellLine] = useState<any>(DEFAULT_CELL_LINE)
    const referenceContext = useContext<References>(ReferenceContext)
    const cellLineOptions = referenceContext.cellLines.map((c: CellLine) => {
        const value = c.cell_line_short_name ? `${c.cell_line_short_name} - ${c.benchling_id}` : c.benchling_id
        return {label: value, id: c.benchling_id}
    })
    cellLineOptions.unshift(DEFAULT_CELL_LINE)
    const instrumentOptions = [Instruments.all, ...allInstruments]
    const [projectCompound, setProjectCompound] = useState<ProjectCompound>(PROJECT_COMPOUND_DEFAULT_OPTION)
    const allCompounds: string[] = referenceContext.refCompounds.map((comp) => comp.value)
    const [experimenter, setExperimenter] = useState(DEFAULT_OPTION)
    const allExperimenters: string[] = referenceContext.shortUsers.map((v:CddValue) => v.value).sort()
    allExperimenters.unshift(DEFAULT_OPTION)



    const handleExperimentCodeChange = (event: SelectChangeEvent<{value: unknown}>) => {
        const value = event.target.value as string
        setExperimentCode(value)
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter),
            experimentCode: filterUtils.getNullableValue(value)
        })
    }

    const handleInstrumentFilterChange = (value: string) => {
        setInstrument(value);
        handleFilterChange({
            instrument: filterUtils.getNullableValue(value),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter),
            experimentCode: filterUtils.getNullableValue(experimentCode)
        })
    }

    const handleStart = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStart(event.currentTarget.value);
    }

    const handleEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnd(event.currentTarget.value);
    }
    const handleApplyDates = () => {
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter),
            experimentCode: filterUtils.getNullableValue(experimentCode)
        })
    }
    const cellLineChange = (value: any) => {
        setCellLine(value)
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(value),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(experimenter),
            experimentCode: filterUtils.getNullableValue(experimentCode)
        })
    }

    const handleProjectCompoundChange = (value: ProjectCompound) => {
        setProjectCompound(value);
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(value),
            experimenter: filterUtils.getNullableValue(experimenter),
            experimentCode: filterUtils.getNullableValue(experimentCode)
        })
    }
    const handleExperimenterChange = (value: string) => {
        setExperimenter(value)
        handleFilterChange({
            instrument: filterUtils.getNullableValue(instrument),
            cellLine: filterUtils.getNullableCellLine(cellLine),
            dateRange: filterUtils.getDateRange(start, end),
            projectCompound: filterUtils.getProjectCompound(projectCompound),
            experimenter: filterUtils.getNullableValue(value),
            experimentCode: filterUtils.getNullableValue(experimentCode)
        })
    }

    const doReset = () => {
        setExperimentCode(DEFAULT_OPTION)
        const resetStart = threeMonthsAgo();
        setStart(resetStart);
        present =  new Date().toISOString().slice(0,10)
        setEnd(present);
        setCellLine(DEFAULT_CELL_LINE)
        setInstrument(DEFAULT_OPTION);
        setProjectCompound(PROJECT_COMPOUND_DEFAULT_OPTION)
        setExperimenter(DEFAULT_OPTION)
        handleFilterChange({
            instrument: null,
            cellLine: null,
            dateRange: filterUtils.getDateRange(resetStart, present),
            projectCompound: null,
            experimenter: null,
            experimentCode: null
        })
    }
    const RenderExpTypeSelection = () => {

        return (
            <FormControl variant="filled" size="small" sx={{ minWidth: 200 }}>
                <StyledFilterLabel id="exp-type-menu-label">Display</StyledFilterLabel>
                <Select
                    id="exp-type-menu"
                    labelId="exp-type-menu-label"
                    // @ts-ignore
                    value={experimentCode}
                    onChange={handleExperimentCodeChange}
                    input={<StyledFilterBase />}
                >
                    <MenuItem value="All">All Experiments except yTKO</MenuItem>
                    <MenuItem value="AllQCInclusive">All Experiments</MenuItem>
                    {experimentTypes.map((t: ExperimentType) => (
                        // @ts-ignore
                        <MenuItem
                            disabled={!t.filterable}
                            key={'typekey_' + t.code}
                            value={t.value}
                            selected={t.code === experimentCode}
                        >
                            {t.label} Experiments
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    };

    const hideText = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

    return (
        <StyledFilterBar variant="denseOptical" role="toolbar" aria-label="Options for filtering experiments">
            <Typography component="h2" variant="subtitle1" fontWeight="medium" color="white">Filters</Typography>
            <RenderExpTypeSelection />
            <InstrumentFilter options={instrumentOptions} instrument={instrument}
                              onInstrumentChangeCallback={handleInstrumentFilterChange} />
            <DateRangeFilter min="2023-01-01" max={present} start={start} end={end} labelPrefix="Planned"
                             handleStart={handleStart} handleEnd={handleEnd} handleApplyDates={handleApplyDates} />
            {experimentCode !== 'QC' && <CellLineFilter selected={cellLine} options={cellLineOptions}
                                                         cellLineCallback={cellLineChange} />}
            {experimentCode !== 'QC' && <ProjectCompoundFilter value={projectCompound}
                                                                projects={referenceContext.projects}
                                                                cddCompounds={allCompounds}
                                                                onFilterChangeCallback={handleProjectCompoundChange}/>}
            <KnownOptionsFilter id="experimenter-filter" label="Responsible"
                                selectedOption={experimenter} knownOptions={allExperimenters}
                                onSelectionChangeCallback={handleExperimenterChange} />
            <Button type="reset" color="primary" variant="contained" onClick={doReset} disableElevation>
                {hideText? 'Reset' : 'Reset Filters'}
            </Button>
        </StyledFilterBar>
    )
}