import {alpha, darken} from '@mui/material';
import {blueGrey, green, lightGreen} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

// custom breakpoints require full theme token, eg: theme.breakpoints.values.xxl instead of 'xxl'
// custom breakpoints may not work as expected as props on components like Container and Grid
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        lg: true;
        xl: true;
        // above default MUI breakpoints (do not change)
        // below custom breakpoints, values defined in mtxTheme
        xxl: true;
        fhd: true;
        qhd: true;
        wqhd: true;
    }
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        bodyList: React.CSSProperties;
        plotTitle: React.CSSProperties;
        xSmall1: React.CSSProperties;
        xSmall2: React.CSSProperties;
        srOnly: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        bodyList?: React.CSSProperties;
        plotTitle?: React.CSSProperties;
        xSmall1?: React.CSSProperties;
        xSmall2?: React.CSSProperties;
        srOnly?: React.CSSProperties;
    }
}
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        bodyList: true;
        plotTitle: true;
        xSmall1: true;
        xSmall2: true;
        srOnly: true;
    }
}

declare module '@mui/material/styles/createPalette' {
    interface PaletteOptions {
        accent?: PaletteColorOptions;
        contrastDark?: PaletteColorOptions;
    }
    interface Palette {
        accent: PaletteColor;
        contrastDark: PaletteColor;
    }
}
declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        accent: true;
        contrastDark: true;
    }    
}

declare module '@mui/material/Toolbar' {
    interface ToolbarPropsVariantOverrides {
        denseOptical: true;
    }  
}

// defaultTheme gives access to theme tokens to use in our custom theme
const defaultTheme = createTheme();

// defining colors to use globally
const colors = {
    mtxUIBlue: {
         50: '#e7eaf9',
        100: '#c3caf0',
        200: '#9aa7e5',
        300: '#6f85dc',
        400: '#4c69d4', // primary.light
        500: '#1e4fcc',
        600: '#1647c1', // primary.main
        700: '#003cb5',
        800: '#0032aa',
        900: '#001e97' // primary.dark
    },
    mtxUISlate: {
         50: '#eceff8',
        100: '#d0d7e4',
        200: '#b4bdce',
        300: '#97a2b8',
        400: '#828ea6',
        500: '#6c7a95',
        600: '#5e6c84',
        700: '#4d586d',
        800: '#3d4657', // secondary.main
        900: '#2a313f',
        'A100': '#687184', // secondary.light
        'A200': '#3d4657',
        'A300': '#161f2e' // secondary.dark
    },
    mtxUIDeep: {
         50: '#e3e4ec',
        100: '#b8bcd1',
        200: '#8a91b2',
        300: '#5f6894',
        400: '#404b81',
        500: '#1e2f6e',
        600: '#192967',
        700: '#10215c',
        800: '#071850',
        900: '#01063b' // text.primary color
    },
    mtxYellow: {
         50: '#ffffe6',
        100: '#fdfbbf',
        200: '#fbf893',
        300: '#f9f464',
        400: '#f6f038',
        500: '#fff700', // mtx brand yellow
        600: '#ffe200',
        700: '#ffc900',
        800: '#ffb000',
        900: '#ff8200'
    },
    mtxDarkBlue: {
         50: '#e5e5f2',
        100: '#bebede',
        200: '#9394c8',
        300: '#6a6cb2',
        400: '#4c4da3',
        500: '#2e2f93',
        600: '#29288b',
        700: '#201f80',
        800: '#171574',
        900: '#040160', // mtx brand dark blue
    },
    centroid: {
        num263white: '#ffffff',
        num267black: '#131313',
        num82yellow: '#f3c300',
        num218purple: '#803e75',
        num48orange: '#ff6800',
        num180lightBlue: '#a1caf1',
        num11red: '#be0032',
        num90buff: '#c2b280',
        num265grey: '#848482',
        num139green: '#007d34',
        num247purplishPink: '#e68fac',
        num178blue: '#0067a5',
        num26yellowishPink: '#f99379',
        num207violet: '#53377a',
        num66orangeYellow: '#f6a600',
        num255purplishRed: '#b3446c',
        num97greenishYellow: '#b3446c',
        num40reddishBrown: '#7f180d',
        num115yellowGreen: '#8db600',
        num75yellowishBrown: '#654522',
        num34reddishOrange: '#e25822',
        num126oliveGreen: '#2b3d26'
    }
}

// UI color constants for styled() components

const uiColors = {
    filters: {
        baseColor: colors.mtxUIDeep[900], // input placeholder and value
        labelColor: colors.mtxUIDeep[900], // input label, fieldset legend
        labelColorFocused: colors.mtxUIBlue[600], // input label, fieldset legend
        inputIconColor: alpha(colors.mtxUIDeep[900], 0.8), // select/dropdown caret, clear icon, etc
        inputIconBgHover: defaultTheme.palette.action.hover,
        inputBaseBg: blueGrey[50], //defaultTheme.palette.grey[200],
        inputActionBorder: colors.mtxUIBlue[200],
        inputActionBorderHover: colors.mtxUIBlue[400],
        // actions keys follow MUI palette.action syntax
        action: {
            hover: darken(blueGrey[50], 0.05),
            focus: defaultTheme.palette.common.white,
        },
        toolbarBg: colors.mtxUIBlue[600],
        toolbarAccentBorder: colors.mtxUIBlue[900]
    }  
};

// TODO: data visualizations should have better colors and controlled here

const dataColors = {
    lcms: {
        plannedColor: colors.mtxUISlate[200],
        lcmsColor: colors.mtxUIBlue[400],
        processedColor: green[600],
        publishedColor: lightGreen[200]
    },
    categorical: {
        // use below on white background, colors ordered by maximal contrast relative to each other (color blind safe)
        contrastOrdered: [
            colors.centroid.num267black, colors.centroid.num82yellow, colors.centroid.num218purple, 
            colors.centroid.num48orange, colors.centroid.num180lightBlue, colors.centroid.num11red,
            colors.centroid.num90buff, colors.centroid.num265grey, colors.centroid.num139green,
            colors.centroid.num247purplishPink, colors.centroid.num178blue, colors.centroid.num26yellowishPink,
            colors.centroid.num207violet, colors.centroid.num66orangeYellow, colors.centroid.num255purplishRed,
            colors.centroid.num97greenishYellow, colors.centroid.num40reddishBrown, colors.centroid.num115yellowGreen,
            colors.centroid.num75yellowishBrown, colors.centroid.num34reddishOrange, colors.centroid.num126oliveGreen
        ],
        // same as above, except Centroid #87 Yellow comes first, and Centroid #11 Red is replaced with Centroid #267 Black
        contrastScatter: [
            colors.centroid.num82yellow, colors.centroid.num218purple, colors.centroid.num48orange, 
            colors.centroid.num180lightBlue, colors.centroid.num267black, colors.centroid.num90buff, 
            colors.centroid.num265grey, colors.centroid.num139green, colors.centroid.num247purplishPink, 
            colors.centroid.num178blue, colors.centroid.num26yellowishPink, colors.centroid.num207violet, 
            colors.centroid.num66orangeYellow, colors.centroid.num255purplishRed, colors.centroid.num97greenishYellow, 
            colors.centroid.num40reddishBrown, colors.centroid.num115yellowGreen, colors.centroid.num75yellowishBrown, 
            colors.centroid.num34reddishOrange, colors.centroid.num126oliveGreen
        ] 
    }
};

const mtxTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            // above default MUI breakpoints (do not change)
            // below custom breakpoints
            xxl: 1640, // a width between 13-16" MBP Retina screens
            fhd: 1920, // a common large desktop HD monitor width, example: Apple Cinema Display 24"
            qhd: 2560,
            wqhd: 3440 // wide QHD, example: Dell UltraSharp 34"
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: colors.mtxUIBlue[600],
            light: colors.mtxUIBlue[400],
            dark: colors.mtxUIBlue[900],
        },
        secondary: {
            main: colors.mtxUISlate[800],
            light: colors.mtxUISlate['A100'],
            dark: colors.mtxUISlate['A300'],
            contrastText: '#ffffff',
        },
        accent: defaultTheme.palette.augmentColor({
          color: { main: colors.mtxYellow[500]},
          name: 'accent'
        }),
        contrastDark: defaultTheme.palette.augmentColor({
          color: { main: defaultTheme.palette.common.white},
          name: 'contrastDark'
        }),
        background: {
            default: defaultTheme.palette.grey[50]
        },
        text: {
            primary: colors.mtxUIDeep[900],
            secondary: alpha(colors.mtxUIDeep[900], 0.7),
            disabled: alpha(colors.mtxUIDeep[900], 0.38),
        },
        divider: alpha(colors.mtxUIDeep[900], 0.12),
    },
    typography: {
        fontFamily: ['"Roboto"', '"Segoe UI"', '-apple-system', 'BlinkMacSystemFont', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(', '),
        fontSize: 12,
        htmlFontSize: defaultTheme.typography.htmlFontSize, // default = 16, do not change!
        bodyList: {
            fontWeight: defaultTheme.typography.body1.fontWeight,
            fontSize: defaultTheme.typography.pxToRem(15.2),
            lineHeight: defaultTheme.typography.body1.lineHeight,
            letterSpacing: defaultTheme.typography.body1.letterSpacing,
            display: 'block'
        },
        plotTitle: {
            fontWeight: 500,
            fontSize: defaultTheme.typography.pxToRem(19),
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
            display: 'block'
        },
        xSmall1: {
            fontSize: defaultTheme.typography.pxToRem(10.84),
            fontWeight: 400,
            lineHeight: 1.3,
        },
        xSmall2: {
            fontSize: defaultTheme.typography.pxToRem(9.52),
            fontWeight: 400,
            lineHeight: 1.3,
        },
        srOnly: {
            display: 'none',
            position: 'absolute',
            height: 1,
            width: 1,
            overflow: 'hidden'
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    ...(ownerState.color === 'primary' && {
                        backgroundColor: colors.mtxUIBlue[800],
                        boxShadow: theme.shadows[2],
                        '& .MuiDivider-root:not(.MuiDivider-light)': {
                            borderColor: darken(theme.palette.primary.dark, 0.1)
                        },
                        '& .MuiDivider-light': { borderColor: theme.palette.primary.light}
                    }),
                    ...(ownerState.color === 'inherit' && {
                        backgroundColor: colors.mtxUIBlue[900],
                        boxShadow: theme.shadows[2],
                        '& .MuiDivider-root:not(.MuiDivider-light)': {
                            borderColor: darken(theme.palette.primary.dark, 0.1)
                        },
                        '& .MuiDivider-light': { borderColor: theme.palette.primary.light}
                    }),
                    ...(ownerState.color === 'secondary' && {
                        backgroundColor: colors.mtxUISlate['A300'],
                        boxShadow: theme.shadows[2],
                        '& .MuiDivider-root:not(.MuiDivider-light)': {
                            borderColor: darken(theme.palette.secondary.dark, 0.1)
                        },
                        '& .MuiDivider-light': { borderColor: theme.palette.secondary.light}
                    }),
                    '& button.Mui-disabled': { color: alpha(theme.palette.common.white, 0.5)},
                    '& .MuiButtonBase-root.MuiButton-root': { textTransform: 'capitalize'},
                }),
                
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: { overflowY: 'hidden'}
            }
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-row': {
                        '&.Mui-selected': {
                            backgroundColor: alpha(colors.mtxUIBlue[50], 0.9)
                        }
                    }
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    '&:not(.underlineNone)': { textUnderlineOffset: '2px'}
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root:{ padding: 0}
            }  
        },
        MuiSnackbarContent: {
            styleOverrides: {
                root:{ padding: 0},
                action: { padding: 0}
            }  
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: 'outlined'},
                    style: {
                        '& input:read-only': { backgroundColor: defaultTheme.palette.grey[50]}
                    }
                }
            ]
        },
        MuiToolbar: {
            variants: [
                {
                    props: { variant: 'denseOptical' },
                    style: {
                        paddingTop: defaultTheme.spacing(1),
                        paddingBottom: defaultTheme.spacing(0.5),
                        [defaultTheme.breakpoints.down('xl')]: {
                            paddingRight: defaultTheme.spacing(1),
                            paddingLeft: defaultTheme.spacing(1)
                        }
                    },
                },
            ],
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    color: colors.mtxUIDeep[900],
                    fontSize: theme.typography.body2.fontSize,
                    backgroundColor: colors.mtxUISlate[50],
                    boxShadow: theme.shadows[1],
                }),
                arrow: { color: colors.mtxUISlate[50]}
            }
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    xSmall1: 'small',
                    xSmall2: 'small'
                }
            }
        }
    }
});

export { colors, mtxTheme, uiColors, dataColors };