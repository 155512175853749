import {ChangeEvent, FC, useContext, useState} from 'react'
import {Autocomplete, Grid, TextField} from '@mui/material';
import {ExperimentFormDataCy} from './ExperimentForm.cy';
import {ReferenceContext} from '../../../store/ReferenceContext';
interface CommonElementsProps {
    projectName: string;
    responsible: string;
    collaborator: string;
    handleSummaryChange: (key: string, value: any) => void;
    isQC: boolean;
    commonRequired: CommonRequired
}

const CommonElements: FC<CommonElementsProps> = ({
    projectName,
    responsible,
    collaborator,
    handleSummaryChange,
    isQC,
    commonRequired
}) => {
    const referenceContext: References = useContext<References>(ReferenceContext)
    const [projectInputValue, setProjectInputValue] = useState(projectName)
    const availableProjects = ['', ...referenceContext.projects]

    const doProjectValueChange = (e: ChangeEvent<any>, newValue: string | null) => {
        handleSummaryChange('projectName', newValue)
    }

    const handleProjectInputChange = (e: ChangeEvent<any>, newInputValue: string) => {
        setProjectInputValue(newInputValue)
    }

    return (
        <Grid container item xs={12} spacing={1}>
            <Grid item xs>
                {isQC ?
                    <TextField
                        id="project-name-select"
                        data-cy={ExperimentFormDataCy.projectName}
                        inputProps={{ readOnly: true, }}
                        label="Project Name"
                        value={'QC'}
                        variant="outlined" size="small" fullWidth
                        name="projectName" />
                    :
                <Autocomplete
                    id="project-name-select"
                    data-cy={ExperimentFormDataCy.projectName}
                    value={projectName}
                    onChange={doProjectValueChange}
                    inputValue={projectInputValue}
                    options={availableProjects}
                    renderInput={(params) =>
                        <TextField {...params} required={commonRequired.projectName}
                                   label="Project Name"
                                   placeholder="Select or enter a name"
                                   helperText={commonRequired.projectName ? 'Required' : ''}
                                   variant="outlined" size="small" fullWidth
                                   name="projectName" />
                    }
                    onInputChange={handleProjectInputChange}
                    forcePopupIcon openOnFocus selectOnFocus handleHomeEndKeys />
                }
            </Grid>
            <Grid item xs>
                <Autocomplete
                    id="project-responsible-select"
                    data-cy={ExperimentFormDataCy.responsiblePerson}
                    value={responsible? referenceContext.shortUsers.find(user => user.value === responsible): null}
                    options={referenceContext.shortUsers}
                    getOptionLabel={(option) => option.value? option.value: ""}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value?.value;
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   label="Responsible"
                                   placeholder="Select"
                                   helperText={commonRequired.responsible ? 'Required' : ''}
                                   variant="outlined" size="small" fullWidth
                                   required={commonRequired.responsible}
                                   name="responsible" />
                    }
                    onChange={(event, value) => {
                        let finalValue = typeof value === "string"? value: value?.value;
                        handleSummaryChange("responsible", finalValue);
                    }}
                    forcePopupIcon openOnFocus selectOnFocus handleHomeEndKeys />
            </Grid>
            <Grid item xs>
                <Autocomplete
                    id="project-collaborator-select"
                    data-cy={ExperimentFormDataCy.collaborator}
                    value={collaborator? referenceContext.shortUsers.find( user => user.value === collaborator): null}
                    options={referenceContext.shortUsers}
                    getOptionLabel={(option) => option.value? option.value: ""}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value?.value;
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   label="Collaborator"
                                   placeholder="Select"
                                   helperText={commonRequired.collaborator ? 'Required' : ''}
                                   variant="outlined" size="small" fullWidth
                                   required={commonRequired.collaborator}
                                   name="collaborator" />
                    }
                    onChange={(event, value) => {
                        let finalValue = typeof value === "string"? value: value?.value;
                        handleSummaryChange("collaborator", finalValue);
                    }}
                    forcePopupIcon openOnFocus selectOnFocus handleHomeEndKeys />
            </Grid>
        </Grid>
    )
}

export default CommonElements
