/* istanbul ignore file */
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';

// use sx prop on component to style border radius according to placement

const StyledColToggleButton = styled(ToggleButton)(({ theme }) => ({
    '--secondary-color': theme.palette.secondary.main,
    '--secondary-color-hover': theme.palette.secondary.contrastText,
    '--secondary-bgcolor': theme.palette.background.paper,
    '--secondary-bgcolor-hover': theme.palette.secondary.main,
    padding: theme.spacing(0.5),
    '&.MuiToggleButton-secondary': {
        color: 'var(--secondary-color)',
        backgroundColor: 'var(--secondary-bgcolor)',
        '&:hover, &:focus': {
            color: 'var(--secondary-color-hover)',
            backgroundColor: 'var(--secondary-bgcolor-hover)'
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--secondary-bgcolor)',
            '&:hover, &:focus': {
                color: 'var(--secondary-color-hover)',
                backgroundColor: 'var(--secondary-bgcolor-hover)'
            }
        }
    }
}));

export default StyledColToggleButton;