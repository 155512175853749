import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import { uiColors } from '../../theme/theme';

const StyledFilterLabel = styled(InputLabel)({
    color: uiColors.filters.labelColor,
    '&.Mui-focused': { color: uiColors.filters.labelColorFocused}
});

export default StyledFilterLabel;
