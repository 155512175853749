import React, {useEffect, useState} from "react";
import { Box, Tab, Tabs } from '@mui/material';
import {Outlet, Link, useLocation, useParams, useOutletContext,} from 'react-router-dom';
import {ExperimentsPanelDataCy} from "./ExperimentsPanel.cy";
import { APPBAR_HEIGHT, FILTERBAR_HEIGHT, MAINTABS_HEIGHT } from '../../../theme/Constants';
import FilterBar from '../../filters/FilterBar';
import {ExperimentFilters} from 'filters';
import {dateFormatMask, threeMonthsAgo} from '../../../util/util';
import dateFormat from 'dateformat';

const DEFAULT_FILTERS: ExperimentFilters = {
    instrument: null,
    cellLine: null,
    dateRange: {start: threeMonthsAgo(), end: dateFormat(new Date(), dateFormatMask)},
    projectCompound: null,
    experimenter: null
}

type ContextType = {filters: ExperimentFilters}
export default function ExperimentsPanel() {

    let location = useLocation();
    
    // TODO: need to refine the way the tabs switch and assign 'active' class state
    let currentTabVal: number = location.pathname.endsWith('details') ? 1 : 0
    const [tabValue, setTabValue] = useState<number>(currentTabVal);
    const [filters, setFilters] = useState<ExperimentFilters>(DEFAULT_FILTERS);
    let {experimentCode} = useParams()

    const handleTabChange = (event: React.ChangeEvent<any>, newValue: any): void => {
        setTabValue(newValue);
    };

    useEffect(() => {
        let currentTabVal: number = location.pathname.endsWith('details') ? 1 : 0
        if (tabValue !== currentTabVal) {
            setTabValue(currentTabVal);
        }
    }, [location, tabValue]);

    return <>
        <FilterBar handleFilterChange={setFilters}/>

        <Tabs value={tabValue} onChange={handleTabChange} 
            sx={theme => ({
                width: '100%',
                height: MAINTABS_HEIGHT,
                backgroundColor: theme.palette.background.paper,
                top: `calc(${APPBAR_HEIGHT} + ${FILTERBAR_HEIGHT})`,
                position: 'fixed',
                zIndex: theme.zIndex.appBar,
                marginLeft: theme.spacing(-1),
                paddingLeft: theme.spacing(3),
                borderBottom: `1px solid ${theme.palette.divider}`
            })}  
            aria-label="Toggle between Dashboard and Details"
        >
            <Tab data-cy={ExperimentsPanelDataCy.dashboard} label="Dashboard" component={Link} to={'/experiment/' + experimentCode + '/dashboard'}/>
            <Tab data-cy={ExperimentsPanelDataCy.details} label="Details" component={Link} to={'/experiment/' + experimentCode + '/details'}/>
        </Tabs>

        <Box role="tabpanel" mt={MAINTABS_HEIGHT} pt={2} px={1} pb={3}>
            <Outlet context={{filters}}/>
        </Box>
    </>
}

export function useFilters() {
    return useOutletContext<ContextType>();
}