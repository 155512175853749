import { styled } from '@mui/material/styles';
import FilledInput from '@mui/material/FilledInput';
import { uiColors } from '../../theme/theme';

const StyledFilterBase = styled(FilledInput)({
    color: uiColors.filters.baseColor,
    backgroundColor: uiColors.filters.inputBaseBg,
    '&.Mui-focused': {
        color: uiColors.filters.baseColor,
        backgroundColor: uiColors.filters.action.focus,
        '& .MuiSelect-icon': { color: uiColors.filters.baseColor}
    },
    '&:hover': {
        '&:not(.Mui-focused)': { backgroundColor: uiColors.filters.action.hover},
        '&:not(.Mui-disabled):before': { borderBottomColor: uiColors.filters.inputActionBorderHover},
        '& .MuiSelect-icon': { color: uiColors.filters.baseColor}
    },
    '&:before': { borderBottomColor: uiColors.filters.inputActionBorder},
    '&:after': { borderBottomColor: uiColors.filters.inputActionBorder}, 
    '& .MuiSelect-icon': { color: uiColors.filters.inputIconColor},
    '& > input[type="date"]': {
        cursor: 'text',
        '&::-webkit-calendar-picker-indicator': { cursor: 'pointer'},
    }
});

export default StyledFilterBase;