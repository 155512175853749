import * as d3 from 'd3';
import {IOverlapProps, ITypedBin} from 'histogram';
import * as _ from 'lodash';

class OverlappingHistogramsComponent {

    containerDiv: HTMLDivElement;
    props: IOverlapProps;
    svg: any;
    margin: any;
    mDiv: any;

    constructor(containerDiv: HTMLDivElement, props: IOverlapProps) {
        this.containerDiv = containerDiv;
        this.props = props;
        this.margin = {
            top: 10, right: 30, bottom: 40, left: 40
        };
        this.mDiv = d3.select(containerDiv)
            .append('div')
            .attr('id', 'tooltip-' + props.id)
            .style('position', 'absolute')
            .style('padding', '5px 6px')
            .style('background-color', 'white')
            .style('color', 'black')
            .style('border-radius', '2px')
            .style('pointer-events', 'none')
            .style('box-shadow', 'rgba(0,0,0,0.25) 0px 1px 2px');

        this.svg = d3.select(containerDiv)
            .append('svg')
            .attr('id', props.id)
            .attr('width', props.width)
            .attr('height', props.height)
            .append("g")
            .attr('class', 'whatever')
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");
        this.draw.bind(this);
        this.resize.bind(this);
        this.showToolTip.bind(this);
        this.draw(true);
    }

    public resize(width: number, height: number) {
        this.props.width = width;
        this.props.height = height;
        d3.select('#' + this.props.id).attr('width', width)
            .attr('height', height);

        this.draw(false);
    }

    public updateData(data: IOverlapProps): void {
        this.props = data;
    }

    public draw(makeAxis: boolean): void {
      try {
          const {svg, props} = this;
          const width = props.width - this.margin.left - this.margin.right;
          const height = props.height - this.margin.top - this.margin.bottom;

          // x axis
          const x = d3.scaleLinear()
              .domain([props.data.min, props.data.max])
              .range([0, width]);

          // y axis
          let yMax: number | undefined = d3.max(props.data.bins, (d: ITypedBin) => d.length);
          yMax = yMax ? yMax : 0;
          const y = d3.scaleLinear()
              .range([height, 0]);

          y.domain([0, yMax]);
          if (makeAxis) {
              svg.append("g")
                  .attr("class", 'x axis ' + props.id)
                  .attr("transform", "translate(0," + height + ")")
                  .call(d3.axisBottom(x));
              svg.append("text")
                  .attr("transform", "translate(" + (width / 2) + " ,"
                      + (height + this.margin.top + 20)
                      + ")")
                  .style("text-anchor", "middle")
                  .attr('class', 'x label ' + props.id)
                  .text("Log10 Intensities");
              svg.append("g")
                  .attr('class', 'y axis ' + props.id)
                  .call(d3.axisLeft(y));
          } else {
              svg.select('.x.axis.' + props.id)
                  .transition('750')
                  .attr("transform", "translate(0," + height + ")")
                  .call(d3.axisBottom(x));
              svg.select('.x.label.' + props.id)
                  .transition('750')
                  .attr("transform", "translate(" + (width / 2) + " ,"
                      + (height + this.margin.top + 20)
                      + ")");
              svg.select('.y.axis.' + props.id)
                  .transition('750')
                  .call(d3.axisLeft(y));
          }
          const bars =
              svg.selectAll('rect.' + props.id)
                  .data(props.data.bins);
          bars.join(
              (enter: any) =>
                  enter
                      .append('rect')
                      .attr('x', 1)
                      .attr('class', props.id)
                      .attr('transform', function (d: ITypedBin) {
                          return "translate(" + x(d.x0) + "," + y(d.length) + ")";
                      })
                      .attr('width', function (d: ITypedBin) {
                          return x(d.x1) - x(d.x0);
                      })
                      // @ts-ignore
                      .attr('height', function (d: ITypedBin) {
                          return height - y(d.length);
                      })
                      .style('fill', (d: ITypedBin) => props.typeColors[d.type])
                      .style('opacity', 1)
                      .call((enter:any) => enter)
                      .on('mouseover', (event: any, d: ITypedBin) => {
                          this.showToolTip(event, d);
                      })
                      .on('mouseout', (event: any, d: ITypedBin) => {
                          d3.select('#tooltip-' + props.id)
                              .transition()
                              .duration(50)
                              .style('opacity', '0');
                      }),
              (update: any) =>
                  update.call((update: any) => update
                      .transition()
                      .duration(750)
                      .attr('x', 1)
                      .attr('class', props.id)
                      .attr('transform', function (d: ITypedBin) {
                          return "translate(" + x(d.x0) + "," + y(d.length) + ")";
                      }))
                      // @ts-ignore
                      .attr('width', function (d: ITypedBin) {
                          return x(d.x1) - x(d.x0);
                      })
                      // @ts-ignore
                      .attr('height', function (d: ITypedBin) {
                          return height - y(d.length);
                      })
                      .style('fill', (d: ITypedBin) => props.typeColors[d.type])
                      .style('opacity', 1),
          );
          const size = 15;
          const pairs = _.toPairs(props.typeColors);
          const keyRects = svg.selectAll('rect.key.' + props.id).data(pairs);
          keyRects.join(
              (enter: any) =>
                  enter.append('rect')
                      .attr('class', 'key ' + props.id)
                      .attr("x", width - 50 - size * 1.3)
                      .attr('y', function (d: any, i: number) {
                          return 10 + i * (size + 5)
                      })
                      .attr('width', size)
                      .attr('height', size)
                      .style('fill', function (d: any) {
                          return d[1];
                      }),
              (update: any) =>
                  update.call((update:any) => update
                      .transition()
                      .duration(500)
                      .attr("x", width - 50 - size * 1.3)
                      .attr('y', function (d: any, i: number) {
                          return 10 + i * (size + 5)
                      })
                      .attr('width', size)
                      .attr('height', size)),
          );
          const keyTexts = svg.selectAll('text.key.' + props.id).data(pairs);
          keyTexts.join(
              (enter: any) =>
                  enter.append("text")
                      .attr('class', 'key ' + props.id)
                      .attr('x', width - 50)
                      .attr('y', function (d: any, i: number) {
                          return 10 + i * (size + 5) + (size / 2);
                      })
                      .style('fill', function (d: any) {
                          return d[1];
                      })
                      .text(function (d: any) {
                          return d[0];
                      })
                      .attr('text-anchor', 'left')
                      .style('alignment-baseline', 'middle'),
              (update: any) =>
                  update.call((update:any) => update
                      .transition()
                      .duration(500)
                      .attr('x', width - 50)
                      .attr('y', function (d: any, i: number) {
                          return 10 + i * (size + 5) + (size / 2);
                      }))
          );
          if (makeAxis) {
              svg.append('line')
                  .attr('x1', x(props.data.median))
                  .attr('y1', 0)
                  .attr('x2', x(props.data.median))
                  .attr('y2', height)
                  .attr('stroke', 'rgba(0, 255, 0, 0.6)')
                  .attr('stroke-width', 3)
                  .attr('class', 'median')
              svg.append('text')
                  .attr('class', 'medianDescription')
                  // @ts-ignore
                  .attr('x', x(props.data.median) + 10)
                  .attr('y', 0)
                  .text(`PSM median: ${props.data.median.toFixed(2)}`)
                  .attr('text-anchor', 'left')
                  .style('alignment-baseline', 'middle')
          } else {
              svg.select('line.median')
                  .transition('750')
                  .attr('x1', x(props.data.median))
                  .attr('y1', 0)
                  .attr('x2', x(props.data.median))
                  .attr('y2', height);
              svg.select('line.median').raise();
              svg.select('text.medianDescription')
                  .transition('750')
                  // @ts-ignore
                  .attr('x', x(props.data.median) + 10)
                  .attr('y', 0)
                  .text(`PSM median: ${props.data.median.toFixed(2)}`);
          }
      } catch (error) {
          console.error('Problem in draw', error)
      }
    }

    private showToolTip(event: any, d: ITypedBin) {
        // const mouse = d3.mouse(d3.event.currentTarget);
        // console.log(`mouse ${mouse}`);
        // console.log(`T: ${event.currentTarget}, d.length ${d.length}`);
        d3.select('#tooltip-' + this.props.id)
            .transition()
            .duration(50)
            .text(`${d.type} count: ${d.length}`)
            .style('opacity', '1')
            // @ts-ignore
            .style('left', (event.pageX + 10) + 'px')
            // @ts-ignore
            .style('top', (event.pageY - 15) + 'px');
    }

}

export default OverlappingHistogramsComponent;