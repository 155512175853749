/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const completedPublishStep = /* GraphQL */ `
  subscription CompletedPublishStep {
    completedPublishStep {
      status
      experimentName
      message
    }
  }
`;
export const completedAnalysisStep = /* GraphQL */ `
  subscription CompletedAnalysisStep {
    completedAnalysisStep {
      status
      experimentName
      message
    }
  }
`;
export const pcaAnalysisForLabelCheck = /* GraphQL */ `
  subscription PcaAnalysisForLabelCheck {
    pcaAnalysisForLabelCheck {
      experimentName
      plot {
        id
        data {
          x
          y
        }
      }
      message
    }
  }
`;
export const completedDownloadConversion = /* GraphQL */ `
  subscription CompletedDownloadConversion {
    completedDownloadConversion {
      experiment
      fileType
      method
      url
      key
    }
  }
`;
export const completeFlashSampleList = /* GraphQL */ `
  subscription CompleteFlashSampleList {
    completeFlashSampleList {
      status
      experimentName
      message
    }
  }
`;
