import {HistogramData, IHistogramConfig, INumberBar} from "histogram";

export function processHistogram(data: HistogramData,
                                 config: IHistogramConfig ): INumberBar[] {
    const bars: INumberBar[] = [];
    data.binCounts.forEach((bin: number, index: number) => {
        const xStart = data.min + (data.binWidth * index);
        if (xStart >= config.min && xStart <= config.max) {
            bars.push({x: Number((xStart).toFixed(3)).toString(), count: bin});
        }
    });
    if (config.min < data.min) {
        const max = data.min < config.max ? data.min : config.max;
        const below: INumberBar[] = [];
        for (let i = config.min; i < max; i += data.binWidth) {
            below.push({x: Number((i).toFixed(3)).toString(), count: 0});
        }
        bars.unshift(...below);
    }
    if (config.max > data.max) {
        const start = bars.length > 0 ? parseFloat(bars[bars.length - 1].x) + data.binWidth : config.min;
        for (let i = start; i < config.max; i += data.binWidth) {
            bars.push({x: Number((i).toFixed(3)).toString(), count: 0});
        }
    }
    return bars
}

export function computeHistogramMedian(data: HistogramData): number {
    let halftotal = data.binCounts.reduce((sum: number, count: number) => sum += count) / 2;
    let medianIndex = 0;
    data.binCounts.forEach((current: number, index: number) => {
        halftotal -= current;
        if (halftotal <= 0 && medianIndex === 0) {
            medianIndex = index;
        }
    });
    return data.min + (data.binWidth * medianIndex);
}
