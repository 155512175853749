import {API, graphqlOperation} from 'aws-amplify';
import {getNextExperimentNumber, uploadPlannedExperiment} from '../graphql/mutations';
import {getExperimentDownloadUrl, getFlashExperimentUploadUrl, getMostRecentNonQCDesigns} from '../graphql/queries';
import axios from 'axios'
import {ExperimentDownload} from '../API';
import {paddedNumber} from '../util/util';


const designs = {
    fetchNonQcExperiments: async (): Promise<any[]> => {
        try {
            const result: any = await API.graphql(graphqlOperation(getMostRecentNonQCDesigns,
                {limit: 1000}));
            console.log(`I found ${result.data.getMostRecentNonQCDesigns.items.length} designs`)
            return result.data.getMostRecentNonQCDesigns.items;
        } catch (error) {
            console.log("Error in getMostRecentNonQCDesigns:", error);
            return [];
        }
    },
    createName: async (type: ExperimentType): Promise<string> => {
        const currentYear = new Date().getFullYear();
        const nextResult: any = await API.graphql(graphqlOperation(getNextExperimentNumber, {year: currentYear}))
        const nextNumber = nextResult.data.getNextExperimentNumber.experiment_counter
        return `PX_${type.code}_${paddedNumber(nextNumber, 5)}_${currentYear}`;
    },
    uploadPlanned: async (designData: any[]): Promise<any> => {
        try {
            return await API.graphql(
                graphqlOperation(uploadPlannedExperiment, {input: designData})
            );
        } catch (err) {
            console.error(err);
        }
    },
    editableByUser: (experimentDesign: any, currentUser: string | undefined) => {
        return true
        // const responsibleUser = experimentDesign?.responsible
        // const collaboratingUser = experimentDesign?.collaborator
        // // Double equals used to catch both null and undefined outcomes
        // if (responsibleUser == null && collaboratingUser == null) {
        //     return true
        // }
        // const currentLastName = currentUser?.substring(1).toLowerCase();
        // return responsibleUser?.toLowerCase().includes(currentLastName as string) ||
        //     collaboratingUser?.toLowerCase().includes(currentLastName as string);
    },
    getFlashUploadUrl: async (key: string) => {
        const result: any = await API.graphql(graphqlOperation(getFlashExperimentUploadUrl, {key: key}))
        return result.data.getFlashExperimentUploadUrl.url
    },
    doFlashUpload: async (url: string, file: File) => {
        await axios.put(url, file)
    },
    doFlashDownload: async (key: string, prefix: string) => {
        const result: any = await API.graphql(graphqlOperation(
            getExperimentDownloadUrl, { keys: [key], experimentDownload: ExperimentDownload.FLASH}))
        const url = result.data.getExperimentDownloadUrl[0]
        // We only want the last part of the key
        const name = prefix + url.key.split('/').pop();
        console.log(`MY URL with name ${name}`, url)
        const a = document.createElement('a')
        a.href = url.url;
        a.download = name;
        a.click()
    }
}

export default designs