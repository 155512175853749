import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';

const StyledChartTipTable = styled(Table)(({ theme }) => ({
    borderCollapse: 'separate',
    borderSpacing: '1px 1.5ex', // 1px horizontal between cells, 1 x-height vertically between cells
    '& caption': {
        captionSide: 'top',
        color: theme.palette.text.primary,
        fontSize: theme.typography.subtitle1.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: theme.typography.subtitle1.lineHeight,
        letterSpacing: theme.typography.subtitle1.letterSpacing,
        wordWrap:'break-word',
        padding: 0
    },
    '& tr > *': { borderBottom: 'none', verticalAlign: 'baseline'},
    '& .fwMedium': { fontWeight: theme.typography.fontWeightMedium}
}));

export default StyledChartTipTable;