import {GridRenderEditCellParams} from '@mui/x-data-grid';
import React, {useState} from 'react';
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControl,
    FormControlLabel,
    FormLabel, Radio,
    RadioGroup,
    NativeSelect
} from '@mui/material';
import {Typography} from '@mui/material/';
import {editRow} from './editHelper';


export default function PositionEdit(props: GridRenderEditCellParams) {
    const updateAble = (row: any) => {
        return 'Unknown' === row['Sample Type']
    }
    const [open, setOpen] = useState<boolean>(true)
    const [position, setPosition] = useState<string>((props.value as string).charAt(0))
    const [mode, setMode] = useState<string>('currentOnly')
    const editable = updateAble(props.row)



    const handleClose = async () => {
        await props.api.stopCellEditMode({id: props.id, field: props.field})
        setOpen(false)
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        await props.api.setEditCellValue({id: props.id, field: props.field, value: position + props.value.slice(1)})
        await handleClose()
        if (mode === 'typed') {
            const rowsIds = props.api.getAllRowIds()
            for (const id of rowsIds) {
                let row = props.api.getRow(id)
                if (updateAble(row)) {
                    await editRow(props.api, id, props.field, position + row.Position.slice(1))
                }
            }
        }
    }

    return (
        <Dialog open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth="sm"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit
                }}
        >
            <DialogTitle>Update {props.field}</DialogTitle>
            <DialogContent>
                {editable ?
                    <>
                        <NativeSelect value={position}
                                      onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                                          setPosition(event.currentTarget.value)
                                      }}>
                            <option value='B'>Blue</option>
                            <option value='G'>Green</option>
                            <option value='Y'>Yellow</option>
                        </NativeSelect>
                        <Typography variant='body1' sx={{mt:3}}>
                            Current Row: <strong>{position}{props.value.slice(1)}</strong>
                        </Typography>
                        <FormControl sx={{mt:3}}>
                            <FormLabel id="rowEditMode">Row Edit Mode</FormLabel>
                            <RadioGroup
                                aria-labelledby="rowEditMode"
                                value={mode}
                                onChange={(event:React.ChangeEvent<HTMLInputElement>) => setMode(event.target.value)}
                                name="fieldEditMode">
                                <FormControlLabel value="currentOnly" control={<Radio />} label="Current Row Only" />
                                <FormControlLabel value="typed" control={<Radio />}
                                                  label={<span>All rows with Sample Type <strong>Unknown</strong></span>}
                                    />
                            </RadioGroup>
                        </FormControl>
                    </>
                  : <Alert severity="error">
                        {props.field} can only be edited when the sample type is <strong>Unknown</strong>
                    </Alert>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {editable && <Button type="submit">Update</Button>}
            </DialogActions>
        </Dialog>
    )
}