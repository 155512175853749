import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FormControl from "@mui/material/FormControl";
import {FC, useRef} from 'react';

interface FileFieldProps {
    id: string
    label: string
    validate: boolean
    file: File | undefined
    setFile(file: File): void
    acceptedFiles: string[]
    onChange(file: File): void
    disabled?: boolean
    dataCy?: string
}

const FileField: FC<FileFieldProps> = ({id, label, validate, file, setFile, acceptedFiles, onChange, disabled=false, dataCy}) => {
    const accepted = acceptedFiles as unknown as string
    const fileInputRef = useRef<HTMLInputElement>(null);

    return <FormControl
        fullWidth
        margin="dense"
        error={validate && !file}
        onClick={() => fileInputRef.current?.click()}
    >
        <InputLabel htmlFor={id} sx={{ marginLeft: '-14px' }}>{label}</InputLabel>
        <Input
            id={id}
            data-cy={dataCy}
            type={'text'}
            value={file ? file.name : ''}
            endAdornment={
                <InputAdornment position="end">
                    <label htmlFor="design-file">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                fileInputRef.current?.click();
                            }}
                        >
                            <AttachFileIcon/>
                        </IconButton>
                    </label>
                </InputAdornment>
            }
            readOnly
        />
        <input
            data-cy="upload-file-field"
            accept={accepted}
            type="file"
            style={{ display: 'none' }}
            onChange={evt => {
                if (evt) {
                    const files = evt.target?.files as unknown as File[]

                    if (files.length) {
                        const file = files[0];
                        setFile(file);
                        if(onChange)
                            onChange(file);
                    }
                }
            }}
            disabled={disabled}
            ref={fileInputRef}
        />
    </FormControl>
}

export default FileField