/**
 * For use with "Details" plots.
 * These are nested in 2 divs with padding of 8 and 16 respectively, so
 * to properly size our plot inside those divs we need to trim double those padding
 * values off our width plus just a little extra.
 *
 * And the switch between 1 and 2 column layout appears to happen right around
 * 960 pixels, with the second column of our 2 column layout occupying 2/3s of the width.
 */
export const calculateWidth = (split: boolean, wideView: boolean): number => {
    const factor = wideView ? 0.89 : 0.67
    if (split) {
        // console.log(window.innerWidth)
        const parent = window.innerWidth > 960 ? window.innerWidth * factor : window.innerWidth;
        // console.info(`parent is ${parent}; wideView: ${wideView}`);
        return  (parent - 58) / 2
    }
    const parent = window.innerWidth > 960 ? window.innerWidth * factor : window.innerWidth;
    return parent - 58;
};

export const calculateHeight = (split: boolean, wideView: boolean) => {
    if (split) {
        return wideView ? window.innerWidth / 3 : window.innerWidth / 4
    }
    return window.innerWidth / 3
}