import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledCenteringBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 500,
    backgroundColor: theme.palette.grey[50],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}));

export default StyledCenteringBox;