import {useEffect, useState} from "react";
import {ResponsiveBar} from "@nivo/bar";
import {ResponsiveLine} from "@nivo/line";
import { Box, Grid, TableCell, TableRow, TableBody, Typography, useTheme, useMediaQuery } from '@mui/material';
import {
    StyledPaper, StyledChartTipTable, StyledChartTipCircleKey, StyledEndBox, StyledStartBox
} from '../StyledComponents';
import { STYLED_PAPER_BASE_HEIGHT, STYLED_PAPER_LG_HEIGHT} from '../../../../theme/Constants';
import {
    DataGrid,
    getGridStringOperators,
    GridColDef,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarFilterButton,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import {useParams} from 'react-router-dom';
import dateFormat from 'dateformat';
import {useFilters} from '../ExperimentsPanel';
import experimentsService from '../../../../services/experimentsService';
import {codeFromName} from '../../../../util/options';
import {useSnackbar} from 'notistack';


const usefulFilters = getGridStringOperators().filter(({ value }) =>
    ['equals', 'contains'].includes(value),
);

const COLUMNS: GridColDef[] = [
    // the first element of the array will be set dynamically
    // {field: 'experiment', headerName: 'Experiment', flex: 1, minWidth: 200, filterOperators: usefulFilters},
    // {field: 'injectionNumber', headerName: 'Injection Number', flex: 1, minWidth: 200, filterOperators: usefulFilters},
    {field: 'date', headerName: 'Date', flex: .33, minWidth: 75, disableColumnMenu: true, type: 'date',
        valueFormatter: (params: GridValueFormatterParams) => `${dateFormat(params.value as string, "dd/mm/yy")}`},
    {field: 'lowSignal', headerName: 'Low Signal', flex: .33, minWidth: 70, align: 'right', filterOperators: usefulFilters},
    {field: 'highInterference', headerName: 'High Interference', flex: .33, minWidth: 65, align: 'right', filterOperators: usefulFilters},
    {field: 'psmCount', headerName: 'PSM Count', flex: .33, minWidth: 70, align: 'right', filterOperators: usefulFilters},
    {field: 'psmsRemoved', headerName: 'PSMs Removed', flex: .33, minWidth: 60, align: 'right', filterOperators: usefulFilters},
    {field: 'proteinCount', headerName: 'Protein Count', flex: .33, minWidth: 60, align: 'right', filterOperators: usefulFilters},
    {field: 'proteinsRemoved', headerName: 'Proteins Removed', flex: .33, minWidth: 70, align: 'right', filterOperators: usefulFilters},
    {field: 'instrument', headerName: 'Instrument', flex: .33, minWidth: 90, filterOperators: usefulFilters},
    {field: 'psmFile', headerName: 'PSM File', flex: .33, minWidth: 510, filterOperators: usefulFilters},
];

export default function ExperimentsOverviewPanel() {

    const { filters } = useFilters()
    const [experiments, setExperiments] = useState([])
    const [counts, setCounts] = useState<any[]>([]);
    const [problems, setProblems] = useState<any[]>([]);
    const {experimentCode} = useParams<{experimentCode: string}>();
    const [start, setStart] = useState<string>('')
    const [end, setEnd] = useState<string>('')
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if(experiments.length) {
            const problems = [
                {
                    'id': 'low signal psms',
                    "data": experiments.map((experiment: any) => ({
                        "x": experiment.experiment,
                        "y": (experiment.low_signal_to_noise / experiment.starting_psm_count)
                    }))
                },
                {
                    'id': 'high interference',
                    "data": experiments.map((experiment: any) => ({
                        "x": experiment.experiment,
                        "y": (experiment.high_isolation_interfence / experiment.starting_psm_count),
                    }))
                },
                {
                    'id': 'total',
                    "data": experiments.map((experiment: any) => ({
                        "x": experiment.experiment,
                        "y": (experiment.total_number_of_rows_removed / experiment.starting_psm_count),
                    }))
                }
            ];

            setCounts(experiments.map((experiment: any) => ({
                "experiment": experiment.experiment,
                "date": dateFormat(experiment.date, "dd/mm/yy"),
                "remaining psms": experiment.starting_psm_count - experiment.total_number_of_rows_removed,
                "removed psms": experiment.total_number_of_rows_removed,
                "remaining proteins": experiment.num_proteins_remaining,
                "removed proteins": experiment.starting_protein_count - experiment.num_proteins_remaining,
            })));
            setProblems(problems);
        } else {
            setCounts([]);
            setProblems([]);
        }
    },[experiments])

    useEffect(() => {
        const calculateStartAndEnd = () => {
            if (filters.dateRange) {
                const startParts = filters.dateRange.start.split('-')
                setStart(`${startParts[1]}/${startParts[0]}`)
                const endParts = filters.dateRange.end.split('-')
                setEnd(`${endParts[1]}/${endParts[0]}`)
            }
        }
        const fetchExperiments = async (code: string | undefined) => {
            if (code) {
                const experiments = await experimentsService.fetchExperimentStats(code, filters)
                setExperiments(experiments)
            }
        }

        fetchExperiments(experimentCode);
        calculateStartAndEnd();
        const completedAnalysisStep: any = experimentsService.fetchAnalysisCompleteSubscription()

        const subscription = completedAnalysisStep.subscribe({
            next: function (result: any) {
                console.log(`OVERVIEW ${JSON.stringify(result.value)}`);
                const experiment = result.value.data.completedAnalysisStep.experimentName
                const code = codeFromName(experiment)
                if (result.value.data.completedAnalysisStep.status === 'OK') {
                    if (code === experimentCode) {
                        fetchExperiments(experimentCode);
                    }
                }
                if (code === 'FDG') {
                    const exp = result.value.data.completedAnalysisStep.experimentName
                    const msg = result.value.data.completedAnalysisStep.status === 'OK'
                        ? `${exp} processing completed`
                        : `${exp} processing failed: ${result.value.data.completedAnalysisStep.message}`
                    const variant = result.value.data.completedAnalysisStep.status === 'OK' ? "success"
                        : "error"
                    enqueueSnackbar(msg, {variant, persist: true})
                }
            }
        });

        return function cleanup():void {
            subscription.unsubscribe();
        }

    }, [experimentCode, filters, enqueueSnackbar]);

    let margin = {top: 30, right: 20, bottom: 100, left: 60};

    const colorsScheme: any = {scheme: 'category10'};

    const getLeadNameHeader = () => {
        return experimentCode === "YTK" ?
            {field: 'experiment', headerName: 'Injection Number', flex: 1, minWidth: 150, filterOperators: usefulFilters} :
            {field: 'experiment', headerName: 'Experiment', flex: 1, minWidth: 150, filterOperators: usefulFilters}
    }
    const customToolBar = () => {
        return  <GridToolbarContainer>
                    <GridToolbarFilterButton />
                    <GridToolbarColumnsButton />
                </GridToolbarContainer>
    }

    // prepare data for data grid
    function convertToRowData(experiments: any[]) {
        return experiments.map((experiment, index) => {
            return {
                id: index,
                experiment: experimentCode === "YTK" ? experiment.injectionNumber : experiment.experiment,
                date: experiment.date,
                lowSignal: ((experiment.low_signal_to_noise / experiment.starting_psm_count) * 100).toFixed(1) + '%',
                highInterference: ((experiment.high_isolation_interfence / experiment.starting_psm_count) * 100).toFixed(1)+ '%',
                psmCount: experiment.starting_psm_count - experiment.total_number_of_rows_removed,
                psmsRemoved: ((experiment.total_number_of_rows_removed / experiment.starting_psm_count) * 100).toFixed(1)+ '%',
                proteinCount: experiment.num_proteins_remaining,
                proteinsRemoved: (((experiment.starting_protein_count - experiment.num_proteins_remaining) / experiment.starting_protein_count) * 100).toFixed(1)+ '%',
                instrument: experiment.instrument,
                psmFile: experiment.pd_psm_file
            }
        });
    }

    const rowData = convertToRowData(experiments);
    
    const theme = useTheme();
    const xlUp = useMediaQuery(theme.breakpoints.up(1920));
    const xlBn = useMediaQuery(theme.breakpoints.between(2000, 2400));
    const xxlUp = useMediaQuery(theme.breakpoints.up(2400));

    function renderToolTip(bar: any) {
        const isFirstHalf = bar.index < (experiments.length/2)
        return (
            <Box width={200} px={1} py={0.5} bgcolor="common.white" boxShadow={2}
                sx={{
                    position: 'absolute',
                    transform: isFirstHalf ? "translate(0,0" : "translate(-200px,0)"
            }}>
                <Typography variant="subtitle1" component="p" fontWeight="medium">
                    {bar.data.experiment}
                </Typography>
                <Typography variant="subtitle2" component="p" color="textSecondary">
                    {bar.data.date}
                </Typography>
                <Box py={1} display="flex" alignItems="center" justifyContent="flex-start">
                    <StyledChartTipCircleKey sx={{color: bar.color}} aria-label={bar.id + ' icon'} />
                    <Typography variant="body2" sx={{ mx: 0.5 }}>{bar.id}:{' '}</Typography>
                    <Typography variant="body2" fontWeight="medium">{bar.value}</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <StyledPaper elevation={2}>
                    <Typography component="h3" variant="plotTitle" align="center" gutterBottom>
                        PSMs Removed
                    </Typography>
                    <ResponsiveLine
                        margin={margin}
                        data={problems}
                        yFormat=".1%"
                        yScale={{type: 'linear', min: 0, max: 1, stacked: false, reverse: false}}
                        colors={colorsScheme}
                        useMesh={true}
                        enableSlices='x'
                        pointLabelYOffset={-12}
                        sliceTooltip={
                            (point: any) => {
                                return (
                                <Box width={180} px={1} py={0.5} bgcolor="common.white" boxShadow={2}>
                                    <StyledChartTipTable padding="none" aria-label="PSM tooltip table">
                                        <caption>{point.slice.points[0].data.x}</caption>
                                        <TableBody>
                                            {point.slice.points.map((e: any, i: any) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    <StyledChartTipCircleKey
                                                        sx={{color: e.color}}
                                                        aria-label={e.serieId + ' icon'}
                                                    />
                                                </TableCell>
                                                <TableCell>{e.serieId}:</TableCell>
                                                <TableCell className="fwMedium">{e.data.yFormatted}</TableCell>
                                            </TableRow>
                                            ))}
                                        </TableBody>
                                    </StyledChartTipTable>
                                 </Box>
                                )
                            }
                        }
                        axisLeft={{
                            format: ".0%",
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        axisBottom={null}
                        legends={[
                            {
                                anchor: 'top',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: -50,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 160,
                                itemHeight: 60,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                    <StyledStartBox>
                        <Typography variant="body2" component="p">{start}</Typography>
                    </StyledStartBox>
                    <StyledEndBox>
                        <Typography variant="body2" component="p">{end}</Typography>
                    </StyledEndBox>
                </StyledPaper>
            </Grid>
            <Grid item xs={6}>
                <StyledPaper elevation={2}>
                    <Typography component="h3" variant="plotTitle" align="center">
                        Experiments
                    </Typography>
                    <Box height={
                            xlUp? `calc(${STYLED_PAPER_LG_HEIGHT} - 40px)` 
                            : `calc(${STYLED_PAPER_BASE_HEIGHT} - 40px)`
                    }>
                        <DataGrid initialState={{ pagination: { paginationModel: {pageSize: 10}}}}
                            pagination rows={rowData} columns={[getLeadNameHeader(), ...COLUMNS]}
                            rowHeight={xlBn? 42 : xxlUp? 50 : 32}
                            density="compact" 
                            sx={theme => ({
                                border: 'none',
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    lineHeight: 1.4,
                                    whiteSpace: 'break-spaces',
                                    [theme.breakpoints.down(2000)]: { fontSize: '0.625rem'}
                                },
                            })}
                            slots={{toolbar: customToolBar}} pageSizeOptions={[10, 25, 50, 100]}
                        />
                    </Box>
                </StyledPaper>
            </Grid>
            <Grid item xs={6}>
                <StyledPaper elevation={2}>
                    <Typography component="h3" variant="plotTitle" align="center" gutterBottom>
                        PSMs
                    </Typography>
                    <ResponsiveBar
                        margin={margin}
                        data={counts}
                        enableLabel={false}
                        labelFormat=","
                        indexBy='experiment'
                        keys={['remaining psms', 'removed psms']}
                        colors={colorsScheme}
                        animate={true}
                        tooltip={renderToolTip}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: ",",
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        axisBottom={null}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'top',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: -50,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 160,
                                itemHeight: 60,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                    <StyledStartBox>
                        <Typography variant="body2" component="p">{start}</Typography>
                    </StyledStartBox>
                    <StyledEndBox>
                        <Typography variant="body2" component="p">{end}</Typography>
                    </StyledEndBox>
                </StyledPaper>
            </Grid>
            <Grid item xs={6}>
                <StyledPaper elevation={2}>
                    <Typography component="h3" variant="plotTitle" align="center" gutterBottom>
                        Proteins
                    </Typography>
                    <ResponsiveBar
                        margin={margin}
                        data={counts}
                        enableLabel={false}
                        labelFormat=","
                        indexBy='experiment'
                        keys={['remaining proteins', 'removed proteins']}
                        colors={colorsScheme}
                        animate={true}
                        tooltip={renderToolTip}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            format: ",",
                            legendOffset: -40,
                            legendPosition: 'middle'
                        }}
                        axisBottom={null}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'top',
                                direction: 'row',
                                justify: false,
                                translateX: 0,
                                translateY: -50,
                                itemsSpacing: 0,
                                itemDirection: 'left-to-right',
                                itemWidth: 160,
                                itemHeight: 60,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: 'circle',
                                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemBackground: 'rgba(0, 0, 0, .03)',
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                    />
                    <StyledStartBox>
                        <Typography variant="body2" component="p">{start}</Typography>
                    </StyledStartBox>
                    <StyledEndBox>
                        <Typography variant="body2" component="p">{end}</Typography>
                    </StyledEndBox>
                </StyledPaper>
            </Grid>
        </Grid>
    );
}