import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const StyledListPlotTip = styled(Box)(({ theme }) => ({
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& strong': { fontWeight: theme.typography.fontWeightMedium},
    '& > li': { 
        marginRight: theme.spacing(2)
    }
}));

export default StyledListPlotTip;