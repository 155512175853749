export const DesignSelectFormDataCy = {
    header: 'design-select-form',
    createNew: 'design-create-new',
    createFromExperiment: 'design-create-from-experiment',
    createFromTemplate: 'design-create-from-template',
    submit: 'design-submit',
    cancel: 'design-cancel',
    experimentList: 'design-experiment-list',
    templateList: 'design-template-list'
};
