import {FormControl, Select, MenuItem} from '@mui/material';
import {InstrumentFilterProps} from 'filters';
import {SelectChangeEvent} from '@mui/material/Select/SelectInput';
import {StyledFilterBase, StyledFilterLabel} from '../StyledComponents';

export default function InstrumentFilter({options, instrument, onInstrumentChangeCallback}: InstrumentFilterProps) {

    const handleChange = (e: SelectChangeEvent<{value: unknown}>) => {
        onInstrumentChangeCallback(e.target.value as string);
    };

    return (
        <FormControl variant="filled" size="small" sx={{ minWidth: 120 }}>
            <StyledFilterLabel id="select-instrument-label">Instrument</StyledFilterLabel>
            <Select
                id="select-instrument-filter"
                labelId="select-instrument-label"
                input={<StyledFilterBase />}
                // @ts-ignore
                value={instrument}
                onChange={handleChange}
            >
                {options.map((option: string, index: number) => (
                    <MenuItem key={'instrument_' + index} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
