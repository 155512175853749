import {FC, useState} from 'react'
import PublishUi from './PublishUi';
import ExportPdfUi from './ExportPdfUi';
import {Button, IconButton, Menu, MenuItem} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from '../../../../modals/ConfirmationDialog';
import ProcessExperimentModal from './ProcessExperimentModal';
import {ExperimentMenuCy} from './ExperimentMenu.cy';
import menuServices from '../../../../../services/menuServices';
import {FlashAnalysis} from '../../../../../API';

interface FlashMenuProps {
    experimentType: ExperimentType;
    publishingCallback: (status: string) => void;
    currentExperiment: any;
    exportPdfCallback: any;
    setExperimentMessage: (message: string) => void;
    deletionHandler: () => void;
    editExperiment: () => void;
    canDelete: boolean;
    canProcess: boolean;
    analysis: FlashAnalysis | null | undefined;
}

const FlashExperimentMenu: FC<FlashMenuProps> = ({
                                                     experimentType,
                                                     publishingCallback,
                                                     currentExperiment,
                                                     exportPdfCallback,
                                                     setExperimentMessage,
                                                     deletionHandler,
                                                     editExperiment,
                                                     canDelete,
                                                     canProcess,
                                                     analysis
                                                 }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showProcessExperiment, setProcessExperiment] = useState(false);
    const open = Boolean(anchorEl);

    const handleMoreClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMoreClose = () => {
        setAnchorEl(null);
    };

    const doProcessExperiment = () => {
        setProcessExperiment(true);
        handleMoreClose();
    }
    const hideProcessExperiment = () => {
        setProcessExperiment(false);
    }

    const editExperimentHandler = () => {
        handleMoreClose();
        editExperiment();
    }

    const doDelete = async () => {
        const experiment = currentExperiment.experiment
        const result: any = await menuServices.deleteExperiment(experiment, experimentType)
        if (result?.data?.deleteExperiment !== null) {
            deletionHandler()
        }
    }
    const doDeleteConfirmation = () => {
        setShowConfirmDelete(true);
        handleMoreClose();
    }
    const cancelDelete = () => {
        setShowConfirmDelete(false);
    }

    return (
        <>
            <PublishUi publishingCallback={publishingCallback}
                       experimentType={experimentType} currentExperiment={currentExperiment}/>
            <ExportPdfUi experimentType={experimentType} exportPdfCallback={exportPdfCallback}
                         setExperimentMessage={setExperimentMessage}/>
            {currentExperiment.status === 'PLANNED' &&
                <Button size="small" variant="contained" onClick={editExperimentHandler} sx={{ml: 1}}>
                    Edit Experiment
                </Button>}
            <IconButton aria-label="Experiment Actions" aria-controls="context-menu" aria-haspopup="true"
                        data-cy={ExperimentMenuCy.contextMenuButton} onClick={handleMoreClick} sx={{ml: 1}}>
                <MoreVertIcon/>
            </IconButton>
            {currentExperiment.status && <Menu id="context-menu" anchorEl={anchorEl} keepMounted open={open}
                                               onClose={handleMoreClose}>
                <MenuItem key="processExp" onClick={doProcessExperiment}
                          disabled={!canProcess
                              || currentExperiment.status.startsWith('PUBLISH')
                              || currentExperiment.status === 'PENDING'}>
                    Process Experiment
                </MenuItem>
                <MenuItem onClick={doDeleteConfirmation}
                          disabled={!canDelete || currentExperiment.status.startsWith('PUBLISH')}>
                    Delete Experiment
                </MenuItem>
            </Menu>}
            <ConfirmationDialog dialogTitleText="Delete this experiment"
                                dialogDescText={'Type name of experiment in the field below to confirm.'}
                                open={showConfirmDelete}
                                handleClose={cancelDelete}
                                submitDialog={doDelete}
                                value={currentExperiment.experiment}/>
            {showProcessExperiment &&
                <ProcessExperimentModal open={showProcessExperiment} handleClose={hideProcessExperiment}
                                        currentExperiment={currentExperiment} analysis={analysis}/>}
        </>
    )
}

export default FlashExperimentMenu