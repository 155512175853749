import { TableCell, Typography, styled } from '@mui/material';
import { TableCellBaseProps } from '@mui/material/TableCell';
import { colors, dataColors } from '../../theme/theme';
import {Instruments} from "../../store/Instruments";

interface CalTableBodyCellProps extends TableCellBaseProps {
    value: any | null;
    date: Date;
    classes?: string | undefined;
}

interface StyledCalTableBodyCellProps extends TableCellBaseProps {
    bgcolor: string;
}

export default function CalTableBodyCell({value, date, classes}: CalTableBodyCellProps) {
    
    const calPlanned = dataColors.lcms.plannedColor;
    const calLCMS = dataColors.lcms.lcmsColor;
    const calProcessed = dataColors.lcms.processedColor;
    const calPublished = dataColors.lcms.publishedColor;
    
    // in future if the 2-week calendar table concept is reused for something other than LCMS
    // then we can add more status:background color options, or even allow this to display icons
    function makeCalStatusColor(value: any, date: Date) {
        const day = date.toISOString().slice(0,10)
        if (value.published_date && value.published_date === day) {
            return [calPublished, `Published ${day}`];
        }  else if (value.processed_date && value.processed_date === day) {
          return [calProcessed, `Processed ${day}`];
        } else if (value.planned_date && value.planned_date === day) {
            return [calPlanned, `Planned ${day}`];
        } else if (value.lcms_start && value.lcms_start <= day && value.lcms_end >= day) {
            return [calLCMS, `In LCMS ${day}`];
        } else {
          return ['inherit', 'n/a'];
        }
    }
    const [assignBgColor,  calStatusCellValue] = makeCalStatusColor(value, date);
    
    const StyledCalTableBodyCell = styled(TableCell)<StyledCalTableBodyCellProps>(({bgcolor, theme}) => ({
        backgroundColor: bgcolor,
        borderBottomColor: colors.mtxUISlate[50],
        textAlign: 'center',
        '&:not(.tdCalRangeStart)': {
            // creates illusion of space between cells without set border-collapse: separate on entire table
            borderLeft: `1px solid ${theme.palette.background.paper}`
        },
        '&.tdCalRangeStart': {
            // when cal range columns are preceded by other data columns then
            // use to give first cal range cell in each row a border to separate cal columns from rest of table
            borderLeft: `2px solid ${colors.mtxUISlate[100]}`
        },
        '&.tdCalRangeEnd': {
            // when cal range columns are followed by other data columns then
            // use to give last cal range cell in each row a border to separate range columns from rest of table
            borderRight: `2px solid ${colors.mtxUISlate[100]}`
        },
    }));

    const RenderInstrumentNumber = () => {
        if (assignBgColor !== calLCMS || !value.instrument || value.instrument === Instruments.unassigned) {
            return null
        }
        return (
            <span style={{color:'white'}}>{value.instrument.slice(-1)}</span>
        )
    }
    
    return (
        <StyledCalTableBodyCell bgcolor={assignBgColor} className={classes}>
            <Typography variant="srOnly">{calStatusCellValue}</Typography>
            <RenderInstrumentNumber />
        </StyledCalTableBodyCell>
    );
}