import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../graphql/queries';
import {ExperimentDownload} from '../API';
import ExperimentStatistic from './ExperimentStatistic';
import Observable from 'zen-observable-ts';
import {completedDownloadConversion} from '../graphql/subscriptions';
import {createConvertedExperimentDownload} from '../graphql/mutations';

const details = {
    fetchStatistics:  async (experimentName: string, statsRecordType: string) => {

        const result: any = await API.graphql(graphqlOperation(queries.getExperimentStatistic,
            {experiment: experimentName, recordType: statsRecordType}));
        return new ExperimentStatistic(result.data.getExperimentStatistic)
    },

    fetchExperimentDownloadUrl: async (keyValues: string[], downloadType: ExperimentDownload) => {
        const result: any = await API.graphql(graphqlOperation(
            queries.getExperimentDownloadUrl, { keys: keyValues, experimentDownload: downloadType}))
        return result.data.getExperimentDownloadUrl
    },

    fetchDesign: async (experiment: string) => {
        const result: any = await API.graphql(
            graphqlOperation(queries.getExperimentDesign, {experiment: experiment}));
        return result.data.getExperimentDesign
    },

    fetchAnalysis: async (experiment: string) => {
        const result: any = await API.graphql(
            graphqlOperation(queries.getExperimentAnalysis, {experiment: experiment}));
        return result.data.getExperimentAnalysis;
    },

    fetchAnalysisForType: async (experiment: string, experimentType: ExperimentType) => {
        if (experimentType.value === 'FLASH_DEGRADATION') {
            const result: any = await API.graphql(
                graphqlOperation(queries.getFlashAnalysis, {experiment: experiment}));
            return result.data.getFlashAnalysis;
        } else {
            const result: any = await API.graphql(
                graphqlOperation(queries.getExperimentAnalysis, {experiment: experiment}));
            return result.data.getExperimentAnalysis;
        }
    },

    fetchFastaDatabases: async (): Promise<string[]> => {
        const response: any = await API.graphql(graphqlOperation(queries.getFastaDatabases))
        return response.data.getFastaDatabases
    },

    fetchYeastTkoStats: async (experiment: string) => {
        const response: any = await API.graphql(graphqlOperation(queries.getYeastTkoStats,
            {experiment: experiment}))
        return response.data.getYeastTkoStats
    },

    fetchOnTheFly: async (experiment: string, fileType: string, method: string) => {
        const observeDownloadReady = (experiment: string, fileType: string, method: string): Promise<any> => {
            return new Promise((resolve, reject) => {
                // console.log('CREATING Promise for subscription')
                const downloadReady: Observable<any> = API.graphql(
                    graphqlOperation(completedDownloadConversion)) as Observable<any>
                const subscription = downloadReady.subscribe({
                    next: function (result: any) {
                        // console.log(`SUB RESULT: ${JSON.stringify(result.value.data.completedDownloadConversion)}`)
                        const data = result.value.data.completedDownloadConversion;
                        if (data.experiment === experiment &&
                                data.fileType === fileType && data.method === method) {
                            subscription.unsubscribe()
                            resolve(data)
                        }
                    },
                    error(err: any) {
                        reject(err)
                    },
                    complete() {
                        console.log('OBSERVATION COMPLETE')
                    }
                })
            })
        }
        const promise = observeDownloadReady(experiment, fileType, method)
        // console.log('fixing to call createConvertedExperimentDownload')
        const response: any = await API.graphql(graphqlOperation(createConvertedExperimentDownload,
            {experiment: experiment, fileType: fileType, method: method}))
        // console.log(`Create download response: ${JSON.stringify(response)}`)
        if (response.data?.createConvertedExperimentDownload !== 'success') {
            console.log(`Unexpected response from createConvertedExperimentDownload: ${JSON.stringify(response)}`)
        }
        return promise
    }
}

export default  details