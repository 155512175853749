import React, { useState } from 'react';
import {
    Alert, Box, Typography,
    Button, FormControl, InputLabel, OutlinedInput, InputAdornment, FormHelperText,
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, LinearProgress
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

type ConfirmationDialogProps = {
    open: boolean;
    value: string;
    handleClose: () => void;
    submitDialog: () => void;
    dialogTitleText: string;
    dialogDescText: string;
}

export default function ConfirmationDialog({open, value, handleClose, submitDialog, dialogTitleText, dialogDescText}: ConfirmationDialogProps) {

    const [userSupplied, setUserSupplied] = useState<string>('');
    const [progress, setProgress] = useState<boolean>(false);
    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserSupplied((e.target as HTMLInputElement).value);
    };
    
    const closeDialog = () => {
        handleClose();
        setUserSupplied('');
    };
    
    const handleConfirm = async () => {
        setProgress(true);
        await submitDialog();
        setProgress(false);
        closeDialog();
    }
    
    // use to create random string to use in ID to prevent Chrome from forcing auto suggestion
    function createInputId(): string {
        let randomID: string = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < 5; i++ ) {
            randomID += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return randomID;
    }
    
    const inputIdAppend: string = createInputId();
    const inputDeleteNameId = 'confirm-name-' + inputIdAppend;
    const inputDeleteNameDesc = inputDeleteNameId + '-desc';
    const inputDeleteNameDescError = inputDeleteNameId + '-error';
    
    const dialogWindowId = 'confirm-dialog';
    const dialogTitleId = 'confirm-title';
    const dialogDescId = 'confirm-desc';
    
    return (
        <Dialog
            fullWidth
            maxWidth={'sm'}
            PaperProps={{
                square: true,
                elevation: 0,
                sx: {border: '2px solid black'}
             }}
            id={dialogWindowId}
            open={open}
            onClose={closeDialog}
            aria-labelledby={dialogTitleId}
            aria-describedby={dialogDescId}
        >
            <DialogTitle id={dialogTitleId}>
               {dialogTitleText}
            </DialogTitle>
            <form autoComplete="off">
                <DialogContent dividers>
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        <Typography align="center">This cannot be undone.</Typography>
                    </Alert>
                    <DialogContentText id={dialogDescId} paragraph>{dialogDescText}</DialogContentText>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor={inputDeleteNameId}>Experiment Name</InputLabel>                   
                        <OutlinedInput autoFocus
                            id={inputDeleteNameId}
                            label={'Experiment Name'}
                            value={userSupplied}
                            spellCheck={false}
                            error={userSupplied === ''? false : userSupplied !== value}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    {userSupplied === ''? <CheckCircleIcon color={'secondary'} />
                                    : userSupplied === value? <CheckCircleIcon color={'success'} />
                                    : <ErrorIcon color={'error'} />
                                    }
                                </InputAdornment>
                            }
                            aria-describedby={`${inputDeleteNameDesc} ${inputDeleteNameDescError}`}
                        />
                        {userSupplied === ''? null : userSupplied === value? null :
                        <FormHelperText id={inputDeleteNameDescError} error={true}>
                            Name does not match selected experiment.
                        </FormHelperText>
                        }
                        <FormHelperText id={inputDeleteNameDesc}
                            error={userSupplied === ''? false : userSupplied !== value}
                        >
                            Selected Experiment: {value}
                        </FormHelperText>
                    </FormControl>
                    {
                        progress && <Box mt={2} width={'100%'}><LinearProgress/></Box>
                    }
                </DialogContent>
                <DialogActions>
                    {!progress && <Button onClick={closeDialog}>Cancel</Button>}
                    {!progress && <Button disabled={userSupplied !== value} onClick={handleConfirm}>Ok</Button>}
                </DialogActions>
            </form>
        </Dialog>
    );
}