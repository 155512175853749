/* istanbul ignore file */
import { IconButton, Tooltip } from '@mui/material';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// this component is intended to be used in the application AppBar, however it can be reused elsewhere
// if you reuse this then put it into SharedComponents directory

// props are intended to allow you to adjust size and alignment of the button as the icon is oddly proportioned
// padding and margin will align it relative to the size of sibling components, you will have to eyeball it
interface FeedbackButtonProps {
    tooltipTitle: string;
    url: string;
    relativePadding?: number;
    relativeMarginX?: number;
    relativeMarginY?: number;
    iconFontSize?: 'inherit' | 'small' | 'medium' | 'large' | undefined;
    //below should probably import types with inclusion of custom variants from theme
    buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

// in future the icon should come from updated MUI icons package, however it has unharmonious proportions
// the SVG below is using a <path> from the MUI default version of 24x24px
// due to proportions must use with prop fontSize="large" to match "medium" icons in the MUI icon set
function CommunicationIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="m10.2 13.2-1.05-1.05q.2-.275.275-.563Q9.5 11.3 9.5 11t-.075-.588q-.075-.287-.275-.537L10.2 8.8q.4.475.6 1.05.2.575.2 1.15t-.2 1.137q-.2.563-.6 1.063Zm2.125 2.15-1.075-1.075q.625-.7.938-1.55.312-.85.312-1.725 0-.875-.312-1.713-.313-.837-.938-1.537l1.075-1.075q.85.925 1.263 2.037Q14 9.825 14 11q0 1.175-.412 2.3-.413 1.125-1.263 2.05ZM5 12q-.825 0-1.413-.588Q3 10.825 3 10t.587-1.413Q4.175 8 5 8q.825 0 1.412.587Q7 9.175 7 10q0 .825-.588 1.412Q5.825 12 5 12Zm-4 4v-.575q0-.6.325-1.1.325-.5.9-.75.65-.275 1.337-.425Q4.25 13 5 13t1.438.15q.687.15 1.337.425.575.25.9.75.325.5.325 1.1V16Zm18-4q-.825 0-1.413-.588Q17 10.825 17 10t.587-1.413Q18.175 8 19 8q.825 0 1.413.587Q21 9.175 21 10q0 .825-.587 1.412Q19.825 12 19 12Zm-4 4v-.575q0-.6.325-1.1.325-.5.9-.75.65-.275 1.337-.425Q18.25 13 19 13t1.438.15q.687.15 1.337.425.575.25.9.75.325.5.325 1.1V16Z"/>
        </SvgIcon>
    )
}

export default function FeedbackButton({
    tooltipTitle, url, relativePadding, relativeMarginX, relativeMarginY, iconFontSize, buttonColor
    }: FeedbackButtonProps) {

    const marginYpx = relativeMarginY? (`${relativeMarginY}px`) : 0;
    const marginXpx = relativeMarginX? (`${relativeMarginX}px`) : 0;
    const paddingPx = relativePadding? (`${relativePadding}px`) : '1px';

    return (
        <Tooltip title={tooltipTitle} arrow>
            <IconButton
                color={buttonColor? buttonColor : 'default'}
                href={url}
                target="_blank"
                sx={{
                    padding: paddingPx,
                    my: marginYpx,
                    mx: marginXpx
                }}
            >
                <CommunicationIcon fontSize={iconFontSize? iconFontSize : 'large'} />
            </IconButton>
        </Tooltip>
    );
}
