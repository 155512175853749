import {useEffect, useLayoutEffect, useState} from 'react';
import {ResponsiveHeatMap} from '@nivo/heatmap';
import {
    Box, CircularProgress, Grid, FormControl, FormControlLabel, FormLabel,
    RadioGroup, Radio, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import debounce from 'lodash/debounce';
import {StatsData} from 'experiment';
import useMounted from "../../util/useMounted";

export default function PeptideOverlapPanel({data, loading}: StatsData) {

    const isMounted = useMounted();
    const [rawData, setRawData] = useState<any | null>(null);
    const [plotData, setPlotData] = useState<any | null>(null);
    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3);
    const [modality, setModality] = useState('percent');

    const calculateGraphDataAsPercents = (serverData: any) => {
        const graphData: any[] = [];
        try {
            serverData.forEach((p: any) => {
                const fractionIndex = p.keys.indexOf(p.fraction);
                const fractionCount = p.counts[fractionIndex];
                const fraction: any = {
                    id: p.fraction,
                    data: []
                };
                p.keys.forEach((k: string, index: number) => {
                    let yValue = k === p.fraction ? 100
                        : Math.round((p.counts[index] * 2.0) / (fractionCount + serverData[fractionIndex].counts[index]) * 100)
                    fraction.data.push({x: k, y: yValue})
                });
                graphData.push(fraction);
            });
        } catch (err) {
            console.log('Problem in calculateGraphDataAsPercents', err)
        }
        return graphData;
    };

    const calculateGraphDataAsCounts = (serverData: any) => {
        const graphData: any[] = [];
        try {
            serverData.forEach((p: any) => {
                const fraction: any = {
                    id: p.fraction,
                    data: []
                };
                p.keys.forEach((k: string, index: number) => {
                    fraction.data.push({x: k, y: p.counts[index]})
                });
                graphData.push(fraction);
            });
        } catch (err) {
            console.log('Problem in calculateGraphDataAsCounts', err)
        }
        return graphData;
    };

    useLayoutEffect(() => {
        const handleResize = debounce(() => {
            setGraphHeight(window.innerWidth / 3)
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const processData = (data: any) => {
            try {
                const raw = data
                if (raw) {
                    const graphData: any[] = (modality === 'percent') ?
                        calculateGraphDataAsPercents(raw) :
                            calculateGraphDataAsCounts(raw);
                    if(isMounted()) {
                        setRawData(raw);
                        setPlotData(graphData);
                    }                
                } else {
                    isMounted() && setPlotData(null);
                }
               
            } catch (error) {
                console.error(error);
                isMounted() && setPlotData(null);
            }
        };
        processData(data);
        // eslint-disable-next-line  react-hooks/exhaustive-deps
    }, [data]);

    const handleModalityChange = (event: any) => {
        setModality(event.target.value);
        if (rawData) {
            const graphData: any[] = (event.target.value === 'percent') ?
                calculateGraphDataAsPercents(rawData) :
                    calculateGraphDataAsCounts(rawData);
            setPlotData(graphData);
        }
    };

    if (data != null) {
        return <Grid container spacing={1}>
            <Grid key="modalityGrid" item xs={4} sm={4} md={2} lg={2}>
                <FormControl component="fieldset" sx={{ ml: 2, mt: 1 }}>
                    <FormLabel component="legend">Modality</FormLabel>
                    <RadioGroup aria-label="heatmap modality" name="overlapModality" value={modality}
                            onChange={handleModalityChange}>
                        <FormControlLabel value={'percent'} control={<Radio />} label="Percent"/>
                        <FormControlLabel value={'count'} control={<Radio />} label="Count"/>
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid key="heatmapGrid" item xs={8} sm={8} md={10} lg={10}>
                <Box height={graphHeight}>
                    <ResponsiveHeatMap
                        animate={false}
                        data={plotData}
                        margin={{top: 40, right: 40, bottom: 40, left: 40}}
                        forceSquare={true}
                        colors={{
                            type: 'sequential',
                            scheme: 'reds',
                            }}
                        axisTop={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -90,
                            legend: '',
                            legendOffset: 36
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: '',
                            legendOffset: -40
                        }}
                        enableLabels={graphHeight > 575}
                    />
                </Box>
            </Grid>
        </Grid>
    }
    if(loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}