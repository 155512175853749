import React, { useState } from 'react';
import MoreIcon from '@mui/icons-material/MoreVert';
import {IconButton, Menu, MenuItem} from '@mui/material';

type SampleRowMenuProps = {
    forSample: string;
    handleDuplicate: () => void;
    handleAddCompound: () => void;
    useCompound: boolean;
    handleClearRow: () => void;
}

export const SampleRowMenuDataCy = {
    more: 'sample-more',
    menu: 'sample-menu',
    duplicate: 'sample-menu-duplicate',
    addCompound: 'sample-menu-add-compound',
    clearRow: 'sample-menu-clear-row'
}

export default function SampleRowMenu({forSample, handleDuplicate, handleAddCompound, useCompound, handleClearRow}: SampleRowMenuProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const menuId = `menu_${forSample}`;
    
    return (<>
        <IconButton color="secondary" aria-controls={menuId} aria-haspopup="true"
                    onClick={handleClick} data-cy={SampleRowMenuDataCy.more}>
            <MoreIcon />
        </IconButton>
        <Menu
            id={menuId}
            data-cy={SampleRowMenuDataCy.menu}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ vertical: 'top', horizontal: 'right'}}
            >
            <MenuItem data-cy={SampleRowMenuDataCy.duplicate} onClick={() => {handleDuplicate(); handleClose()}}>Duplicate Sample Row Values</MenuItem>
            <MenuItem data-cy={SampleRowMenuDataCy.addCompound} onClick={() => {handleAddCompound(); handleClose()}} disabled={!useCompound}>Add Compound to Sample</MenuItem>
            <MenuItem data-cy={SampleRowMenuDataCy.clearRow} onClick={() => {handleClearRow(); handleClose()}}>Clear Row</MenuItem>
        </Menu>
    </>);
}