/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ValidateFastaInput = {
  key: string,
};

export type RunAnalysisInput = {
  experimentName: string,
  experimentType: string,
  psmFile: string,
  experimentFolder?: string | null,
  database: string,
  instrument: string,
  benchling?: string | null,
  psmThreshold: number,
  isolationInterferenceCutoff: number,
  averageReporterSNCutoff: number,
  missingChannelThreshold: number,
  spsMassMatchesCutoff?: number | null,
  ignoreSpsMassMatches: boolean,
  skipNormalization: boolean,
  tmt: TMT,
  tmtLot: string,
  pdVersion: PDVersion,
  injectionNumber?: number | null,
  quantitationMethod: QuantitationMethod,
  psmAmbiguityOptions: Array< string | null >,
  identifyingNodeOptions: Array< string | null >,
};

export enum TMT {
  TMT_10_PLEX = "TMT_10_PLEX",
  TMT_11_PLEX = "TMT_11_PLEX",
  TMT_16_PLEX = "TMT_16_PLEX",
  TMT_18_PLEX = "TMT_18_PLEX",
}


export enum PDVersion {
  PD_2_2 = "PD_2_2",
  PD_2_3 = "PD_2_3",
  PD_2_4 = "PD_2_4",
  PD_2_5 = "PD_2_5",
  PD_3_0 = "PD_3_0",
  PD_3_1 = "PD_3_1",
}


export enum QuantitationMethod {
  MS_2 = "MS_2",
  MS_3 = "MS_3",
  MS3_RT = "MS3_RT",
}


export type SubmissionUpdate = {
  __typename: "SubmissionUpdate",
  status: string,
  experimentName?: string | null,
  message?: string | null,
};

export type FlashAnalysisInput = {
  experimentName: string,
  transferQvalueCutoff: string,
  samples: Array< string | null >,
};

export type PublishExperimentInput = {
  experimentName: string,
  significanceMeasure: string,
  experimentType: string,
};

export type PublishCompleteInput = {
  experimentName: string,
  status?: string | null,
  message?: string | null,
};

export type LabelEfficiencyPcaInput = {
  experimentName: string,
  plot?: Array< PcaDataInput | null > | null,
  message?: string | null,
};

export type PcaDataInput = {
  id: string,
  data: Array< PcaPointInput | null >,
};

export type PcaPointInput = {
  x: number,
  y: number,
};

export type LabelEfficiencyPca = {
  __typename: "LabelEfficiencyPca",
  experimentName: string,
  plot?:  Array<PcaData | null > | null,
  message?: string | null,
};

export type PcaData = {
  __typename: "PcaData",
  id: string,
  data:  Array<PcaPoint | null >,
};

export type PcaPoint = {
  __typename: "PcaPoint",
  x: number,
  y: number,
};

export type ExperimentTableInput = {
  experiment: string,
  record_type: string,
  schema_version?: string | null,
  // STATUS records will get a record_value of 'PLANNED' inserted by the resolver
  record_value?: RecordValueInput | null,
  original_name?: string | null,
  original_type?: string | null,
};

export type RecordValueInput = {
  type: ExperimentType,
  projectName?: string | null,
  conditions?: Array< ConditionInput | null > | null,
  responsible?: string | null,
  collaborator?: string | null,
  benchling?: string | null,
  noOfSamples?: number | null,
  bufferVolume?: number | null,
  experiment_folder?: string | null,
  peptideBarcode?: string | null,
};

export enum ExperimentType {
  DEGRADATION = "DEGRADATION",
  QC = "QC",
  TURBO_ID = "TURBO_ID",
  PHOSPHORYLATION = "PHOSPHORYLATION",
  UBIQUITINATION = "UBIQUITINATION",
  SILAC = "SILAC",
  LABEL_FREE = "LABEL_FREE",
  PARALLEL_REACTION = "PARALLEL_REACTION",
  CHEMOPROTEOMICS = "CHEMOPROTEOMICS",
  FLOW_THROUGH = "FLOW_THROUGH",
  GENERAL = "GENERAL",
  FLASH_DEGRADATION = "FLASH_DEGRADATION",
}


export type ConditionInput = {
  channel?: string | null,
  cells?: string | null,
  treatmentTime?: number | null,
  treatmentTimeUnit?: string | null,
  type?: string | null,
  description?: string | null,
  compounds?: Array< CompoundInput | null > | null,
};

export type CompoundInput = {
  batch?: string | null,
  compound?: string | null,
  concentration?: ConcentrationInput | null,
};

export type ConcentrationInput = {
  concentration?: number | null,
  unit?: string | null,
};

export type ExperimentTable = {
  __typename: "ExperimentTable",
  experiment: string,
  record_type: string,
  schema_version: string,
  record_value?: RecordValue | null,
  edit_date: string,
  creation_date: string,
};

export type RecordValue = {
  __typename: "RecordValue",
  type?: ExperimentType | null,
  projectName?: string | null,
  conditions?:  Array<Condition | null > | null,
  responsible?: string | null,
  collaborator?: string | null,
  benchling?: string | null,
  noOfSamples?: number | null,
};

export type Condition = {
  __typename: "Condition",
  channel?: string | null,
  cells?: string | null,
  genotype?: string | null,
  treatmentTime?: number | null,
  treatmentTimeUnit?: string | null,
  type?: string | null,
  taxon?: string | null,
  description?: string | null,
  compounds?:  Array<Compound | null > | null,
};

export type ConditionType = {
  __typename: "ConditionType",
  channel?: string | null,
  cells?: string | null,
  treatmentTime?: number | null,
  description?: string | null,
};

export type TMTCondition = {
  __typename: "TMTCondition",
  channel?: string | null,
  cells?: string | null,
  compound: string,
  cddBatchId: number,
  concentration: Concentration,
  treatmentTime?: number | null,
  description?: string | null,
};

export type Concentration = {
  __typename: "Concentration",
  concentration?: number | null,
  unit?: string | null,
};

export type Compound = {
  __typename: "Compound",
  batch?: string | null,
  compound?: string | null,
  concentration?: Concentration | null,
};

export type Next = {
  __typename: "Next",
  year?: number | null,
  experiment_counter?: number | null,
};

export type LcmsStatus = {
  __typename: "LcmsStatus",
  experiment?: string | null,
  exp_type?: string | null,
  exp_status?: string | null,
  planned_date?: string | null,
  processed_date?: string | null,
  published_date?: string | null,
  responsible?: string | null,
  collaborator?: string | null,
  projectName?: string | null,
  benchling?: string | null,
  lcms_start?: string | null,
  lcms_end?: string | null,
  instrument?: string | null,
  experiment_folder?: string | null,
};

export enum PXPfileType {
  PEPTIDE = "PEPTIDE",
  SPOTFIRE = "SPOTFIRE",
}


export enum PXPAnalysisMethod {
  sum_PSMs = "sum_PSMs",
  MVI_TMP = "MVI_TMP",
}


export type GeneratedFileDownload = {
  experiment: string,
  fileType: PXPfileType,
  method: PXPAnalysisMethod,
  url?: string | null,
  key?: string | null,
};

export type ConversionForDownload = {
  __typename: "ConversionForDownload",
  experiment: string,
  fileType: PXPfileType,
  method: PXPAnalysisMethod,
  url?: string | null,
  key?: string | null,
};

export type PairResult = {
  __typename: "PairResult",
  keys?: string | null,
  cancellationReasons?: string | null,
};

export type FlashSampleListInput = {
  experimentName: string,
  samplesPerBlock: string,
  bufferVolumeUL: string,
  peptideBarcode: string,
};

export type DesignRecord = {
  __typename: "DesignRecord",
  experiment?: string | null,
  record_type?: string | null,
  record_value?: ExperimentDesign | null,
};

export type ExperimentDesign = {
  __typename: "ExperimentDesign",
  conditions?:  Array<ConditionType | null > | null,
  type: ExperimentType,
  tmt_lot?: string | null,
  projectName?: string | null,
  responsible?: string | null,
  collaborator?: string | null,
  benchling?: string | null,
  noOfSamples?: number | null,
  bufferVolume?: number | null,
  schema_version?: string | null,
  experiment_folder?: string | null,
  scinamicPlateMap?: string | null,
  cellCultureBarcode?: string | null,
  peptideBarcode?: string | null,
  flashWarningsAcknowledged?: string | null,
};

export type IFI_STATS = {
  __typename: "IFI_STATS",
  imputedValue?: number | null,
  ifiStatistics?:  Array<IFI_Channel | null > | null,
};

export type IFI_Channel = {
  __typename: "IFI_Channel",
  TMT_channel?: string | null,
  num_PSMs?: number | null,
  num_imputed_values?: number | null,
  median?: number | null,
  mean?: number | null,
  num_outliers?: number | null,
};

export type ExperimentFilterInput = {
  instrument?: string | null,
  dateRange?: DateRange | null,
  cellLine?: string | null,
  projectCompound?: ProjectCompound | null,
  experimenter?: string | null,
};

export type DateRange = {
  start: string,
  end: string,
};

export type ProjectCompound = {
  name: string,
  type: string,
};

export type ExperimentStatisticsConnection = {
  __typename: "ExperimentStatisticsConnection",
  items:  Array<BulkStatistics | null >,
};

export type BulkStatistics = {
  __typename: "BulkStatistics",
  experiment: string,
  date: string,
  starting_psm_count?: number | null,
  starting_protein_count?: number | null,
  high_isolation_interfence?: number | null,
  low_signal_to_noise?: number | null,
  total_number_of_rows_removed?: number | null,
  num_proteins_remaining?: number | null,
  instrument?: string | null,
  injectionNumber?: number | null,
  pd_psm_file?: string | null,
};

export type ExperimentStatistic = {
  __typename: "ExperimentStatistic",
  experiment: string,
  date: string,
  starting_psm_count: number,
  high_interference_psm_count?: number | null,
  low_signal_noise_psm_count?: number | null,
  missing_channel_psm_count?: number | null,
  under_sps_mass_count?: number | null,
  identifying_node_count?: number | null,
  confidence_count?: number | null,
  ambiguity_count?: number | null,
  number_of_proteins_count?: number | null,
  total_psm_removed?: number | null,
  starting_protein_count?: number | null,
  remaining_protein_count?: number | null,
  unique_peptides_filter_count?: number | null,
  pd_psm_file?: string | null,
  instrument?: string | null,
  injectionNumber?: number | null,
  unmodified_peptides_counter?: number | null,
  modified_peptides_counter?: number | null,
  removed_by_sum_PSMs_filters_count?: string | null,
  removed_by_MVI_TMP_filters_count?: string | null,
};

export type FlashStatistics = {
  __typename: "FlashStatistics",
  experiment: string,
  record_value?: FlashStatsRecordValue | null,
};

export type FlashStatsRecordValue = {
  __typename: "FlashStatsRecordValue",
  method_a_filters_count?: Method_A_Counts | null,
  starting_total_PCM_count?: number | null,
  starting_total_peptide_count?: number | null,
  starting_total_protein_count?: number | null,
  starting_unique_PCM_count?: number | null,
  starting_unique_peptide_count?: number | null,
  starting_unique_protein_count?: number | null,
  total_number_proteotypic_PCM?: number | null,
  total_number_proteotypic_peptides?: number | null,
  total_number_proteotypic_proteins?: number | null,
  unique_number_proteotypic_PCM?: number | null,
  unique_number_proteotypic_peptides?: number | null,
  unique_number_proteotypic_proteins?: number | null,
  missing_MSF_files?: Array< string | null > | null,
  issues_parsing_MSF_files?: Array< string | null > | null,
};

export type Method_A_Counts = {
  __typename: "Method_A_Counts",
  number_PCM_rescued_due_to_transfer_q_value?: number | null,
  number_peptides_rescued_due_to_transfer_q_value?: number | null,
  total_PCM_count_remaining?: number | null,
  total_peptide_count_remaining?: number | null,
  total_protein_count_remaining?: number | null,
  unique_PCM_count_remaining?: number | null,
  unique_peptide_count_remaining?: number | null,
  unique_protein_count_remaining?: number | null,
};

export type ExperimentListConnection = {
  __typename: "ExperimentListConnection",
  items?:  Array<ExperimentStatus | null > | null,
  nextToken?: string | null,
};

export type ExperimentStatus = {
  __typename: "ExperimentStatus",
  experiment: string,
  status: string,
  history:  Array<StatusUpdate >,
  injectionNumber?: number | null,
};

export type StatusUpdate = {
  __typename: "StatusUpdate",
  status: string,
  date: string,
};

export type AllExperimentListConnection = {
  __typename: "AllExperimentListConnection",
  items?:  Array<ExperimentList | null > | null,
  nextToken?: string | null,
};

export type ExperimentList = {
  __typename: "ExperimentList",
  experiment: string,
  creation_date: string,
};

export type SimpleStatus = {
  __typename: "SimpleStatus",
  experiment: string,
  status: string,
};

export type ExperimentAnalysis = {
  __typename: "ExperimentAnalysis",
  database: string,
  instrument: string,
  pd_version: string,
  pd_psm_file: string,
  psm_threshold: number,
  isolation_interference_cutoff: number,
  average_reporter_sn_cutoff: number,
  missing_channel_threshold: number,
  sps_mass_matches_cutoff?: number | null,
  ignore_sps_mass_matches?: boolean | null,
  skip_normalization?: boolean | null,
  psm_ambiguity?: Array< string | null > | null,
  identifying_node?: Array< string | null > | null,
  experiment_folder?: string | null,
};

export type FlashAnalysis = {
  __typename: "FlashAnalysis",
  experiment: string,
  record_value?: FlashRecordValue | null,
};

export type FlashRecordValue = {
  __typename: "FlashRecordValue",
  samples_selected_by_user?: Array< string | null > | null,
  transfer_qValue_cutoff?: number | null,
};

export type SignedUrl = {
  __typename: "SignedUrl",
  url: string,
  key: string,
};

export enum ExperimentDownload {
  LIMMA = "LIMMA",
  QC = "QC",
  FLASH = "FLASH",
  STATIC = "STATIC",
}


export type LabelEfficiency = {
  __typename: "LabelEfficiency",
  totalPeptides: number,
  labeledNTermPeptides: number,
  totalKTermPeptides: number,
  labelKTermPeptides: number,
  boxPlot?: BoxPlot | null,
};

export type BoxPlot = {
  __typename: "BoxPlot",
  min: number,
  max: number,
  bandDomain: Array< string | null >,
  stats:  Array<BoxPlotStat | null >,
};

export type BoxPlotStat = {
  __typename: "BoxPlotStat",
  fiveNums: Array< number | null >,
  iqr: number,
  step: number,
  fences:  Array<Range | null >,
  boxes:  Array<Range | null >,
  whiskers:  Array<Range | null >,
  points:  Array<BoxPlotPoint | null >,
};

export type Range = {
  __typename: "Range",
  start: number,
  end: number,
};

export type BoxPlotPoint = {
  __typename: "BoxPlotPoint",
  value: number,
  datum: number,
  outlier: boolean,
  farout: boolean,
};

export type Stats = {
  __typename: "Stats",
  snLog10Histogram?: HistogramData | null,
  channelIntensityStats?: BoxPlot | null,
  peptideOverlaps?:  Array<PeptideOverlap | null > | null,
  fractionCounts?:  Array<FractionCount | null > | null,
  psmPrecursorIntensities?: HistogramData | null,
  qpsmPrecursorIntensities?: HistogramData | null,
  massErrorHistogram?: HistogramData | null,
  sampleControlRatios?: BoxPlot | null,
  pcaData?:  Array<PcaData | null > | null,
};

export type HistogramData = {
  __typename: "HistogramData",
  min: number,
  max: number,
  binWidth: number,
  binCounts: Array< number | null >,
};

export type PeptideOverlap = {
  __typename: "PeptideOverlap",
  fraction: string,
  keys: Array< string | null >,
  counts: Array< number | null >,
};

export type FractionCount = {
  __typename: "FractionCount",
  fraction: string,
  psm: number,
  qpsm: number,
};

export type DispatchInput = {
  function_name: string,
  function_parameters?: string | null,
};

export type ReferenceExperiment = {
  __typename: "ReferenceExperiment",
  instrument?: string | null,
  experiment?: string | null,
};

export type LcmsFilters = {
  start: string,
  end: string,
  experimentType?: string | null,
  instrument?: string | null,
  cellLine?: string | null,
  projectName?: string | null,
  experimenter?: string | null,
  compound?: string | null,
  experiment?: string | null,
};

export type LcmsStatusConnection = {
  __typename: "LcmsStatusConnection",
  items?:  Array<LcmsStatus | null > | null,
};

export type FlashSample = {
  __typename: "FlashSample",
  fileName: string,
  sampleId: string,
  position: string,
  injVol: string,
  L1Replicate: string,
  L2Compound: string,
  L3CUM: string,
  L4Time: string,
  L5CellLine: string,
  comment: string,
  sampleName: string,
  fileSize?: number | null,
};

export type Resource = {
  __typename: "Resource",
  resource_name: string,
  resource: string,
};

export type ValidateFastaUploadMutationVariables = {
  input?: ValidateFastaInput | null,
};

export type ValidateFastaUploadMutation = {
  validateFastaUpload?: string | null,
};

export type StartAnalysisMutationVariables = {
  input: RunAnalysisInput,
};

export type StartAnalysisMutation = {
  startAnalysis?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type StartFlashAnalysisMutationVariables = {
  input: FlashAnalysisInput,
};

export type StartFlashAnalysisMutation = {
  startFlashAnalysis?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type AnalysisStepCompleteMutationVariables = {
  status: string,
  experimentName: string,
  message?: string | null,
};

export type AnalysisStepCompleteMutation = {
  analysisStepComplete?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type PublishExperimentMutationVariables = {
  input: PublishExperimentInput,
};

export type PublishExperimentMutation = {
  publishExperiment?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type PublishCompleteMutationVariables = {
  input?: PublishCompleteInput | null,
};

export type PublishCompleteMutation = {
  publishComplete?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type DeleteExperimentMutationVariables = {
  experimentName: string,
  experimentType: string,
};

export type DeleteExperimentMutation = {
  deleteExperiment?: string | null,
};

export type RevertExperimentMutationVariables = {
  experimentName: string,
  experimentType: string,
};

export type RevertExperimentMutation = {
  revertExperiment?: string | null,
};

export type LabelCheckPcaAnalysisMutationVariables = {
  input?: LabelEfficiencyPcaInput | null,
};

export type LabelCheckPcaAnalysisMutation = {
  labelCheckPcaAnalysis?:  {
    __typename: "LabelEfficiencyPca",
    experimentName: string,
    plot?:  Array< {
      __typename: "PcaData",
      id: string,
      data:  Array< {
        __typename: "PcaPoint",
        x: number,
        y: number,
      } | null >,
    } | null > | null,
    message?: string | null,
  } | null,
};

export type UploadPlannedExperimentMutationVariables = {
  input: Array< ExperimentTableInput | null >,
};

export type UploadPlannedExperimentMutation = {
  uploadPlannedExperiment?:  Array< {
    __typename: "ExperimentTable",
    experiment: string,
    record_type: string,
    schema_version: string,
    record_value?:  {
      __typename: "RecordValue",
      type?: ExperimentType | null,
      projectName?: string | null,
      conditions?:  Array< {
        __typename: "Condition",
        channel?: string | null,
        cells?: string | null,
        genotype?: string | null,
        treatmentTime?: number | null,
        treatmentTimeUnit?: string | null,
        type?: string | null,
        taxon?: string | null,
        description?: string | null,
        compounds?:  Array< {
          __typename: "Compound",
          batch?: string | null,
          compound?: string | null,
          concentration?:  {
            __typename: "Concentration",
            concentration?: number | null,
            unit?: string | null,
          } | null,
        } | null > | null,
      } | null > | null,
      responsible?: string | null,
      collaborator?: string | null,
      benchling?: string | null,
      noOfSamples?: number | null,
    } | null,
    edit_date: string,
    creation_date: string,
  } | null > | null,
};

export type GetNextExperimentNumberMutationVariables = {
  year: number,
};

export type GetNextExperimentNumberMutation = {
  getNextExperimentNumber?:  {
    __typename: "Next",
    year?: number | null,
    experiment_counter?: number | null,
  } | null,
};

export type UpdateLcmsInfoMutationVariables = {
  experiment: string,
  start: string,
  end: string,
  instrument: string,
};

export type UpdateLcmsInfoMutation = {
  updateLcmsInfo?:  {
    __typename: "LcmsStatus",
    experiment?: string | null,
    exp_type?: string | null,
    exp_status?: string | null,
    planned_date?: string | null,
    processed_date?: string | null,
    published_date?: string | null,
    responsible?: string | null,
    collaborator?: string | null,
    projectName?: string | null,
    benchling?: string | null,
    lcms_start?: string | null,
    lcms_end?: string | null,
    instrument?: string | null,
    experiment_folder?: string | null,
  } | null,
};

export type CompleteExperimentMutationVariables = {
  experimentName: string,
  experimentType: string,
  status: string,
};

export type CompleteExperimentMutation = {
  completeExperiment?: string | null,
};

export type CreateConvertedExperimentDownloadMutationVariables = {
  experiment: string,
  fileType: PXPfileType,
  method: PXPAnalysisMethod,
};

export type CreateConvertedExperimentDownloadMutation = {
  createConvertedExperimentDownload?: string | null,
};

export type GetConvertedExperimentDownloadUrlMutationVariables = {
  input?: GeneratedFileDownload | null,
};

export type GetConvertedExperimentDownloadUrlMutation = {
  getConvertedExperimentDownloadUrl?:  {
    __typename: "ConversionForDownload",
    experiment: string,
    fileType: PXPfileType,
    method: PXPAnalysisMethod,
    url?: string | null,
    key?: string | null,
  } | null,
};

export type UpdateBarcodePairMutationVariables = {
  parent: string,
  child: string,
};

export type UpdateBarcodePairMutation = {
  updateBarcodePair?:  {
    __typename: "PairResult",
    keys?: string | null,
    cancellationReasons?: string | null,
  } | null,
};

export type DeleteBarcodePairMutationVariables = {
  parent: string,
  child: string,
};

export type DeleteBarcodePairMutation = {
  deleteBarcodePair?:  {
    __typename: "PairResult",
    keys?: string | null,
    cancellationReasons?: string | null,
  } | null,
};

export type ProcessFlashSampleListMutationVariables = {
  input?: FlashSampleListInput | null,
};

export type ProcessFlashSampleListMutation = {
  processFlashSampleList?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type AcknowledgeFlashWarningsMutationVariables = {
  experimentName: string,
  user: string,
};

export type AcknowledgeFlashWarningsMutation = {
  acknowledgeFlashWarnings?:  {
    __typename: "DesignRecord",
    experiment?: string | null,
    record_type?: string | null,
    record_value?:  {
      __typename: "ExperimentDesign",
      conditions:  Array<( {
          __typename: "Condition",
          channel?: string | null,
          cells?: string | null,
          treatmentTime?: number | null,
          description?: string | null,
          genotype?: string | null,
          treatmentTimeUnit?: string | null,
          type?: string | null,
          taxon?: string | null,
          compounds?:  Array< {
            __typename: string,
            batch?: string | null,
            compound?: string | null,
            concentration?:  {
              __typename: string,
              concentration?: number | null,
              unit?: string | null,
            } | null,
          } | null > | null,
        } | {
          __typename: "TMTCondition",
          channel?: string | null,
          cells?: string | null,
          treatmentTime?: number | null,
          description?: string | null,
          compound: string,
          cddBatchId: number,
          concentration:  {
            __typename: string,
            concentration?: number | null,
            unit?: string | null,
          },
        }
      ) | null > | null,
      type: ExperimentType,
      tmt_lot?: string | null,
      projectName?: string | null,
      responsible?: string | null,
      collaborator?: string | null,
      benchling?: string | null,
      noOfSamples?: number | null,
      bufferVolume?: number | null,
      schema_version?: string | null,
      experiment_folder?: string | null,
      scinamicPlateMap?: string | null,
      cellCultureBarcode?: string | null,
      peptideBarcode?: string | null,
      flashWarningsAcknowledged?: string | null,
    } | null,
  } | null,
};

export type UpdateFlashSampleListMutationVariables = {
  experimentName: string,
};

export type UpdateFlashSampleListMutation = {
  updateFlashSampleList?: string | null,
};

export type GetYeastTkoStatsQueryVariables = {
  experiment: string,
};

export type GetYeastTkoStatsQuery = {
  getYeastTkoStats?:  {
    __typename: "IFI_STATS",
    imputedValue?: number | null,
    ifiStatistics?:  Array< {
      __typename: "IFI_Channel",
      TMT_channel?: string | null,
      num_PSMs?: number | null,
      num_imputed_values?: number | null,
      median?: number | null,
      mean?: number | null,
      num_outliers?: number | null,
    } | null > | null,
  } | null,
};

export type GetExperimentStatisticsQueryVariables = {
  recordType: string,
  filters: ExperimentFilterInput,
};

export type GetExperimentStatisticsQuery = {
  getExperimentStatistics?:  {
    __typename: "ExperimentStatisticsConnection",
    items:  Array< {
      __typename: "BulkStatistics",
      experiment: string,
      date: string,
      starting_psm_count?: number | null,
      starting_protein_count?: number | null,
      high_isolation_interfence?: number | null,
      low_signal_to_noise?: number | null,
      total_number_of_rows_removed?: number | null,
      num_proteins_remaining?: number | null,
      instrument?: string | null,
      injectionNumber?: number | null,
      pd_psm_file?: string | null,
    } | null >,
  } | null,
};

export type GetExperimentStatisticQueryVariables = {
  experiment: string,
  recordType: string,
};

export type GetExperimentStatisticQuery = {
  getExperimentStatistic?:  {
    __typename: "ExperimentStatistic",
    experiment: string,
    date: string,
    starting_psm_count: number,
    high_interference_psm_count?: number | null,
    low_signal_noise_psm_count?: number | null,
    missing_channel_psm_count?: number | null,
    under_sps_mass_count?: number | null,
    identifying_node_count?: number | null,
    confidence_count?: number | null,
    ambiguity_count?: number | null,
    number_of_proteins_count?: number | null,
    total_psm_removed?: number | null,
    starting_protein_count?: number | null,
    remaining_protein_count?: number | null,
    unique_peptides_filter_count?: number | null,
    pd_psm_file?: string | null,
    instrument?: string | null,
    injectionNumber?: number | null,
    unmodified_peptides_counter?: number | null,
    modified_peptides_counter?: number | null,
    removed_by_sum_PSMs_filters_count?: string | null,
    removed_by_MVI_TMP_filters_count?: string | null,
  } | null,
};

export type GetFlashStatsQueryVariables = {
  experiment: string,
};

export type GetFlashStatsQuery = {
  getFlashStats?:  {
    __typename: "FlashStatistics",
    experiment: string,
    record_value?:  {
      __typename: "FlashStatsRecordValue",
      method_a_filters_count?:  {
        __typename: "Method_A_Counts",
        number_PCM_rescued_due_to_transfer_q_value?: number | null,
        number_peptides_rescued_due_to_transfer_q_value?: number | null,
        total_PCM_count_remaining?: number | null,
        total_peptide_count_remaining?: number | null,
        total_protein_count_remaining?: number | null,
        unique_PCM_count_remaining?: number | null,
        unique_peptide_count_remaining?: number | null,
        unique_protein_count_remaining?: number | null,
      } | null,
      starting_total_PCM_count?: number | null,
      starting_total_peptide_count?: number | null,
      starting_total_protein_count?: number | null,
      starting_unique_PCM_count?: number | null,
      starting_unique_peptide_count?: number | null,
      starting_unique_protein_count?: number | null,
      total_number_proteotypic_PCM?: number | null,
      total_number_proteotypic_peptides?: number | null,
      total_number_proteotypic_proteins?: number | null,
      unique_number_proteotypic_PCM?: number | null,
      unique_number_proteotypic_peptides?: number | null,
      unique_number_proteotypic_proteins?: number | null,
      missing_MSF_files?: Array< string | null > | null,
      issues_parsing_MSF_files?: Array< string | null > | null,
    } | null,
  } | null,
};

export type GetExperimentListQueryVariables = {
  recordType: string,
  filters: ExperimentFilterInput,
};

export type GetExperimentListQuery = {
  getExperimentList?:  {
    __typename: "ExperimentListConnection",
    items?:  Array< {
      __typename: "ExperimentStatus",
      experiment: string,
      status: string,
      history:  Array< {
        __typename: "StatusUpdate",
        status: string,
        date: string,
      } >,
      injectionNumber?: number | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetMostRecentNonQCDesignsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type GetMostRecentNonQCDesignsQuery = {
  getMostRecentNonQCDesigns?:  {
    __typename: "AllExperimentListConnection",
    items?:  Array< {
      __typename: "ExperimentList",
      experiment: string,
      creation_date: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetExperimentContrastsQueryVariables = {
  experimentName: string,
};

export type GetExperimentContrastsQuery = {
  getExperimentContrasts?: Array< string | null > | null,
};

export type GetExperimentDesignQueryVariables = {
  experiment: string,
};

export type GetExperimentDesignQuery = {
  getExperimentDesign?:  {
    __typename: "ExperimentDesign",
    conditions:  Array<( {
        __typename: "Condition",
        channel?: string | null,
        cells?: string | null,
        treatmentTime?: number | null,
        description?: string | null,
        genotype?: string | null,
        treatmentTimeUnit?: string | null,
        type?: string | null,
        taxon?: string | null,
        compounds?:  Array< {
          __typename: string,
          batch?: string | null,
          compound?: string | null,
          concentration?:  {
            __typename: string,
            concentration?: number | null,
            unit?: string | null,
          } | null,
        } | null > | null,
      } | {
        __typename: "TMTCondition",
        channel?: string | null,
        cells?: string | null,
        treatmentTime?: number | null,
        description?: string | null,
        compound: string,
        cddBatchId: number,
        concentration:  {
          __typename: string,
          concentration?: number | null,
          unit?: string | null,
        },
      }
    ) | null > | null,
    type: ExperimentType,
    tmt_lot?: string | null,
    projectName?: string | null,
    responsible?: string | null,
    collaborator?: string | null,
    benchling?: string | null,
    noOfSamples?: number | null,
    bufferVolume?: number | null,
    schema_version?: string | null,
    experiment_folder?: string | null,
    scinamicPlateMap?: string | null,
    cellCultureBarcode?: string | null,
    peptideBarcode?: string | null,
    flashWarningsAcknowledged?: string | null,
  } | null,
};

export type GetExperimentStatusQueryVariables = {
  experiment: string,
  recordType: string,
};

export type GetExperimentStatusQuery = {
  getExperimentStatus?:  {
    __typename: "SimpleStatus",
    experiment: string,
    status: string,
  } | null,
};

export type GetExperimentAnalysisQueryVariables = {
  experiment: string,
};

export type GetExperimentAnalysisQuery = {
  getExperimentAnalysis?:  {
    __typename: "ExperimentAnalysis",
    database: string,
    instrument: string,
    pd_version: string,
    pd_psm_file: string,
    psm_threshold: number,
    isolation_interference_cutoff: number,
    average_reporter_sn_cutoff: number,
    missing_channel_threshold: number,
    sps_mass_matches_cutoff?: number | null,
    ignore_sps_mass_matches?: boolean | null,
    skip_normalization?: boolean | null,
    psm_ambiguity?: Array< string | null > | null,
    identifying_node?: Array< string | null > | null,
    experiment_folder?: string | null,
  } | null,
};

export type GetFlashAnalysisQueryVariables = {
  experiment: string,
};

export type GetFlashAnalysisQuery = {
  getFlashAnalysis?:  {
    __typename: "FlashAnalysis",
    experiment: string,
    record_value?:  {
      __typename: "FlashRecordValue",
      samples_selected_by_user?: Array< string | null > | null,
      transfer_qValue_cutoff?: number | null,
    } | null,
  } | null,
};

export type GetUploadUrlQueryVariables = {
  key: string,
};

export type GetUploadUrlQuery = {
  getUploadUrl:  {
    __typename: "SignedUrl",
    url: string,
    key: string,
  },
};

export type GetExperimentDownloadUrlQueryVariables = {
  keys: Array< string | null >,
  experimentDownload: ExperimentDownload,
};

export type GetExperimentDownloadUrlQuery = {
  getExperimentDownloadUrl?:  Array< {
    __typename: "SignedUrl",
    url: string,
    key: string,
  } | null > | null,
};

export type GetLabelEfficiencyUploadUrlQueryVariables = {
  key: string,
};

export type GetLabelEfficiencyUploadUrlQuery = {
  getLabelEfficiencyUploadUrl:  {
    __typename: "SignedUrl",
    url: string,
    key: string,
  },
};

export type GetLabelEfficiencyQueryVariables = {
  psmFile: string,
  pdVersion?: PDVersion | null,
  tmt: TMT,
  experimentName?: string | null,
  experimentType?: ExperimentType | null,
};

export type GetLabelEfficiencyQuery = {
  getLabelEfficiency:  {
    __typename: "LabelEfficiency",
    totalPeptides: number,
    labeledNTermPeptides: number,
    totalKTermPeptides: number,
    labelKTermPeptides: number,
    boxPlot?:  {
      __typename: "BoxPlot",
      min: number,
      max: number,
      bandDomain: Array< string | null >,
      stats:  Array< {
        __typename: "BoxPlotStat",
        fiveNums: Array< number | null >,
        iqr: number,
        step: number,
        fences:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        boxes:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        whiskers:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        points:  Array< {
          __typename: "BoxPlotPoint",
          value: number,
          datum: number,
          outlier: boolean,
          farout: boolean,
        } | null >,
      } | null >,
    } | null,
  },
};

export type GetExperimentPcaDataQueryVariables = {
  experiment: string,
  recordType: string,
};

export type GetExperimentPcaDataQuery = {
  getExperimentPcaData?:  Array< {
    __typename: "PcaData",
    id: string,
    data:  Array< {
      __typename: "PcaPoint",
      x: number,
      y: number,
    } | null >,
  } | null > | null,
};

export type GetFastaDatabasesQuery = {
  getFastaDatabases?: Array< string | null > | null,
};

export type GetFastaUploadUrlQueryVariables = {
  key: string,
};

export type GetFastaUploadUrlQuery = {
  getFastaUploadUrl:  {
    __typename: "SignedUrl",
    url: string,
    key: string,
  },
};

export type GetStatsQueryVariables = {
  experiment: string,
  recordType: string,
};

export type GetStatsQuery = {
  getStats?:  {
    __typename: "Stats",
    snLog10Histogram?:  {
      __typename: "HistogramData",
      min: number,
      max: number,
      binWidth: number,
      binCounts: Array< number | null >,
    } | null,
    channelIntensityStats?:  {
      __typename: "BoxPlot",
      min: number,
      max: number,
      bandDomain: Array< string | null >,
      stats:  Array< {
        __typename: "BoxPlotStat",
        fiveNums: Array< number | null >,
        iqr: number,
        step: number,
        fences:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        boxes:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        whiskers:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        points:  Array< {
          __typename: "BoxPlotPoint",
          value: number,
          datum: number,
          outlier: boolean,
          farout: boolean,
        } | null >,
      } | null >,
    } | null,
    peptideOverlaps?:  Array< {
      __typename: "PeptideOverlap",
      fraction: string,
      keys: Array< string | null >,
      counts: Array< number | null >,
    } | null > | null,
    fractionCounts?:  Array< {
      __typename: "FractionCount",
      fraction: string,
      psm: number,
      qpsm: number,
    } | null > | null,
    psmPrecursorIntensities?:  {
      __typename: "HistogramData",
      min: number,
      max: number,
      binWidth: number,
      binCounts: Array< number | null >,
    } | null,
    qpsmPrecursorIntensities?:  {
      __typename: "HistogramData",
      min: number,
      max: number,
      binWidth: number,
      binCounts: Array< number | null >,
    } | null,
    massErrorHistogram?:  {
      __typename: "HistogramData",
      min: number,
      max: number,
      binWidth: number,
      binCounts: Array< number | null >,
    } | null,
    sampleControlRatios?:  {
      __typename: "BoxPlot",
      min: number,
      max: number,
      bandDomain: Array< string | null >,
      stats:  Array< {
        __typename: "BoxPlotStat",
        fiveNums: Array< number | null >,
        iqr: number,
        step: number,
        fences:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        boxes:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        whiskers:  Array< {
          __typename: "Range",
          start: number,
          end: number,
        } | null >,
        points:  Array< {
          __typename: "BoxPlotPoint",
          value: number,
          datum: number,
          outlier: boolean,
          farout: boolean,
        } | null >,
      } | null >,
    } | null,
    pcaData?:  Array< {
      __typename: "PcaData",
      id: string,
      data:  Array< {
        __typename: "PcaPoint",
        x: number,
        y: number,
      } | null >,
    } | null > | null,
  } | null,
};

export type DispatchPostgresFunctionQueryVariables = {
  input: DispatchInput,
};

export type DispatchPostgresFunctionQuery = {
  dispatchPostgresFunction?: string | null,
};

export type GetYeastReferenceExperimentQueryVariables = {
  instrument: string,
};

export type GetYeastReferenceExperimentQuery = {
  getYeastReferenceExperiment?:  {
    __typename: "ReferenceExperiment",
    instrument?: string | null,
    experiment?: string | null,
  } | null,
};

export type GetLcmsStatusQueryVariables = {
  filters?: LcmsFilters | null,
};

export type GetLcmsStatusQuery = {
  getLcmsStatus:  {
    __typename: "LcmsStatusConnection",
    items?:  Array< {
      __typename: "LcmsStatus",
      experiment?: string | null,
      exp_type?: string | null,
      exp_status?: string | null,
      planned_date?: string | null,
      processed_date?: string | null,
      published_date?: string | null,
      responsible?: string | null,
      collaborator?: string | null,
      projectName?: string | null,
      benchling?: string | null,
      lcms_start?: string | null,
      lcms_end?: string | null,
      instrument?: string | null,
      experiment_folder?: string | null,
    } | null > | null,
  },
};

export type GetFlashExperimentUploadUrlQueryVariables = {
  key?: string | null,
};

export type GetFlashExperimentUploadUrlQuery = {
  getFlashExperimentUploadUrl?:  {
    __typename: "SignedUrl",
    url: string,
    key: string,
  } | null,
};

export type GetBarcodeInfoQueryVariables = {
  peptideBarcode?: string | null,
};

export type GetBarcodeInfoQuery = {
  getBarcodeInfo?: string | null,
};

export type GetProcessableFlashSamplesQueryVariables = {
  experiment: string,
};

export type GetProcessableFlashSamplesQuery = {
  getProcessableFlashSamples?:  Array< {
    __typename: "FlashSample",
    fileName: string,
    sampleId: string,
    position: string,
    injVol: string,
    L1Replicate: string,
    L2Compound: string,
    L3CUM: string,
    L4Time: string,
    L5CellLine: string,
    comment: string,
    sampleName: string,
    fileSize?: number | null,
  } | null > | null,
};

export type KeyExistsQueryVariables = {
  key?: string | null,
  bucketType?: ExperimentDownload | null,
};

export type KeyExistsQuery = {
  keyExists?: boolean | null,
};

export type GetResourceQueryVariables = {
  resource: string,
};

export type GetResourceQuery = {
  getResource?:  {
    __typename: "Resource",
    resource_name: string,
    resource: string,
  } | null,
};

export type CompletedPublishStepSubscription = {
  completedPublishStep?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type CompletedAnalysisStepSubscription = {
  completedAnalysisStep?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};

export type PcaAnalysisForLabelCheckSubscription = {
  pcaAnalysisForLabelCheck?:  {
    __typename: "LabelEfficiencyPca",
    experimentName: string,
    plot?:  Array< {
      __typename: "PcaData",
      id: string,
      data:  Array< {
        __typename: "PcaPoint",
        x: number,
        y: number,
      } | null >,
    } | null > | null,
    message?: string | null,
  } | null,
};

export type CompletedDownloadConversionSubscription = {
  completedDownloadConversion?:  {
    __typename: "ConversionForDownload",
    experiment: string,
    fileType: PXPfileType,
    method: PXPAnalysisMethod,
    url?: string | null,
    key?: string | null,
  } | null,
};

export type CompleteFlashSampleListSubscription = {
  completeFlashSampleList?:  {
    __typename: "SubmissionUpdate",
    status: string,
    experimentName?: string | null,
    message?: string | null,
  } | null,
};
