import {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {IPlotData,
    boxplot,
    boxplotSymbolDot,
    boxplotSymbolTick,
    IBoxPlotOptions} from 'd3-boxplot';
import { Box, Typography } from '@mui/material/';

export const DEFAULT_BOX_PLOT_OPTIONS: IBoxPlotOptions = {
    minimalStyle: false,
    jitter: 0.2,
    opacity: 0.3,
    showInnerDots: false,
    useSymbolTick: false,
    plotId: 'channelIntensities',
    scale: 'log10',
    margins: {
        left: 20,
        right: 5,
        top: 5,
        bottom: 35
    }
};

export function createD3BoxPlot(plotData: IPlotData, svgContainer: HTMLElement, options: IBoxPlotOptions = DEFAULT_BOX_PLOT_OPTIONS): void {
    // console.log(`PLOT DATA: ${JSON.stringify(plotData, null, 2)}`);
    const width = svgContainer.clientWidth;
    const height = svgContainer.clientHeight;

    // without this, each invocation will append another svg nodes resulting in multiple plots.
    // TODO: need to let React do the rendering so this doesn't happen.
    svgContainer.childNodes.forEach(n => n.remove());

    const svg = d3.select(svgContainer)
        .append('svg')
        .attr('id', options.plotId)
        .attr('width', width)
        .attr('height', height);

    svg.append('g')
        .attr('class', 'plots');

    const scale = d3.scaleLinear()
        .domain([plotData.min, plotData.max])
        .range([height - options.margins.top - options.margins.bottom, options.margins.top]);

    const band = d3.scaleBand()
        .domain(plotData.bandDomain)
        .range([options.margins.left, width - options.margins.left - options.margins.right])
        .paddingInner(options.minimalStyle ? 0 : 0.3)
        .paddingOuter(options.minimalStyle ? 2 : 0.2);

    svg.select('g.plots')
        .append('g')
        .attr('transform', `translate(${options.margins.left},${options.margins.top})`)
        .call(d3.axisLeft(scale));

    svg.select('g.plots')
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - options.margins.left)
        .attr('x', 0 - (height / 2))
        .attr('dy', '1em')
        .style('text-anchor', 'middle')
        .text('Log 2 Intensity');

    svg.select('g.plots')
        .append('g')
        .attr('transform', `translate(0,${height - options.margins.top - options.margins.bottom})`)
        .call(d3.axisBottom(band));

    svg.select('g.plots').append('text')
        .attr('transform',
              'translate(' + width/2 + ' ,'
                           + (height - 10) + ')')
        .text('Channel');

    const plot = boxplot()
        .scale(scale)
        .jitter(options.jitter)
        .opacity(options.opacity)
        .showInnerDots(options.showInnerDots)
        .symbol(options.useSymbolTick ? boxplotSymbolTick : boxplotSymbolDot)
        .bandwidth(band.bandwidth())
        .boxwidth(options.minimalStyle ? 6 : band.bandwidth())
        .vertical(true)
        .key((d: number) => d);

    svg.select('g.plots')
        .attr('transform', 'translate(' + [options.margins.left, options.margins.top] + ')')
        .selectAll('.plot')
        .data(plotData.stats)
        .join('g')
        .attr('class', 'plot')
        .transition()
        .attr('transform', (v, i) => `translate(${[band(plotData.bandDomain[i]), 0]})`)
        .attr('color', (v, i) => '#000')
        .call(plot as any);
}

export default function BoxPlot(plotData: any) {
    const svgContainer = useRef<HTMLElement>(null);
    const [plotReady, setPlotReady] = useState<boolean>(false);

    useEffect(() => {
        if (plotData) {
            if (plotData && svgContainer.current) {
                setPlotReady(false);
                createD3BoxPlot(plotData.plotData.boxPlot, svgContainer.current);
                setPlotReady(true);
            }
        }
    }, [plotData]);

    if (!plotData) {
        return null;
    } else {
        return (
        // component props and styles below will center box plot as screen gets larger
        // and provide horizontal scroll bar as screen gets smaller
        // props/styles would change if you change box plot to be responsive
        <Box sx={{ overflowX: 'auto' }}>
            <Typography variant="h6" align="center">
                {plotReady ? 'Channel Intensities' : 'Preparing Channel Intensities plot...'}
            </Typography>
            <Box ref={svgContainer as any} width="100%" minHeight={400}
                sx={theme => ({
                    [theme.breakpoints.up('lg')]: { display: 'flex'},
                    '& > svg': { mx: 'auto'}
                })}
            />
        </Box>
        );
    }
}
