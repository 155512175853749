import {ReactElement, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions';
import useMounted from '../../../../../../util/useMounted';
import {PublishUiDataCy} from "./PublishUi.cy";
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText'
import menuServices from '../../../../../../services/menuServices';
import {OptionsObject, useSnackbar} from 'notistack';

interface PublishUIProps {
    publishingCallback: (status: string) => void,
    experimentType: ExperimentType,
    currentExperiment: ExperimentStatusRecord,
}

const PublishUi = ({currentExperiment, experimentType, publishingCallback}: PublishUIProps): ReactElement => {
 
    const isMounted = useMounted()
    const [localStatus, setLocalStatus] = useState<string>(currentExperiment.status);
    const [confirm, setConfirm] = useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        const updateLocalStatus = () => {
            isMounted() && setLocalStatus(currentExperiment.status);
        }
        updateLocalStatus();
    }, [currentExperiment, isMounted]);

    const cancel = () => {
        setConfirm(false)
    }

    const doPublishConfirm = () => {
        setConfirm(true)
    }

    const doPublishCallback = async () => {
        publishingCallback('PUBLISHING');
        setLocalStatus('PUBLISHING');
        setConfirm(false)
        const result: any = await menuServices.publish(currentExperiment.experiment, experimentType)
        if (result.status !== 'OK') {
            publishingCallback(result.status)
            const errorOptions: OptionsObject = {
                variant: 'error',
                persist: true
            };
            if (result.message) {
                enqueueSnackbar(result.message, errorOptions);
            } else {
                enqueueSnackbar(`Unable to publish experiment with status ${result.status}`, errorOptions);
            }
            setLocalStatus(result.status)
        }
    }
    // @ts-ignore
    const publishable: boolean = experimentType.publish && (localStatus === 'PROCESSED' || localStatus === 'PUBLISHING');
    if (publishable) {
        if (localStatus === 'PROCESSED') {
            return (
                <>
                    <Button data-cy={PublishUiDataCy.publish} size="small" variant="contained"
                        onClick={doPublishConfirm}>Publish</Button>
                    <Dialog open={confirm}
                        onClose={cancel}
                        PaperProps={{
                            square: true,
                            elevation: 0,
                            sx: {border: '2px solid black'}
                        }}
                        aria-labelledby="publish-dialog-title"
                        aria-describedby="publish-dialog-desc"
                    >
                        <DialogTitle id="publish-dialog-title">
                            {`Publish ${currentExperiment.experiment} results?`}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="publish-dialog-desc">
                                Clicking OK will publish experimental results to BaseCamp and alert users
                                of the newly published experimental results.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={cancel}>Cancel</Button>
                            <Button onClick={doPublishCallback} autoFocus>OK</Button>
                        </DialogActions>
                    </Dialog>
                </>
            )
        } else if (localStatus === 'PUBLISHING') {
            return <Button data-cy={PublishUiDataCy.publish} size="small" variant="contained">
                Publishing
                <CircularProgress size={16} sx={{ ml: 1 }} />
            </Button>
        } else {
            return <></>;
        }
    } else {
        return <></>;
    }
}

export default PublishUi;