import {FC, SyntheticEvent} from 'react'
import {Box, FormControl, FormLabel, Grid, Link, TextField} from '@mui/material'
import CommonElements from './CommonElements';
import FileField from '../../FileField';
import designs from '../../../services/designs';

interface FlashExperimentFormProps {
    experiment: string|undefined;
    projectName: string;
    responsible: string;
    collaborator: string;
    handleSummaryChange: (key: string, value: any) => void;
    benchling: string;
    noOfSamples: number;
    experiment_folder?: string;
    peptideBarcode: string;
    bufferVolume: number;
    tecanFile: File | undefined;
    setTecanFile: (file: File | undefined) => void;
    tecanExists: boolean;
    invalid: boolean;
}
const FlashExperimentForm: FC<FlashExperimentFormProps> = ({
    experiment,
    projectName,
    responsible,
    collaborator,
    handleSummaryChange,
    benchling,
    noOfSamples,
    experiment_folder,
    peptideBarcode,
    bufferVolume,
    tecanFile,
    setTecanFile,
    tecanExists,
    invalid,
}) => {
    const handleFileChange = (file: File) => {
        console.log(`handleFileChange with ${file.name}`)
    }

    const getFluorescence = (event: SyntheticEvent) => {
        event.preventDefault()
        designs.doFlashDownload(`flash/deg/w-assay/fluorescence/${experiment}.csv`, 'fluorescence-')
    }

    return (<>
    <Box pb={1.5} position="relative" zIndex={3} bgcolor="background.paper"
        borderBottom={0} borderColor="divider">
        <Grid container rowSpacing={2}>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={4} lg={3}>
                    <FormControl variant="outlined" size="small" required fullWidth>
                       <TextField id="expTypeField" label="Experiment Type" inputProps={{readOnly: true}}
                                  value="FLASH_DEGRADATION" name="type"
                                  variant="outlined" size="small" fullWidth  />
                    </FormControl>
                </Grid>
                <Grid item xs={4} lg={3}>
                    <TextField
                       id="input-benchling"
                       name={"benchling"}
                       label="Benchling"
                       helperText="Optional"
                       variant="outlined" size="small" fullWidth
                       value={benchling}
                       onChange={(event) => {
                           handleSummaryChange("benchling", event.target.value);
                       }}/>
                </Grid>
            </Grid>
            <CommonElements projectName={projectName} responsible={responsible}
                            collaborator={collaborator} handleSummaryChange={handleSummaryChange} isQC={false}
                            commonRequired={{projectName: false, responsible: false, collaborator: false}} />
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={12}>
                    <FormControl variant="outlined" required fullWidth>
                        <TextField id="expFolder" label="Experiment Folder"
                                   sx={{width: '50%'}}
                                   value={experiment_folder} name="experiment_folder"
                                   onChange={(evt) => handleSummaryChange("experiment_folder", evt.target.value)}
                                   variant="outlined" size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" required fullWidth>
                        <TextField id="samplesPerBlock" label="Number of samples per block"
                                   name="noOfSamples" type="number" required
                                   sx={{width: '25%'}}
                                   value={noOfSamples}
                                   onChange={(evt) => handleSummaryChange("noOfSamples", evt.target.value)}
                                   variant="outlined" size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" required fullWidth>
                        <TextField id="bufferVolume" label="Buffer Volume in &#181;L"
                                   sx={{width: '25%'}}
                                   value={bufferVolume} name="bufferVolume" required
                                   onChange={(evt) => handleSummaryChange('bufferVolume', evt.target.value)}
                                   variant="outlined" size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" required fullWidth>
                        <TextField id="peptideBarcode" label="Peptide barcode"
                                   sx={{width: '25%'}}
                                   value={peptideBarcode} name="peptideBarcode" required
                                   onChange={(evt) => handleSummaryChange('peptideBarcode', evt.target.value)}
                                   variant="outlined" size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth component="fieldset" id="tecanFile" sx={{ mt: 0}}>
                        <FormLabel component="legend">Fluorescence measurement (Tecan output) as CSV</FormLabel>
                        {tecanExists && <Link onClick={getFluorescence}>{`fluorescence-${experiment}.csv`}</Link>}
                        <Box ml={1} mr={1} mt={0} width={'calc(100% - 16px)'}>
                            <FileField id="tecanFileField"
                                       label={tecanExists ?"Replace current Tecan Output" : "Tecan Output"}
                                       validate={invalid && !experiment}
                                       file={tecanFile} setFile={(file) => setTecanFile(file)}
                                       acceptedFiles={['.csv']}
                                       onChange={handleFileChange} />
                        </Box>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>

    </Box>
    </>)
}

export default FlashExperimentForm