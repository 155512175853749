export enum Instruments {
    unassigned = 'unassigned',
    all = 'All',
    eclipse001 = 'Eclipse-001',
    eclipse002 = 'Eclipse-002',
    eclipse003 = 'Eclipse-003',
    eclipse004 = 'Eclipse-004',
    eclipse005 = 'Eclipse-005',
    eclipse006 = 'Eclipse-006',
    astral001 = 'Astral-001',
}

export const activeInstruments = [
    Instruments.eclipse001,
    Instruments.eclipse002,
    Instruments.eclipse003,
    Instruments.eclipse004,
    Instruments.eclipse005,
    Instruments.eclipse006,
    Instruments.astral001,
]

export const allInstruments = [
    Instruments.eclipse001,
    Instruments.eclipse002,
    Instruments.eclipse003,
    Instruments.eclipse004,
    Instruments.eclipse005,
    Instruments.eclipse006,
    Instruments.astral001,
]