import React, {ChangeEvent, useEffect, useState} from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Typography, FormControl, IconButton, Popper,
    Autocomplete, autocompleteClasses,
    useMediaQuery, ListSubheader
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {ProjectCompound, ProjectCompoundFilterProps} from 'filters';
import {VariableSizeList, ListChildComponentProps} from 'react-window';
import { StyledFilterAutoCompleteInput, StyledFilterLabel } from '../StyledComponents';

export const PROJECT_COMPOUND_DEFAULT_OPTION: ProjectCompound = {name : 'All', type: ''};

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
    const {data, index, style} = props
    const dataSet = data[index]
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING
    }

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        )
    }
    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1]}
        </Typography>
    )
}
const OuterElementContext = React.createContext({})

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const {children, ...other} = props;
        const itemData: React.ReactElement[] = [];
        (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[]}) => {
            itemData.push(item);
            itemData.push(...(item.children || []))
        })

        const theme = useTheme()
        // @ts-ignore
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true,
        })
        const itemCount = itemData.length
        const itemSize = smUp ? 36 : 48

        const getChildSize = (child: React.ReactChild) => {
            if (child.hasOwnProperty('group')) {
                return 48
            }
            return itemSize
        }
        // TODO finish https://mui.com/material-ui/react-autocomplete/#virtualization
        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
        }
        const gridRef = useResetCache(itemCount)

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index: number) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}>
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        )
    }
)

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
})

export default function ProjectCompoundFilter({
    value,
    projects,
    cddCompounds,
    onFilterChangeCallback}: ProjectCompoundFilterProps) {
    const allOptions = [PROJECT_COMPOUND_DEFAULT_OPTION.name, ...projects, ...cddCompounds]
    const [autoValue, setAutoValue] = useState<string | null>(value.name);
    const [inputValue, setInputValue] = useState<string>(PROJECT_COMPOUND_DEFAULT_OPTION.name);

    const doInputChange = (e: ChangeEvent<any>, newInputValue: string) => {
        setInputValue(newInputValue)
        if (projects.find(element => element === newInputValue)) {
            onFilterChangeCallback({name: newInputValue, type: 'project'});
        } else if (cddCompounds.find(element => element === newInputValue)) {
            onFilterChangeCallback({name: newInputValue, type: 'compound'});
        } else if (newInputValue === PROJECT_COMPOUND_DEFAULT_OPTION.name) {
            onFilterChangeCallback(PROJECT_COMPOUND_DEFAULT_OPTION)
        }
    }

    const doValueChange = (e: ChangeEvent<any>, newValue: string | null) => {
        // console.log(`The newValue is ${newValue}`)
        setAutoValue(newValue)
    }

    useEffect(() => {
        if (value.name === PROJECT_COMPOUND_DEFAULT_OPTION.name) {
            setAutoValue(PROJECT_COMPOUND_DEFAULT_OPTION.name)
        }
    }, [value])

    return (
        <Autocomplete
            id="project-compound-filter"
            sx={{ width: 160 }}
            value={autoValue}
            onChange={doValueChange}
            inputValue={inputValue}
            onInputChange={doInputChange}
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={allOptions}
            size="small" fullWidth openOnFocus
            renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
            renderGroup={(params) => params as unknown as React.ReactNode}
            renderInput={(params) => (
                <FormControl ref={params.InputProps.ref}
                    className="MuiTextField-root"
                    variant="filled" size="small" fullWidth
                >
                    <StyledFilterLabel id="project-compound-filter-label"
                        htmlFor="project-compound-filter" title="Project or Compound" 
                    >
                        Project or Compound
                    </StyledFilterLabel>
                    <StyledFilterAutoCompleteInput
                        inputProps={params.inputProps}
                        componentsProps={{
                            root: {
                                className: 'MuiFilledInput-root MuiFilledInput-underline MuiAutocomplete-inputRoot'
                            },
                            input: {
                                className: 'MuiFilledInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused'
                            }
                        }} 
                        endAdornment={
                            <div className="MuiAutocomplete-endAdornment">
                                <IconButton className="MuiAutocomplete-clearIndicator"
                                    aria-label="Clear" title="Clear"
                                    onClick={() => setInputValue('')}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                                <IconButton className="MuiAutocomplete-popupIndicator">
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </div>
                        }
                    />
                </FormControl>
            )}
        />
    )
}
