import {useContext, useEffect, useState} from 'react';
import {
    CheckBoxOutlineBlank as UncheckedIcon,
    CheckBox as CheckedIcon
} from '@mui/icons-material';
import {
    Alert, AlertTitle, Box, Button, Typography
} from '@mui/material';
import { StyledListBox } from '../../StyledComponents';
import {ReferenceContext} from '../../../../../store/ReferenceContext';

const sxAlert = {
    borderRadius: 0,
    '&.MuiAlert-root': { flexWrap: 'wrap'},
    '& .MuiAlert-action': { width: '100%', mb: 2, ml: 2}
} as const;

interface FlashXcaliburAlterProps {
    experiment: any
    design: any
    sampleResults: any
    saveAcknowledgement: (ackUser: string) => void
    tabValue: number
}

export default function FlashXcaliburAlert({experiment,
                                               design,
                                               sampleResults,
                                               saveAcknowledgement,
                                               tabValue}: FlashXcaliburAlterProps) {
    
    const [warningAcknowledged, setWarningAcknowledged] = useState<boolean>(!!design?.flashWarningsAcknowledged);
    const referenceContext = useContext<References>(ReferenceContext)

    useEffect(() => {
        setWarningAcknowledged(!!design?.flashWarningsAcknowledged)
    }, [design])
    
    const handleWarningAcknowledged = () => {
        let ackUser = ''
        if (!warningAcknowledged) {
            ackUser = referenceContext.userName!
            setWarningAcknowledged(true)
        } else {
            setWarningAcknowledged(false)
        }

        saveAcknowledgement(ackUser)
    };

    if (!sampleResults) {
        if (experiment.status === 'PLANNED') {
            return (
                <Alert severity="error">
                    <AlertTitle>Sample List Preparation Failed</AlertTitle>
                    <Typography variant="body1" gutterBottom>
                        You will need to click the Edit Experiment button and save the experiment design in order to
                        attempt sample list preparation again. You should see the a successful save of the input
                        parameters. Please wait for up to an additional 30 seconds for a report on sample list
                        preparation.
                    </Typography>
                </Alert>
            )
        } else {
            return null
        }
    } else if (!sampleResults.experiment_properties.warnings) {
        return (
            <Alert severity="success">
                <AlertTitle>Sample List Preparation Success</AlertTitle>
                <Typography variant="body1" gutterBottom>
                    There were no errors or warnings found during sample list preparation.
                </Typography>
            </Alert>
        )
    }
    const injectionVolumeWarnings = sampleResults.experiment_properties.warnings.injection_volume_warnings
        ? sampleResults.experiment_properties.warnings.injection_volume_warnings.length + ' warnings' : 'none'
    const outOfRangeWarnings = sampleResults.experiment_properties.warnings.out_of_range_warnings
        ? sampleResults.experiment_properties.warnings.out_of_range_warnings.length + ' warnings' : 'none'
    const missingWells = sampleResults.experiment_properties.warnings.missing_well_warnings
        ? sampleResults.experiment_properties.warnings.missing_well_warnings.length + ' warnings' : 'none'
    const ack = design?.flashWarningsAcknowledged
        ? design?.flashWarningsAcknowledged + ' acknowledged the warnings and errors and adapted the Xcalibur sample list accordingly.'
        : 'I acknowledge the warnings and errors and will adapt the Xcalibur sample list in PXP accordingly.'
    return (
        <Alert severity="warning"
            action={
                <Button color="inherit" variant="outlined"
                    startIcon={warningAcknowledged? <CheckedIcon /> : <UncheckedIcon />}
                    onClick={handleWarningAcknowledged}
                    sx={{ textTransform: 'none' }}
                >
                    {ack}
                </Button>
            }
            sx={sxAlert}
        >
            <AlertTitle>Inspect warnings in the Plots & Stats tab</AlertTitle>
            {tabValue === 0 && <>
            <StyledListBox component="ul">
                <Box component="li" display="flex" align-items="center" width="100%">
                    <Typography component="strong" variant="body2" mr={1}>Missing well(s):{' '}</Typography>
                    <Typography variant="body2">{missingWells}</Typography>
                </Box>
                <Box component="li" display="flex" align-items="center" width="100%">
                    <Typography component="strong" variant="body2" mr={1}>Out-of-range:{' '}</Typography>
                    <Typography variant="body2">{outOfRangeWarnings}</Typography>
                </Box>
                <Box component="li" display="flex" align-items="center" width="100%">
                    <Typography component="strong" variant="body2" mr={1}>Injection volume:{' '}</Typography>
                    <Typography variant="body2">{injectionVolumeWarnings}</Typography>
                </Box>
            </StyledListBox>
            <Typography variant="body2" fontWeight={'fontWeightMedium'} display="block">
                You must acknowledge this warning to edit or download the Xcalibur sample list.
            </Typography>
            </>}
        </Alert>
    );
}