import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const StyledPlotTipPaper = styled(Paper)(({ theme }) => ({
    width: 'fit-content',
    maxWidth: 300,
    padding: theme.spacing(0.5, 1),
    borderRadius: 2,
    alignItems: 'center'
}));

export default StyledPlotTipPaper;