import {useEffect} from 'react'
import html2canvas from 'html2canvas'
import {jsPDF} from "jspdf";
import {Button} from "@mui/material";
interface ImageExporterProps {
    fileName: string;
    children: JSX.Element[];
    exportEnabled?: boolean
    exportTrigger?: any;
    cleanUpFunction?: () => void;
}

/**
 *
 * ImageExporter is a component which can be wrapped around any other rendered component in order to produce a PDF of
 * its child component(s). It relies on using ids with the suffix '-toPdf' being in its child components. It will then
 * find them automatically and produce a PDF page for each.
 *
 * @param fileName
 * @param children
 * @param exportEnabled Optional. If included, controls the button used to produce the PDF.
 * @param exportTrigger Optional. If included, disables the button and relies on the parent to trigger PDF generation.
 * @param cleanUpFunction Optional. Used for execution after export.
 * @constructor
 */
const ImageExporter = ({
    fileName,
    children,
    exportEnabled = true,
    exportTrigger,
    cleanUpFunction = () => {}
}: ImageExporterProps): JSX.Element => {
    // This is a "tricky" use effect that lets the control over our function technically live in the parent components
    // Since it sets the value of the exportTrigger on render, we need to include fileName for it to be changeable
    useEffect(() => {
        if (exportTrigger) exportTrigger.current = exportPdf;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exportTrigger, fileName]);

    const exportPdf = () => {
        const allElementsToCapture = Array.from(document.querySelectorAll("[id$='toPdf']"));
        const pdf = new jsPDF('p', 'pt');

        Promise.all(
            allElementsToCapture.map((element) => html2canvas(element as HTMLElement))
        ).then((canvasArray) => {
            let pagesAmount = 0;
            let previousElementsHeight = 0;
            let currentPageWidth = 0;
            let currentPageHeight = 0;
            const leftMargin = 25;
            const topMargin = 25;

            canvasArray.forEach((canvas) => {
                const htmlWidth = canvas.width;
                const htmlHeight = canvas.height;
                const pdfWidth = htmlWidth + leftMargin * 2;
                const pdfHeight = pdfWidth * 1.5 + topMargin * 2;
                const dataURL = canvas.toDataURL('image/png', 1.0);

                /**
                 * Create a new page if more than 1/4 of the page is already filled
                 * or if current page width less then next element width
                 */
                const createNewPage = previousElementsHeight > (1 / 4) * currentPageHeight || currentPageWidth < pdfWidth;

                if (createNewPage || pagesAmount === 0) {
                    pdf.addPage([pdfWidth, pdfHeight]);
                    currentPageWidth = pdfWidth;
                    currentPageHeight = pdfHeight;

                    pdf.addImage(dataURL, 'JPG', leftMargin, 2 * topMargin, htmlWidth, htmlHeight);
                    pagesAmount++;
                    previousElementsHeight = 0;
                } else {
                    pdf.addImage(dataURL, 'JPG', leftMargin, previousElementsHeight + 2 * topMargin, htmlWidth, htmlHeight);
                }

                previousElementsHeight += htmlHeight + 2 * topMargin;
            });

            /* We use dynamic page sizing before each image to give us more control of width x height.
             * This means we create an extra page that needs to be removed
             */
            pdf.deletePage(1);
            pdf.save(`${fileName}.pdf`);
            cleanUpFunction();
        }).catch((reason: any) => {
            console.error('EXPORT ERROR', reason)
        });
    };

    return (
        <>
            {!exportTrigger && (
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={exportPdf}
                    disabled={!exportEnabled}
                >
                    Export PDF
                </Button>
            )}
            <div id={`capture-${fileName}`}>{children}</div>
        </>
    );
};

export default ImageExporter;