const createRange = (startDate: Date):Date[] => {
    const twoWeeks = new Array(14)
    twoWeeks[0] = startDate;
    const time = startDate.getTime();
    const dayOfMonth = startDate.getDate();
    for(let i = 1; i < 14; i++) {
        const newDate = new Date(time)
        newDate.setDate(dayOfMonth + i)
        twoWeeks[i] = newDate
    }
    return twoWeeks
}

const dateRangeHeader = (start: Date, end: Date): string => {
    const calMonthStart = start.toLocaleString('default', { month: 'short' })
    const calDayStart = start.getDate()
    const calMonthEnd = end.toLocaleString('default', { month: 'short' })
    const calYear = end.getFullYear()
    const calDayEnd = end.getDate()

    const month = calMonthStart === calMonthEnd ? '' : calMonthEnd + ' '
    return calMonthStart.concat(' ', calDayStart.toString(), ' - ',
        month, calDayEnd.toString(), ' ', calYear.toString());
}

export {createRange, dateRangeHeader}