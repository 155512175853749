import {ChangeEvent, FC, useState} from 'react';
import {CellLineFilterProps} from 'filters';
import {Autocomplete, FormControl, IconButton} from '@mui/material';
import {StyledFilterAutoCompleteInput, StyledFilterLabel} from '../StyledComponents';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CellLineFilter: FC<CellLineFilterProps> = ({selected, options, cellLineCallback}) => {

    const [inputValue, setInputValue] = useState<string>('');

    const doInputChange = (e: ChangeEvent<any>, newInputValue: string) => {
        setInputValue(newInputValue)
    }

    return(
        <Autocomplete
            sx={{ width: 340 }}
            value={selected}
            onInputChange={doInputChange}
            onChange={(event, newValue) => cellLineCallback(newValue)}
            inputValue={inputValue}
            options={options}
            isOptionEqualToValue={(option: any, value: any) => {
                return option.id === value.id
            }}
            size="small" fullWidth openOnFocus
            renderInput={(params) => (
                <FormControl
                    key={`id-${params.id}`}
                    ref={params.InputProps.ref}
                    className="MuiTextField-root"
                    variant="filled" size="small" fullWidth
                >
                    <StyledFilterLabel id="CellLineLabel" htmlFor="cell-type-filter" title="Cell Line">
                        Cell Line
                    </StyledFilterLabel>
                    <StyledFilterAutoCompleteInput style={{fontSize: '0.9em'}}
                        inputProps={params.inputProps}
                        componentsProps={{
                            root: {
                                className: 'MuiFilledInput-root MuiFilledInput-underline MuiAutocomplete-inputRoot'
                            },
                            input: {
                                className: 'MuiFilledInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused'
                            }
                        }}
                        endAdornment={
                            <div className="MuiAutocomplete-endAdornment">
                                <IconButton className="MuiAutocomplete-clearIndicator"
                                            aria-label="Clear" title="Clear"
                                            onClick={() => {
                                                cellLineCallback(options[0])
                                            }}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                                <IconButton className="MuiAutocomplete-popupIndicator">
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </div>
                        }
                    />
                </FormControl>
            )}
        />

    )
}

export default CellLineFilter

