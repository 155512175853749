import { ChangeEvent, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormControl, IconButton, Autocomplete } from '@mui/material';
import {KnownOptionsFilterProps} from 'filters';
import { StyledFilterAutoCompleteInput, StyledFilterLabel } from '../StyledComponents';

export default function KnownOptionsFilter({id, label, selectedOption, knownOptions,
                                               onSelectionChangeCallback}: KnownOptionsFilterProps) {

    const [inputValue, setInputValue] = useState('');

    const doInputChange = (e: ChangeEvent<any>, newInputValue: string) => {
        // console.log('doInputChange....')
        setInputValue(newInputValue)
        if (knownOptions.find(element => element === newInputValue)) {
            onSelectionChangeCallback(newInputValue);
        }
    }

    return (
        <Autocomplete
            id={id}
            sx={{ width: 160 }}
            value={selectedOption}
            onInputChange={doInputChange}
            inputValue={inputValue}
            options={knownOptions}
            size="small" fullWidth openOnFocus
            renderInput={(params) => (
                <FormControl
                    key={`id-${params.id}`}
                    ref={params.InputProps.ref}
                    className="MuiTextField-root"
                    variant="filled" size="small" fullWidth
                >
                    <StyledFilterLabel id={id + '-label'}
                        htmlFor={id} title={label} 
                    >
                        {label}
                    </StyledFilterLabel>
                    <StyledFilterAutoCompleteInput
                        inputProps={params.inputProps}
                        componentsProps={{
                            root: {
                                className: 'MuiFilledInput-root MuiFilledInput-underline MuiAutocomplete-inputRoot'
                            },
                            input: {
                                className: 'MuiFilledInput-input MuiAutocomplete-input MuiAutocomplete-inputFocused'
                            }
                        }} 
                        endAdornment={
                            <div className="MuiAutocomplete-endAdornment">
                                <IconButton className="MuiAutocomplete-clearIndicator"
                                    aria-label="Clear" title="Clear"
                                    onClick={() => setInputValue('')}
                                >
                                    <ClearIcon fontSize="small" />
                                </IconButton>
                                <IconButton className="MuiAutocomplete-popupIndicator">
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </div>
                        }
                    />
                </FormControl>
            )}
        />
    );
}
