import {useEffect, useLayoutEffect, useState, useRef, RefObject} from 'react';
import {ResponsiveBar} from '@nivo/bar';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import {IHistogramConfig, IHistogramProps, INumberBar, HistogramData} from 'histogram';
import {processHistogram} from './HistogramUtil';
import * as _ from 'lodash';
import {NamedStatsData} from 'experiment';
import useMounted from "../../util/useMounted";

export default function DeltaMHistogramPanel({experimentName, data, loading}: NamedStatsData) {

    const isMounted = useMounted();
    const ref: RefObject<HTMLDivElement> | null = useRef(null);
    const [histogram, setHistogram] = useState<IHistogramProps | null>(null);
    const [graphHeight, setGraphHeight] = useState(window.innerWidth / 3);

    useLayoutEffect(() => {

        const handleResize = _.debounce(() => {
            setGraphHeight(window.innerWidth / 3);
        }, 250);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const getWarning = (data: any): string => {
            let warning = '';
            if (data.binCounts.length === 0) {
                warning = 'No acceptable PSMs in run';
            }
            return warning;
        };

        const processData =  (experiment: string, data: HistogramData) => {
            try {
                if (data) {
                    const histogramConfig: IHistogramConfig = {min: data.min, max: data.max};
                    const bars: INumberBar[] = processHistogram(data, histogramConfig);
                    const histogram: IHistogramProps = {
                        experiment: experiment,
                        bars: bars,
                        binSize: data.binWidth,
                        warning: getWarning(data)
                    };
                    isMounted() && setHistogram(histogram);
                } else {
                    isMounted() && setHistogram(null);
                }
            } catch (error) {
                console.error('DeltaMHistogramPanel error', error);
                isMounted() && setHistogram(null);
            }
        };

        processData(experimentName, data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [experimentName, data]);

    /**
     * Determines if this tick represent a whole number, more or less.
     * @param n
     */
    const testTickValue = (n: number) => {
        return (Math.floor(n) + histogram!.binSize > n);
    };

    /**
     * Only renders whole number ticks, as there are far too many to show
     * one tick per bar.
     * @param tick
     * @constructor
     */
    const CustomTick = (tick: any) => {
        if (testTickValue(tick.value)) {
            return <g className="tick"  opacity="1" transform={`translate(${tick.x},0)`}>
                <line stroke="currentColor" y2="6"/>
                <text fill="currentColor" y="9" dy="0.71em">{Math.floor(tick.value)}</text>
            </g>
        }
        return null;
    };

    if (histogram != null) {
        return <>
            <Box ref={ref} height={graphHeight}>
                {histogram.warning ? <Alert severity="warning">{histogram.warning}</Alert> : null }
                <ResponsiveBar
                    animate={false}
                    data={histogram.bars}
                    keys={['count']}
                    indexBy="x"
                    margin={{top: 10, bottom:50, left: 55, right: 10}}
                    padding={0}
                    colors={{scheme: 'category10'}}
                    axisTop={null}
                    axisRight={null}
                    axisLeft={{
                        legend: 'DeltaM Count',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    axisBottom={{
                        legend: 'DeltaM (binned)',
                        legendOffset: 40,
                        legendPosition: 'middle',
                        // @ts-ignore
                        renderTick: CustomTick
                    }}
                    labelSkipWidth={25}
                    labelSkipHeight={12}
                />
            </Box>
        </>
    }
    if(loading) {
        return <StyledCenteringBox><CircularProgress size={70}/></StyledCenteringBox>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}