import {useState, useEffect} from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography
} from '@mui/material';
import { StyledListBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import {ExperimentDownload, IFI_Channel, IFI_STATS, SignedUrl} from '../../API';
import {DesignPanelDataCy} from '../Dashboard/ExperimentsPanel/ExperimentDetailsPanel/DesignPanel/DesignPanel.cy';
import details from '../../services/details';

interface YeastTkoStatsProps {
    experimentName: string;
    isReference: boolean;
}

export default function YeastTkoStats({experimentName, isReference}: YeastTkoStatsProps) {

    const [ifiStatistics, setIfiStatistics] = useState<IFI_Channel[]>([])
    const [imputedValue, setImputedValue] = useState<number|null>(null)
    const [images, setImages] = useState<SignedUrl[]>([])
    
    const [showReferenceContent] = useState<boolean>(isReference);

    useEffect(() => {
        const fetchData = async (experiment: string) => {
            const data: IFI_STATS = await details.fetchYeastTkoStats(experimentName); //response.data.getYeastTkoStats
            if (data.imputedValue) {
                fetchPreparedPlots(experiment)
                setImputedValue(data.imputedValue!)
                if (data.ifiStatistics && data.ifiStatistics.length > 0) {
                    setIfiStatistics(data.ifiStatistics as any as IFI_Channel[])
                }
            }
        }
        const fetchPreparedPlots = async (experiment: string) => {
            const svgPlots = [
                `ytko/${experiment}_IFI_BoxPlot.svg`,
                `ytko/${experiment}_RT_vs_131C.svg`,
                `ytko/${experiment}_RT_vs_131N.svg`,
                `ytko/${experiment}_RT_vs_InjectTime.svg`
            ]
            const data = await details.fetchExperimentDownloadUrl(svgPlots, ExperimentDownload.QC);  //response.data.getExperimentDownloadUrl
            // console.log(`The IMAGE data: ${JSON.stringify(data)}`)
            if (data) {
                setImages(data)
            }
        }
        fetchData(experimentName)
    }, [experimentName])

    const ifiHeaderRow = <TableRow>
        <TableCell align="left">TMT Channel</TableCell>
        <TableCell align="left">Num PSMs</TableCell>
        <TableCell align="left">Num Imputed Vals</TableCell>
        <TableCell align="left">Median</TableCell>
        <TableCell align="left">Mean</TableCell>
        <TableCell align="left">Outliers</TableCell>
    </TableRow>

    const ifiRowContent = ifiStatistics.map((channel: IFI_Channel) => (
        <TableRow key={channel.TMT_channel}>
            <TableCell align="left">{channel.TMT_channel}</TableCell>
            <TableCell align="left">{channel.num_PSMs}</TableCell>
            <TableCell align="left">{channel.num_imputed_values}</TableCell>
            <TableCell align="right">{channel.median}</TableCell>
            <TableCell align="right">{channel.mean}</TableCell>
            <TableCell align="left">{channel.num_outliers}</TableCell>
        </TableRow>
    ))
    return <>
        {showReferenceContent 
            ? <Box display="flex" flexDirection="row" alignItems="baseline">
                <Typography variant="overline" component="h5">Reference</Typography>
                <Typography variant="caption" sx={{ml:1}}>{experimentName}</Typography>
              </Box>
            : <Typography variant="overline" component="h5" id="Title-toPdf">This experiment</Typography>
        }
        <StyledListBox component="dl" mb={2} mt={1} ml={2}
                       id={showReferenceContent ? `imputed${experimentName}` : `imputed${experimentName}-toPdf`}>
            <Typography component="dt" fontWeight="medium">Imputed Value:</Typography>
            <Typography component="dd">{imputedValue ? imputedValue : 'Unavailable'}</Typography>
        </StyledListBox>
        {imputedValue ?
        <TableContainer sx={{ mb: 2 }}
                        id={showReferenceContent ? `ifiStats${experimentName}` : `ifiStats${experimentName}-toPdf`}>
            <Table data-cy={DesignPanelDataCy.designTable} size="small" aria-label="ifi stats table"
                sx={{ width: 'auto', overflowX: 'auto'}}
            >
                <TableHead>{ifiHeaderRow}</TableHead>
                <TableBody>{ifiRowContent}</TableBody>
            </Table>
        </TableContainer>
        : null}
        {/* If we fetch these images and try to put them in the export, it just fails as it is too big */}
        {images.map((svg: SignedUrl) => (
        <Box key={svg.key} mb={1} border={1} borderColor="divider" borderRadius={1}
            sx={theme => ({
                '& > img': {
                    [theme.breakpoints.down('lg')]: { width: '100%', maxWidth: 500},
                    [theme.breakpoints.up('lg')]: { width: '100%', maxWidth: 600},
                    [theme.breakpoints.between('xl', theme.breakpoints.values.fhd)]: { width: '100%', maxWidth: 700},
                    [theme.breakpoints.up(theme.breakpoints.values.fhd)]: { width: '100%', maxWidth: 900}
                }
            })}
        >
            <img src={svg.url} alt={svg.key} />
        </Box>
        ))}
    </>
}

