import Button from "@mui/material/Button";
import {ExportPdfUiDataCy} from "./ExportPdfUi.cy";
import {FC} from 'react';

interface ExportPdfUiProps {
    experimentType: ExperimentType,
    exportPdfCallback: () => void,
    setExperimentMessage: (message: string, callback?: any) => void;
}

const ExportPdfUi: FC<ExportPdfUiProps> = ({experimentType, exportPdfCallback, setExperimentMessage}) => {

    const doPublishCallback = () => {
        setExperimentMessage('Exporting PDF. This process may take some time...')
        // You can put the exportPdfCallback within a timeout to make the message immediately, but then the
        // normalized channel intensities plot is empty. Not worth further investigation.
        exportPdfCallback()
    }

    if (experimentType.value === 'GENERAL') {
        return null
    }

    return <Button data-cy={ExportPdfUiDataCy.export} size="small" variant="contained"
                   onClick={doPublishCallback} sx={{ ml: 1 }}>
        {"Export PDF"}
    </Button>
}

export default ExportPdfUi;