import {memo, RefObject, useContext, useEffect, useRef, useState} from 'react';
import { Box, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import OverlappingHistogramsComponent from './OverlappingHistogramsComponent';
import {HistogramData, IOverlapHistogramData, IOverlapProps, ITypedBin} from 'histogram';
import {RefAwareStatsData} from 'experiment';
import {computeHistogramMedian} from './HistogramUtil';
import useMounted from "../../util/useMounted";
import {DisplayContext} from '../../store/DisplayContext';

const IntensitiesPanel = ({data, showsRef, experimentName}: RefAwareStatsData) => {
    const isMounted = useMounted();
    const detailsDisplay = useContext<DetailsDisplay>(DisplayContext);
    const [plotData, setPlotData] = useState<IOverlapHistogramData | null>(null);
    const [width, setWidth] = useState(showsRef ? detailsDisplay.wideView ? 800 : 580 : 1000);
    const heightDivisor = showsRef ? 2 : 4
    const refDiv: RefObject<HTMLDivElement> | null = useRef(null);
    const containerId = "intensities-container-" + experimentName
    const [vis, setVis] = useState<OverlappingHistogramsComponent | null>(null);

    useEffect(() => {
        let resizeTimer: number;
        const handleResize = () => {
            clearTimeout(resizeTimer);
            resizeTimer = window.setTimeout(function() {
                setWidth(refDiv!.current!.offsetWidth);
            }, 300);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setWidth(showsRef ? detailsDisplay.wideView ? 800 : 580 : 1000)
    }, [showsRef, detailsDisplay.wideView, experimentName])

    useEffect(() => {
        const newHeight = width / heightDivisor;
        vis && vis.resize(width, newHeight)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, heightDivisor, experimentName]);

    useEffect(() =>  {
        processData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initVisualization, [plotData]);

    const processData = (data: any) => {
        try {
            if (data) {
                const typedBins: ITypedBin[] = [];
                const pIntensities: HistogramData = data.psmPrecursorIntensities;
                pIntensities.binCounts.forEach((count: number, index: number) => {
                    const start = pIntensities.min + (pIntensities.binWidth * index);
                    typedBins.push({
                        x0: start,
                        x1: start + pIntensities.binWidth,
                        length: count,
                        type: 'psm'
                    });
                });
                const psmMedian = computeHistogramMedian(pIntensities);
                const qIntensities = data.qpsmPrecursorIntensities;
                qIntensities.binCounts.forEach((count: number, index: number) => {
                    const start = qIntensities.min + (qIntensities.binWidth * index);
                    typedBins.push({
                        x0: start,
                        x1: start + qIntensities.binWidth,
                        length: count,
                        type: 'qpsm'
                    });
                });
                const plot: IOverlapHistogramData = {
                    min: pIntensities.min,
                    max: pIntensities.max,
                    bins: typedBins,
                    median: psmMedian
                };
                isMounted() && setPlotData(plot);
            } else {
                isMounted() && setPlotData(null);
            }
        } catch (error) {
            console.warn('IntensitiesPanel error', error);
            isMounted() && setPlotData(null);
        }
    };

    function initVisualization() {
        if (plotData && plotData.bins.length && refDiv && refDiv.current) {
            const d3Props: IOverlapProps = {
                id: 'intensities-' + experimentName,
                data: plotData,
                width,
                height: width / heightDivisor,
                typeColors: {
                    psm: '#ff7f0e',
                    qpsm: '#1f77b4'
                } 
            };
            
            if (vis === null || refDiv.current.children.length === 0) {
                setVis(new OverlappingHistogramsComponent(refDiv.current, d3Props));
            } else {
                vis.updateData(d3Props);
                vis.draw(false);
            }
        }
    }

    if(data != null) {
        return <>
            <Box ref={refDiv} id={containerId} mx={'auto'}>
            </Box>
        </>
    }

    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>

}

function areEqual(prevProps: RefAwareStatsData, nextProps: RefAwareStatsData) {
    return  prevProps.data?.psmPrecursorIntensities.max === nextProps.data.psmPrecursorIntensities.max
     && prevProps.data?.psmPrecursorIntensities.min === nextProps.data.psmPrecursorIntensities.min
     && prevProps.data?.qpsmPrecursorIntensities.max === nextProps.data.qpsmPrecursorIntensities.max
     && prevProps.data?.qpsmPrecursorIntensities.min === nextProps.data.qpsmPrecursorIntensities.min
     && prevProps.showsRef === nextProps.showsRef

}

export default memo(IntensitiesPanel, areEqual)