import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { uiColors } from '../../theme/theme';

const internalComponentHeight = '1.4375em';

const StyledFilterAutoCompleteInput = styled(InputBase)(({ theme }) => ({
    width: '100%',
    color: uiColors.filters.baseColor,
    backgroundColor: uiColors.filters.inputBaseBg,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    display: 'inline-flex',
    alignItems: 'center',
    position: 'relative',
    transition: `background-color 200ms ${theme.transitions.easing.easeOut} 0ms`,
    '& > input': {
        height: internalComponentHeight,
        font: 'inherit',
        color: 'currentColor',
        letterSpacing: 'inherit',
        background: 'none',
        border: 0,
        margin: 0,
        display: 'block',
        boxSizing: 'content-box',
        WebkitTapHighlightColor: 'transparent',
        animationName: 'mui-auto-fill-cancel',
        animationDuration: '10ms',
    },
    '&.Mui-focused': {
        color: uiColors.filters.baseColor,
        backgroundColor: uiColors.filters.action.focus,
        '& > input': { outline: 0},
        '& .MuiAutocomplete-popupIndicator': {
            color: uiColors.filters.baseColor,
            '& svg': { transform: 'rotate(180deg)'}
        }
    },
    '&:hover': {
        '&:not(.Mui-focused)': { backgroundColor: uiColors.filters.action.hover},
        '&:not(.Mui-disabled):before': { borderBottomColor: uiColors.filters.inputActionBorderHover},
        '& .MuiAutocomplete-popupIndicator': { color: uiColors.filters.baseColor}
    },
    '&:before': {
        borderBottom: `1px solid ${uiColors.filters.inputActionBorder}`,
        left: 0,
        bottom: 0,
        content: '""',
        position: 'absolute',
        right: 0,
        transition: `border-bottom-color 200ms ${theme.transitions.easing.easeInOut} 0ms`,
        pointerEvents: 'none',
    },
    '&:after': {
        borderBottom: `2px solid ${uiColors.filters.inputActionBorder}`,
        left: 0,
        bottom: 0,
        content: '""',
        position: 'absolute',
        right: 0,
        transform: 'scaleX(0)',
        transition: `transform 200ms ${theme.transitions.easing.easeOut} 0ms`,
        pointerEvents: 'none',
    }, 
    '& .MuiAutocomplete-endAdornment': {
        position: 'absolute',
        top: 'calc(50% - 14px)',
        '& button': {
            color: uiColors.filters.inputIconColor,
            padding: 2,
            marginRight: -2,
            '&:hover': {
                color: uiColors.filters.baseColor,
                backgroundColor: uiColors.filters.inputIconBgHover
            }
        }
    },
    '& .MuiAutocomplete-clearIndicator': { visibility: 'hidden'}
}));

export default StyledFilterAutoCompleteInput;