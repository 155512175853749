import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import { colors } from '../../theme/theme';

const StyledCalTable = styled(Table)(({ theme }) => ({
    '& .MuiTableCell-root:first-of-type': { paddingLeft: theme.spacing(1)},
    '& .MuiTableRow-head:first-of-type .MuiTableCell-root': { 
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.25),
        borderBottomColor: colors.mtxUISlate[50],
        [theme.breakpoints.down(theme.breakpoints.values.xxl)]: { 
            paddingRight: theme.spacing(0.5),
            '&:not(:first-of-type)': { paddingLeft: theme.spacing(0.5)}
        },
        [theme.breakpoints.up(theme.breakpoints.values.xxl)]: { 
            paddingRight: theme.spacing(0.25),
            '&:not(:first-of-type)': { paddingLeft: theme.spacing(0.25)}
        }
    },
    '& .MuiTableRow-head:last-of-type .MuiTableCell-root': { 
        paddingTop: theme.spacing(0.25),
        paddingBottom: theme.spacing(0.25),
        borderBottomColor: colors.mtxUISlate[100],
        [theme.breakpoints.down(theme.breakpoints.values.xxl)]: {
            paddingRight: theme.spacing(0.5),
            '&:not(:first-of-type)': { paddingLeft: theme.spacing(0.5)}
        },
        [theme.breakpoints.up(theme.breakpoints.values.xxl)]: { 
            paddingRight: theme.spacing(0.25),
            '&:not(:first-of-type)': { paddingLeft: theme.spacing(0.25)}
        }
    },
    '& .MuiTableBody-root .MuiTableCell-root': {
        paddingTop: 0,
        paddingBottom: 0,
        borderBottomColor: colors.mtxUISlate[50],
        [theme.breakpoints.down(theme.breakpoints.values.xxl)]: {
            paddingRight: theme.spacing(0.5),
            '&:not(:first-of-type)': { paddingLeft: theme.spacing(0.5)}
        },
        [theme.breakpoints.up(theme.breakpoints.values.xxl)]: { 
            paddingRight: theme.spacing(0.25),
            '&:not(:first-of-type)': { paddingLeft: theme.spacing(0.25)}
        }
    },
    [theme.breakpoints.up(theme.breakpoints.values.qhd)]: { 
        '& .MuiTableCell-root': { fontSize: '0.9rem'}
    }
}));

export default StyledCalTable;