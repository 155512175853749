import {DateRange, ProjectCompound} from 'filters';

export const DEFAULT_OPTION = 'All'
export const DEFAULT_CELL_LINE = {label: 'All', id: 'All'}
const filterUtils = {

    getNullableValue: (value: string) => {
        return DEFAULT_OPTION === value ? null : value
    },
    getNullableCellLine: (value: any) => {
        return value.id === 'All' ? null : value.id
    },
    getProjectCompound: (value: ProjectCompound) => {
        return value?.type ? value : null
    },
    getDateRange: (startValue: string, endValue: string): DateRange => {
        return {start: startValue, end: endValue};
    }
}
export default filterUtils;