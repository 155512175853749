import { Grid, Theme, useMediaQuery, useTheme} from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

export default function SingleColLayout({children}: BoxProps) {
    
    const theme = useTheme<Theme>();
    const isQHDup = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.qhd));
    
    return (
        <Box mx={isQHDup? 7 : 4}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}