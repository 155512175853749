import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import { colors } from '../../theme/theme';

// assumes 14 calendar columns (width * number of cols)
// if this component used with less/more than 14 cols, then update values below to be conditionally calculated
const minWidthFHDdown = 392; // 28 * 14
const minWidthFHDup = 588; // 42 * 14

const StyledCalTableColGroupHeader = styled(TableCell)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    backgroundColor: colors.mtxUISlate[600],
    [theme.breakpoints.down(theme.breakpoints.values.fhd)]: { 
        minWidth: minWidthFHDdown // maintains consistent width for the calendar columns
    },
    [theme.breakpoints.up(theme.breakpoints.values.fhd)]: { 
        minWidth: minWidthFHDup // maintains consistent width for the calendar columns
    },
    '&:last-of-type': { borderTopRightRadius: theme.shape.borderRadius},
    '&:first-of-type': { borderTopLeftRadius: theme.shape.borderRadius},
    '&:not(:last-of-type)': { borderRight: `2px solid ${colors.mtxUISlate[100]}`},
    '&:not(:first-of-type)': { borderLeft: `2px solid ${colors.mtxUISlate[100]}`},
    '& .MuiIconButton-colorInherit': {
        '&:hover': { backgroundColor: colors.mtxUISlate[800]}
    }
}));

export default StyledCalTableColGroupHeader;