
import {
    Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { AlertDialogDataCy } from './AlertDialog.cy';

type AlertDialogProps = {
    openDialog: boolean;
    handleDialogClose: () => void;
    handlePositiveClick: () => void;
    dialogTitle: string;
    dialogContentText: string;
    dialogContentExtraText?: any;
    positiveButtonText: string;
    negativeButtonText?: string;
}

export default function AlertDialog({
    openDialog, 
    handleDialogClose, 
    handlePositiveClick, 
    dialogTitle,
    dialogContentText,
    dialogContentExtraText,
    positiveButtonText,
    negativeButtonText}: AlertDialogProps) {

    const handleClose = () => {
        handleDialogClose()
    }

    const handlePositive = () => {
        handlePositiveClick()
    }

    return (
        <Dialog
            fullWidth
            maxWidth={'xs'}
            PaperProps={{
                square: true,
                elevation: 0,
                sx: {border: '2px solid black'}
             }}
            open={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" data-cy={AlertDialogDataCy.header}
                sx={{ fontSize: '1.25rem' }}>
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description" data-cy={AlertDialogDataCy.message} 
                sx={{ fontSize: '1rem' }}>
                {dialogContentText}
            </DialogContentText>
            {dialogContentExtraText}
            </DialogContent>
            <DialogActions>
            {negativeButtonText && (
                <Button onClick={handleClose} data-cy={AlertDialogDataCy.cancel}>
                    {negativeButtonText}
                </Button>
            )}
            <Button onClick={handlePositive} data-cy={AlertDialogDataCy.submit}>
                {positiveButtonText}
            </Button>
            </DialogActions>
        </Dialog>
    )
}