import { styled } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';

const StyledChartTipCircleKey = styled(CircleIcon)({
    width: '0.75em',
    height: '0.75em',
    fontSize: '1rem',
    lineHeight: 1.43,
    verticalAlign: '-.125em'
});

export default StyledChartTipCircleKey;