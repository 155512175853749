import { styled } from '@mui/material/styles';
import { alpha, darken } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { APPBAR_HEIGHT } from '../../theme/Constants';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    '--appbar-hover-bgcolor': darken(theme.palette.primary.dark, 0.2),
    '--appbar-selected-color': alpha(theme.palette.common.white, .7),
    '--appbar-selected-bgcolor': alpha(theme.palette.primary.dark, .5),
    '--appbar-selected-border-color': alpha(theme.palette.primary.dark, .7),
    '& .MuiButton-root:hover, & a[class*="navLink"]:not(.active):hover, & .MuiIconButton-root:hover': {
        backgroundColor: 'var(--appbar-hover-bgcolor)'
    },
    '& .navH1Link': {
        padding: 12,
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': { backgroundColor: 'var(--appbar-hover-bgcolor)'}
    },
    // navButtonGroup used for wrapping buttons that perform related actions (e.g. opening modals) rather than link to other pages
    // navLinkGroup used for wrapping NavLinks that belong to a workflow
    '& .navButtonGroup, .navLinkGroup': {
        border: `1px solid ${alpha(theme.palette.primary.light, 0.4)}`,
        borderRadius: theme.shape.borderRadius,
        '& .navLinkGrouped': {
            '&:nth-of-type(1)': { 
                borderTopLeftRadius: 'inherit',
                borderBottomLeftRadius: 'inherit',
            },
            '&:last-of-type': { 
                borderTopRightRadius: 'inherit',
                borderBottomRightRadius: 'inherit',
            },
            '& + .navLinkGrouped': {
                borderLeft: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`
            }
        },
        '& .navButtonGrouped': {
            '&:not(:last-of-type)': { 
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            },
            '&:not(:nth-of-type(1))': { 
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
            '& + .navButtonGrouped': {
                borderLeft: `1px solid ${alpha(theme.palette.primary.light, 0.2)}`
            }
        }
    },
    // below used for when child is a NavLink component from react-router dom, or MUI button
    // requires className="navLink navLinkGrouped" or "navButton navButtonGrouped on the component
    '& .navButtonGrouped, & .navLinkGrouped': {
        height: 30,
        width: 'auto',
        fontSize: '0.723rem', // change to theme.typography.caption.fontSize when PxP's type scale is synced with Basecamp
        padding: theme.spacing(1, 1.5),
        // use below when icon is child of Button
        '& .MuiButton-startIcon': { 
            marginRight: 3,
            '& > *:nth-of-type(1)': { fontSize: '0.8rem',}
        },
        // use below when icon is child of NavLink
        '& > .MuiSvgIcon-root': { fontSize: '0.8rem', marginRight: 3}
    },
    // below used for when child link is the NavLink component from react-router dom, or MUI button that should have same styling
    // requires className="navLink" on the component
    '& .navButton, & .navLink': {
        fontWeight: 500,
        textDecoration: 'none',
        textAlign: 'center',
        lineHeight: 'normal',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:not(.active)': { color: 'inherit'},
        '&.active': {
            color: 'var(--appbar-selected-color)',
            backgroundColor: 'var(--appbar-selected-bgcolor)',
            borderLeftColor: 'var(--appbar-selected-border-color)',
            borderRightColor: 'var(--appbar-selected-border-color)',
            '&:hover': { }
        }
    },
    '& .navLinkText': {
        width: 'fit-content',
        height: APPBAR_HEIGHT,
        padding: theme.spacing(0, 2),
        fontSize: '0.8rem' // change to theme.typography.body2.fontSize when PxP's type scale is synced with Basecamp
    }
}));

export default StyledAppBar;
