import {useEffect, useState, useRef, RefObject, useLayoutEffect, useContext} from 'react';
import { Box, Typography } from '@mui/material';
import { StyledCenteringBox } from '../Dashboard/ExperimentsPanel/StyledComponents/';
import BoxPlotD3 from './BoxPlotD3';
import {IBoxPlotOptions, IBoxPlotProps, IPlotData} from "d3-boxplot";
import * as _ from 'lodash';
import {calculateWidth} from './PlotUtils';
import {RefAwareStatsData} from 'experiment';
import useMounted from "../../util/useMounted";
import {DisplayContext} from '../../store/DisplayContext';

/**
 * This plot typically has really long labels on the x-axis, except when the experiment
 * is a Yeast TKO experiment, which has short labels for contrasts, which will make it
 * seem like there is too much padding below the plot. Probably NOT worth trying to address.
 */
export default function SampleVsControlComparison({experimentName, data, showsRef}: RefAwareStatsData) {

    const detailsDisplay = useContext<DetailsDisplay>(DisplayContext);
    const isMounted = useMounted();
    const [boxPlot, setBoxPlot] = useState<IPlotData | null>(null);
    const [width, setWidth] = useState(calculateWidth(showsRef, detailsDisplay.wideView));
    const refDiv: RefObject<HTMLDivElement> | null = useRef(null);
    const containerId = `sample-control-comparison-container-${experimentName}`
    const [vis, setVis] = useState<BoxPlotD3 | null>(null);

    const options: IBoxPlotOptions = {
        minimalStyle: false,
        jitter: 0.2,
        opacity: 0.3,
        showInnerDots: false,
        useSymbolTick: false,
        plotId: 'sample-control-comparison-' + experimentName,
        scale: 'linear',
        margins: {
            left: 25,
            right: 5,
            top: 0,
            bottom: 200
        }
    };

    const fetchData = (plotData: IPlotData) => {
        try {
            isMounted() && setBoxPlot(plotData);
        } catch (error) {
            console.error('Sample Vs Control Comparison error: ', error);
            isMounted() && setBoxPlot(null);
        }
    };

    function initVisualization() {
        if (boxPlot && boxPlot.bandDomain.length && refDiv && refDiv.current) {
            if (vis === null || refDiv.current.children.length === 0) {
                const props: IBoxPlotProps = {
                    width,
                    height: showsRef ? width : width / 2,
                    data: boxPlot
                };
                setVis(new BoxPlotD3(refDiv.current, options, props));
            } else {
                vis.updateData(boxPlot)
            }
        }
    }

    useEffect(() => {
        fetchData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(initVisualization, [boxPlot]);

    useEffect(() => {
        setWidth(calculateWidth(showsRef, detailsDisplay.wideView))
    }, [showsRef, detailsDisplay.wideView])

    useLayoutEffect(() => {
        const handleResize = _.debounce(() => {
            setWidth(calculateWidth(showsRef, detailsDisplay.wideView));
        }, 350);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [showsRef, detailsDisplay.wideView]);

    useEffect(() => {
        const height = showsRef ? width : width / 2
        // console.log(`${experimentName} resizing to ${width} x ${height}`)
        vis && vis.resize(width, height);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, experimentName, options.plotId, showsRef]);

    if (boxPlot != null) {
        return <>
            <Box ref={refDiv} id={containerId} mt={3} />
        </>
    }
    return <StyledCenteringBox><Typography align="center" paragraph>No plot data</Typography></StyledCenteringBox>
}