import {styled} from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';

const StyledListBox = styled(Box, {
    shouldForwardProp: (prop) => true})<BoxProps>(({ component, theme }) => ({
    ...(component === 'dl'?
        {   '& > *': { display: 'inline-block'},
            '& > dt + dd': { marginLeft: theme.spacing(1)}
        } :
        {'& > li + li': { marginTop: theme.spacing(0.5)}}
    ),
    listStyleType: 'none',
    padding: 0,
    '& .MuiTypography-body1': { fontSize: theme.typography.bodyList.fontSize},
    '& strong': { fontWeight: theme.typography.fontWeightMedium},
}));
export default StyledListBox;
