import React from 'react';
import {
    Avatar, Checkbox, Paper, 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    useTheme, alpha
} from '@mui/material';
import {FlashAnalysis} from '../../../../../../../API';

interface HeadCell {
    disablePadding: boolean;
    id: keyof FlashSample | 's3';
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'fileName',
        numeric: false,
        disablePadding: true,
        label: 'File Name',
    },
    {
      id: 'fileSize',
      numeric: true,
      disablePadding: false,
      label: 'Size'
    },
    {
        id: 's3',
        numeric: false,
        disablePadding: true,
        label: ''
    },
    {
        id: 'sampleId',
        numeric: true,
        disablePadding: false,
        label: 'Sample ID',
    },
    {
        id: 'position',
        numeric: false,
        disablePadding: false,
        label: 'Position',
    },
    {
        id: 'injVol',
        numeric: true,
        disablePadding: false,
        label: 'Inj. Vol',
    },
    {
        id: 'L1Replicate',
        numeric: true,
        disablePadding: false,
        label: 'L1 Replicate',
    },
    {
        id: 'L2Compound',
        numeric: false,
        disablePadding: false,
        label: 'L2 Compound',
    },
    {
        id: 'L3CUM',
        numeric: true,
        disablePadding: false,
        label: 'L3 C uM',
    },
    {
        id: 'L4Time',
        numeric: true,
        disablePadding: false,
        label: 'L4 Time',
    },
    {
        id: 'L5CellLine',
        numeric: false,
        disablePadding: false,
        label: 'L5 Cell Line',
    },
    {
        id: 'comment',
        numeric: false,
        disablePadding: false,
        label: 'Comment',
    },
    {
        id: 'sampleName',
        numeric: false,
        disablePadding: false,
        label: 'Sample Name',
    }
];

interface EnhancedTableProps {
    numSelected: number;
    onSelectAllClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
    selectAllDisabled: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, numSelected, rowCount, selectAllDisabled } = props;
    return (
    <TableHead>
        <TableRow>
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{ 'aria-label': 'select all samples'}}
                    disabled={selectAllDisabled}
                />
            </TableCell>
            {headCells.map((headCell) => (
                <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                    {headCell.label}
                </TableCell>
            ))}
        </TableRow>
    </TableHead>
    );
}

const sizeInMB = (bytes: number|null|undefined) => {
    if (!bytes) {
        return ''
    }
    if (bytes < 1048576) {
        return (bytes / 1024).toFixed(0) + ' KB'
    }
    return (bytes / 1048576).toFixed(0) + ' MB'
}

interface ProcessProps {
    flashSamples: FlashSample[];
    selected: string[];
    setSelected: (files: string[]) => void;
    analysis: FlashAnalysis | null | undefined;
}
export default function ProcessExperimentTable({flashSamples, selected, setSelected, analysis}: ProcessProps) {
    
    const theme = useTheme();
    const sizeWarningColor = alpha(theme.palette.warning.light, 0.15);
    const byteThreshold = 104857600;
    
    const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            const newSelected = flashSamples.map((flashSample) => flashSample.fileName);
            setSelected(newSelected);
        } else {
            setSelected([]);
        }
    };
    
    const handleClick = (e: React.MouseEvent<unknown>, fileName: string, fileSize: number|undefined) => {
        // only do something if the file has size
        if (fileSize) {
            const selectedIndex = selected.indexOf(fileName);
            let newSelected: string[] = [];
            if (selectedIndex === -1) {
                newSelected = newSelected.concat(selected, fileName);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
                newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    selected.slice(0, selectedIndex),
                    selected.slice(selectedIndex + 1),
                );
            }
            setSelected(newSelected);
        }
    };
    
    const isSelected = (fileName: string) => selected.indexOf(fileName) !== -1;

    if (!flashSamples) {
        return null
    }
    
    return (
    <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: '60vh' }}>
            <Table stickyHeader sx={{ minWidth: 750 }} size="small">
                <EnhancedTableHead
                    numSelected={selected.length}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={flashSamples.length}
                    selectAllDisabled={!!flashSamples.find((sample: FlashSample) => sample.fileSize === null)}
                />
                <TableBody>
                {flashSamples.map((row, index) => {
                    const isItemSelected = isSelected(row.fileName);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const disabled = !row.fileSize
                    const myCursor = disabled ? 'not-allowed' : 'pointer'
                    const bgColor =
                        !!row.fileSize && row.fileSize < byteThreshold
                        ? sizeWarningColor
                            : (analysis?.record_value?.samples_selected_by_user &&
                                analysis.record_value.samples_selected_by_user.includes(row.fileName)) ?
                                alpha(theme.palette.success.light, 0.7) : ''
                    return (
                    <TableRow
                        hover
                        onClick={(event:any) => handleClick(event, row.fileName, row.fileSize)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.sampleId}
                        selected={isItemSelected}
                        sx={{ 
                            cursor: myCursor,
                            '& > .MuiTableCell-root': {backgroundColor: bgColor}
                        }}
                    >
                        <TableCell padding="checkbox">
                            <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                    'aria-labelledby': labelId,
                                }}
                                disabled={disabled}
                            />
                        </TableCell>
                        <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                        >
                            {row.fileName}
                        </TableCell>
                        <TableCell align="right">
                            {sizeInMB(row.fileSize)}
                        </TableCell>
                        <TableCell padding="none">
                            {row.fileSize && row.fileSize > 0 &&
                                <Avatar alt="S3"
                                        sx={(theme) => ({
                                            width: 16,
                                            height: 16,
                                            fontSize: theme.typography.xSmall2.fontSize,
                                            fontWeight: 700,
                                            bgcolor: theme.palette.primary.main
                                        })}
                                >
                                    S3
                                </Avatar>
                            }
                        </TableCell>
                        <TableCell align="right">{row.sampleId}</TableCell>
                        <TableCell align="left">{row.position}</TableCell>
                        <TableCell align="right">{row.injVol}</TableCell>
                        <TableCell align="right">{row.L1Replicate}</TableCell>
                        <TableCell align="left">{row.L2Compound}</TableCell>
                        <TableCell align="right">{row.L3CUM}</TableCell>
                        <TableCell align="right">{row.L4Time}</TableCell>
                        <TableCell align="left">{row.L5CellLine}</TableCell>
                        <TableCell align="left">{row.comment}</TableCell>
                        <TableCell align="left">{row.sampleName}</TableCell>
                    </TableRow>
                    );
                })}
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
    );
}