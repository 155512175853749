import React, {ReactElement, useEffect, useState} from 'react';
import {API, graphqlOperation} from '@aws-amplify/api';
import { Box, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';
import VolcanoPanel from '../../../../visualizations/VolcanoPanel';
import SNHistogramPanel from "../../../../visualizations/SNHistogramPanel";
import ChannelIntensitiesPanel from "../../../../visualizations/ChannelIntensitiesPanel";
import PeptideOverlapPanel from "../../../../visualizations/PeptideOverlap";
import FractionCountsPanel from "../../../../visualizations/FractionCountsPanel";
import IntensitiesPanel from "../../../../visualizations/IntensitiesPanel";
import DeltaMHistogramPanel from "../../../../visualizations/DeltaMHistogramPanel";
import SampleVsControlComparison from "../../../../visualizations/SampleVsControlComparison";
import PcaAnalysisPanel from "../../../../visualizations/PcaAnalysisPanel";
import ExperimentDetails from './ExperimentDetails';
import ImageExporter from "../../../../../util/ImageExporter";
import { ExperimentTabsDataCy } from './ExperimentTabs.cy';
import YeastTkoStats from '../../../../visualizations/YeastTkoStats';
import {getStats, getYeastReferenceExperiment} from '../../../../../graphql/queries'
import {ExperimentAnalysis} from '../../../../../API';
import {PROCESSED_STATES, TAB_FILE_NAMES} from '../../../../../util/options';


export interface ExperimentProps {
    experiment: {experiment: string; status: string};
    experimentType: ExperimentType;
    experimentDesign?: any;
    experimentAnalysis?: ExperimentAnalysis | null;
    showProcessed?: boolean;
}

export interface ExperimentTabsProps extends ExperimentProps {
    tabValue: number;
    handleTabSelection: (event: React.ChangeEvent<any>, value: any) => void;
    exportPdfHandler: any;
    setExperimentMessage: (message: string) => void;
}

const wrapComponent = (header: string, componentId: string, componentToWrap: ReactElement): ReactElement => {
    return <Box id={componentId} mb={4}>
        <Typography gutterBottom variant="h5" component="h2">
            {header}
        </Typography>
        {componentToWrap}
    </Box>
}

function PlotsTabPanel({experiment, experimentType, experimentAnalysis}: ExperimentProps)  {

    const fractionsPanelTitle = experimentType.value === 'QC' ? 'PSMs and QPSMs' :'PSMs and QPSMs Per Fraction'
    const channelIntensitiesTitle = experimentAnalysis?.skip_normalization ? 'Non-normalized Channel Intensities' :
        'Normalized Channel Intensities'
    const instrument = experimentAnalysis?.instrument
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>(null)
    const [refExperiment, setRefExperiment] = useState<string|null>(null)
    const [refData, setRefData] = useState<any>(null)

    useEffect(() => {
        let mounted = true;
        setLoading(true)
        const fetchStats = async (name: string, statsRecordType: string) => {
            try {
                const response: any = await API.graphql(graphqlOperation(getStats,
                    {experiment: name, recordType: statsRecordType}))
                const statsData = response.data.getStats;
                if (mounted) {
                    setData(statsData)
                }
            } catch (error) {
                setLoading(false)
            }
        }
        const fetchReferenceStats = async (instrument: string | undefined) => {
            if (!instrument) {
                console.warn('No instrument for ', experimentAnalysis)
            } else {
                try {
                    const refResponse: any = await API.graphql(graphqlOperation(getYeastReferenceExperiment, {instrument}))
                    const ref: any = await API.graphql(graphqlOperation(getStats, {recordType: experimentType.statsType,
                        experiment: refResponse.data.getYeastReferenceExperiment.experiment
                    }))
                    if (mounted) {
                        setRefExperiment(refResponse.data.getYeastReferenceExperiment.experiment)
                        setRefData(ref.data.getStats)
                    }
                } catch (error) {
                    console.error('Problem loading reference data', error)
                }

            }
        }
        if (experimentType.value === 'QC') {
            fetchStats(experiment.experiment, experimentType.statsType).then()
            fetchReferenceStats(instrument).then()
        } else {
            fetchStats(experiment.experiment, experimentType.statsType).then()
            setRefData(null)
            setRefExperiment(null)
        }
        return () => {
            mounted = false;
        }
    }, [experiment, experimentType.statsType, experimentAnalysis, experimentType.value, instrument])

    return <CardContent>
        <Grid container columnSpacing={2}>
            <Grid item xs={12} md={experimentType.value === 'QC' && 6}>
                {experimentType.value === 'QC' && wrapComponent("STATS", 'yeastTkoStats', <YeastTkoStats
                experimentName={experiment.experiment} isReference={false} />)}
                {wrapComponent("Histogram - Log₁₀ S/N", 'snHistogram-toPdf', <SNHistogramPanel
                    experimentName={experiment.experiment} data={data?.snLog10Histogram} loading={loading}/>)}
                {wrapComponent(channelIntensitiesTitle, 'channelIntensities-toPdf',
                    <ChannelIntensitiesPanel data={data?.channelIntensityStats} loading={loading}/>)}
                {experimentType.value !== 'QC' && wrapComponent("Peptide Overlap Across Fractions", 'peptideOverlap-toPdf',
                    <PeptideOverlapPanel data={data?.peptideOverlaps} loading={loading}/>)}
                {wrapComponent(fractionsPanelTitle, 'fractionCounts-toPdf',
                    <FractionCountsPanel data={data?.fractionCounts} loading={loading} experimentType={experimentType}/>)}
                {wrapComponent("Distribution of Precursor Intensities", 'intensities-toPdf',
                    <IntensitiesPanel key="IntensitiesMain" experimentName={experiment.experiment}
                                      showsRef={experimentType.value === 'QC'}
                                      data={data ? {
                                          psmPrecursorIntensities: data.psmPrecursorIntensities,
                                          qpsmPrecursorIntensities: data.qpsmPrecursorIntensities
                                      } : null}
                                      loading={loading}/>)}
                {wrapComponent("Histogram Mass Error (ppm)", 'DeltaM-toPdf',
                    <DeltaMHistogramPanel experimentName={experiment.experiment} data={data?.massErrorHistogram} loading={loading}/>)}
                {wrapComponent("Log2 Ratios for Sample/Control Comparison.", 'sampleVControl-toPdf',
                    <SampleVsControlComparison key="SampleVsControlMain" experimentName="main"
                                               showsRef={experimentType.value === 'QC'}
                                               data={data?.sampleControlRatios} loading={loading}/>)}
                <Box id={'pcaAnalysis-toPdf'} mb={4}>
                    <Typography gutterBottom variant="h5" component="h2">
                        PCA Analysis
                    </Typography>
                    <PcaAnalysisPanel showsRef={experimentType.value === 'QC'} experimentName={experiment.experiment}
                                      data={data?.pcaData} loading={loading}/>
                </Box>
            </Grid>
            {/* The reference data gets skipped in the export to pdf, becuase when the export gets too big, it just fails */}
            {experimentType.value === 'QC' && refData && <Grid item xs={12} md={6}>
                {wrapComponent("STATS", 'yeastTkoStats-2', <YeastTkoStats
                experimentName={refExperiment!} isReference={true} />)}
                {wrapComponent("Histogram - Log₁₀ S/N", 'refSnHistogram', <SNHistogramPanel
                    experimentName={refExperiment!} data={refData?.snLog10Histogram} loading={loading}/>)}
                {wrapComponent(channelIntensitiesTitle, 'refChannelIntensities',
                    <ChannelIntensitiesPanel data={refData?.channelIntensityStats} loading={loading}/>)}
                {wrapComponent(fractionsPanelTitle, 'refFractionCounts',
                    <FractionCountsPanel data={refData?.fractionCounts} loading={loading} experimentType={experimentType}/>)}
                {wrapComponent("Distribution of Precursor Intensities", 'refIntensities',
                    <IntensitiesPanel key="IntensitiesRef" experimentName={refExperiment!} showsRef={true}
                                      data={refData ? {
                                          psmPrecursorIntensities: refData.psmPrecursorIntensities,
                                          qpsmPrecursorIntensities: refData.qpsmPrecursorIntensities
                                      } : null}
                                      loading={loading}/>)}
                {wrapComponent("Histogram Mass Error (ppm)", 'refDeltaM',
                    <DeltaMHistogramPanel experimentName={refExperiment!} data={refData?.massErrorHistogram} loading={loading}/>)}
                {wrapComponent("Log2 Ratios for Sample/Control Comparison.", 'refSampleVControl',
                    <SampleVsControlComparison key="SampleVsControlRef" showsRef={true} experimentName={refExperiment!}
                                               data={refData?.sampleControlRatios} loading={loading}/>)}
                <Box id={'refPcaAnalysis'} mb={4}>
                    <Typography gutterBottom variant="h5" component="h2">
                        PCA Analysis
                    </Typography>
                    <PcaAnalysisPanel experimentName="reference" data={refData?.pcaData} loading={loading}
                                     showsRef={true}/>
                </Box>
            </Grid>}
        </Grid>
    </CardContent>
}

function VolcanoTabPanel({experiment, experimentType}: ExperimentProps)  {

    return <CardContent id={'volcano-toPdf'}>
        <VolcanoPanel experimentName={experiment.experiment.toString()} experimentType={experimentType}/>
    </CardContent>
}

export default function ExperimentTabs({
       experiment, experimentType, experimentDesign, experimentAnalysis,
       tabValue, handleTabSelection,
       exportPdfHandler, setExperimentMessage
    }: ExperimentTabsProps): ReactElement {

    if (!experiment) {
        return <></>
    }
    const showProcessed = PROCESSED_STATES.includes(experiment.status)

    return <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={tabValue} onChange={handleTabSelection} aria-label="Tabs of Data for experiment">
                <Tab data-cy={ExperimentTabsDataCy.detailsTab} label="Experiment Details" id="DetailsTab" aria-controls="DetailsTabPanel"/>
                {showProcessed &&
                    <Tab data-cy={ExperimentTabsDataCy.plotsTab} label="Plots & Stats" id="PlotsTab" aria-controls="PlotsTabPanel"/>}
                {showProcessed &&
                    <Tab data-cy={ExperimentTabsDataCy.volcanoTab} label="Volcano Plots" id="VolcanoTab" aria-controls="VolcanoTabPanel"/>}
            </Tabs>
        </Box>
        <ImageExporter fileName={`${experiment.experiment}_${TAB_FILE_NAMES[tabValue]}`} exportTrigger={exportPdfHandler}
                       cleanUpFunction={() => setExperimentMessage('')}>
            {tabValue === 0 ? <ExperimentDetails  experiment={experiment}
                               experimentType={experimentType} showProcessed={showProcessed}
                               experimentDesign={experimentDesign} experimentAnalysis={experimentAnalysis}/> : <></>}
            {tabValue === 1 ? <PlotsTabPanel experiment={experiment} experimentType={experimentType}
                           experimentAnalysis={experimentAnalysis} /> : <></>}
            {tabValue === 2 ? <VolcanoTabPanel experiment={experiment} experimentType={experimentType}
                             /> : <></>}
        </ImageExporter>
    </Box>
}

