export const experimentTypes: ExperimentType[] = [
    // Non-Disabled Options
    { value: 'DEGRADATION', label: 'Degradation', code: 'DEG', statsType: 'STATISTICS', list: 'STATUS', publish: true,
        upload: true, filterable: true, disable: false, lcms: 4},
    { value: 'TURBO_ID', label: 'TurboID', code: 'TRB', statsType: 'TURBO_ID_STATISTICS', list: 'TURBO_ID_STATUS',
        publish: true, upload: true, filterable: true, disable: false, lcms: 2},
    //Phosphorylation is synonymous with Phosphoproteomics. The backend already expects the 'Phosphorylation' so we'll just use a different label
    { value: 'PHOSPHORYLATION', label: 'Phosphoproteomics', code: 'PHS', statsType: 'PHOSPHORYLATION_STATISTICS',
        list: 'PHOSPHORYLATION_STATUS', publish: false, upload: true, filterable: true, disable: false, lcms: 0},
    { value: 'CHEMOPROTEOMICS', label: 'Chemoproteomics', code: 'CHM', statsType: 'CHEMOPROTEOMICS_STATISTICS', 
        list: 'CHEMOPROTEOMICS_STATUS', publish: false, upload: true, filterable: true, disable: false, lcms: 0},
    { value: 'FLOW_THROUGH', label: 'Flow Through', code: 'FLW', statsType: 'FLOW_THROUGH_STATISTICS', 
        list: 'FLOW_THROUGH_STATUS', publish: false, upload: true, filterable: true, disable: false, lcms: 0},
    { value: 'UBIQUITINATION', label: 'Ubiquitination', code: 'UBI', statsType: 'UBIQUITINATION_STATISTICS',
        list: 'UBIQUITINATION_STATUS', publish: false, upload: true, filterable: true, disable: false, lcms: 0},
    // Disabled Options
    { value: 'QC', label: 'Yeast TKO', code: 'YTK', statsType: 'QC_STATISTICS', list: 'QC_STATUS', publish: false,
        upload: true, filterable: true, disable: true, lcms: 0},
    { value: 'SILAC', label: 'SILAC', code: 'SLC', statsType: 'SILAC_STATISTICS', list: 'SILAC_STATUS', publish: false,
        upload: false, filterable: false, disable: true, lcms: 0},
    { value: 'LABEL_FREE', label: 'Label-free', code: 'LF', statsType: 'LABEL_FREE_STATISTICS',
        list: 'LABEL_FREE_STATUS', publish: false, upload: false, filterable: false, disable: true, lcms: 0},
    { value: 'PARALLEL_REACTION', label: 'Parallel Reaction Monitoring', code: 'PRM',
        statsType: 'PARALLEL_REACTION_STATISTICS', list: 'PARALLEL_REACTION_STATUS', publish: false, upload: false,
        filterable: false, disable: true, lcms: 0},
    { value: 'GENERAL', label: 'General', code: 'GEN',
        statsType: 'GENERAL_STATISTICS', list: 'GENERAL_STATUS', publish: false, upload: false,
        filterable: true, disable: true, lcms: 0},
    { value: 'FLASH_DEGRADATION', label: 'Flash Degradation', code: 'FDG', statsType: 'FLASH_DEGRADATION_STATISTICS',
     list: 'FLASH_DEGRADATION_STATUS', publish: false, upload: true, filterable: true, disable: false, lcms: 4}
];

export const codeFromName = (experiment: string) => {
    const groups = /PX_([A-Z]{3})_[0-9]{5}_[0-9]{4}/.exec(experiment)
    if (groups) {
        return groups[1]
    }
        return null
}
export const TMT_TYPES = ['DEGRADATION', 'TURBO_ID', 'QC', 'UBIQUITINATION', 'PHOSPHORYLATION', 'CHEMOPROTEOMICS', 'FLOW_THROUGH']
export const PTM_TYPES = ['PHOSPHORYLATION', 'CHEMOPROTEOMICS', 'UBIQUITINATION']

export const concentrationOptions = [
    { value: '0.05'},
    { value: '100'},
    { value: '10'}
];

export const concOptions = ['0.05', '100', '10']

export const concentrationUnits = [
    { value: '', label: ''},
    { value: '%', label: '%'},
    { value: 'uM', label: 'µM'},
    { value: 'nM', label: 'nM'},
    { value: 'pM', label: 'pM'},
    { value: 'fM', label: 'fM'}
];

export const treatmentTimeOptions: CddValue[] = [
    { value: ''},
    { value: '2'},
    { value: '6'},
    { value: '24'}
];

export const TMTOptions: Labeled[] = [
    { label: '126', value: '_126_'},
    { label: '127N', value: '_127N'},
    { label: '127C', value: '_127C'},
    { label: '128N', value: '_128N'},
    { label: '128C', value: '_128C'},
    { label: '129N', value: '_129N'},
    { label: '129C', value: '_129C'},
    { label: '130N', value: '_130N'},
    { label: '130C', value: '_130C'},
    { label: '131N', value: '_131N'},
    { label: '131C', value: '_131C'},
    { label: '132N', value: '_132N'},
    { label: '132C', value: '_132C'},
    { label: '133N', value: '_133N'},
    { label: '133C', value: '_133C'},
    { label: '134N', value: '_134N'},
    { label: '134C', value: '_134C'},
    { label: '135N', value: '_135N'}
]

export const TMTProDOptions: Labeled[] = [
    { label: '127D', value: '_127D'},
    { label: '128ND', value: '_128ND'},
    { label: '128CD', value: '_128CD'},
    { label: '129ND', value: '_129ND'},
    { label: '129CD', value: '_129CD'},
    { label: '130ND', value: '_130ND'},
    { label: '130CD', value: '_130CD'},
    { label: '131ND', value: '_131ND'},
    { label: '131CD', value: '_131CD'},
    { label: '132ND', value: '_132ND'},
    { label: '132CD', value: '_132CD'},
    { label: '133ND', value: '_133ND'},
    { label: '133CD', value: '_133CD'},
    { label: '134ND', value: '_134ND'},
    { label: '134CD', value: '_134CD'},
    { label: '135ND', value: '_135ND'}
]

export function getRowDataSample(): SampleRow[] {
    return [
        createData(1,'_126_',[],'',emptyCellLine,'','NaN','','hour'),
        createData(2,'_127N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(3,'_127C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(4,'_128N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(5,'_128C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(6,'_129N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(7,'_129C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(8,'_130N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(9,'_130C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(10,'_131N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(11,'_131C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(12,'_132N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(13,'_132C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(14,'_133N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(15,'_133C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(16,'_134N',[],'',emptyCellLine,'','NaN','','hour'),
        createData(17,'_134C',[],'',emptyCellLine,'','NaN','','hour'),
        createData(18,'_135N',[],'',emptyCellLine,'','NaN','','hour')
    ]
}

export const emptyCellLine = {benchling_id: '', cell_line_name: '', cell_line_short_name: ''}
export function createData(sampleNum: number, tmt: string,
                           compounds: Array<SampleCompound>, cells: string, cellLine: any, treatmentTime: string|null,
                           type: string, description: string, treatmentTimeUnit: string): SampleRow {
    return { sampleNum, tmt, compounds, cells, cellLine, treatmentTime, type, description, treatmentTimeUnit };
}

export const TAB_FILE_NAMES = ['Details', 'Plots', 'Volcano'];
export const PROCESSED_STATES = ['PROCESSED', 'PUBLISHED', 'PUBLISHING']



