import React, {MouseEvent, useMemo, useState} from 'react';
import {
    Square as CalStatusIcon,
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon
} from '@mui/icons-material';
import {
    Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, Link, MenuItem, Paper,
    TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,
    TextField, Theme, Typography, useMediaQuery, useTheme
} from '@mui/material';
import {visuallyHidden} from '@mui/utils'
import { StyledCalTable, StyledCalTableColGroupHeader, StyledListBox } from '../StyledComponents';
import { CalTableBodyCell, CalTableHeadCell, SingleColLayout } from '../SharedComponents';
import { colors, dataColors } from '../../theme/theme';
import EditExpLCMSDialog from '../modals/EditExpLCMSDialog';
import lcmsStatus from '../../services/lcmsStatus';
import StatusFilterBar from '../filters/StatusFilterBar';
import {ExperimentFilters} from 'filters';
import CopyToClipboard from "../SharedComponents/CopyToClipboard";
import {Order} from 'LCMS';
import {LcmsStatus} from '../../API';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {Dialog} from '@mui/material/';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {createRange, dateRangeHeader} from './dateHelper';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (!b[orderBy] && !a[orderBy]) {
        return 0
    } else if (!b[orderBy] && !!a[orderBy]) {
        return 1
    } else if (!!b[orderBy] && !a[orderBy]) {
        return -1
    } else if (b[orderBy] < a[orderBy]) {
        return -1;
    } else if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: string},
    b: { [key in Key]: string}
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}

// WHEN ADDING COLUMNS!! Update the <colgroup>, and the colSpan props on the first header row's TableHead elements
const headers = [
    {id: 'experiment', label: 'Experiment'},
    {id: 'responsible', label: 'Responsible'},
    {id: 'collaborator', label: 'Collaborator'},
    {id: 'exp_status', label: 'Status', filterOptions: ['PLANNED', 'PROCESSED', 'PUBLISHED']},
    {id: 'projectName', label: 'Project Name'},
    {id: 'benchling', label: 'Benchling'},
    {id: 'lcms_start', label: 'LCMS Start'},
    {id: 'lcms_end', label: 'LCMS End'},
    {id: 'instrument', label: 'Instrument'},
]
const ALL_STATUS = 'ALL'

export default function StatusView() {
    
    const theme = useTheme<Theme>();
    const isFHDup = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.fhd));
    const isQHDup = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.qhd));
    const [order, setOrder] = useState<Order>('desc')
    const [orderBy, setOrderBy] = useState<keyof LcmsStatus>('lcms_start')
    const [filteredBy, setFilteredBy] = useState<string>('PLANNED')
    const [open, setOpen] = useState<boolean>(false)

    const [calDateRange, setCalDateRange] = useState<Date[]>(createRange(new Date()))

    const [data, setData] = useState<any[]>([])

    let formattedCalHeader = dateRangeHeader(calDateRange[0], calDateRange[13])
    
    const calPlanned = dataColors.lcms.plannedColor;
    const calLCMS = dataColors.lcms.lcmsColor;
    const calProcessed = dataColors.lcms.processedColor;
    const calPublished = dataColors.lcms.publishedColor;

    const handleRequestSort = (event: MouseEvent<unknown>, property: keyof LcmsStatus) => {
        console.log(`handleRequestSort with ${property}`)
        const isDesc = orderBy === property && order === 'desc'
        const newOrder = isDesc ? 'asc' : 'desc'
        setOrder(newOrder)
        console.log(`handleRequestSort with ${property} and order ${newOrder}`)
        setOrderBy(property)
    }

    const visibleRows = useMemo(() => {
        if (data) {
            return data.slice()
                .filter((value) => {
                    if (filteredBy === ALL_STATUS) {
                        return true
                    }
                    return value.exp_status === filteredBy
                })
                .sort(getComparator(order, orderBy))
        }
        return []
    }, [order, orderBy, data, filteredBy])
    const captionText = data.length > 0
        ? `${visibleRows.length} experiments match filter criteria` : 'No experiments match filter criteria';
    const backByDay = () => {
        changeDate(-1, calDateRange)
    }
    const forwardByDay = () => {
        changeDate(1, calDateRange)
    }
    const changeDate = (by: 1 | -1, dateRange: Date[]) => {
        let twoWeeks
        if (by === 1) {
            const newDate = new Date(dateRange[13].getTime())
            newDate.setDate(dateRange[13].getDate() + by)
            twoWeeks = calDateRange.slice(1)
            twoWeeks.push(newDate)
        } else {
            const newDate = new Date(dateRange[0].getTime())
            newDate.setDate(dateRange[0].getDate() + by)
            twoWeeks = calDateRange.slice(0, 13)
            twoWeeks.splice(0,0, newDate)
        }
        setCalDateRange(twoWeeks)
    }

    const handleStatusFilterChange = (event: SelectChangeEvent) => {
        const val = event.target.value as string
        setFilteredBy(val)
        console.log(`status filter changed to ${val}`)
    }

    const handleFilterChange = async (filters: ExperimentFilters) => {
        let project = null
        let compound = null
        if (filters.projectCompound) {
            if (filters.projectCompound.type === 'project' ) {
                project = filters.projectCompound.name
            } else {
                compound = filters.projectCompound.name
            }
        }
        let excludeQC = true
        let expCode = filters.experimentCode
        if (filters.experimentCode === 'AllQCInclusive') {
            excludeQC = false
            expCode = null
        }
        // console.log(`expCode is ${expCode} and excludeQC is ${excludeQC}`)
        const lcmsFilters = {
            start: filters.dateRange!.start,
            end: filters.dateRange!.end,
            experimentType: expCode,
            instrument: filters.instrument,
            cellLine: filters.cellLine,
            projectName: project,
            experimenter: filters.experimenter,
            compound: compound
        }
        const statusData = await lcmsStatus.fetchData(lcmsFilters);
        if (statusData) {
            const sortedAndFiltered = statusData.filter((record) => {
                //console.log(`AM I OK ${JSON.stringify(record)}`)
                // Don't show QC experiments unless requested specifically or AllQCInclusive
                if (expCode === null && excludeQC) {
                    return record?.exp_type !== "QC"
                }
                return true
            }).sort((a: any, b: any) => b.experiment.localeCompare(a.experiment))
            // console.log('sorted DATA', statusData)
            setData(sortedAndFiltered)
        }
    }

    const updateChangeHandler = (record: any) => {
        const copy = [...data]
        const toUpdate = copy.find((exp: any) => exp.experiment === record.experiment)
        if (toUpdate) {
            toUpdate.lcms_start = record.lcms_start
            toUpdate.lcms_end = record.lcms_end
            toUpdate.instrument = record.instrument
            setData(copy)
            //console.log("Data updated!")
        } else {
            console.log('Why did we not get updated', record)
        }
    }
    
    // below to use for when benchling string is a URL
    // the hostname, subdomain, and mtx part of the pathname are irrelevant to display
    function getPathnameFromUrl(url: string) {
        const a = document.createElement('a')
        a.setAttribute('href', url);
        return a.pathname.split('/monterosatx/f/');
    }

    const createSortHandler = (property: keyof LcmsStatus) => (event: MouseEvent<unknown>) => {
        // console.log(`hello from createSortHandler with ${property}`)
        handleRequestSort(event, property)
    }

    const handleMoreClick = (property: string) => {
        // console.log(`I have been clicked, what now? ${property}`)
        setOpen(true)
    }

    const handleMoreClose = () => {
        setOpen(false);
    };
    
    const sxLongLinkStyles = {
        width: 'inherit',
        maxWidth: 200,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden', 
        display: 'inline-block'
    };
    const sxLongTextStyles = {
        width: 'inherit',
        maxWidth: 200,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2 
    };
    
    return (<>
        <StatusFilterBar handleFilterChange={handleFilterChange}/>
        <SingleColLayout>
            <Box mt={1} mb={1.5} display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h4">LCMS Status</Typography>
            </Box>
            <Paper sx={{display: 'flex', flexDirection: 'row'}}>
                <Box px={1} pt={1}>
                    <Typography variant="body1" fontWeight={500}>Calendar</Typography>
                    <Typography variant="body2" color="secondary" sx={{mb:0.5}}>
                        Two week range
                    </Typography>
                    <TextField id="cal-range-start"  
                        type="date" label="Range start" value={calDateRange[0].toISOString().slice(0,10)}
                               onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                   const newVal = event.currentTarget.value
                                   setCalDateRange(createRange(new Date(newVal + 'T12:00:00')))
                               }}
                        InputLabelProps={{ shrink: true}}
                        variant="outlined" size="small" margin="dense"
                    />
                    <Typography variant="body1" fontWeight={500} gutterBottom sx={{mt:1}}>Legend</Typography>
                    <Typography variant="subtitle2" sx={{ml: 0.5}}>Calendar</Typography>
                    <StyledListBox component="ul" mt={0.25} ml={0.5}
                        sx={{ '& li svg': { mr: 0.5, verticalAlign: '-3px'}}}
                    >
                        <Typography component="li" variant="body2">
                            <CalStatusIcon sx={{color: calPlanned}} fontSize="small" />
                            Planned
                        </Typography>
                        <Typography component="li" variant="body2">
                            <CalStatusIcon sx={{color: calLCMS}} fontSize="small" />
                            LC-MS
                        </Typography>
                        <Typography component="li" variant="body2">
                            <CalStatusIcon sx={{color: calProcessed}} fontSize="small" />
                            Processed
                        </Typography>
                        <Typography component="li" variant="body2">
                            <CalStatusIcon sx={{color: calPublished}} fontSize="small" />
                            Published
                        </Typography>
                        <Typography component="li" variant="body2">
                            <CalStatusIcon sx={{color: calPublished}} fontSize="small" />
                            Complete
                        </Typography>
                    </StyledListBox>
                    <Typography variant="body2" fontStyle="italic" fontSize="caption.fontSize" sx={{maxWidth: 150, mb: 1}}>
                        LC-MS cell displays number corresponding to assigned Eclipse instrument.
                    </Typography>
                </Box>
                <TableContainer sx={{borderLeft: `1px solid ${colors.mtxUISlate[100]}`}}>
                    <StyledCalTable>
                        <colgroup>
                            <col span={1} style={{minWidth: 200, maxWidth: isFHDup? '15%' : '20%'}} />
                            <col span={2} />
                            <col span={1} style={{minWidth: 100}} />
                            <col span={1} />
                            <col span={1} style={{minWidth: 100}} />
                            <col span={3} />
                            <col span={1} style={{minWidth: 44}} />
                            <col span={14} />
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell scope="colGroup" colSpan={10} 
                                    sx={(theme) => ({color: theme.palette.text.secondary})}
                                >
                                    {captionText}
                                </TableCell>
                                <StyledCalTableColGroupHeader scope="colGroup" colSpan={14} align="center">
                                    <IconButton size={isQHDup? 'medium' : 'small'} color="inherit" sx={{mt: -0.25, mr: 0.25}}
                                        onClick={backByDay}
                                    >
                                        <ArrowBackIcon fontSize={isQHDup? 'medium' : 'small'} color="inherit"/>
                                    </IconButton>
                                    {formattedCalHeader}
                                    <IconButton size={isQHDup? 'medium' : 'small'} color="inherit" sx={{mt: -0.25, ml: 0.25}}
                                                onClick={forwardByDay}
                                    >
                                        <ArrowForwardIcon fontSize={isQHDup? 'medium' : 'small'} color="inherit"/>
                                    </IconButton>
                                </StyledCalTableColGroupHeader>
                            </TableRow>
                            <TableRow>
                                {/* WHEN ADDING COLUMNS!! Update the <colgroup>, and the colSpan props on the first header row's TableCell elements (that is the TableRow above this one!) */}
                                {headers.map((header) => (
                                    <TableCell key={header.id} sx={{lineHeight: '1rem'}}
                                        sortDirection={orderBy === header.id ? order : false}>
                                        <TableSortLabel active={orderBy === header.id}
                                                        direction={orderBy === header.id ? order : 'desc'}
                                                        onClick={createSortHandler(header.id as keyof LcmsStatus)}>
                                            {header.label} {orderBy === header.id ? (
                                            <Box component="span" sx={visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </Box> ) : null}
                                        </TableSortLabel>
                                        {header.filterOptions && !open && filteredBy === ALL_STATUS &&
                                            <IconButton aria-label="addStatusFilterButton"
                                                                      aria-controls="context-menu" aria-haspopup="true"
                                                                      size="small"
                                                                      onClick={() => handleMoreClick(header.id)} >
                                            <MoreVertIcon/>
                                        </IconButton>}
                                        {header.filterOptions && filteredBy !== ALL_STATUS &&
                                            <IconButton aria-label="updateStatusFilterButton"
                                                        aria-controls="context-menu" aria-haspopup="true"
                                                        size="small"
                                                        onClick={() => handleMoreClick(header.id)} >
                                                <FilterAltIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </TableCell>
                                ))}
                                <TableCell aria-label="Edit row">&nbsp;</TableCell>
                                {calDateRange.map((d: Date, index: number) => (
                                    <CalTableHeadCell key={d.getTime()} date={d}
                                                      classes={index === 0 ? 'thCalRangeStart' : undefined}/>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {visibleRows.map((r) => (
                            <TableRow key={'row_' + r.experiment}>
                                <TableCell sx={{ whiteSpace: 'nowrap'}}>
                                    {r.experiment}
                                    {r.experiment_folder && <CopyToClipboard folder={r.experiment_folder}/>}
                                </TableCell>
                                <TableCell>{r.responsible}</TableCell>
                                <TableCell>{r.collaborator}</TableCell>
                                <TableCell>{r.exp_status}</TableCell>
                                <TableCell>{r.projectName}</TableCell>
                                <TableCell>
                                    {r.benchling === null ? ''
                                    : r.benchling.includes('https://') ? 
                                    <Link href={r.benchling} title={r.benchling.replace('https://','')} target="_blank" 
                                        underline="hover" sx={sxLongLinkStyles}>
                                        {getPathnameFromUrl(r.benchling)}
                                    </Link>
                                    :
                                    <Typography component="span" title={r.benchling} fontSize="inherit" lineHeight="inherit"
                                        sx={sxLongTextStyles}>
                                        {r.benchling}
                                    </Typography>
                                    }
                                </TableCell>
                                <TableCell>{r.lcms_start}</TableCell>
                                <TableCell>{r.lcms_end}</TableCell>
                                <TableCell>{r.instrument}</TableCell>
                                
                                <TableCell align="center">
                                    <EditExpLCMSDialog
                                        expName={r.experiment}
                                        type={r.exp_type}
                                        start={r.lcms_start}
                                        end={r.lcms_end}
                                        instrument={r.instrument}
                                        readonly={r.exp_status === 'PUBLISHED'}
                                        updateCallback={updateChangeHandler}
                                    />
                                </TableCell>
                                {calDateRange.map((d: Date, index: number) => (
                                    <CalTableBodyCell key={r.experiment + d.getTime() + index} date={d}
                                                      value={r}
                                                      classes={index === 0 ? 'tdCalRangeStart' : undefined}/>
                                ))}
                            </TableRow>  
                            ))}
                        </TableBody>
                    </StyledCalTable>
                </TableContainer>
                <Dialog maxWidth="xs" open={open} onClose={handleMoreClose}>
                    <DialogTitle>Status Filter</DialogTitle>
                    <DialogContent>
                    <Box noValidate
                         component="form"
                         sx={{
                             display: 'flex',
                             flexDirection: 'column',
                             m: 'auto',
                             width: 'fit-content',
                         }}>
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <InputLabel id="statusFilterLabelId">Status Filter</InputLabel>
                        <Select autoFocus labelId="statusFilterLabelId" id="statusFilterId" value={filteredBy}
                                label="Status Filter"
                                onChange={handleStatusFilterChange}>
                            <MenuItem value="ALL">ALL</MenuItem>
                            <MenuItem value="PLANNED">PLANNED</MenuItem>
                            <MenuItem value="PROCESSED">PROCESSED</MenuItem>
                            <MenuItem value="PUBLISHED">PUBLISHED</MenuItem>
                            <MenuItem value="COMPLETE">COMPLETE</MenuItem>
                        </Select>
                    </FormControl>
                    </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleMoreClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </SingleColLayout>
    </>);
}