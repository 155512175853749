import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { STYLED_PAPER_BASE_HEIGHT, STYLED_PAPER_LG_HEIGHT} from '../../../../theme/Constants';

const StyledPaper = styled(Paper)(({ theme }) => ({
    minHeight: STYLED_PAPER_BASE_HEIGHT,
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down('lg')]: { height: STYLED_PAPER_BASE_HEIGHT},
    [theme.breakpoints.up('lg')]: { height: STYLED_PAPER_LG_HEIGHT},
}));

export default StyledPaper;