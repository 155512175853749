import {AnalysisFilterResult} from 'experiment';

const optional = [{label: '# Proteins', key: 'number_of_proteins_count'},
    {label: 'Confidence', key:'confidence_count'},
    {label: 'Ambiguity', key: 'ambiguity_count'},
    {label: 'Identifying Node', key: 'identifying_node_count'},
    {label: 'Low SPS Mass', key: 'under_sps_mass_count'}
]
class ExperimentStatistic {
    readonly original: any
    readonly legacyFilters: AnalysisFilterResult | null
    readonly mviTmpFilters: AnalysisFilterResult | null
    readonly psmPlotData: any
    readonly proteinPlotData: any
    readonly removalPlotData: any
    readonly peptidePlotData: any

    constructor(stat: any) {
        this.original = stat;
        if (stat?.removed_by_sum_PSMs_filters_count) {
            this.legacyFilters = JSON.parse(stat.removed_by_sum_PSMs_filters_count)
        } else {
            this.legacyFilters = {
                total_number_of_rows_removed: stat.total_psm_removed,
                missing_channels: stat.missing_channel_psm_count,
                high_isolation_interfence: stat.high_interference_psm_count,
                low_signal_to_noise: stat.low_signal_noise_psm_count,
                low_SPS_mass_matches: stat.under_sps_mass_count,
                "identifying node": stat.identifying_node_count,
                PSM_ambiguity: stat.ambiguity_count,
                confidence: stat.confidence_count,
                unique_peptides: stat.number_of_proteins_count,
                "de-duplicating_features": 0,
                num_proteins_remaining: stat.starting_psm_count! - stat.total_psm_removed!
            }
        }
        if (stat.removed_by_MVI_TMP_filters_count) {
            this.mviTmpFilters = JSON.parse(stat.removed_by_MVI_TMP_filters_count)
            this.psmPlotData = [
                {
                    "id": "psms-retained",
                    "label": "PSMs retained",
                    "value": stat.starting_psm_count! - this.mviTmpFilters!.total_number_of_rows_removed
                }, {
                    "id": "psms-removed",
                    "label": "PSMs removed",
                    "value": this.mviTmpFilters!.total_number_of_rows_removed
                }
            ]
            this.removalPlotData = [
                {category: `# Proteins (${this.mviTmpFilters!.unique_peptides})`, value: this.mviTmpFilters!.unique_peptides},
                {category: `Confidence (${this.mviTmpFilters!.confidence})`, value: this.mviTmpFilters!.confidence},
                {category: `Ambiguity (${this.mviTmpFilters!.PSM_ambiguity})`, value: this.mviTmpFilters!.PSM_ambiguity},
                {category: `Identifying Node (${this.mviTmpFilters!["identifying node"]})`, value: this.mviTmpFilters!["identifying node"]},
                {category: `Low SPS Mass (${this.mviTmpFilters!.low_SPS_mass_matches})`, value: this.mviTmpFilters!.low_SPS_mass_matches},
                {category: `Low Signal (${this.mviTmpFilters!.low_signal_to_noise})`, value: this.mviTmpFilters!.low_signal_to_noise},
                {category: `High Interference (${this.mviTmpFilters!.high_isolation_interfence})`, value: this.mviTmpFilters!.high_isolation_interfence},
                {category: `Missing Channel (${this.mviTmpFilters!.missing_channels})`, value: this.mviTmpFilters!.missing_channels},
                {category: `Deduplicated (${this.mviTmpFilters!["de-duplicating_features"]})`, value: this.mviTmpFilters!["de-duplicating_features"]},
                {category: `Total (${this.mviTmpFilters!.total_number_of_rows_removed})`, value: this.mviTmpFilters!.total_number_of_rows_removed},
            ]
            const remaining = this.mviTmpFilters!.num_proteins_remaining ? this.mviTmpFilters!.num_proteins_remaining : 0
            this.proteinPlotData = [
                {
                    "id": "proteins-retained",
                    "label": "Proteins retained",
                    "value": remaining
                }
            ];
            if (stat.starting_protein_count! - remaining) {
                this.proteinPlotData.push({
                        "id": "proteins-removed",
                        "label": "Proteins removed",
                        "value": stat.starting_protein_count! - remaining
                    })
            }
        } else {
            this.mviTmpFilters = null
            this.psmPlotData = [
                {
                    "id": "psms-retained",
                    "label": "PSMs retained",
                    "value": stat.starting_psm_count! - stat.total_psm_removed!
                }, {
                    "id": "psms-removed",
                    "label": "PSMs removed",
                    "value": stat.total_psm_removed ? stat.total_psm_removed : 0
                }
            ]
            type ObjectKey = keyof typeof stat
            this.removalPlotData = []
            optional.forEach((item) => {
                if (item.key in stat && stat[item.key as ObjectKey] !== null) {
                    this.removalPlotData.push({
                        "category": `${item.label} (${stat[item.key as ObjectKey]})`,
                        "value": stat[item.key as ObjectKey] as number
                    })
                }
            })
            this.removalPlotData.push({
                "category": `Low Signal (${stat.low_signal_noise_psm_count})`,
                "value": stat.low_signal_noise_psm_count!
            })
            this.removalPlotData.push({
                "category": `High Interference (${stat.high_interference_psm_count})`,
                "value": stat.high_interference_psm_count!
            })
            this.removalPlotData.push({
                "category": `Missing Channel (${stat.missing_channel_psm_count})`,
                "value": stat.missing_channel_psm_count!
            })
            this.removalPlotData.push({
                "category": `Total (${stat.total_psm_removed})`,
                "value": stat.total_psm_removed!
            })
            this.proteinPlotData = [
                {
                    "id": "proteins-retained",
                    "label": "Proteins retained",
                    "value": stat.remaining_protein_count ? stat.remaining_protein_count : 0
                }, {
                    "id": "proteins-removed",
                    "label": "Proteins removed",
                    "value": stat.starting_protein_count! - stat.remaining_protein_count!
                }
            ];
        }

        if (stat.unmodified_peptides_counter != null && stat.modified_peptides_counter != null) {
            this.peptidePlotData = [
                {
                    "category": "Total",
                    "value": stat.unmodified_peptides_counter + stat.modified_peptides_counter
                },
                {
                    "category": "Modified",
                    "value": stat.modified_peptides_counter
                },
                {
                    "category": "Unmodified",
                    "value": stat.unmodified_peptides_counter
                }
            ]
        } else {
            this.peptidePlotData = null
        }
    }

    calculatePercentage(count: number | null | undefined): string {
        if (count) {
            const amt = count / this.original.starting_psm_count * 100;
            if (amt < 0.01) {
                return `, ${amt.toFixed(3)}%`
            } else {
                return `, ${amt.toFixed(2)}%`
            }
        }
        return '';
    }

}

export default ExperimentStatistic