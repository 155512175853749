import { styled } from '@mui/material/styles';
import Card from '@mui/material/Paper';

// first child should be CardHeader with title and subtitle props defined as needed

const StyledPlotLabelCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    '&:nth-of-type(2)': { marginLeft: theme.spacing(1)},
    '& .MuiCardHeader-root': {
        padding: theme.spacing(0.5, 0.75),
        '& .MuiCardHeader-title': { fontSize: '0.8rem'},
        '& .MuiCardHeader-subheader': { fontSize: '0.65rem'},
    }
}));

export default StyledPlotLabelCard;