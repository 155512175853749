import {API, graphqlOperation} from '@aws-amplify/api';
import {completeFlashSampleList} from '../graphql/subscriptions';
import Observable from 'zen-observable-ts';
import {
    getBarcodeInfo,
    getExperimentDownloadUrl,
    getProcessableFlashSamples,
    getFlashStats,
    keyExists
} from '../graphql/queries';
import {acknowledgeFlashWarnings, startFlashAnalysis, updateFlashSampleList} from '../graphql/mutations'
import {ExperimentDownload, FlashStatistics} from '../API';
import * as d3 from 'd3-fetch';
import * as dsv from 'd3-dsv'
import {codeFromName} from '../util/options';

const flashService = {
    subscribeToFlashSampleList: (): Observable<any> => {
        return API.graphql(graphqlOperation(completeFlashSampleList)) as Observable<any>
    },
    fetchSampleListResults: async (experiment: string): Promise<any> => {
        if ('FDG' === codeFromName(experiment)) {
            console.log(`fixing to fetchSampleListResults for ${experiment}`)
            const key = `flash/deg/w-assay/results/${experiment}.json`
            const result: any = await API.graphql(graphqlOperation(
                getExperimentDownloadUrl, { keys: [key], experimentDownload: ExperimentDownload.FLASH}))
            try {
                if (result) {
                    const data = result.data.getExperimentDownloadUrl[0]
                    const jsonData = await d3.json(data.url)
                    return jsonData
                }
            } catch (err) {
                console.error(`${key} ERROR`, err)
            }
        }
        return null
    },
    fetchXcaliburSampleList: async (experiment: string): Promise<any> => {
        const key = `flash/deg/sample-list/${experiment}.csv`
        const result: any = await API.graphql(graphqlOperation(
            getExperimentDownloadUrl, { keys: [key], experimentDownload: ExperimentDownload.FLASH}))
        try {
            const rawData = await d3.text(result.data.getExperimentDownloadUrl[0].url)
            // Throw away the first three lines
            let n = 3
            let i = -1
            while (n-- && i++ < rawData.length) {
                i = rawData.indexOf('\n', i)
                if (i < 0) {
                    break
                }
            }
            const headerData = rawData.substring(0, i + 1)
            const rowData = dsv.csvParse(rawData.substring(i + 1))
            return {header: headerData, rows: rowData}
        } catch (err) {
            console.error(`WARNINGS for ${experiment}`, err)
        }
        return null
    },

    saveAcknowledgement: async (experiment: string, ackUser: string): Promise<any> => {
        const designResult: any = await API.graphql(graphqlOperation(
            acknowledgeFlashWarnings, {experimentName: experiment, user: ackUser}))
        // console.log(`saveAcknowledgement RESULT`, designResult)
        return designResult.data
    },
    updateFlashSampleList: async (experiment: string): Promise<any> => {
        const results: any = await API.graphql(graphqlOperation(
            updateFlashSampleList, {experimentName: experiment}))
        return results.data.updateFlashSampleList
    },
    getBarcodeInfo: async (peptideBarcode: string): Promise<any> => {
        const results: any = await API.graphql(graphqlOperation(
            getBarcodeInfo, {peptideBarcode: peptideBarcode}))
        // console.log(results)
        // The data is coming double wrapped
        // ie results.data.getBarcodeInfo is a string after the first
        // parse, necessitating the second parse
        return JSON.parse(JSON.parse(results.data.getBarcodeInfo))
    },
    getProcessableSamples: async (experiment: string): Promise<FlashSample[]> => {
        const results: any = await API.graphql(graphqlOperation(
            getProcessableFlashSamples, {experiment: experiment}))
        return results.data.getProcessableFlashSamples
    },
    processExperiment: async (experiment: string, cutoff: number, samples: string[]): Promise<any> => {
        const results: any = await API.graphql(graphqlOperation(startFlashAnalysis, {
            input: {
                experimentName: experiment,
                transferQvalueCutoff: cutoff.toString(),
                samples
            }
        }))
        console.log('MY RESULTS: ', results)
        return results.data.startFlashAnalysis
    },
    fetchStats: async (experiment: string): Promise<FlashStatistics> => {
        console.log(`DO fetchStats for ${experiment}`)
        const results: any = await API.graphql(graphqlOperation(getFlashStats, {experiment: experiment}))
        console.log('Flash STATS RESULTS', results)
        return results.data.getFlashStats
    },
    hasTcanFile: async (key: string): Promise<boolean> => {
        const results: any = await API.graphql(graphqlOperation(keyExists,
            {key: key, bucketType: 'FLASH'}))
        return results.data.keyExists
    }
}

export default flashService