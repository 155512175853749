import {API, graphqlOperation} from 'aws-amplify';
import * as queries from '../graphql/queries';
import {updateLcmsInfo} from '../graphql/mutations'
import {LcmsFilters, LcmsStatus} from '../API';

const lcmsStatus = {
    fetchData: async (filters: LcmsFilters): Promise<Array<LcmsStatus | null > | null> => {
        const result: any = await API.graphql(graphqlOperation(queries.getLcmsStatus,
            {filters: filters}));
        return result.data.getLcmsStatus.items
    },
    updateLcms: async (experiment: string, start: string, end: string, instrument: string) => {
        const result: any = await API.graphql(graphqlOperation(updateLcmsInfo,
            {experiment, start, end, instrument}))
        return result.data.updateLcmsInfo
    }
}

export default lcmsStatus