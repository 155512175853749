export const DesignTableDataCy = {
    container: 'create-experiment-samples-table',
    row: 'create-exp-row',
    tmt: 'create-exp-tmt-',
    cells: 'create-exp-cells-',
    genotype: 'create-exp-genotype-',
    compound: 'create-exp-compound-',
    batch: 'create-exp-batch-',
    concentration: 'create-exp-concentration-',
    concentrationUnit: 'create-exp-concentration-unit-',
    treatmentTime: 'create-exp-treatment-time-',
    treatmentTimeUnit: 'create-exp-treatment-time-unit-',
    type: 'create-exp-type-',
    taxon: 'create-exp-taxon-',
    description: 'create-exp-description-',
    extraCompoundDeleteButton: '-extra-compound-delete-'
};
